import LayoutAuthServiceStyleOne from '~/src/pages/auth/layout/LayoutAuthServiceStyleOne'
import LayoutAuthServiceStyleThree from '~/src/pages/auth/layout/LayoutAuthServiceStyleThree'
import LayoutAuthServiceStyleTwo from '~/src/pages/auth/layout/LayoutAuthServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getAuthLayout = (style: TemplateEnum, args: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutAuthServiceStyleTwo().getLayout(args)
    case TemplateEnum.STYLE_THREE:
      return new LayoutAuthServiceStyleThree().getLayout()
    default:
      return new LayoutAuthServiceStyleOne().getLayout(args)
  }
}

export default getAuthLayout
