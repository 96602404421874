import getLayoutTemplateService from '~/src/components/layout/styles/GetLayoutTemplateService'
import StylesLayoutServiceInterface from '~/src/components/layout/styles/StylesLayoutServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { useMemo } from 'react'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLayoutTemplate = (): StylesLayoutServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const layout = useEoValue(LAYOUT_STATE)

  return useMemo(() => (
    getLayoutTemplateService(pageStyle, muiTheme, firmTheme, layout)
  ), [ pageStyle, muiTheme, firmTheme, layout ])
}

export default useGetLayoutTemplate
