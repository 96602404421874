import LayoutFirmInfosServiceStyleOne from '~/src/pages/firm/infos/layout/LayoutFirmInfosServiceStyleOne'
import LayoutFirmInfosServiceStyleTwo from '~/src/pages/firm/infos/layout/LayoutFirmInfosServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getFirmInfosLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutFirmInfosServiceStyleTwo().getLayout(getLayoutArgs)
    default:
      return new LayoutFirmInfosServiceStyleOne().getLayout(getLayoutArgs)
  }
}

export default getFirmInfosLayout
