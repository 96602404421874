import { ListItemIcon, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import useGetSideDrawerNavigationItemTemplate
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/styles/useGetSideDrawerNavigationItemTemplate'
import Link from '~/src/components/mui-wrappers/Link'
import ListItemButton from '~/src/components/mui-wrappers/styled/list-item-button'
import SideBarListItem from '~/src/types/SideBarListItem'

interface Props {
  item: SideBarListItem,
}

const SideDrawerNavigationItem = ({ item }: Props) => {
  const styles = useGetSideDrawerNavigationItemTemplate()
  const [ selected, setSelected ] = useState<boolean>(false)

  const isSelected = (): boolean => {
    return location.href.includes(item.href)
  }

  useEffect(() => {
    setSelected(isSelected())
  }, [ location.href ])

  return (
    <Link
      className='eo-w-full'
      href={item.href}
      resolve={item.resolve}
      onClick={item.onClick}
      removeBaseLinkStyle
    >
      <ListItemButton
        selected={selected}
        sx={styles.getListItemButtonSx()}
      >
        {item?.startAdornment && (
          <ListItemIcon sx={styles.getListItemIconSx()}>
            {item.startAdornment}
          </ListItemIcon>
        )}
        <ListItemText
          primary={item.label}
        />
        {item?.endAdornment}
      </ListItemButton>
    </Link>
  )
}

export default SideDrawerNavigationItem
