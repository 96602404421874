import EnvironmentAbstract from './environment.abstract'

export default class Environment extends EnvironmentAbstract {
  public static getApiEndpoint(): string {
    return 'https://api.easyorderapp.com'
  }

  public static isSentryEnabled(): boolean {
    return true
  }
}
