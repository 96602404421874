enum Params {
  'REDIRECT_TO' = 'redirectTo',
  'MODAL' = 'modal',
  'DRAWER' = 'drawer',
  'VIEW_TYPE' = 'viewType',
  'MAP' = 'map',
  'LIST' = 'list',
  'SEARCH' = 'q',
}

export default Params
