import { SxProps } from '@mui/material'
import StylesShoppingCartActionsServiceInterface
  from '~/src/components/cart/shopping-cart-actions/styles/StylesShoppingCartActionsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartActionsServiceStyleOne extends TemplateServiceStyleBase implements StylesShoppingCartActionsServiceInterface {
  public getContainerSxProps(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      mt: 1
    }
  }

  public getLoginAndGoToCheckoutSxProps(): SxProps {
    return {
      width: 'inherit'
    }
  }

  public getGoToCheckoutSxProps(): SxProps {
    return {}
  }

  public getContinueAsGuestSxProps(): SxProps {
    return {
      color: 'text.black'
    }
  }
}
