import { SxProps } from '@mui/material'
import StylesMarketplaceSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMarketplaceSubHeaderServiceStyleTwo extends TemplateServiceStyleBase implements StylesMarketplaceSubHeaderServiceInterface {
  public getLinkSx(): SxProps {
    return {
      transition: 'var(--ease-in-out)',
      py: 1.5,
      '&:hover': {
        opacity: 0.5
      }
    }
  }

  public getHeaderMarketplaceSubHeaderSx(): SxProps {
    return {
      position: 'sticky',
      zIndex: 2,
      overflow: 'hidden',
      backgroundColor: 'background.main',
      color: 'background.contrastText',
      pl: 12,
      pb: 3,
      '.padding-left': {
        pl: 2
      },
      [this.muiTheme.breakpoints.down('md')]: {
        transition: 'all 0.25s ease-in-out',
        top: -1,
        width: '100%',
        px: 0,
        py: 2,
        '&.is-pinned': {
          pt: `calc(${this.muiTheme.spacing(2)} + var(--eo-safe-area-top))`,
          transition: 'all 0.25s ease-in'
        }
      }
    }
  }

  public getHeaderMarketplaceSubHeaderItemSx(): SxProps {
    return {
      border: 'none',
      padding: 0,
      margin: 0,
      borderWidth: 0,
      px: 3,
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      minWidth: 'unset',
      opacity: 1,
      background: 'transparent !important',
      '&:hover': {
        background: 'transparent !important',
        '&>div>span': {
          color: 'primary.main'
        }
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 1.5
      }
    }
  }
}
