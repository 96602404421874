import { Box, SxProps, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'
import FirmLoyaltyRewardPointsLeftToEarn
  from '~/src/components/profile/account/loyalty/reward-card/point-balance/left-to-earn'

interface Props {
  sx?: SxProps,
}

const FirmLoyaltyRewardPointBalance = ({ sx }: Props) => {
  const { t } = useTranslations()
  const loyaltyService = useLoyaltyService()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,
        ...sx
      }}
    >
      <Typography>{loyaltyService.getRequiredPoints()} {t('profile.loyalty.points')}</Typography>
      <FirmLoyaltyRewardPointsLeftToEarn />
    </Box>
  )
}

export default FirmLoyaltyRewardPointBalance
