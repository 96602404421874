import LayoutNotFoundServiceStyleOne from '~/src/pages/not-found/layout/LayoutNotFoundServiceStyleOne'
import LayoutNotFoundServiceStyleThree from '~/src/pages/not-found/layout/LayoutNotFoundServiceStyleThree'
import LayoutNotFoundServiceStyleTwo from '~/src/pages/not-found/layout/LayoutNotFoundServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getNotFoundLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutNotFoundServiceStyleTwo().getLayout()
    case TemplateEnum.STYLE_THREE:
      return new LayoutNotFoundServiceStyleThree().getLayout()
    default:
      return new LayoutNotFoundServiceStyleOne().getLayout()
  }
}

export default getNotFoundLayout
