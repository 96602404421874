import { FormikProps } from 'formik'
import { ThemeColorsFormInterface } from '~/src/components/layout/dev-mode/dialog'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import { Stack } from '@mui/material'

interface Props {
  form: FormikProps<ThemeColorsFormInterface>,
}

const SuccessColors = ({ form }: Props) => {
  return (
    <Stack
      direction='row'
      gap={2}
      flexWrap='wrap'
      sx={{
        '& > *': {
          flex: '1 1 100px'
        }
      }}>
      <FormikTextField
        TextFieldProps={{
          fullWidth: true,
          form,
          color: 'content',
          name: 'success_gradient_color_from',
          label: 'Success color gradient start:',
          type: 'color',
          size: 'small',
          helperText: form.values.success_gradient_color_from
        }}
      />
      <FormikTextField
        TextFieldProps={{
          form,
          color: 'content',
          name: 'success_gradient_color_to',
          label: 'success color gradient end:',
          type: 'color',
          size: 'small',
          helperText: form.values.success_gradient_color_to
        }}
      />
      <FormikTextField
        TextFieldProps={{
          form,
          color: 'content',
          name: 'success_gradient_contrast_color',
          label: 'success color gradient contrast:',
          type: 'color',
          size: 'small',
          helperText: form.values.success_gradient_contrast_color
        }}
      />
    </Stack>
  )
}

export default SuccessColors
