import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import FirmLoyaltyRewardDiscount from '~/src/components/profile/account/loyalty/reward-card/discount'
import FirmLoyaltyRewardProduct from '~/src/components/profile/account/loyalty/reward-card/product'
import FirmLoyaltyRewardCategory from '~/src/components/profile/account/loyalty/reward-card/category'
import Card from '~/src/components/mui-wrappers/Card'

const FirmLoyaltyRewardCard = () => {
  const theme = useCustomMuiTheme()
  const firm = useEoValue(FIRM_SELECTOR)
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)

  if (!isLoyaltyEnabled) {
    return null
  }

  return (
    <Card
      sx={{
        p: 1,
        '&:hover': {
          boxShadow: `0 0 5px ${theme.palette.primary.main}`
        }
      }}
    >
      {firm?.settings.loyalty?.loyalty_type === 'discount' && <FirmLoyaltyRewardDiscount />}
      {firm?.settings.loyalty?.loyalty_type === 'category' && <FirmLoyaltyRewardCategory />}
      {firm?.settings.loyalty?.loyalty_type === 'product' && <FirmLoyaltyRewardProduct />}
    </Card>
  )
}

export default FirmLoyaltyRewardCard
