import { styled, ToggleButton as MUIToggleButton, toggleButtonClasses } from '@mui/material'

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.root}`]: {
    textTransform: 'none',
    fontSize: '1em',
    fontWeight: 'normal',
    border: 0,
    borderTopLeftRadius: 'var(--btn-border-radius) !important',
    borderTopRightRadius: 'var(--btn-border-radius) !important',
    borderBottomLeftRadius: 'var(--btn-border-radius) !important',
    borderBottomRightRadius: 'var(--btn-border-radius) !important',
    transition: 'var(--ease-in-out)',
    color: 'inherit'
  },
  '&.Mui-disabled': {
    opacity: .4,
    cursor: 'not-allowed'
  },
  [`&.${toggleButtonClasses.primary}.${toggleButtonClasses.selected}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  [`&.${toggleButtonClasses.secondary}.${toggleButtonClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}))

export default ToggleButton
