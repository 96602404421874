import getSubHeaderTemplateService
  from '~/src/components/layout/sub-header/location-sub-header/styles/GetSubHeaderTemplateService'
import StylesSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetSubHeaderTemplate = (): StylesSubHeaderServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSubHeaderTemplateService(pageStyle, muiTheme)
}

export default useGetSubHeaderTemplate
