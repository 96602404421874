import useFetchCustomLinks from '~/src/hooks/firm/useFetchCustomLinks'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useEffect } from 'react'

const useCustomLinksFetchEffect = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const { fetch: fetchCustomLinks } = useFetchCustomLinks()

  useEffect(() => {
    void fetchCustomLinks()
  }, [ firm ])
}

export default useCustomLinksFetchEffect
