import { Day, PickupHour } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { buildOpeningHoursString } from '~/src/services/OpeningHourService'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  day: Day,
}

const FirmPickUpHour = ({ day }: Props) => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)
  const pickingUpHour = firm?.settings.periods.pickup_hours?.find((pickingUpHour: PickupHour) => pickingUpHour.day_id === day.day_id)

  if (!pickingUpHour) {
    return null
  }

  const pickingUpHourSentence = buildOpeningHoursString(pickingUpHour)
    .replace(/-/g, t('global.to').toLowerCase())
    .replace(/&/g, t('global.and').toLowerCase())

  if (pickingUpHourSentence === 'closed') {
    return null
  }

  return <Title variant='subtitle2'>{t('firm.pick_up_hours_from')} {pickingUpHourSentence}</Title>
}

export default FirmPickUpHour
