import { useTranslations } from '~/src/hooks/useTranslations'
import { Box } from '@mui/material'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  onClick(): void,
}

const SkipAnnouncementsButton = ({ onClick }: Props) => {
  const { t } = useTranslations()

  return (
    <Box sx={{ textAlign: 'left' }}>
      <Button
        onClick={onClick}
        disableRipple
        sx={{
          textTransform: 'none',
          minWidth: 0,
          fontWeight: 'normal'
        }}
      >
        {t('global.skip')}
      </Button>
    </Box>
  )
}

export default SkipAnnouncementsButton
