import { useLayoutEffect, useState } from 'react'
import { Constants } from '~/src/helpers/constants'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useWindowResize from '~/src/hooks/layout/useWindowResize'

interface ElementSize {
  width: number,
  height: number,
  scrollTop: number,
  scrollHeight: number,
  clientHeight: number,
}

const useGetDOMNodeProperties = (id: string | undefined): ElementSize & { element: HTMLElement | null } => {
  const layout = useEoValue(LAYOUT_STATE)
  const { windowSize: windowResizeHeight } = useWindowResize()
  const [ elementSize, setElementSize ] = useState<ElementSize>({
    width: 0,
    height: 0,
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  })
  const [ element, setElement ] = useState<HTMLElement | null>(null)
  let resizeObserver: ResizeObserver

  useLayoutEffect(() => {
    const getElement = () => {
      const element = id ? document.getElementById(id) : document.documentElement

      if (!element) {
        // If the element is not found, try again after a short delay
        setTimeout(getElement, Constants.DEBOUNCE_TIME)
        return
      }

      // Function to update the size
      const updateSizeAndElement = () => {
        setElement(element)
        setElementSize({
          width: element.offsetWidth,
          height: element.offsetHeight,
          scrollTop: element.scrollTop,
          scrollHeight: element.scrollHeight,
          clientHeight: element.clientHeight
        })
      }

      // Initial size update
      updateSizeAndElement()

      // Observer for element resize
      resizeObserver = new ResizeObserver(updateSizeAndElement)
      resizeObserver.observe(element)
    }

    getElement()

    // Cleanup on component unmount
    return () => {
      resizeObserver && resizeObserver.disconnect()
    }
  }, [
    id,
    layout,
    windowResizeHeight
  ])

  return {
    ...elementSize,
    element
  }
}

export default useGetDOMNodeProperties
