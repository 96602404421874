import {
  ApiConfiguration,
  DateUtils,
  Id,
  KnownEntitiesInterface,
  Product,
  ProductPromotion,
  ProductService as EOProductService
} from '@eo-storefronts/eo-core'

interface ProductAvailable {
  inStock: boolean,
  snoozed: boolean,
}

const fetchCategories = async (firmId: Id): Promise<KnownEntitiesInterface> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return EOProductService.getProductData()
}

const getFirstAvailableProduct = (products: Record<Id, Product>): Product|null => {
  for (const id in products) {
    if (!products[id]) {
      continue
    }

    if (!products[id].categoryId) {
      continue
    }

    return products[id]
  }

  return null
}

const clearHeaders = (): void => {
  [ 'storeId', 'firmId' ].forEach((key: string) => {
    ApiConfiguration.deleteHeader(key)
  })
}

const checkProductAvailability = (product: Product, time: Date): ProductAvailable => {
  if (!product.inStock) {
    return {
      inStock: false,
      snoozed: false
    }
  }

  if (product.snooze?.start && product.snooze?.end) {
    const snoozed = DateUtils.isAfter(time, new Date(product.snooze.start))
      && DateUtils.isBefore(time, new Date(product.snooze.end))

    return {
      inStock: true,
      snoozed
    }
  }

  return {
    inStock: true,
    snoozed: false
  }
}

const _getProductInterval = (product: Product): number => {
  return product.unit?.id === 4 ? parseFloat((product.unitInterval / 1000).toFixed(3)) : product.unitInterval
}

const _getProductMinQuantity = (product: Product): number | undefined => {
  if (!product.min) {
    return
  }

  return product.unit?.id === 4 ? parseFloat((product.min / 1000).toFixed(3)) : product.min
}

const _getProductMaxQuantity = (product: Product): number | undefined => {
  if (!product.max) {
    return
  }

  return product.unit?.id === 4 ? parseFloat((product.max / 1000).toFixed(3)) : product.max
}

const convertProductUnitKgInGr = (product: Product | null): Pick<Product, 'unitInterval'|'min'|'max'> => {
  return {
    unitInterval: product ? _getProductInterval(product) : 0,
    min: product ? _getProductMinQuantity(product) : 0,
    max: product ? _getProductMaxQuantity(product) : 0
  }
}

const _productPromotionIsActive = (promotion: ProductPromotion): boolean => {
  const startDate: Date = new Date(promotion.from)
  const endDate: Date = new Date(promotion.till)

  return DateUtils.dateIsBetween(new Date(), { start: startDate, end: endDate }, 'day', '[]')
}

const productHasPromotion = (product: Product): boolean => {
  if (!product.hasPromotionalPrice || !product.promotion) {
    return false
  }

  return _productPromotionIsActive(product.promotion)
}

const getProductPrice = (product: Product): number => {
  return productHasPromotion(product) ? product.promotion!.price : product.price
}

const searchProductsByName = (search: string, lang: string, products: Record<Id, Product>): string[] => {
  if (search === '') {
    return []
  }

  const searchResults: string[] = []

  for (const productId in products) {
    if (!products[productId]) {
      continue
    }

    if ((products[productId].name[lang] || '').toLowerCase().indexOf(search.toLowerCase()) === -1) {
      continue
    }

    searchResults.push(productId)
  }

  return searchResults
}

export {
  fetchCategories,
  productHasPromotion,
  getFirstAvailableProduct,
  checkProductAvailability,
  convertProductUnitKgInGr,
  searchProductsByName,
  getProductPrice
}
