import getHeaderCartTemplateService
  from '~/src/components/layout/header/header-cart/styles/GetHeaderCartTemplateService'
import StylesHeaderCartServiceInterface
  from '~/src/components/layout/header/header-cart/styles/StylesHeaderCartServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetHeaderCartTemplate = (): StylesHeaderCartServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getHeaderCartTemplateService(pageStyle, muiTheme)
}

export default useGetHeaderCartTemplate
