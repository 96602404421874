import { ReactNode } from 'react'
import TakeawayIcon from '~/src/components/marketplace/order-buttons/TakeawayIcon'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  href: string,
  children: ReactNode,
}

const TakeawayOrderButton = ({ children, href }: Props) => {
  return (
    <a href={href} target="_blank">
      <Button
        fullWidth
        sx={{
          backgroundColor: 'var(--takeaway-color)',
          color: 'white',
          py: 1.25,
          textTransform: 'none',
          ':hover': {
            color: 'var(--takeaway-color)',
            svg: {
              'path:first-of-type': {
                fill: 'transparent'
              },
              'path:last-of-type': {
                fill: 'var(--takeaway-color)'
              }
            }
          }
        }}
        startIcon={<TakeawayIcon/>}
      >
        {children}
      </Button>
    </a>
  )
}

export default TakeawayOrderButton
