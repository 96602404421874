import { ApiConfiguration, Customer, CustomerAuthResponse, Firm, HttpError, Id } from '@eo-storefronts/eo-core'
import { useContext, useState } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import usePushNotifications from '~/src/hooks/push-notifications/usePushNotifications'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import useGetQueryParam from '~/src/hooks/router/useGetQueryParam'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum, { RoutesEnumType } from '~/src/router/enums/routes.enum'
import { loginAsCustomer } from '~/src/services/AuthenticationService'
import { AuthenticationState, IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import Credentials from '~/src/types/Credentials'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'
import useDialog from '~/src/hooks/useDialog'
import useSetCustomerState from '~/src/hooks/customer/useSetCustomerState'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'

interface ReturnsType {
  login(credentials: Credentials): void,
  error: string | null,
  loading: boolean,
}

const useLoginAsCustomer = (redirectAfterLogin = true): ReturnsType => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { params: redirectTo } = useGetQueryParam(Params.REDIRECT_TO)
  const { initPushNotifications } = usePushNotifications()
  const { setAuthState } = useSetAuthState()
  const { dismiss } = useDialog()
  const { setCustomer } = useSetCustomerState()
  const [ error, setError ] = useState<string | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)
  const isUsingNewBrandAuthSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const login = (credentials: Credentials) => {
    setError(null)
    setLoading(true)

    loginAsCustomer(credentials, isUsingNewBrandAuthSystem, brand?.id).then(async (response: CustomerAuthResponse) => {
      if (!response.data.id || !response.data.uuid || !response.data?.customerToken) {
        return
      }

      const authState: Partial<AuthenticationState> = {
        guest: undefined,
        customer: {
          id: response.data.id,
          uuid: response.data.uuid,
          expiresIn: 3600,
          token: response.data?.customerToken ?? ''
        },
        loggedInAs: 'customer'
      }

      const customerId: Id = response.data.id

      void initPushNotifications(customerId)

      setLoading(true)

      ApiConfiguration.addOrReplaceHeader('customerToken', authState.customer?.token)

      const customer: Customer | null = await setCustomer(authState)

      setAuthState(authState)

      responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose({
        successfullyLoggedIn: true,
        customer
      })

      dismiss && dismiss('login-form-dialog')

      if (redirectAfterLogin) {
        if (!redirectTo) {
          push(resolve(fallbackPage))
        } else if (redirectTo === 'ACCOUNT') {
          push(resolve(`${RoutesEnum.PROFILE}/${RoutesEnum.ACCOUNT}`))
        } else {
          push(resolve(RoutesEnum[redirectTo as keyof RoutesEnumType]))
        }
      }
    }).catch((httpError: HttpError) => {
      setError(httpError.message)
    }).finally(() => setLoading(false))
  }

  return { login, error, loading }
}

export {
  useLoginAsCustomer
}
