import StylesShoppingCartItemServiceInterface
  from '~/src/components/cart/shopping-cart-item/styles/StylesShoppingCartItemServiceInterface'
import StylesShoppingCartItemServiceStyleOne
  from '~/src/components/cart/shopping-cart-item/styles/StylesShoppingCartItemServiceStyleOne'
import StylesShoppingCartItemServiceStyleTwo
  from '~/src/components/cart/shopping-cart-item/styles/StylesShoppingCartItemServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getShoppingCartItemTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesShoppingCartItemServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesShoppingCartItemServiceStyleTwo(muiTheme)
    default:
      return new StylesShoppingCartItemServiceStyleOne(muiTheme)
  }
}

export default getShoppingCartItemTemplateService
