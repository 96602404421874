import StylesSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceInterface'
import StylesSubHeaderServiceStyleOne
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceStyleOne'
import StylesSubHeaderServiceStyleThree
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceStyleThree'
import StylesSubHeaderServiceStyleTwo
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getSubHeaderTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesSubHeaderServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesSubHeaderServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesSubHeaderServiceStyleThree(muiTheme)
    default:
      return new StylesSubHeaderServiceStyleOne(muiTheme)
  }
}

export default getSubHeaderTemplateService
