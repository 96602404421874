import { DateUtils, Interval, Timeslot } from '@eo-storefronts/eo-core'
import { createSelector, createState } from '~/src/hooks/useEoState'
import { LOCATOR_STATE } from '~/src/stores/locator'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

export const TIMESLOTS_STATE = createState<Record<string, Timeslot[]>>({
  key: 'timeslotsState',
  default: { }
})

export const CURRENT_TIMESLOT_KEY_SELECTOR = createSelector<string>({
  key: 'currentTimeslotKey',
  get: ({ get }): string => {
    const firm = get(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const { firmSelected } = get(LOCATOR_STATE)
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const pickupPoint = get(CHECKOUT_PICKUP_POINT_SELECTOR)

    if (!checkoutForm.delivery_method.time || !checkoutForm.delivery_method.method) {
      return ''
    }

    let key: string = DateUtils.momentFormat(new Date(checkoutForm.delivery_method.time), 'YYYY-MM-DD')
    key += `-${checkoutForm.delivery_method.method}-${firm?.id || firmSelected?.id || ''}`

    if (pickupPoint?.id) {
      key += `-${pickupPoint.id}`
    }

    return key
  }
})

export const TIMESLOT_FOR_SELECTED_DATE_SELECTOR = createSelector<Timeslot[]>({
  key: 'timeslotForSelectedDateSelector',
  get: ({ get }): Timeslot[] => {
    const timeslots = get(TIMESLOTS_STATE)
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const currentTimeslotKey = get(CURRENT_TIMESLOT_KEY_SELECTOR)

    if (
      !checkoutForm.delivery_method.time
      || !checkoutForm.delivery_method.minDate
    ) {
      return []
    }

    const slots = timeslots[currentTimeslotKey] ?? []

    if (DateUtils.isSameDate(
      new Date(checkoutForm.delivery_method.minDate),
      new Date(checkoutForm.delivery_method.time),
      'day')
    ) {
      return slots.filter((slot: Timeslot) => {
        const [ h, m ] = slot.to_time.split(':')
        const minDate = new Date(checkoutForm.delivery_method.minDate!)
        const selectedTime = new Date(checkoutForm.delivery_method.time!)

        selectedTime.setHours(parseInt(h))
        selectedTime.setMinutes(parseInt(m))

        return DateUtils.isAfter(selectedTime, minDate)
      })
    }

    return slots
  }
})

export const INTERVALS_STATE = createState<Record<number, Interval[]>>({
  key: 'intervalsState',
  default: { }
})

export const INTERVALS_FOR_TIMESLOT_ID_SELECTOR = createSelector<Interval[]>({
  key: 'intervalsForTimeslotIdSelector',
  get: ({ get }): Interval[] => {
    const intervals = get(INTERVALS_STATE)
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    if (!checkoutForm.delivery_method.timeslotId) {
      return []
    }

    return intervals[Number(checkoutForm.delivery_method.timeslotId)] ?? []
  }
})
