import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'

const CartItemsCount = () => {
  const { t } = useTranslations()
  const totalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)

  return (
    <span>{`${totalItems} ${t('cart.articles').toLowerCase()}`}</span>
  )
}

export default CartItemsCount
