import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesExpandableSearchButtonServiceInterface
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/StylesExpandableSearchButtonServiceInterface'
import getExpandableSearchButtonTemplateService
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/GetExpandableSearchButtonTemplateService'

const useGetExpandableSearchButtonTemplate = (): StylesExpandableSearchButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getExpandableSearchButtonTemplateService(pageStyle, muiTheme)
}

export default useGetExpandableSearchButtonTemplate
