import { ReactNode } from 'react'
import UberEatsIcon from '~/src/components/marketplace/order-buttons/UberEatsIcon'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  href: string,
  children: ReactNode,
}

const UberEatsOrderButton = ({ children, href }: Props) => {
  return (
    <a href={href} target='_blank'>
      <Button
        fullWidth
        sx={{
          width: '100%',
          backgroundColor: 'var(--uber-eats-color)',
          color: '#FFFFFF',
          py: 1.25,
          textTransform: 'none',
          ':hover': {
            color: 'var(--uber-eats-color)',
            svg: {
              'path:first-of-type': {
                fill: 'black'
              },
              'path:last-of-type': {
                fill: 'var(--uber-eats-color)'
              }
            }
          }
        }}
        startIcon={<UberEatsIcon />}
      >
        {children}
      </Button>
    </a>
  )
}

export default UberEatsOrderButton
