import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import EatInModalActions from '~/src/components/eat-in/eat-in-modal/eat-in-modal-actions'
import EatInModalContent from '~/src/components/eat-in/eat-in-modal/eat-in-modal-content'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { isOnHoliday, isOpened } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CheckoutFormState, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface TableState {
  method: number|null,
  number: string|null,
}

const EatInModal = () => {
  const { t } = useTranslations()
  const { pathname } = useLocation()
  const params = useParams()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const firm = useEoValue(FIRM_SELECTOR)
  const setCheckout = useSetEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const setFrozenDeliveryMethod = useSetEoState<boolean>(FROZEN_DELIVERY_METHOD_STATE)
  const [ opened, setOpened ] = useState<boolean>(pathname.includes(RoutesEnum.EAT_IN))
  const [ table, setTable ] = useState<TableState>({
    method: null,
    number: null
  })

  const handleOnAccept = () => {
    setFrozenDeliveryMethod(
      !!firm 
      && isOpened(firm.settings.periods.opening_hours) 
      && !isOnHoliday(new Date(), firm.settings.periods.holiday_period)
    )
    setCheckout((checkout: CheckoutFormState) => ({
      ...checkout,
      delivery_method: {
        ...checkout.delivery_method,
        method: DeliveryMethodNames.ON_THE_SPOT,
        table_method: table.method,
        table_number: table.number
      }
    }))
    setOpened(false)
    push(resolve(RoutesEnum.MENU), { modal: true })
  }

  useEffect(() => {
    if (!pathname.includes(RoutesEnum.EAT_IN)) {
      return
    }

    if (params.tableMethod === 'table' && params.tableNumber) {
      setTable({
        method: null,
        number: params.tableNumber
      })
    } else if (params.tableMethod !== 'table') {
      setTable({
        method: Number(params.tableMethod),
        number: params.tableNumber ?? null
      })
    }
  }, [ pathname ])

  return (
    <ResponsiveDialog
      openByDefault={opened}
      persistent
      Title={<Title>{t('delivery_methods.table')}</Title>}
      Actions={<EatInModalActions onAccept={handleOnAccept} />}
      maxWidth='xs'
    >
      <EatInModalContent method={table.method} number={table.number} />
    </ResponsiveDialog>
  )
}

export default EatInModal
