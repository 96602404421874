import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR, LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR } from '~/src/stores/loyalty'
import { PRODUCTS_LOYALTY_REWARDS_SELECTOR } from '~/src/stores/product'
import useDialog from '~/src/hooks/useDialog'
import LoyaltyProductsResponsiveDialog from '~/src/components/products/modals/loyalty'

interface ReturnsType {
  checkLoyaltyProductIsSelected(): boolean,
}

const useLoyaltyProductsModal = (): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const loyaltyDiscountCanBeApplied = useEoValue(LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR)
  const loyaltyTypeIsFreeProduct = useEoValue(LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR)
  const productsLoyaltyRewards = useEoValue(PRODUCTS_LOYALTY_REWARDS_SELECTOR)
  const { present } = useDialog()

  const checkLoyaltyProductIsSelected = (): boolean => {
    const mustOpen = loyaltyDiscountCanBeApplied
      && loyaltyTypeIsFreeProduct
      && !checkoutForm.loyaltyProductId
      && !!productsLoyaltyRewards.length

    if (mustOpen) {
      present(
        LoyaltyProductsResponsiveDialog,
        'loyalty-products-modal'
      )
    }

    return mustOpen
  }

  return {
    checkLoyaltyProductIsSelected
  }
}

export default useLoyaltyProductsModal
