import { Address, Customer, Id } from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { addCustomerAddress } from '~/src/services/CustomerService'
import { DefaultValue } from 'recoil'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { AUTHENTICATION_STATE, IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'

const key = 'customerState'

export const CUSTOMER_STATE = createState<Customer | null>({
  key,
  default: null,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue: Customer | null, oldValue: DefaultValue | Customer | null) => {
        if (oldValue !== null || !newValue?.id || !newValue?.uuid) {
          return
        }

        const checkoutForm: CheckoutFormState = await getPromise(CHECKOUT_FORM_STATE)
        const address = checkoutForm.delivery_method.address

        if (address && !address.id) {
          try {
            await addCustomerAddress(newValue.id, newValue.uuid as string, {
              ...address,
              description: address.street
            })
          } catch (e) {
            // Ignore
          }
        }
      })
    }
  ]
})

export const CUSTOMER_LOYALTY_REACHED_SELECTOR = createSelector<boolean>({
  key: 'customerLoyaltyReachedSelector',
  get: ({ get }) => {
    const loyaltyIsEnabledAndCustomerIsLoggedIn = get(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
    const customer = get(CUSTOMER_STATE)

    if (!loyaltyIsEnabledAndCustomerIsLoggedIn) {
      return false
    }

    return customer!.loyalty?.add_loyalty_to_order || false
  }
})

export const CUSTOMER_ADDRESS_BY_ID = createSelectorFamily({
  key: 'customerAddressById',
  get: (id: number) => ({ get }) => {
    const customer = get(CUSTOMER_STATE)

    return customer?.addresses?.find((address: Address) => address.id === id)
  }
})

export interface CustomerLoadingState {
  customer: boolean,
  address: boolean,
  addresses: boolean,
}

const loaderKey = 'customerLoadingState'

export const CUSTOMER_LOADING_STATE = createState<CustomerLoadingState>({
  key: loaderKey,
  default: {
    customer: false,
    address: false,
    addresses: false
  }
})

export const IS_FETCHING_CUSTOMER_SELECTOR = createSelectorFamily({
  key: 'isFetchingCustomerData',
  get: (key: keyof CustomerLoadingState) => ({ get }): boolean => {
    const loadingState = get<CustomerLoadingState>(CUSTOMER_LOADING_STATE)

    return loadingState[key]
  }
})

export const GET_CUSTOMER_ID_SELECTOR = createSelector<Id>({
  key: 'getCustomerIdSelector',
  get: ({ get }): Id => {
    const isLoggedInAsCustomer = get(IS_LOGGED_IN_SELECTOR)
    const customer: Customer | null = get(CUSTOMER_STATE)

    if (isLoggedInAsCustomer) {
      return customer?.id || 0
    }

    return 1
  }
})

export const GET_CUSTOMER_UUID_SELECTOR = createSelector<string>({
  key: 'getCustomerUuidSelector',
  get: ({ get }): string => {
    const authState = get(AUTHENTICATION_STATE)
    const isLoggedInAsCustomer = get(IS_LOGGED_IN_SELECTOR)
    const customer: Customer | null = get(CUSTOMER_STATE)

    if (isLoggedInAsCustomer) {
      return customer?.uuid ?? ''
    }

    return authState.guest?.uuid ?? ''
  }
})

export const GET_CUSTOMER_QUICK_AUTH_ID_SELECTOR = createSelector<string | null | undefined>({
  key: 'getCustomerQuickAuthIdSelector',
  get: ({ get }): string | null | undefined => {
    return get(CUSTOMER_STATE)?.quick_auth_id
  }
})

export const IS_CUSTOMER_DEVELOPER = createSelector<boolean>({
  key: 'isCustomerDeveloper',
  get: ({ get }): boolean => {
    return get(CUSTOMER_STATE)?.email.includes('@easyorderapp.com') || false
  }
})
