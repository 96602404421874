import StylesProductItemServiceInterface
  from '~/src/components/products/product-item/styles/StylesProductItemServiceInterface'
import StylesProductItemServiceStyleFour
  from '~/src/components/products/product-item/styles/StylesProductItemServiceStyleFour'
import StylesProductItemServiceStyleOne
  from '~/src/components/products/product-item/styles/StylesProductItemServiceStyleOne'
import StylesProductItemServiceStyleTwo
  from '~/src/components/products/product-item/styles/StylesProductItemServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getProductItemTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme, layout?: LayoutState): StylesProductItemServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProductItemServiceStyleTwo(muiTheme, firmTheme, layout)
    case TemplateEnum.STYLE_FOUR:
      return new StylesProductItemServiceStyleFour(muiTheme, firmTheme, layout)
    default:
      return new StylesProductItemServiceStyleOne(muiTheme, firmTheme, layout)
  }
}

export default getProductItemTemplateService
