import { Language } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  language: Language,
}

const SideDrawerLanguageToggle = ({ language }: Props) => {
  const { t } = useTranslations()

  return <>{t(`global.language.${language.code}`)}</>
}

export default SideDrawerLanguageToggle
