import { ListItem } from '@mui/material'

interface Props {
  error: string,
}

const ErrorListItem = ({ error }: Props) => {
  return (
    <ListItem
      sx={{
        fontSize: '0.8em',
        p: 0.5
      }}>
      - {error}
    </ListItem>
  )
}

export default ErrorListItem
