import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import ProductUnitPriceTemplateServiceInterface
  from '~/src/components/products/product-unit-price/styles/ProductUnitPriceTemplateServiceInterface'
import ProductUnitPriceTemplateServiceStyleOne
  from '~/src/components/products/product-unit-price/styles/ProductUnitPriceTemplateServiceStyleOne'
import ProductUnitPriceTemplateServiceStyleTwo
  from '~/src/components/products/product-unit-price/styles/ProductUnitPriceTemplateServiceStyleTwo'
import ProductUnitPriceTemplateServiceStyleFour
  from '~/src/components/products/product-unit-price/styles/ProductUnitPriceTemplateServiceStyleFour'

const getProductUnitPriceTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): ProductUnitPriceTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_FOUR:
      return new ProductUnitPriceTemplateServiceStyleFour(muiTheme)
    case TemplateEnum.STYLE_TWO:
      return new ProductUnitPriceTemplateServiceStyleTwo(muiTheme)
    default:
      return new ProductUnitPriceTemplateServiceStyleOne(muiTheme)
  }
}

export default getProductUnitPriceTemplateService
