import getHeaderCurrentFirmTitleTemplateService
  from '~/src/components/layout/header/header-current-firm-title/styles/GetHeaderCurrentFirmTitleTemplateService'
import StylesHeaderCurrentFirmTitleServiceInterface
  from '~/src/components/layout/header/header-current-firm-title/styles/StylesHeaderCurrentFirmTitleServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useGetHeaderCurrentFirmTitleTemplate = (): StylesHeaderCurrentFirmTitleServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('header')

  return getHeaderCurrentFirmTitleTemplateService(componentStyle, muiTheme)
}

export default useGetHeaderCurrentFirmTitleTemplate
