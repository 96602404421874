import { CustomerAuthBaseResponse, Firm } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkEmail, checkEmailV2 } from '~/src/services/CustomerService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  check(email: string, google?: boolean): Promise<CustomerAuthBaseResponse | undefined>,
}

const useCustomerCheckEmail = (): ReturnsType => {
  const brand: Firm | null = useEoValue(BRAND_STATE)
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const isUsingNewBrandAuthSystem: boolean = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)

  const check = async (email: string, google?: boolean): Promise<CustomerAuthBaseResponse | undefined> => {
    if (isUsingNewBrandAuthSystem && brand?.id) {
      return checkEmailV2(brand.id, email, google)
    }

    if (firm?.id) {
      return checkEmail(firm.id, email, google)
    }
  }

  return { check }
}

export default useCustomerCheckEmail
