import { Modifier } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import ModifierService from '~/src/services/ModifierService'
import { CartModifierStateInterface } from '~/src/stores/cart'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'

interface ReturnsType {
  isModifierInStock(): boolean,
  isModifierSnoozed(): boolean,
  isModifierAvailable(): boolean,
  calculatePrice(cartModifier: CartModifierStateInterface): number,
}

const useModifiersService = (modifier: Modifier): ReturnsType => {
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)
  const modifierService: ModifierService = new ModifierService(modifier)

  const isModifierInStock = (): boolean => {
    return modifierService.isModifierInStock()
  }

  const isModifierSnoozed = (): boolean => {
    return modifierService.isModifierSnoozed(checkoutTime)
  }

  const isModifierAvailable = (): boolean => {
    return isModifierInStock() && !isModifierSnoozed()
  }

  const calculatePrice = (cartModifier: CartModifierStateInterface): number => {
    return modifierService.calculatePrice(cartModifier)
  }

  return {
    isModifierInStock,
    isModifierSnoozed,
    isModifierAvailable,
    calculatePrice
  }
}

export default useModifiersService
