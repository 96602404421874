import { useEffect } from 'react'

const useAsyncEffect = (effect: () => void, deps: unknown[]): void => {
  useEffect(() => {
    void effect()
  }, deps)
}

export {
  useAsyncEffect
}
