import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import CanPlaceOrderService from '~/src/services/CanPlaceOrderService'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { TIMESLOT_FOR_SELECTED_DATE_SELECTOR } from '~/src/stores/timeslots'

interface ReturnsType {
  checkAll(): boolean,
  checkOrderIsLimitedToSameDay(): boolean,
  checkOrdersOnlyDuringOpeningHours(): boolean,
  checkOrdersOnlyDuringOperationalHours(): boolean,
}

const useFirmAcceptsOrder = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const { firmSelected } = useEoValue<LocatorState>(LOCATOR_STATE)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const timeslots = useEoValue(TIMESLOT_FOR_SELECTED_DATE_SELECTOR)
  const canPlaceOrderService = new CanPlaceOrderService(
    firm || firmSelected || null,
    checkoutForm.delivery_method.method,
    timeslots
  )

  const checkAll = (): boolean => {
    return canPlaceOrderService.checkAll()
  }

  const checkOrderIsLimitedToSameDay = (): boolean => {
    return canPlaceOrderService.checkOrderIsLimitedToSameDay()
  }

  const checkOrdersOnlyDuringOpeningHours = (): boolean => {
    return canPlaceOrderService.checkOrdersOnlyDuringOpeningHours()
  }

  const checkOrdersOnlyDuringOperationalHours = (): boolean => {
    return canPlaceOrderService.checkOrdersOnlyDuringOperationalHours()
  }

  return {
    checkAll,
    checkOrderIsLimitedToSameDay,
    checkOrdersOnlyDuringOpeningHours,
    checkOrdersOnlyDuringOperationalHours
  }
}

export {
  useFirmAcceptsOrder
}
