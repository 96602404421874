import { TemplateEnum } from '@eo-storefronts/eo-core'
import DeliveryMethodsSummaryContainerStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-container/delivery-methods-summary-container-style-one'
import DeliveryMethodsSummaryContainerStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-container/delivery-methods-summary-container-style-two'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const DeliveryMethodsSummaryContainer = () => {
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  switch (componentStyle) {
    case TemplateEnum.STYLE_TWO:
      return <DeliveryMethodsSummaryContainerStyleTwo/>
    default:
      return <DeliveryMethodsSummaryContainerStyleOne/>
  }
}

export default DeliveryMethodsSummaryContainer
