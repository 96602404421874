import Title from '~/src/components/mui-wrappers/Title'
import { DateUtils } from '@eo-storefronts/eo-core'

interface Props {
  time: string,
}

const DeliveryMethodDatePickerToolbar = ({ time }: Props) => (
  <Title
    sx={{
      p: 0,
      pt: 2,
      textAlign: 'center'
    }}
    variant={'h3'}
  >
    {DateUtils.momentFormat(new Date(time), 'DD MMMM')}
  </Title>
)

export default DeliveryMethodDatePickerToolbar
