import { useContext } from 'react'
import { ConfirmDialogContext, ConfirmDialogContextOptions } from '~/src/components/providers/ConfirmDialogProvider'

interface ReturnsType {
  triggerConfirmDialog(options: ConfirmDialogContextOptions): void,
}

const useConfirmDialog = (): ReturnsType => {
  const context = useContext(ConfirmDialogContext)

  const triggerConfirmDialog = (options: ConfirmDialogContextOptions): void => {
    context.triggerConfirmDialog(options)
  }

  return { triggerConfirmDialog }
}

export {
  useConfirmDialog
}
