import { DeliveryMethodNames, Firm, OrderOnSpotCustomMethod } from '@eo-storefronts/eo-core'

const buildEatInSentence = (firm: Firm|null, baseString: string, tableMethod: number|null, tableNumber: string|null): string => {
  const eatInLocation = firm?.settings.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.custom_methods?.find(
    (customMethod: OrderOnSpotCustomMethod) => customMethod.id === tableMethod
  )

  if (!firm) {
    return ''
  }

  if (tableMethod === null && tableNumber) {
    return `${baseString}: ${firm!.labels!.at_table} (${firm!.labels!.table_number} ${tableNumber})`
  }

  if (tableMethod === null) {
    return `${baseString}: ${firm!.labels!.at_table}`
  }

  if (eatInLocation && tableNumber) {
    return `${baseString}: ${eatInLocation.name} (${eatInLocation.location_number_name} ${tableNumber})`
  }

  if (eatInLocation) {
    return `${baseString}: ${eatInLocation.name}`
  }

  return baseString
}

export {
  buildEatInSentence
}
