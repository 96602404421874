import StylesLinkTabServiceInterface
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLinkTabServiceStyleOne extends TemplateServiceStyleBase implements StylesLinkTabServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      maxWidth: '100%',
      whiteSpaces: 'nowrap',
      minHeight: '48px',
      height: '48px'
    }
  }
}
