import { useEoValue } from '~/src/hooks/useEoState'
import { DIALOGS_OVERLAY } from '~/src/stores/dialogs_overlay'
import DialogsOverlay from '~/src/types/DialogsOverlay'
import { useMemo } from 'react'

const DialogsRoot = () => {
  const dialogs = useEoValue(DIALOGS_OVERLAY)

  return useMemo(() => (
    <>
      {dialogs.map(({ component: Component, name, options }: DialogsOverlay) => (
        <Component key={name} {...options} />
      ))}
    </>
  ), [ dialogs ])
}

export default DialogsRoot
