import { MenuProps } from '@mui/material'
import { useContext } from 'react'
import DropdownContext from '~/src/components/mui-wrappers/dropdown/DropdownContext'
import Menu from '~/src/components/mui-wrappers/styled/menu'

const DropdownMenu = (props: Partial<MenuProps>) => {
  const dropdownContext = useContext(DropdownContext)

  const handleOnClose = () => {
    dropdownContext.handleClose && dropdownContext.handleClose()
  }

  return (
    <Menu
      open={dropdownContext.open}
      anchorEl={dropdownContext.anchorEl}
      onClick={handleOnClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  )
}

export default DropdownMenu

