import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications'
import PushNotificationDialog, { PushNotificationDialogProps } from '~/src/components/dialogs/push-notification'
import useDialog from '~/src/hooks/useDialog'

interface ReturnsType {
  presentModal(notification: PushNotificationSchema): Promise<void>,
}

const usePushNotificationsModal = (): ReturnsType => {
  const { present } = useDialog()

  const presentModal = async (notification: PushNotificationSchema): Promise<void> => {
    const title = notification.data.aps?.alert?.data?.title || notification.data?.title
    const body = notification.data.aps?.alert?.data?.body || notification.data?.body
    const buttonText = notification.data.aps?.alert?.data?.buttonText || notification.data?.buttonText
    const redirectTo =  notification.data.aps?.alert?.data?.redirectTo || notification.data?.redirectTo

    if (title && body) {
      await PushNotifications.removeAllDeliveredNotifications()

      present<PushNotificationDialogProps>(
        PushNotificationDialog,
        'push-notification',
        {
          title,
          body,
          buttonText,
          redirectTo
        }
      )
      return
    }
  }

  return  { presentModal }
}

export default usePushNotificationsModal
