import { Address } from '@eo-storefronts/eo-core'
import LoginFormDialog from '~/src/components/auth/login/LoginFormDialog'
import LoginFormDialogActivator from '~/src/components/auth/login/LoginFormDialogActivator'
import AddressForm from '~/src/components/customers/addresses/AddressForm'
import Divider from '~/src/components/mui-wrappers/Divider'
import useIsLocatorPage from '~/src/hooks/router/useIsLocatorPage'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'

interface Props {
  address: Address | null,
  onFill(address: Address): void,
}

const FillDeliveryAddress = ({ address, onFill }: Props) => {
  const isLocatorPage = useIsLocatorPage()
  const isLoggedIn = useEoValue<boolean>(IS_LOGGED_IN_SELECTOR)

  const handleOnSubmit = (address: Address) => {
    onFill(address)
  }

  return (
    <>
      <AddressForm
        address={address}
        showDescription={false}
        showSetAsDefault={false}
        onSubmit={handleOnSubmit}
      />
      {(!isLocatorPage && !isLoggedIn) && (
        <>
          <Divider />
          <LoginFormDialog activator={<LoginFormDialogActivator />} />
        </>
      )}
    </>
  )
}

export default FillDeliveryAddress
