import { Box } from '@mui/material'
import { ReactNode } from 'react'
import ErrorList from '~/src/components/error/ErrorList'
import ErrorListItem from '~/src/components/error/ErrorListItem'

interface Props {
  errors?: string | Record<string, string[]>,
  children?: ReactNode,
}

const FormError = ({ children, errors }: Props) => {
  return (
    <Box
      sx={{
        padding: '8px 16px',
        color: 'rgb(42, 50, 52)',
        backgroundColor: 'rgb(251, 224, 224)',
        borderRadius: '0px',
        marginBottom: '16px'
      }}
    >
      { children }

      { errors &&
        <>
          { typeof errors === 'string' && errors }

          { typeof errors !== 'string' && (
            <ErrorList>
              { Object.values(errors as Record<string, string[]>)
                .flat()
                .map((error: string, index: number) => <ErrorListItem key={index} error={error} />)
              }
            </ErrorList>
          )}
        </>
      }
    </Box>
  )
}

export default FormError
