import { DateUtils } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  format(date: Date): string,
}

const useDateFormatter = (): ReturnsType => {
  const locale = useEoValue(LANGUAGE_STATE)

  const format = (date: Date): string => {
    return DateUtils.format(date, locale)
  }

  return { format }
}

export default useDateFormatter
