import { Location, LocationFilter } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  location: Location,
}

const LocationFilters = ({ location }: Props) => {
  if (!location.filters?.length) {
    return null
  }

  return (
    <Title
      component='p'
      variant='subtitle2'
      sx={{
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        pr: 0.5
      }}
    >
      {location.filters
        .map((filter: LocationFilter) => filter.value.name)
        .join(' - ')}
    </Title>
  )
}

export default LocationFilters
