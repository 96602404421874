import { SxProps } from '@mui/material'
import StylesSideDrawerNavigationItemServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/styles/StylesSideDrawerNavigationItemServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSideDrawerNavigationItemServiceStyles extends TemplateServiceStyleBase implements StylesSideDrawerNavigationItemServiceInterface {
  public getListItemButtonSx(): SxProps {
    return {
      px: 0,
      py: .5,
      color: 'background.contrastText',
      '& svg': {
        fill: `${(this.muiTheme.palette.background as any).contrastText}`
      }
    }
  }

  public getListItemIconSx(): SxProps {
    return {
      mr: 2,
      minWidth: 0
    }
  }
}
