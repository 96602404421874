import { styled, Tab as MUITab, TabProps } from '@mui/material'
import useGetTabTemplate from '~/src/components/mui-wrappers/styled/tab/styles/useGetTabTemplate'

const Tab = styled((props: TabProps) => (
  <MUITab
    disableFocusRipple
    disableTouchRipple
    disableRipple
    {...props} />
))(() => useGetTabTemplate().getComponentSx())

export default Tab
