import { Id, OrderCalculateTime } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useProductsAndCategoriesFromCart } from '~/src/hooks/cart/useProductsAndCategoriesFromCart'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { confirmTime } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  confirmTimeRequest(time: string): Promise<OrderCalculateTime | null>,
  confirmCheckoutTime(time: string): Promise<boolean>,
}

const useConfirmTime = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const auth = useEoValue(AUTHENTICATION_STATE)
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const pickupPoint = useEoValue(CHECKOUT_PICKUP_POINT_SELECTOR)
  const { getCategoriesIds, getProductsIds } = useProductsAndCategoriesFromCart()

  const confirmTimeRequest = async (time: string): Promise<OrderCalculateTime | null> => {
    if (!firm || (!auth.guest?.id && !auth.customer?.id)) {
      return null
    }

    return confirmTime(
      firm.id,
      (auth.guest?.id || auth.customer?.id) as Id,
      {
        delivery_method: checkoutForm.delivery_method.method,
        timestamp: time,
        firm_pickup_point_id: pickupPoint?.id,
        categories: getCategoriesIds(),
        products: getProductsIds()
      }
    )
  }

  const confirmCheckoutTime = async (time: string): Promise<boolean> => {
    if (!firm || (!auth.guest?.id && !auth.customer?.id)) {
      return false
    }

    try {
      const confirmTimeResponse = await confirmTimeRequest(time)

      if (!confirmTimeResponse) {
        return false
      }

      if (!confirmTimeResponse.valid) {
        enqueueSnackbar(t('delivery_methods.time_not_valid'), { variant: 'error' })
        return false
      }

      return true
    } catch (e) {
      enqueueSnackbar(t('delivery_methods.could_not_validate_time'), { variant: 'error' })
    }

    return false
  }

  return {
    confirmTimeRequest,
    confirmCheckoutTime
  }
}

export {
  useConfirmTime
}
