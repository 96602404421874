import { Box, Button } from '@mui/material'
import { FormikProps } from 'formik'
import { ThemeColorsFormInterface } from '~/src/components/layout/dev-mode/dialog/index'

interface Props {
  form: FormikProps<ThemeColorsFormInterface>,
}

const DevModeDialogActions = ({ form }: Props) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 2
    }}>
      <Button
        type='submit'
        disabled={!form.dirty || !form.isValid}>
        Save
      </Button>
    </Box>
  )
}

export default DevModeDialogActions
