import { createState } from '~/src/hooks/useEoState'
import EoLinkProps from '~/src/types/EoLinkProps'

const key = 'backButtonState'

export interface BackButtonState extends Partial<EoLinkProps> {
  label?: string,
}

export const BACK_BUTTON_STATE = createState<BackButtonState | null>({
  key,
  default: null
})
