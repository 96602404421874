import { Location } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  location: Location,
}

const LocationName = ({ location }: Props) => {
  if (!location?.name) {
    return null
  }

  return (
    <Title
      variant='h6'
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}
    >
      {location.name}
    </Title>
  )
}

export default LocationName
