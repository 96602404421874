import { Box, Slide, SlideProps } from '@mui/material'
import { ReactNode, useRef } from 'react'

interface Props extends Omit<SlideProps, 'container' | 'children'> {
  children: ReactNode[] | ReactNode,
}

const SlideFromContainer = ({ children, ...props }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <Box
      ref={containerRef}
      sx={{
        overflow: 'hidden'
      }}>
      <Slide
        container={containerRef.current}
        {...props}
      >
        <Box>
          {children}
        </Box>
      </Slide>
    </Box>
  )
}

export default SlideFromContainer
