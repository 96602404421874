import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { format, isToday } from 'date-fns'
import { useMemo } from 'react'
import DeliveryMethodsSummaryMethodSentenceSplitter from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceSplitter'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface Props {
  showSplitter?: boolean,
}

const DeliveryMethodsSummaryMethodSentenceDate = ({ showSplitter = true }: Props) => {
  const { delivery_method } = useEoValue(CHECKOUT_FORM_STATE)

  const asapDateSentence = useMemo(() => {
    if (!delivery_method.time) {
      return ''
    }

    return ` ${format(new Date(delivery_method.time), 'dd/MM')}`
  }, [ delivery_method.time ])

  if (
    delivery_method.asap
    || delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
    || (delivery_method.time && isToday(new Date(delivery_method.time)))
  ) {
    return null
  }

  return (
    <span>
      {showSplitter && <DeliveryMethodsSummaryMethodSentenceSplitter/>}
      <CalendarMonthOutlinedIcon/>
      <>&nbsp;</>
      {asapDateSentence}
    </span>
  )
}

export default DeliveryMethodsSummaryMethodSentenceDate
