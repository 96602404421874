import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import LayoutNewsServiceStyleTwo from '~/src/pages/news/layout/LayoutNewsServiceStyleTwo'
import LayoutNewsServiceStyleThree from '~/src/pages/news/layout/LayoutNewsServiceStyleThree'
import LayoutNewsServiceStyleOne from '~/src/pages/news/layout/LayoutNewsServiceStyleOne'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getNewsLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutNewsServiceStyleTwo().getLayout(getLayoutArgs)
    case TemplateEnum.STYLE_THREE:
      return new LayoutNewsServiceStyleThree().getLayout()
    default:
      return new LayoutNewsServiceStyleOne().getLayout(getLayoutArgs)
  }
}

export default getNewsLayout
