import { Location } from '@eo-storefronts/eo-core'
import { Card, CardProps } from '@mui/material'
import { useContext } from 'react'
import LocationCardContent from '~/src/components/marketplace/locations/location-card/location-card-content'
import LocationCardMedia from '~/src/components/marketplace/locations/location-card/location-card-media'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useSetEoState } from '~/src/hooks/useEoState'
import { MARKETPLACE_VIEWED_RECENTLY_STATE } from '~/src/stores/marketplace/viewed-recently'

interface Props extends CardProps {
  location: Location,
  displayOpeningHours?: boolean,
  displayFilters?: boolean,
  direction?: 'horizontal' | 'vertical',
}

const LocationCard = ({
  location,
  displayOpeningHours = false,
  displayFilters = false,
  direction = 'vertical',
  sx = {},
  ...cardProps
}: Props) => {
  const muiTheme = useCustomMuiTheme()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const setViewedRecently = useSetEoState(MARKETPLACE_VIEWED_RECENTLY_STATE)

  const _handleClick = () => {
    if (!responsiveDialogContext?.toggleOpen) {
      return
    }

    responsiveDialogContext.toggleOpen()
    setViewedRecently((state: Location[]) => ([
      location,
      ...state
    ]))
  }

  return (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 0,
        display: 'flex',
        flexDirection: direction === 'vertical' ? 'column' : 'row',
        width: direction === 'vertical' ? '250px' : 'unset',
        [muiTheme.breakpoints.down('sm')]: {
          width: direction === 'vertical' ? '200px' : 'unset'
        },
        [muiTheme.breakpoints.up('xl')]: {
          width: direction === 'vertical' ? '350px' : 'unset'
        },
        ...sx
      }}
      onClick={_handleClick}
      {...cardProps}
    >
      <LocationCardMedia
        location={location}
        direction={direction}
      />
      <LocationCardContent
        location={location}
        direction={direction}
        displayOpeningHours={displayOpeningHours}
        displayFilters={displayFilters}
      />
    </Card>
  )
}

export default LocationCard
