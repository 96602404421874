import { Location } from '@eo-storefronts/eo-core'
import { CardContent } from '@mui/material'
import LocationCategories
  from '~/src/components/marketplace/locations/location-card/location-card-content/LocationCategories'
import LocationFilters from '~/src/components/marketplace/locations/location-card/location-card-content/LocationFilters'
import LocationName from '~/src/components/marketplace/locations/location-card/location-card-content/LocationName'
import LocationOpeningHour from '~/src/components/marketplace/locations/LocationOpeningHour'
import LocationTags
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/location-tags'

interface Props {
  location: Location,
  displayOpeningHours: boolean,
  displayFilters: boolean,
  direction: 'horizontal' | 'vertical',
}

const LocationCardContent = ({ location, direction, displayFilters = false, displayOpeningHours = false }: Props) => {
  return (
    <CardContent
      sx={{
        py: '0 !important',
        px: direction === 'vertical' ? 0 : 1,
        overflow: 'hidden',
        width: direction === 'horizontal' ? '70%' : 'unset'
      }}
    >
      <LocationName location={location} />
      <LocationCategories location={location} />
      {displayFilters && <LocationFilters location={location} />}
      <LocationTags location={location} />
      {displayOpeningHours && (
        <LocationOpeningHour
          location={location}
          date={new Date()}
        />
      )}
    </CardContent>
  )
}

export default LocationCardContent
