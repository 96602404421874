import { Box, BoxProps } from '@mui/material'
import QRCode from 'react-qr-code'
import { useEoValue } from '~/src/hooks/useEoState'
import { GET_CUSTOMER_QUICK_AUTH_ID_SELECTOR } from '~/src/stores/customer'

const ProfileQuickAuthIdAsQrCode = ({ sx, ...otherProps }: BoxProps) => {
  const value = useEoValue(GET_CUSTOMER_QUICK_AUTH_ID_SELECTOR)

  if (!value) {
    return null
  }

  return (
    <Box
      sx={{
        borderRadius: '20px',
        background: 'white',
        padding: 4,
        ...sx
      }}
      {...otherProps}>
      <QRCode
        value={value}
        size={150}
      />
    </Box>
  )
}

export default ProfileQuickAuthIdAsQrCode
