const UberEatsIcon = () => (
  <svg enableBackground='new 0 0 60 60'
    version='1.1'
    viewBox='0 0 60 60'
    width='1em'
    height='1em'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fill='#06C167'
      d='M0,22.4c0-7.8,0-11.8,1.5-14.8C2.9,5,5,2.9,7.6,1.5c3-1.5,7-1.5,14.8-1.5h15.2c7.8,0,11.8,0,14.8,1.5  C55,2.9,57.1,5,58.5,7.6c1.5,3,1.5,6.9,1.5,14.8v15.2c0,7.8,0,11.8-1.5,14.8c-1.3,2.6-3.5,4.8-6.1,6.1c-3,1.5-6.9,1.5-14.8,1.5H22.4  c-7.8,0-11.8,0-14.8-1.5C5,57.1,2.9,55,1.5,52.4C0,49.4,0,45.4,0,37.6V22.4z'/>
    <path
      d='m28.7 40.5c0-1.8-1.4-3.2-3.1-3.2-1.8 0-3.2 1.4-3.2 3.1 0 1.8 1.4 3.2 3.1 3.2 1.8 0.1 3.2-1.3 3.2-3.1m2.6-5.1v10.3h-2.6v-0.9c-0.9 0.8-2.1 1.2-3.4 1.2-3.1 0-5.6-2.4-5.6-5.4s2.5-5.4 5.6-5.4c1.2 0 2.4 0.4 3.4 1.2v-0.9c0-0.1 2.6-0.1 2.6-0.1zm8.8 7.9h-2c-0.6 0-1-0.3-1-0.8v-4.8h3v-2.3h-3v-2.9h-2.7v2.9h-2v2.3h2v5.5c0 1.4 1 2.5 2.8 2.5h2.9v-2.4zm6.1 2.6c3.1 0 4.8-1.4 4.8-3.4 0-1.4-1-2.5-3.2-2.9l-2.3-0.5c-1.3-0.2-1.7-0.5-1.7-1 0-0.6 0.6-1 1.8-1 1.3 0 2.2 0.3 2.5 1.5h2.6c-0.1-2.1-1.7-3.6-4.9-3.6-2.8 0-4.7 1.1-4.7 3.3 0 1.5 1.1 2.5 3.4 3l2.5 0.6c1 0.2 1.3 0.5 1.3 0.9 0 0.6-0.8 1-2 1-1.5 0-2.4-0.3-2.7-1.5h-2.7c0.3 2.2 2 3.6 5.3 3.6m-37.1-14.2h10v2.4h-7.3v3.4h7.1v2.3h-7.1v3.5h7.2v2.4h-10v-14zm41.6-12.1v-1.9h-0.7c-1.1 0-2 0.5-2.5 1.3v-1.3h-2v10.3h2.1v-5.8c0-1.6 1-2.6 2.3-2.6h0.8zm-14.7 2.4c0.4-1.6 1.6-2.6 3.2-2.6 1.5 0 2.8 1 3.1 2.6h-6.3zm3.2-4.3c-2.9 0-5.3 2.3-5.3 5.2 0 3 2.4 5.3 5.5 5.3 1.9 0 3.4-0.8 4.5-2.2l-1.6-1c-0.8 1-1.8 1.5-3 1.5-1.7 0-3.2-1.2-3.4-2.9h8.4v-0.7c0-3-2.1-5.2-5.1-5.2m-11.7 8.8c-1.9 0-3.5-1.6-3.5-3.6 0-1.9 1.6-3.5 3.6-3.5 1.9 0 3.5 1.6 3.5 3.5-0.1 2-1.7 3.6-3.6 3.6m-5.6 1.5h2v-1.3c1 1 2.3 1.5 3.7 1.5 3 0 5.4-2.4 5.4-5.3s-2.4-5.3-5.4-5.3c-1.4 0-2.7 0.5-3.7 1.5v-5.1h-2.1v14zm-7.2-1.6c2 0 3.5-1.5 3.5-3.7v-8.6h2.1v13.9h-2.1v-1.3c-1 1-2.3 1.5-3.8 1.5-3 0-5.4-2.2-5.4-5.5v-8.7h2.1v8.6c0.1 2.3 1.6 3.8 3.6 3.8'/>
  </svg>
)

export default UberEatsIcon
