import { Address, Firm, FirmStoreLocator, Id } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useFindFirmByAddress } from '~/src/hooks/firm/useFindFirmByAddress'
import { useConfirmDialog } from '~/src/hooks/layout/useConfirmDialog'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import RoutesEnum from '~/src/router/enums/routes.enum'
import useIsLocatorPage from '~/src/hooks/router/useIsLocatorPage'

interface ReturnsType {
  validateAddressForCurrentFirm(address: Address): Promise<boolean | undefined>,
}

const useDeliveryAddressValidator = (): ReturnsType => {
  const { t } = useTranslations()
  const { find: findFirm } = useFindFirmByAddress()
  const { enqueueSnackbar } = useSnackbar()
  const { triggerConfirmDialog } = useConfirmDialog()
  const { push } = useRouterPush()
  const { isLocatorPage } = useIsLocatorPage()
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)
  const setLocatorState = useSetEoState(LOCATOR_STATE)

  const lang = useEoValue(LANGUAGE_STATE)

  const _updateCheckout = (deliveryMethods: Partial<CheckoutFormState['delivery_method']>): void => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        ...deliveryMethods
      }
    }))
  }

  const _redirectToMatchingFirm = (id: Id): void => {
    if (!firm?.locations) {
      return
    }

    const matchingFirm = firm?.locations.find((childFirm: Firm) => childFirm.id === id)

    if (!matchingFirm) {
      return
    }

    push(`/${matchingFirm.sitename}/${lang}/${RoutesEnum.MENU}`)
  }

  const validateAddressForCurrentFirm = async (address: Address): Promise<boolean | undefined> => {
    try {
      const storeLocator: FirmStoreLocator | null = await findFirm(address)

      if (!storeLocator) {
        return false
      }

      if (storeLocator.message) {
        throw new Error(storeLocator.message)
      }

      if (!isLocatorPage && firm && (firm.id !== storeLocator.id)) {
        triggerConfirmDialog({
          message: `${t('firm.redirect_to_another_location')} ${storeLocator.name}`,
          onConfirm: async () => {
            _updateCheckout({
              address,
              pickupPoint: null,
              time: null,
              minDate: null,
              timeslotId: null
            })
            _redirectToMatchingFirm(storeLocator.id)
          }
        })

        return false
      }

      setLocatorState((state: LocatorState) => ({
        ...state,
        firmAvailableForDelivery: storeLocator.id
      }))

      _updateCheckout({ address })
      enqueueSnackbar(t('delivery_methods.address_chosen'), { variant: 'success' })

      return true
    } catch (e) {
      enqueueSnackbar(t('delivery_methods.cannot_deliver_to_address'), { variant: 'error' })
    }
  }

  return { validateAddressForCurrentFirm }
}

export {
  useDeliveryAddressValidator
}
