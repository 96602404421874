import DropdownMenu from '~/src/components/mui-wrappers/dropdown/DropdownMenu'
import { Box, MenuProps } from '@mui/material'
import useGetProfileNavigationItems from '~/src/hooks/router/navigation/useGetProfileNavigationItems'
import DropdownLinkItem from '~/src/components/mui-wrappers/dropdown/DropdownLinkItem'
import DropdownItem from '~/src/components/mui-wrappers/dropdown/DropdownItem'
import { useTranslations } from '~/src/hooks/useTranslations'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import useLogout from '~/src/hooks/auth/useLogout'

interface Props {
  onClick?(): void,
  MenuProps?: Partial<MenuProps>,
}

const ProfileDropdownMenu = ({ onClick, MenuProps = {} }: Props) => {
  const { t } = useTranslations()
  const navItems = useGetProfileNavigationItems()
  const { logout } = useLogout()
  const _handleLogout = () => {
    logout()
    onClick && onClick()
  }

  return (
    <DropdownMenu {...MenuProps}>
      {navItems.map((navItem, index) => (
        <DropdownLinkItem
          disabled={false}
          key={`${navItem.href}-${index}`}
          href={navItem.href}
          onClick={onClick}
        >
          {navItem.label}
        </DropdownLinkItem>
      ))}

      <DropdownItem onClick={_handleLogout}>
        <Box
          component='span'
          sx={{
            display: 'flex',
            gap: 1
          }}>
          <LogoutOutlinedIcon/>
          {t('global.logout')}
        </Box>
      </DropdownItem>
    </DropdownMenu>
  )
}

export default ProfileDropdownMenu
