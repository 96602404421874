import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import PushNotificationDialogAction from '~/src/components/dialogs/push-notification/actions'

export interface PushNotificationDialogProps {
  title: string,
  body: string,
  buttonText?: string,
  redirectTo?: string,
}

const PushNotificationDialog = ({ title, body, buttonText, redirectTo }: PushNotificationDialogProps) => (
  <ResponsiveDialog
    openByDefault={true}
    Title={<Title>{title}</Title>}
    name={'push-notification'}
    Actions={(
      <PushNotificationDialogAction
        buttonText={buttonText}
        redirectTo={redirectTo}
      />
    )}
  >
    {body}
  </ResponsiveDialog>
)

export default PushNotificationDialog
