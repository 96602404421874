import { useEoValue } from '~/src/hooks/useEoState'
import { DeliveryMethodNames, Firm } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import DeliveryMethodService from '~/src/services/DeliveryMethodService'

interface ReturnsType {
  isMethodEnabled(method: DeliveryMethodNames | null): boolean,
  isMethodInactive(method: DeliveryMethodNames | null): boolean,
}

const useDeliveryMethodService = (): ReturnsType => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const service = new DeliveryMethodService(firm)

  const isMethodEnabled = (method: DeliveryMethodNames | null): boolean => {
    if (!method) {
      return false
    }

    return service.isMethodEnabled(method)
  }

  const isMethodInactive = (method: DeliveryMethodNames | null): boolean => {
    if (!method) {
      return true
    }

    return service.isMethodInactive(method)
  }

  return {
    isMethodEnabled,
    isMethodInactive
  }
}

export default useDeliveryMethodService
