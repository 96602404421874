import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import CurrentFirmLogo from '~/src/components/firms/CurrentFirmLogo'
import { useTranslations } from '~/src/hooks/useTranslations'

const AnnouncementsDialogTitle = () => {
  const { t } = useTranslations()
  const { isMobile } = useEoValue(LAYOUT_STATE)

  if (!isMobile) {
    return <>{t('announcements.title')}</>
  }

  return (
    <CurrentFirmLogo
      sx={{
        maxHeight: '5vh'
      }}
    />
  )
}

export default AnnouncementsDialogTitle
