import { Location } from '@eo-storefronts/eo-core'
import { Skeleton } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  location?: Location,
  TitleProps?: TypographyProps,
  isLoading?: boolean,
}

const LocationAddress = ({ location, isLoading = false, TitleProps = {} }: Props) => {
  if (isLoading || !location) {
    return (
      <Skeleton
        variant='text'
        sx={{ fontSize: '1rem' }}
      />
    )
  }

  const { street_nr, zipcode, locality } = location

  if (!street_nr || !zipcode || !locality) {
    return null
  }

  return (
    <Title {...TitleProps}>{street_nr}, {zipcode} {locality}</Title>
  )
}

export default LocationAddress
