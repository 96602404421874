import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  min?: number | null,
  max?: number | null,
}

const CartItemMinMax = ({ min, max }: Props) => {
  const { t } = useTranslations()

  if (!min || !max) {
    return null
  }

  return (
    <>
      <small>{`${t('global.min')}: ${min}`}</small>
      <br />
      <small>{`${t('global.max')}: ${max}`}</small>
    </>
  )
}

export default CartItemMinMax
