import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'

export default class CartService {
  private readonly _modifierGroups: Record<string, ModifierGroup>
  private readonly _modifiers: Record<string, Modifier>

  public constructor(
    modifierGroups: Record<string, ModifierGroup>,
    modifiers: Record<string, Modifier>,
  ) {
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers
  }

  public calculateCartProductPrice(productPrice: number, quantity: number, cartModifierGroups: CartModifierGroupStateInterface[]): number {
    let calculatedPrice = productPrice

    calculatedPrice += this._modifierGroupsPrice(cartModifierGroups)

    return calculatedPrice * quantity
  }

  private _modifierGroupsPrice(cartModifierGroups: CartModifierGroupStateInterface[]): number {
    let calculatedPrice = 0

    for (const modifierGroup of cartModifierGroups) {
      if (!this._modifierGroups[modifierGroup.id]) {
        continue
      }

      for (const modifier of modifierGroup.modifiers) {
        if (!this._modifiers[modifier.id]) {
          continue
        }

        calculatedPrice += this._modifiers[modifier.id].price * modifier.quantity
        calculatedPrice += this._modifierGroupsPrice(modifier.modifierGroups)
      }
    }

    return calculatedPrice
  }
}
