import { DeliveryMethodNames, OrderOnSpotCustomMethod } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  tableMethodLocationName: string,
  tableNumbers: Array<string | number>,
}

const useEatInLocationPickerDetails = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const [ tableMethodLocationName, setTableMethodLocationName ] = useState<string>('')
  const [ tableNumbers, setTableNumbers ] = useState<Array<string|number>>([])

  useEffect(() => {
    if (!firm) {
      return
    }

    if (checkoutForm.delivery_method.table_method === null) {
      setTableMethodLocationName(firm.labels.table_number)
      setTableNumbers(firm.settings.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.table_numbers || [])

      return
    }

    const customTableMethod = firm.settings.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.custom_methods?.find(
      (method: OrderOnSpotCustomMethod) => method.id === checkoutForm.delivery_method.table_method
    )

    if (!customTableMethod) {
      return
    }

    setTableMethodLocationName(customTableMethod.location_number_name)
    setTableNumbers(customTableMethod.location_numbers)
  }, [ firm, checkoutForm.delivery_method.table_method ])

  return {
    tableMethodLocationName,
    tableNumbers
  }
}

export {
  useEatInLocationPickerDetails
}
