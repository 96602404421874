import { SxProps } from '@mui/material'
import StylesShoppingCartTotalServiceInterface
  from '~/src/components/cart/shopping-cart-total/styles/StylesShoppingCartTotalServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartTotalServiceStyleOne extends TemplateServiceStyleBase implements StylesShoppingCartTotalServiceInterface {
  public getContainerSxProps(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }

  public getTotalItemsContainerSxProps(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }

  public getTotalItemsLabelSxProps(): SxProps {
    return {}
  }

  public getTotalItemsValueSxProps(): SxProps {
    return {
      fontWeight: '700'
    }
  }

  public getTotalPriceContainerSxProps(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  }

  public getTotalPriceLabelSxProps(): SxProps {
    return {}
  }

  public getTotalPriceValueSxProps(): SxProps {
    return {
      fontWeight: '700'
    }
  }
}
