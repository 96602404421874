import { LanguageType } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { useLocation } from 'react-router-dom'

interface ReturnsType {
  build(lang: LanguageType): string,
}

const useBuildLinkWithLanguage = (): ReturnsType => {
  const { pathname } = useLocation()
  const languageState = useEoValue(LANGUAGE_STATE)

  const build = (lang: LanguageType) => {
    const paths = pathname.split('/')
    const languageParamIndex = paths.findIndex((value: string) => languageState === value)

    if (languageParamIndex !== -1) {
      paths[languageParamIndex] = lang
    }

    return paths.join('/')
  }

  return { build }
}

export default useBuildLinkWithLanguage

