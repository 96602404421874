import { SxProps } from '@mui/material'
import StylesNotFoundServiceInterface from '~/src/pages/not-found/styles/StylesNotFoundServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { PlatformUtils } from '@eo-storefronts/eo-core'

export default class StylesNotFoundServiceStyleTwo extends TemplateServiceStyleBase implements StylesNotFoundServiceInterface {
  public getPageSx(): SxProps {
    return {
      mt: PlatformUtils().isCapacitorNative ? 'var(--eo-safe-area-top)' : 2,
      mx: 2
    }
  }

  public getContainerSx(): SxProps {
    return {
      my: 4,
      width: '40%',
      maxWidth: 'unset !important',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '90%'
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '60%',
        minWidth: '450px'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '70%'
      },
      [this.muiTheme.breakpoints.only('2k')]: {
        width: 'auto',
        maxWidth: '35% !important'
      }
    }
  }

  public getTitleSx(): SxProps {
    return {
      color: 'background.contrastText'
    }
  }

  public getContainerActionButtonSx(): SxProps {
    return {
      mt: 5
    }
  }
}
