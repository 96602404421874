import { Product } from '@eo-storefronts/eo-core'
import { Chip } from '@mui/material'
import { useProductAvailabilityMessage } from '~/src/hooks/products/useProductAvailabilityMessage'

interface Props {
  product: Product,
}

const ProductAvailabilityMessage = ({ product }: Props) => {
  const { nextAvailability: availabilityMessage } = useProductAvailabilityMessage(product)

  if (availabilityMessage === '') {
    return null
  }

  return (
    <Chip
      color='error'
      size='small'
      label={availabilityMessage}
      sx={{
        position: 'absolute',
        zIndex: 1,
        right: '-10px',
        top: '-10px'
      }}
    />
  )
}

export default ProductAvailabilityMessage
