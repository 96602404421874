import LocationSubHeaderItem from '~/src/components/layout/sub-header/location-sub-header/location-sub-header-item'
import { Box, Theme as MuiTheme, useMediaQuery } from '@mui/material'
import useGetSubHeaderTemplate
  from '~/src/components/layout/sub-header/location-sub-header/styles/useGetSubHeaderTemplate'
import { useEffect, useRef } from 'react'
import LocationSubHeaderSearchButton
  from '~/src/components/layout/sub-header/location-sub-header/location-sub-header-search-button'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'

const LocationSubHeader = () => {
  const styles = useGetSubHeaderTemplate()
  const subheaderRef = useRef<HTMLDivElement | undefined>()
  const isSmallDevice = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('md'))
  const { height } = useGetDOMNodeProperties('layout_header')
  let observer: IntersectionObserver | undefined

  useEffect(() => {
    if (!isSmallDevice || !subheaderRef.current || observer) {
      return
    }

    // Add class "is pinned" when subheader is sticky
    observer = new IntersectionObserver(
      ([ e ]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
      { threshold: 1 }
    )

    observer.observe(subheaderRef.current)
  }, [ subheaderRef ])

  useEffect(() => {
    return () => {
      observer && observer.disconnect()
    }
  }, [])

  return (
    <Box
      id='layout_sub_header'
      component='nav'
      gridArea='sub-header'
      sx={styles.getHeaderSubHeaderSx(height)}
      ref={subheaderRef}
    >
      <LocationSubHeaderSearchButton />
      <LocationSubHeaderItem />
    </Box>
  )
}

export default LocationSubHeader
