import { Theme, useTheme } from '@mui/material'

export interface CustomMuiTheme extends Theme {
  elevation: number,
}

const useCustomMuiTheme = (): CustomMuiTheme => {
  return useTheme() as CustomMuiTheme
}

export {
  useCustomMuiTheme
}
