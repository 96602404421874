import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import useGetProductItemTemplate from '~/src/components/products/product-item/styles/useGetProductItemTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

export interface ProductItemCardContentProps {
  product: Product | null,
  showDescription?: boolean,
  containerTemplate?: TemplateEnum,
}

const ProductItemCardContent = ({ product, containerTemplate, showDescription = false }: ProductItemCardContentProps) => {
  const style = useGetProductItemTemplate(containerTemplate)
  const lang = useEoValue(LANGUAGE_STATE)

  if (!product) {
    return null
  }

  return (
    <>
      <Title
        component='h6'
        className='eo-ellipsis'
        sx={style.getCardContentTitleSx()}
        variant={style.getCardContentTitleVariant()}
      >
        {product.name[lang]}
      </Title>
      {showDescription && (
        <Typography component='p'>{product.description[lang]}</Typography>
      )}
    </>
  )
}

export default ProductItemCardContent
