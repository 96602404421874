import LayoutCategoryProductsServiceStyleOne
  from '~/src/pages/category/[slug]/products/layout/LayoutCategoryProductsServiceStyleOne'
import LayoutCategoryProductsServiceStyleTwo
  from '~/src/pages/category/[slug]/products/layout/LayoutCategoryProductsServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getCategoryProductsLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutCategoryProductsServiceStyleTwo().getLayout(getLayoutArgs)
    default:
      return new LayoutCategoryProductsServiceStyleOne().getLayout()
  }
}

export default getCategoryProductsLayout
