import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryMethodButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/StylesDeliveryMethodsSummaryMethodButtonServiceInterface'
import getDeliveryMethodsSummaryMethodButtonTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/GetDeliveryMethodsSummaryMethodButtonTemplateService'

const useGetDeliveryMethodsSummaryMethodButtonTemplate = (): StylesDeliveryMethodsSummaryMethodButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryMethodButtonTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryMethodButtonTemplate
