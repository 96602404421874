import StylesListItemButtonServiceInterface
  from '~/src/components/mui-wrappers/styled/list-item-button/styles/StylesListItemButtonServiceInterface'
import StylesListItemButtonServiceStyles
  from '~/src/components/mui-wrappers/styled/list-item-button/styles/StylesListItemButtonServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getListItemButtonTemplateService = (style: string | null, muiTheme: CustomMuiTheme): StylesListItemButtonServiceInterface => {
  return new StylesListItemButtonServiceStyles(muiTheme)
}

export default getListItemButtonTemplateService
