import { useSetEoState } from '~/src/hooks/useEoState'
import { AUTHENTICATION_STATE, AuthenticationState } from '~/src/stores/authentication'
import { ApiConfiguration } from '@eo-storefronts/eo-core'

const useSetAuthState = () => {
  const setAppAuthState = useSetEoState(AUTHENTICATION_STATE)

  const setAuthState = (auth: Partial<AuthenticationState>) => {
    if (auth.loggedInAs) {
      let customerToken: string | undefined = undefined

      if (auth.loggedInAs === 'customer' && auth.customer?.token) {
        customerToken = auth.customer.token
      }

      if (auth.loggedInAs === 'guest' && auth.guest?.token) {
        customerToken = auth.guest.token
      }

      if (customerToken) {
        ApiConfiguration.addOrReplaceHeader('customerToken', customerToken)
      }
    }

    setAppAuthState((state: AuthenticationState) => ({
      ...state,
      ...auth
    }))
  }

  return {
    setAuthState
  }
}

export default useSetAuthState
