import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesProductsModalListContainerServiceStyleFour
  from '~/src/components/products/modals/products-list/container/styles/StylesProductsModalListContainerServiceStyleFour'
import StylesProductsModalListContainerServiceStyleTwo
  from '~/src/components/products/modals/products-list/container/styles/StylesProductsModalListContainerServiceStyleTwo'
import StylesProductsModalListContainerServiceInterface
  from '~/src/components/products/modals/products-list/container/styles/StylesProductsModalListContainerServiceInterface'

const getProductsModalListContainerTemplateService = (style: TemplateEnum | null | undefined): StylesProductsModalListContainerServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_FOUR:
      return new StylesProductsModalListContainerServiceStyleFour()
    default:
      return new StylesProductsModalListContainerServiceStyleTwo()
  }
}

export default getProductsModalListContainerTemplateService
