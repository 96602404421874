import { useEffect, useState } from 'react'

export interface WindowSize {
  width: number,
  height: number,
}

interface ReturnsType {
  windowSize: WindowSize,
}

const useWindowResize = (): ReturnsType => {
  const [ windowSize, setWindowSize ] = useState<WindowSize>({
    width: 0,
    height: 0
  })

  useEffect(() => {
    function handleResize () {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    // ensure to remove the listener when the component will unmount
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return { windowSize }
}

export default useWindowResize
