import { SxProps } from '@mui/material'
import StylesMarketplaceSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMarketplaceSubHeaderServiceStyleOne extends TemplateServiceStyleBase implements StylesMarketplaceSubHeaderServiceInterface {
  public getLinkSx(): SxProps {
    return {
      transition: 'var(--ease-in-out)',
      py: 1.5,
      '&:hover': {
        opacity: 0.5
      }
    }
  }

  public getHeaderMarketplaceSubHeaderSx(): SxProps {
    return {
      borderTop: '1px solid',
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      zIndex: 3,
      maxWidth: '100vw',
      [this.muiTheme.breakpoints.down('md')]: {
        position: 'sticky',
        px: 0,
        transition: 'all 0.25s ease-in-out',
        top: -1,
        pb: 2,
        width: '100%',
        '&.is-pinned': {
          pt: `calc(${this.muiTheme.spacing(1)} + var(--eo-safe-area-top))`,
          transition: 'all 0.25s ease-in'
        }
      }
    }
  }

  public getHeaderMarketplaceSubHeaderItemSx(): SxProps {
    return {
      border: 'none',
      padding: 0,
      margin: 0,
      borderWidth: 0,
      px: 3,
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      minWidth: 'unset',
      opacity: 1,
      background: 'transparent !important',
      '&:hover': {
        background: 'transparent !important',
        '&>div>span': {
          color: 'primary.main'
        }
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 1.5
      }
    }
  }
}

