import { Box } from '@mui/material'
import BrandSocialLink from '~/src/components/brand/BrandSocialLink'

const FooterSocials = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3
      }}
    >
      <BrandSocialLink socialType='facebook' />
      <BrandSocialLink socialType='google' />
      <BrandSocialLink socialType='twitter' />
      <BrandSocialLink socialType='linkedin' />
      <BrandSocialLink socialType='instagram' />
    </Box>
  )
}

export default FooterSocials
