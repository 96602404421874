import LayoutCartServiceStyleOne from '~/src/pages/cart/layout/LayoutCartServiceStyleOne'
import LayoutCartServiceStyleTwo from '~/src/pages/cart/layout/LayoutCartServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getCartLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutCartServiceStyleTwo().getLayout()
    default:
      return new LayoutCartServiceStyleOne().getLayout()
  }
}

export default getCartLayout
