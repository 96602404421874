import getSideDrawerNavigationItemTemplateService
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/styles/GetSideDrawerNavigationItemTemplateService'
import StylesSideDrawerNavigationItemServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/styles/StylesSideDrawerNavigationItemServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetSideDrawerNavigationItemTemplate = (): StylesSideDrawerNavigationItemServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSideDrawerNavigationItemTemplateService(pageStyle, muiTheme)
}

export default useGetSideDrawerNavigationItemTemplate
