import { BottomNavigationActionProps, styled } from '@mui/material'
import Link from '~/src/components/mui-wrappers/Link'
import BottomNavigationAction from '~/src/components/mui-wrappers/styled/bottom-navigation-action'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'

const LinkBottomNavigationAction = styled(({ href, resolve, onClick, ...otherProps }: CustomNavItemProps) => {
  return (
    <Link
      href={href}
      resolve={resolve}
      onClick={onClick}
      removeBaseLinkStyle
    >
      <BottomNavigationAction {...otherProps as BottomNavigationActionProps} />
    </Link>
  )
})(() => ({}))

export default LinkBottomNavigationAction
