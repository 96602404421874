import getProductUnitPriceTemplateService
  from '~/src/components/products/product-unit-price/styles/getProductUnitPriceTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const useProductUnitPriceTemplateService = (containerTemplate?: TemplateEnum) => {
  const muiTheme = useCustomMuiTheme()

  return getProductUnitPriceTemplateService(containerTemplate, muiTheme)
}

export default useProductUnitPriceTemplateService
