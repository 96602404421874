import { LanguageType } from '@eo-storefronts/eo-core'
import deTranslations from './de/common.json'
import enTranslations from './en/common.json'
import frTranslations from './fr/common.json'
import nlTranslations from './nl/common.json'
import ptTranslations from './pt/common.json'
import esTranslations from './es/common.json'
import itTranslations from './it/common.json'
import noTranslations from './no/common.json'

const locales: Record<LanguageType, any> = {
  en: enTranslations,
  fr: frTranslations,
  nl: nlTranslations,
  de: deTranslations,
  pt: ptTranslations,
  es: esTranslations,
  it: itTranslations,
  no: noTranslations
}

export default locales
