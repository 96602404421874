import { buttonClasses, Link as MUILink } from '@mui/material'
import { LinkProps } from '@mui/material/Link/Link'
import { MouseEvent, useEffect, useState } from 'react'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import EoLinkProps from '~/src/types/EoLinkProps'
import RegexUtils from '~/src/utils/RegexUtils'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'

interface BaseLinkProps extends Partial<EoLinkProps>, Omit<LinkProps, 'onClick'> {
  disabled?: boolean,
  removeBaseLinkStyle?: boolean,
}

const BaseLink = ({
  href,
  sx,
  onClick,
  children,
  disabled = false,
  removeBaseLinkStyle = true,
  className = '',
  ...otherProps
}: BaseLinkProps) => (
  <MUILink
    className={disabled ? `${className} Mui-disabled` : className}
    onClick={onClick}
    href={href}
    sx={{
      ...sx,
      display: 'inline-flex',
      cursor: disabled ? 'not-allowed' : 'pointer',
      listStyle: removeBaseLinkStyle ? 'none' : undefined,
      textDecoration: removeBaseLinkStyle ? 'none' : undefined,
      [`&.${buttonClasses.disabled}`]: {
        borderColor: 'rgba(0,0,0,0.12)',
        color: 'rgba(0,0,0,0.26)',
        boxShadow: 'none'
      }
    }}
    {...otherProps}
  >
    {children}
  </MUILink>
)

const Link = ({
  href,
  sx,
  onClick,
  children,
  disabled = false,
  resolve = true,
  state = {},
  className = '',
  ...otherProps
}: BaseLinkProps) => {
  const { push } = useRouterPush()
  const { resolve: resolveLink } = useFirmPathResolver()
  const [ linkHref, setLinkHref ] = useState<string>('')

  useEffect(() => {
    if (!href) {
      return
    }

    if (resolve && !RegexUtils.test(RegexUtils.urlRegex, href)) {
      setLinkHref(resolveLink(href))
      return
    }

    setLinkHref(href)
  }, [ href, resolveLink ])

  const _handleLinkClicked = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (
      linkHref !== ''
      && !disabled
    ) {
      if (!RegexUtils.test(RegexUtils.urlRegex, linkHref)) {
        push(linkHref, state)
      } else {
        window.open(linkHref, '_blank')
      }
    }

    onClick && onClick(event)
  }

  return (
    <BaseLink
      disabled={disabled}
      className={disabled ? `${className} Mui-disabled` : className}
      onClick={_handleLinkClicked}
      href={linkHref}
      sx={sx}
      {...otherProps}
    >
      {children}
    </BaseLink>
  )
}

export default Link

