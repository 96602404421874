import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Box, Button } from '@mui/material'
import CartItemsCount from '~/src/components/cart/CartItemsCount'
import CartTotalPrice from '~/src/components/cart/CartTotalPrice'
import useGetHeaderCartTemplate from '~/src/components/layout/header/header-cart/styles/useGetHeaderCartTemplate'
import Link from '~/src/components/mui-wrappers/Link'
import { useEoValue } from '~/src/hooks/useEoState'
import routes from '~/src/router/enums/routes.enum'
import { LAYOUT_STATE } from '~/src/stores/layout'

const HeaderCart = () => {
  const styles = useGetHeaderCartTemplate()
  const { isMobile } = useEoValue(LAYOUT_STATE)

  return (
    <Link
      removeBaseLinkStyle
      href={routes.CART}
      sx={styles.getLinkSx()}
    >
      <Button
        variant='contained'
      >
        <ShoppingCartIcon />
        <Box
          component='label'
          sx={styles.getLabelSx()}
        >
          <CartTotalPrice />
          {!isMobile && (
            <>
              <span>/</span>
              <CartItemsCount />
            </>
          )}
        </Box>

      </Button>
    </Link>

  )
}

export default HeaderCart
