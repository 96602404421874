import { createState } from '~/src/hooks/useEoState'

export interface GoogleMapsApiState {
  isLoaded: boolean,
  loadError: Error | undefined,
}

export const GOOGLE_MAPS_API_STATE = createState<GoogleMapsApiState>({
  key: 'google-maps-api',
  default: {
    isLoaded: false,
    loadError: undefined
  }
})
