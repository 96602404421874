import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, InputBase, Theme, Typography, useMediaQuery } from '@mui/material'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import ProductSearchIconAdornment from '~/src/components/products/ProductSearchIconAdornment'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { MARKETPLACE_SEARCH_VALUE_STATE } from '~/src/stores/marketplace/filter'

interface Props {
  setShowPanel(state: boolean): void,
}

const LocationSearchAutocompleteInput = ({ setShowPanel }: Props) => {
  const [ searchValue, setSearchValue ] = useEoState(MARKETPLACE_SEARCH_VALUE_STATE)
  const [ value, setValue ] = useState<string>(searchValue)
  const { t } = useTranslations()
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const _handleOnEnterKeyUp = () => {
    if (isMobile) {
      if (!location.href.includes(RoutesEnum.SEARCH)) {
        void push(resolve(RoutesEnum.SEARCH))
      }

      setSearchValue(value)

      return
    }

    setSearchValue(value)
    setShowPanel(value.length > 3)
  }

  const _handleOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return
    }

    _handleOnEnterKeyUp()
  }

  const _handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      _clearInput()

      return
    }

    setValue(event.target.value)
  }

  const _clearInput = () => {
    if (!value) {
      return
    }

    setValue('')
    setSearchValue('')
    setShowPanel(false)
  }

  const _handleOnFocus = () => {
    if (isMobile || value.length <= 3) {
      return
    }

    setShowPanel(true)
  }

  return (
    <InputBase
      startAdornment={
        <ProductSearchIconAdornment
          buttonMode={false}
          position='start'
        />
      }
      endAdornment={value && (
        <Box sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center'
        }}>
          {searchValue !== value && value.length > 3 && (
            <Typography
              variant={'caption'}
              component={'i'}
              sx={{
                opacity: .6,
                whiteSpace: 'nowrap'
              }}>
              {t('search.hint')}
            </Typography>
          )}

          <IconButton size={'small'} onClick={_clearInput}>
            <ClearIcon/>
          </IconButton>
        </Box>
      )}
      placeholder={t('search.placeholder')}
      fullWidth
      autoComplete={'off'}
      onKeyUp={_handleOnKeyUp}
      onChange={_handleOnChange}
      onFocus={_handleOnFocus}
      value={value}
      inputProps={{
        name: 'search-location',
        inputMode: 'search',
        type: 'text'
      }}
    />
  )
}

export default LocationSearchAutocompleteInput
