import { createContext, FC } from 'react'

export interface IDialogsContext {
  present<T>(component: FC<T>, name: string, options?: T): void,
  dismiss(name: string): void,
  dismissAll(): void,
}

const present = () => {
  throw new Error('DialogsContext.present not implemented')
}
const dismiss = () => {
  throw new Error('DialogsContext.dismiss not implemented')
}
const dismissAll = () => {
  throw new Error('DialogsContext.dismissAll not implemented')
}

const DialogsContext = createContext<IDialogsContext>({
  present,
  dismiss,
  dismissAll
})

export default DialogsContext
