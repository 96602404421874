import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Customer, Firm, LoyaltyService } from '@eo-storefronts/eo-core'

const useLoyaltyService = (): LoyaltyService => {
  const customer = useEoValue<Customer|null>(CUSTOMER_STATE)
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)

  return new LoyaltyService(
    firm,
    customer
  )
}

export default useLoyaltyService
