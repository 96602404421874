import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle as MUIDialogTitle, IconButton, SxProps } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode,
  CloseButtonProps?: ButtonProps,
  showCloseButton?: boolean,
  sx?: SxProps,
}

const DialogTitle = ({ sx, children, showCloseButton = true, CloseButtonProps }: Props) => {
  return (
    <MUIDialogTitle
      component='div'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: children ? 'space-between' : 'flex-end',
        width: '100%',
        ...sx
      }}
    >
      {children}

      {(CloseButtonProps && showCloseButton) && (
        <IconButton
          onClick={CloseButtonProps.onClick}
          sx={{
            justifyContent: 'right',
            borderRadius: 'var(--btn-border-radius)',
            color: 'inherit',
            ...CloseButtonProps.sx
          }}
        >
          <CloseIcon style={{ fontSize: 20 }} />
        </IconButton>
      )}
    </MUIDialogTitle>
  )
}

export default DialogTitle
