import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useContext } from 'react'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const ProductConfigurationCloseButton = () => {
  const responsiveDialogContext = useContext(ResponsiveDialogContext)
  const muiTheme = useCustomMuiTheme()
  const { isMobile } = useEoValue(LAYOUT_STATE)

  const _handleOnClose = () => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
  }

  return (
    <IconButton
      sx={{
        color: 'secondary.contrastText',
        position: isMobile ? 'fixed' : 'absolute',
        top: `calc(var(--eo-safe-area-top) - 1px + ${muiTheme.spacing(2)})`,
        right: `calc(var(--eo-safe-area-right) + ${muiTheme.spacing(2)})`,
        zIndex: 10,
        p: 0.5,
        mr: 1,
        [muiTheme.breakpoints.down('md')]: {
          mr: 0
        }
      }}
      onClick={_handleOnClose}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default ProductConfigurationCloseButton
