import { SxProps } from '@mui/material'
import StylesSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSubHeaderServiceStyleThree extends TemplateServiceStyleBase implements StylesSubHeaderServiceInterface {
  public getHeaderSubHeaderSx(): SxProps {
    return {
      zIndex: 2,
      backgroundColor: 'background.main',
      color: 'background.contrastText',
      p: '0 15rem',
      [this.muiTheme.breakpoints.down('lg')]: {
        p: '0 5rem'
      }
    }
  }

  public getLinkSx(): SxProps {
    return {
      transition: 'var(--ease-in-out)',
      py: 1.5,
      '&:hover': {
        opacity: 0.5
      }
    }
  }
}
