import { Location } from '@eo-storefronts/eo-core'
import { indexDbPersist } from '~/src/helpers/recoilPersist'
import { createState } from '~/src/hooks/useEoState'

const key = 'marketplaceViewedRecentlyState'

export const MARKETPLACE_VIEWED_RECENTLY_STATE = createState<Location[]>({
  key,
  default: [],
  effects: [
    ({ onSet, setSelf }) => {
      onSet((locations: Location[]): void => {
        // removed duplicates
        const locationsCleaned: Location[] = Array.from(new Set(locations).values())

        locationsCleaned.length > 10 && locationsCleaned.pop()
        setSelf(locationsCleaned)
      })
    },
    indexDbPersist(key) // 2 days
  ]
})
