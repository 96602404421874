import getMarketplaceSubHeaderTemplateService
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/GetMarketplaceSubHeaderTemplateService'
import StylesMarketplaceSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetMarketplaceSubHeaderTemplate = (): StylesMarketplaceSubHeaderServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getMarketplaceSubHeaderTemplateService(pageStyle, muiTheme)
}

export default useGetMarketplaceSubHeaderTemplate
