import EoLinkProps from '~/src/types/EoLinkProps'
import { ReactNode } from 'react'
import Link from '~/src/components/mui-wrappers/Link'
import DropdownItem from '~/src/components/mui-wrappers/dropdown/DropdownItem'

interface Props extends Partial<EoLinkProps> {
  children: ReactNode,
  disabled: boolean,
}

const DropdownLinkItem = ({ children, href, resolve, onClick, disabled }: Props) => {
  return (
    <DropdownItem
      disabled={disabled}
      sx={{
        p: 0
      }}
    >
      <Link
        removeBaseLinkStyle
        href={href}
        resolve={resolve}
        onClick={onClick}
        sx={{
          py: 1,
          px: 2,
          width: '100%'
        }}
      >
        { children }
      </Link>
    </DropdownItem>
  )
}

export default DropdownLinkItem
