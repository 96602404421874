import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Box, LinkProps } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'
import ButtonWithIcon, { ButtonWithIconProps } from '~/src/components/mui-wrappers/buttons/ButtonWithIcon'
import Link from '~/src/components/mui-wrappers/Link'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'

interface Props extends Omit<ButtonProps, 'component'> {
  text?: string,
  displayIcon?: boolean,
  LinkProps?: Omit<LinkProps, 'removeBaseLinkStyle'>,
  ButtonProps?: Omit<ButtonWithIconProps, 'startIcon'>,
}

const LoginButton = ({ href = RoutesEnum.LOGIN, text = 'global.login', displayIcon = true, LinkProps, ButtonProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Link
      removeBaseLinkStyle
      href={href}
      {...LinkProps}
    >
      <ButtonWithIcon
        startIcon={displayIcon && <PersonOutlineOutlinedIcon />}
        {...ButtonProps}
      >
        <Box
          component='span'
          id='text'
        >
          {t(text)}
        </Box>
      </ButtonWithIcon>
    </Link>
  )
}

export default LoginButton
