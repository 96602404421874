import Dropdown from '~/src/components/mui-wrappers/dropdown/Dropdown'
import ProfileDropdownLabel from '~/src/components/user-actions/profile-dropdown-button/ProfileDropdownLabel'
import ProfileDropdownMenu from '~/src/components/user-actions/profile-dropdown-button/ProfileDropdownMenu'
import { ButtonProps } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'

const ProfileDropdownButton = (buttonProps: Pick<ButtonProps, 'sx' | 'color' | 'useAutomaticShading' | 'variant'>) => {
  const customer = useEoValue(CUSTOMER_STATE)

  if (!customer?.first_name && !customer?.last_name) {
    return null
  }

  return (
    <Dropdown
      toggleContent={<ProfileDropdownLabel/>}
      {...buttonProps}
    >
      <ProfileDropdownMenu MenuProps={{
        color: buttonProps.color,
        useAutomaticShading: buttonProps.useAutomaticShading
      }}/>
    </Dropdown>
  )
}

export default ProfileDropdownButton
