import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import LayoutExtraInfoCustomerServiceStyleTwo
  from '~/src/pages/extra-info/customer/layout/LayoutExtraInfoCustomerServiceStyleTwo'
import LayoutExtraInfoCustomerServiceStyleOne
  from '~/src/pages/extra-info/customer/layout/LayoutExtraInfoCustomerServiceStyleOne'
import LayoutExtraInfoCustomerServiceStyleThree
  from '~/src/pages/extra-info/customer/layout/LayoutExtraInfoCustomerServiceStyleThree'

const getExtraInfoCustomerLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutExtraInfoCustomerServiceStyleTwo().getLayout()
    case TemplateEnum.STYLE_THREE:
      return new LayoutExtraInfoCustomerServiceStyleThree().getLayout()
    default:
      return new LayoutExtraInfoCustomerServiceStyleOne().getLayout()
  }
}

export default getExtraInfoCustomerLayout
