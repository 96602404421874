import StylesHeaderMultiFirmSwitchServiceInterface
  from '~/src/components/layout/header/header-multi-firm-switch/styles/StylesHeaderMultiFirmSwitchServiceInterface'
import StylesHeaderMultiFirmSwitchServiceStyleOne
  from '~/src/components/layout/header/header-multi-firm-switch/styles/StylesHeaderMultiFirmSwitchServiceStyleOne'
import StylesHeaderMultiFirmSwitchServiceStyleTwo
  from '~/src/components/layout/header/header-multi-firm-switch/styles/StylesHeaderMultiFirmSwitchServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getHeaderMultiFirmSwitchTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesHeaderMultiFirmSwitchServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesHeaderMultiFirmSwitchServiceStyleTwo(muiTheme)
    default:
      return new StylesHeaderMultiFirmSwitchServiceStyleOne(muiTheme)
  }
}

export default getHeaderMultiFirmSwitchTemplateService
