import { useTranslations } from '~/src/hooks/useTranslations'
import { Typography } from '@mui/material'
import { CartProduct } from '~/src/types/CartProduct'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCT_COUPON } from '~/src/stores/product'
import { CHECKOUT_PRODUCT_LOYALTY_IN_CART } from '~/src/stores/checkout'

interface Props {
  cartProduct: CartProduct,
}

const ShoppingCartItemDiscounts = ({ cartProduct }: Props) => {
  const { t } = useTranslations()
  const productCoupon = useEoValue(PRODUCT_COUPON(cartProduct.id))
  const productLoyaltyDiscount = useEoValue(CHECKOUT_PRODUCT_LOYALTY_IN_CART)

  if (!productCoupon && !productLoyaltyDiscount) {
    return null
  }

  return (
    <Typography>
      {cartProduct.is_loyalty &&  t('shopping_cart.loyalty_discount')}
      {cartProduct.is_coupon && t('shopping_cart.coupon_discount')}
    </Typography>
  )
}

export default ShoppingCartItemDiscounts
