import {
  BackdropProps,
  Box,
  IconButtonProps,
  Paper,
  PaperProps,
  Theme,
  useMediaQuery
} from '@mui/material'
import { useLayoutEffect, useState } from 'react'
import LocationSearchAutocompleteBackdrop from '~/src/components/marketplace/ui/search/LocationSearchAutocompleteBackdrop'
import LocationSearchAutocompleteInput from '~/src/components/marketplace/ui/search/LocationSearchAutocompleteInput'
import LocationSearchAutocompletePanel from '~/src/components/marketplace/ui/search/LocationSearchAutocompletePanel'
import useGlobalSearchInputProperties from '~/src/hooks/marketplace/search/useGlobalSearchInputProperties'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props extends Pick<IconButtonProps, 'size'> {
  PaperProps?: PaperProps,
  BackdropProps?: BackdropProps,
}

const LocationSearchAutocomplete = (
  {
    PaperProps,
    BackdropProps,
    size = 'large'
  }: Props
) => {
  const [ showPanel, setShowPanel ] = useState<boolean>(false)
  const theme = useCustomMuiTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { py, px, radius } = useGlobalSearchInputProperties(size)

  useLayoutEffect(() => {
    if (isMobile || !showPanel) {
      return
    }

    document.getElementsByTagName('body')[0].style.overflow = 'hidden'

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [ isMobile, showPanel ])

  return (
    <>
      <LocationSearchAutocompleteBackdrop
        setShowPanel={setShowPanel}
        showPanel={showPanel}
        {...BackdropProps}
      />
      <Paper
        {...PaperProps}
        sx={{
          width: '70%',
          borderRadius: radius,
          position: 'relative',
          px,
          py,
          [theme.breakpoints.down('md')]: {
            width: '100%'
          },
          ...(PaperProps?.sx ?? {})
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pb: (!isMobile && showPanel) ? py : 0
          }}
        >
          <LocationSearchAutocompleteInput setShowPanel={setShowPanel}/>
        </Box>
        <LocationSearchAutocompletePanel showPanel={showPanel} size={size}/>
      </Paper>
    </>
  )
}

export default LocationSearchAutocomplete
