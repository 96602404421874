import { SxProps } from '@mui/material'
import StylesHeaderCurrentFirmServiceInterface
  from '~/src/components/layout/header/header-current-firm/styles/StylesHeaderCurrentFirmServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderCurrentFirmServiceStyleTwo extends TemplateServiceStyleBase implements StylesHeaderCurrentFirmServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'inline-flex',
      alignItems: 'center'
    }
  }
}
