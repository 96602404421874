import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import useNextOrderAvailableTimeSentence from '~/src/hooks/orders/useNextOrderAvailableTimeSentence'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import InfoIcon from '@mui/icons-material/Info'
import { Firm } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const CanOnlyOrderDuringOperationalHoursButton = () => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { t } = useTranslations()
  const sentence = useNextOrderAvailableTimeSentence()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnClick = () => {
    if (!firm) {
      return
    }

    responsiveDialogContext?.toggle && responsiveDialogContext.toggle()
  }

  return (
    <Button
      variant='outlined'
      startIcon={<InfoIcon />}
      color='error'
      sx={{
        textTransform: 'none',
        cursor: ''
      }}
      onClick={handleOnClick}
    >
      {t('checkout.orders_only_during_operational_hours')}: {sentence}
    </Button>
  )
}

export default CanOnlyOrderDuringOperationalHoursButton
