import { paperClasses } from '@mui/material'
import StylesPopoverServiceInterface
  from '~/src/components/mui-wrappers/styled/popover/styles/StylesPopoverServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesPopoverServiceStyleTwo extends TemplateServiceStyleBase implements StylesPopoverServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`.${paperClasses.root}`]: {
        borderRadius: '20px'
      }
    }
  }
}
