import Fee from '~/src/components/fees/Fee'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const DeliveryFees = () => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  return (
    <Fee
      label={t('global.delivery_fee')}
      value={checkoutForm.delivery_method.deliveryCost}
    />
  )
}

export default DeliveryFees
