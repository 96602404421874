import { buttonBaseClasses, tabsClasses, tabScrollButtonClasses } from '@mui/material'
import StylesTabsServiceInterface from '~/src/components/mui-wrappers/styled/tabs/styles/StylesTabsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabsServiceStyleTwo extends TemplateServiceStyleBase implements StylesTabsServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      minHeight: 0,
      overflowX: 'hidden !important',
      [`.${buttonBaseClasses.root}.${tabScrollButtonClasses.root}.${tabScrollButtonClasses.disabled}`]: {
        width: 0,
        transition: 'var(--ease-in-out)'
      },
      [`.${tabsClasses.scroller}`]: {
        height: '100%',
        [`.${tabsClasses.indicator}`]: {
          borderRadius: '12px',
          height: '100%',
          zIndex: 0
        },
        [`.${tabsClasses.flexContainer}`]: {
          '> *': {
            zIndex: 1
          }
        }
      }
    }
  }
}
