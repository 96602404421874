import { ReactNode } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import TooGoodToGoIcon from '~/src/components/marketplace/order-buttons/TooGoodToGoIcon'

interface Props {
  href: string,
  children: ReactNode,
}

const TooGoodToGoOrderButton = ({ children, href }: Props) => {
  return (
    <a href={href} target="_blank">
      <Button
        fullWidth
        sx={{
          backgroundColor: 'var(--to-good-to-go-color)',
          color: 'white',
          py: 1.25,
          textTransform: 'none',
          ':hover': {
            color: 'var(--to-good-to-go-color)'
          }
        }}
        startIcon={<TooGoodToGoIcon />}
      >
        {children}
      </Button>
    </a>
  )
}

export default TooGoodToGoOrderButton
