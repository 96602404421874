import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { Button } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

const ViewAllInfo = () => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const _handleOnClick = () => {
    responsiveDialogContext?.toggleOpen && responsiveDialogContext.toggleOpen()
  }

  return (
    <Button
      onClick={_handleOnClick}
      sx={{
        textTransform: 'none',
        p: 0,
        m: 0,
        minWidth: 0,
        fontWeight: 'normal',
        color: 'secondary.contrastText',
        '&:hover': {
          backgroundColor: 'secondary.main'
        }
      }}
    >
      {t('firm.view_all_info')}
    </Button>
  )
}

export default ViewAllInfo
