import { Box } from '@mui/material'
import { useState } from 'react'
import FirmDetail from '~/src/components/firms/FirmDetail'
import FirmHolidayPeriods from '~/src/components/firms/FirmHolidayPeriods'
import FirmTitle from '~/src/components/firms/FirmTitle'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import SelectAnotherShopButton from '~/src/components/firms/SelectAnotherShopButton'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import FirmOpeningHours from '~/src/components/firms/FirmOpeningHours'

const FirmInfoContainer = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const theme = useCustomMuiTheme()
  const [ hasImage, setHasImage ] = useState<boolean>(true)

  const _getLayoutGridTemplateAreas = (hasImage: boolean): string => {
    if (hasImage) {
      return '' +
        '"firm-image firm-name"' +
        '"firm-image firm-opening-hours"' +
        '"firm-details firm-opening-hours"' +
        '"firm-details firm-opening-hours"' +
        '"firm-details firm-holidays"' +
        '"actions ."'
    }

    return '' +
      '"firm-details firm-name"' +
      '"firm-details firm-opening-hours"' +
      '"firm-details firm-opening-hours"' +
      '"firm-details firm-opening-hours"' +
      '"firm-details firm-holidays"' +
      '"actions ."'
  }

  const handleImageOnError = () => {
    setHasImage(false)
  }

  if (!firm) {
    return null
  }

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateAreas: _getLayoutGridTemplateAreas(hasImage),
      gridTemplateColumns: '30% auto',
      gridTemplateRows: 'max-content repeat(5, auto)',
      rowGap: 2,
      columnGap: 8,
      [theme.breakpoints.down('md')]: {
        gridTemplateAreas: '' +
          '"firm-name firm-name"' +
          '"firm-image ."' +
          '"firm-details firm-details"' +
          '"firm-opening-hours firm-opening-hours"' +
          '"firm-holidays firm-holidays"' +
          '"actions actions"',
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: '' +
          '"firm-name"' +
          '"firm-image"' +
          '"firm-details"' +
          '"firm-opening-hours"' +
          '"firm-holidays"' +
          '"actions"',
        gridTemplateColumns: 'auto'
      }
    }}>
      {hasImage && (
        <Box
          gridArea='firm-image'
          component='img'
          src={firm.assets.shop_image}
          alt={firm.name}
          onError={handleImageOnError}
          sx={{
            width: '100%',
            borderRadius: '20px',
            aspectRatio: '1 / 1',
            objectFit: 'cover'
          }}
        />
      )}
      <Box gridArea='firm-details'>
        <FirmDetail />
      </Box>
      <Box gridArea='actions'>
        <SelectAnotherShopButton />
      </Box>

      <Box gridArea='firm-name'>
        <FirmTitle variant='h3' />
      </Box>

      <Box gridArea='firm-opening-hours'>
        <FirmOpeningHours />
      </Box>

      <Box gridArea='firm-holidays'>
        <FirmHolidayPeriods />
      </Box>
    </Box>
  )
}

export default FirmInfoContainer
