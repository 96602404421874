import DropdownContext from '~/src/components/mui-wrappers/dropdown/DropdownContext'
import { MouseEvent, ReactNode, useState } from 'react'
import DropdownToggle from '~/src/components/mui-wrappers/dropdown/DropdownToggle'
import { ButtonProps } from '@mui/material'

interface Props extends Partial<Pick<ButtonProps, 'color' | 'variant' | 'useAutomaticShading' | 'sx'>> {
  toggleContent: ReactNode,
  children: ReactNode,
}

const Dropdown = ({ toggleContent, color, variant = 'text', useAutomaticShading, sx = {}, children }: Props) => {
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <DropdownToggle
        color={color}
        variant={variant}
        useAutomaticShading={useAutomaticShading}
        open={open}
        onClick={handleClick}
        sx={{
          borderRadius: 'var(--btn-border-radius)',
          '&:hover': {
            border: 'none'
          },
          ...sx
        }}
      >
        {toggleContent}
      </DropdownToggle>

      <DropdownContext.Provider
        value={{
          anchorEl,
          open,
          handleClose
        }}
      >
        {children}
      </DropdownContext.Provider>
    </>
  )
}

export default Dropdown
