import LayoutTermsAndConditionsServiceStyleOne
  from '~/src/pages/legal/terms-and-conditions/layout/LayoutTermsAndConditionsServiceStyleOne'
import LayoutTermsAndConditionsServiceStyleThree
  from '~/src/pages/legal/terms-and-conditions/layout/LayoutTermsAndConditionsServiceStyleThree'
import LayoutTermsAndConditionsServiceStyleTwo
  from '~/src/pages/legal/terms-and-conditions/layout/LayoutTermsAndConditionsServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTermsAndConditionsLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutTermsAndConditionsServiceStyleTwo().getLayout()
    case TemplateEnum.STYLE_THREE:
      return new LayoutTermsAndConditionsServiceStyleThree().getLayout()
    default:
      return new LayoutTermsAndConditionsServiceStyleOne().getLayout()
  }
}

export default getTermsAndConditionsLayout
