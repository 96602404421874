import { PickupPoint } from '@eo-storefronts/eo-core'
import PlaceIcon from '@mui/icons-material/Place'
import { Box, Typography } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  point: PickupPoint,
  showDetails?: boolean,
  showIcon?: boolean,
}

const DeliveryPickupPointWithDetails = ({ point, showIcon = true, showDetails = true }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        width: `calc(100% - ${muiTheme.spacing(5)})`
      }}
    >
      {showIcon && <PlaceIcon/>}
      <Box sx={{ width: '100%' }}>
        <Typography
          className='eo-ellipsis'
          variant={showDetails ? 'body1' : 'body2'}>
          {point.name}
        </Typography>
        {showDetails && (
          <Typography
            className='eo-ellipsis'
            variant='body2'
          >
            {point.address}, {point.zipcode} {point.locality}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default DeliveryPickupPointWithDetails
