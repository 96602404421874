const DeliverooIcon = () => (
  <svg
    viewBox='0 0 24 24'
    width='1em'
    height='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      xmlns='http://www.w3.org/2000/svg'
      fill='#00CCBC'
      d='m16.869 0-1.123 10.578-1.914-8.918-6.039 1.258 1.896 8.918L1 13.66l1.546 7.112L17.845 24l3.498-7.751L23 .638 16.869 0zM11.5 15.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm4 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z'/>
  </svg>
)

export default DeliverooIcon
