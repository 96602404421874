import { useEffect } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Firm } from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'

const getIosAppId = (firm: Firm): string => {
  const iosAppUrl: string | undefined = firm?.settings.ios_app_url
  let id = ''

  if (iosAppUrl) {
    const url: URL = new URL(iosAppUrl)
    const splitUrl: string[] = url.pathname.split('/')
    const lastUrlElement: string = splitUrl[splitUrl.length - 1]

    id = lastUrlElement.substring(2)
  }

  return id
}

const addIosBanner = (appId: string): void => {
  const meta = document.createElement('meta')

  meta.name = 'apple-itunes-app'
  meta.content = `app-id=${appId}`
  document.head.appendChild(meta)
}

const removeIosBanner = (): void => {
  const iosMeta = document
    .getElementsByTagName('meta')
    .namedItem('apple-itunes-app')

  if (iosMeta) {
    document.head.removeChild(iosMeta)
  }
}

const useInitDownloadAppBanner = (): void => {
  const firm = useEoValue(FIRM_SELECTOR)

  useEffect(() => {
    if (!firm || EnvUtils.isDeviceApp) {
      return
    }

    const appId = getIosAppId(firm)

    if (appId) {
      addIosBanner(appId)
    }
  }, [ firm ])

  useEffect(() => {
    return () => {
      removeIosBanner()
    }
  }, [])
}

export default useInitDownloadAppBanner
