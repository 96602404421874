import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Collapse as MUICollapse, Stack, StackProps, SxProps } from '@mui/material'
import { ReactNode, useState } from 'react'

interface Props extends Omit<StackProps, 'children' | 'title'> {
  title?: ReactNode,
  children?: ReactNode,
  expandIcon?: ReactNode,
  collapseIcon?: ReactNode,
  sx?: SxProps,
}

const Collapse = ({
  title,
  children,
  expandIcon = <ExpandMore />,
  collapseIcon = <ExpandLess />,
  flexDirection = 'row',
  justifyContent = 'flex-end',
  alignItems = 'center',
  sx = {},
  ...otherProps
}: Props) => {
  const [ open, setOpen ] = useState<boolean>(false)

  const handleOnClick = () => {
    setOpen(!open)
  }

  return (
    <Box sx={sx}>
      <Stack
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
        sx={{
          gap: 1,
          cursor: 'pointer'
        }}
        onClick={handleOnClick}
        {...otherProps}
      >
        {title}
        {open ? collapseIcon : expandIcon}
      </Stack>
      <MUICollapse
        in={open}
        sx={{
          ml: 3.5
        }}
      >
        {children}
      </MUICollapse>
    </Box>
  )
}

export default Collapse
