import FacebookPixelEventData from '~/src/types/facebook-pixel/FacebookPixelEventData'
import { FacebookPixelEventType } from '~/src/types/facebook-pixel/FacebookPixelEventType'
import ReactPixel from 'react-facebook-pixel'

interface ReturnsType {
  logEvent(eventName: FacebookPixelEventType | string, customer?: boolean, data?: FacebookPixelEventData): void,
}

const useLogFacebookAnalyticEvent = (): ReturnsType => {
  const logEvent = (eventName: FacebookPixelEventType | string, custom = false, data?: FacebookPixelEventData): void => {
    if (!ReactPixel) {
      return
    }

    if (!custom) {
      ReactPixel.track(eventName, data)
      return
    }

    ReactPixel.trackCustom(eventName, data)
  }

  return { logEvent }
}

export default useLogFacebookAnalyticEvent
