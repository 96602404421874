import { Box } from '@mui/material'
import { useDeliveryMethodsInlineTime } from '~/src/hooks/delivery-methods/useDeliveryMethodsInlineTime'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const DeliveryMethodsTimeSummary = () => {
  const { sentence } = useDeliveryMethodsInlineTime()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <AccessTimeIcon /> {sentence}
    </Box>
  )
}

export default DeliveryMethodsTimeSummary
