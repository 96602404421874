import { GridProps, SxProps } from '@mui/material'
import StylesProductsModalListContainerServiceInterface
  from '~/src/components/products/modals/products-list/container/styles/StylesProductsModalListContainerServiceInterface'

export default class StylesProductsModalListContainerServiceStyleFour implements StylesProductsModalListContainerServiceInterface {
  public getGridItemsProps(): Partial<GridProps> {
    return {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6
    }
  }

  public getGridProps(): Partial<GridProps> {
    return {
      columns: 6,
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  public getPageSx(): SxProps {
    return {}
  }
}
