import { Box, Link, LinkProps } from '@mui/material'
import { ReactElement } from 'react'

interface Props extends LinkProps {
  startIcon?: ReactElement,
  endIcon?: ReactElement,
}

const IconLink = ({ startIcon, endIcon, children, ...otherProps }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1
      }}
      {...otherProps}
    >
      {startIcon}

      <Link {...otherProps}>
        {children}
      </Link>

      {endIcon}
    </Box>
  )
}

export default IconLink
