import StylesTabServiceInterface from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceInterface'
import StylesTabServiceStyleOne from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceStyleOne'
import StylesTabServiceStyleThree from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceStyleThree'
import StylesTabServiceStyleTwo from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTabTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTabServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTabServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesTabServiceStyleThree(muiTheme)
    default:
      return new StylesTabServiceStyleOne(muiTheme)
  }
}

export default getTabTemplateService
