import StylesLinkTabServiceInterface
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLinkTabServiceStyleTwo extends TemplateServiceStyleBase implements StylesLinkTabServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      '--font-size': '1em !important',
      '--base-size': '40px !important',
      maxWidth: '100%',
      transition: 'var(--ease-in-out)',
      borderRadius: '12px',
      minHeight: 'var(--base-size)',
      height: 'var(--base-size)',
      whiteSpaces: 'nowrap',
      fontWeight: 500,
      fontSize: 'var(--font-size)',
      textTransform: 'none',
      '&:not(:first-of-type)': {
        marginLeft: this.muiTheme.spacing(0.5)
      },
      '&:not(:last-of-type)': {
        marginRight: this.muiTheme.spacing(0.5)
      },
      backgroundColor: (this.muiTheme.palette as any).container.main,
      color: (this.muiTheme.palette as any).container.contrastText,
      '&.Mui-selected': {
        color: (this.muiTheme.palette as any).primary.contrastText,
        backgroundColor: this.muiTheme.palette.primary.main
      },
      '&:not(.Mui-selected):hover': {
        opacity: 0.65,
        color: (this.muiTheme.palette as any).primary.contrastText,
        backgroundColor: this.muiTheme.palette.primary.main
      },
      [this.muiTheme.breakpoints.up('2k')]: {
        '--base-size': 'calc(40px + var(--font-size) / 2) !important'
      }
    }
  }
}
