import { PlatformUtils } from '@eo-storefronts/eo-core'
import { Box, drawerClasses, styled, SwipeableDrawer as MUISwipeableDrawer, SwipeableDrawerProps } from '@mui/material'
import { SyntheticEvent } from 'react'

const Puller = styled(Box)(({ theme }) => ({
  width: '30px',
  height: '6px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: 3,
  left: 'calc(50% - 15px)',
  ':hover': {
    cursor: 'grab'
  }
}))

interface Props extends SwipeableDrawerProps {
  showPuller?: boolean,
}

const SwipeableDrawerStyled = styled(({ children, showPuller = false, ...otherProps }: Props) => {
  const handleOnPullerClick = ($event: SyntheticEvent) => {
    !PlatformUtils().isMobile && otherProps.onClose($event)
  }

  return (
    <MUISwipeableDrawer
      disableSwipeToOpen
      disableBackdropTransition
      disableDiscovery
      {...otherProps}
    >
      {showPuller && (
        <Box
          sx={{
            height: '24px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Puller onClick={handleOnPullerClick} />
        </Box>
      )}

      {children}
    </MUISwipeableDrawer>
  )
})(({ theme }) => ({
  [`.${drawerClasses.paper}`]: {
    padding: theme.spacing(3),
    backgroundColor: 'background.main',
    color: 'background.contrastText'
  },
  [`.${drawerClasses.paperAnchorLeft}`]: {
    paddingTop: 'var(--eo-safe-area-top)'
  },
  [`.${drawerClasses.paperAnchorBottom}`]: {
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    paddingTop: 0,
    paddingBottom: 'var(--eo-safe-area-bottom)',
    paddingLeft: 'var(--eo-safe-area-left)',
    paddingRight: 'var(--eo-safe-area-right)',
    maxHeight: 'calc(100vh - var(--eo-safe-area-top))'
  }
}))

export default SwipeableDrawerStyled
