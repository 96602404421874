export enum DialogNameEnum {
  SHOPPING_CART = 'shopping-cart',
  PROFILE_QUICK_AUTH = 'profile-quick_auth',
  AUTH_MODAL = 'auth-modal',
  LANGUAGE_SELECTION = 'language-selection',
  ANNOUNCEMENTS = 'announcements',
  LOCATION_CONFIRMATION = 'location-confirmation',
  DELIVERY_METHOD_CONFIRMATION = 'delivery-methods-confirmation',
  THANK_YOU_FOR_YOUR_ORDER = 'thank-your-for-your-order-dialog',
  DEV_MODE = 'dev-mode-dialog'
}
