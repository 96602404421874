import ExpandableSearchButton from '~/src/components/mui-wrappers/styled/expandable-search-button'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useLocation } from 'react-router-dom'

const LocationSubHeaderSearchButton = () => {
  const [ search, setSearch ] = useState<string>('')
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { t } = useTranslations()
  const { pathname } = useLocation()

  const _handleOnEnterPressed = ($event: KeyboardEvent<HTMLInputElement>) => {
    if ($event.key !== 'Enter') {
      return
    }

    if (search === '') {
      push(resolve(RoutesEnum.MENU))
    } else {
      push(resolve(`${RoutesEnum.SEARCH}/${search}`))
    }
  }

  const _handleOnInputChange = ($event: ChangeEvent<HTMLInputElement>) => {
    setSearch($event.target.value)
  }

  const _handleOnClose = () => {
    if (pathname.includes(RoutesEnum.SEARCH)) {
      push(resolve(RoutesEnum.MENU))
    }

    setSearch('')
  }

  return (
    <ExpandableSearchButton
      name='product_search'
      placeholder={t('products.search')}
      onKeyUp={_handleOnEnterPressed}
      onChange={_handleOnInputChange}
      onClose={_handleOnClose}
      value={search}
    />
  )
}

export default LocationSubHeaderSearchButton
