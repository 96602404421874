import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import router from '~/src/router'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Environment from '~/src/config/environment'
import { RecoilRoot } from 'recoil'
import '~/src/styles/index.css'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import EnvUtils from '~/src/utils/EnvUtils'

if (EnvUtils.isDeviceApp) {
  void CapacitorUpdater.notifyAppReady()
}

if (Environment.isSentryEnabled()) {
  Sentry.init({
    dsn: 'https://5f09eeffc8ba4ee08013d21c95e62755@o740136.ingest.sentry.io/6324320',
    integrations: [ new BrowserTracing(), new Sentry.Replay() ],
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  })
}

// eslint-disable-next-line no-undef
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>
)
