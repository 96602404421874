import getShoppingCartTemplateService from '~/src/components/cart/shopping-cart/styles/GetShoppingCartTemplateService'
import StylesShoppingCartServiceInterface
  from '~/src/components/cart/shopping-cart/styles/StylesShoppingCartServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartTemplate = (): StylesShoppingCartServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartTemplate
