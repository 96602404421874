import StylesTabServiceInterface from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabServiceStyleOne extends TemplateServiceStyleBase implements StylesTabServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      maxWidth: '100%'
    }
  }
}
