import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import TemplateServiceStyleBaseInterface from '~/src/utils/template/TemplateServiceStyleBaseInterface'
import { Theme } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

export default class TemplateServiceStyleBase implements TemplateServiceStyleBaseInterface {
  constructor(
    public readonly muiTheme: CustomMuiTheme,
    public readonly firmTheme?: Theme,
    public readonly layout?: LayoutState
  ) {
  }
}
