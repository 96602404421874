import Price from '~/src/components/Price'
import { Typography } from '@mui/material'
import useModifiersService from '~/src/hooks/products/modifier-groups/modifiers/useModifiersService'
import { useEoValue } from '~/src/hooks/useEoState'
import { Modifier } from '@eo-storefronts/eo-core'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { CartModifierStateInterface } from '~/src/stores/cart'

interface Props {
  cartModifier: CartModifierStateInterface,
}

const CartModifierInlinePrice = ({ cartModifier }: Props) => {
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)
  const { calculatePrice } = useModifiersService(modifiers[cartModifier.id])

  return (
    <Typography variant="body2">
      <Price
        price={calculatePrice(cartModifier)}
        displayZeroAmount={ false }
      />
    </Typography>
  )
}

export default CartModifierInlinePrice
