import { lighten, menuItemClasses, MenuProps, paperClasses } from '@mui/material'
import StylesMenuServiceInterface from '~/src/components/mui-wrappers/styled/menu/styles/StylesMenuServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMenuServiceStyleTwo extends TemplateServiceStyleBase implements StylesMenuServiceInterface {
  public getComponentSx(props?: MenuProps): Record<string, unknown> {
    if (props?.color === 'header' && props?.useAutomaticShading) {
      return {
        [`.${paperClasses.root}`]: {
          backgroundColor: lighten((this.muiTheme.palette as any).header.main, .1),
          [`.${menuItemClasses.root}`]: {
            color: (this.muiTheme.palette as any).header.contrastText,
            '> *': {
              color: 'inherit'
            }
          }
        }
      }
    }

    return {
      [`.${paperClasses.root}`]: {
        backgroundColor: (this.muiTheme.palette.background as any).main,
        [`.${menuItemClasses.root}`]: {
          color: (this.muiTheme.palette.background as any).contrastText,
          '> *': {
            color: 'inherit'
          }
        }
      }
    }
  }
}
