import { styled, Tab as MUITab, TabProps } from '@mui/material'
import { MouseEvent, useEffect, useState } from 'react'
import useGetLinkTabTemplate from '~/src/components/mui-wrappers/styled/link-tab/styles/useGetLinkTabTemplate'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import EoLinkProps from '~/src/types/EoLinkProps'

type Props = Partial<EoLinkProps> & Pick<TabProps, 'sx' | 'classes' | 'children' | 'label' | 'value'>

const LinkTab = styled(({
  href,
  children,
  resolve = true,
  onClick,
  ...tabProps
}: Props) => {
  const { push } = useRouterPush()
  const { resolve: resolveLink } = useFirmPathResolver()
  const [ linkHref, setLinkHref ] = useState('')

  useEffect(() => {
    if (!href) {
      return
    }

    if (resolve) {
      setLinkHref(resolveLink(href))
      return
    }

    setLinkHref(href)
  }, [ resolveLink, href ])

  const handleLinkClicked = (event: MouseEvent) => {
    event.preventDefault()

    if (!linkHref) {
      if (onClick) {
        onClick(event)
      }

      return
    }

    push(linkHref)
  }

  return (
    <MUITab
      component='a'
      onClick={handleLinkClicked}
      href={linkHref}
      {...tabProps}
    >
      {children}
    </MUITab>
  )
})(() => useGetLinkTabTemplate().getComponentSx())

export default LinkTab
