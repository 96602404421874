import {
  Address,
  ApiConfiguration,
  BasicResponse,
  BusinessDetails,
  CheckActivationResponse,
  CreateCustomerResponse,
  Customer,
  CustomerAuthBaseResponse,
  CustomerService,
  Firm,
  Id,
  LoyaltyHistory
} from '@eo-storefronts/eo-core'

export const fetchCustomerById = async (id: Id, firmId: Id): Promise<Customer> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.getOneById(id)
}

export const fetchCustomerByUUID = async (uuid: string, brandId: Id): Promise<Customer> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return CustomerService.getOneByUUID(uuid)
}

export const resetPassword = async (email: string, firmId: Id): Promise<BasicResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.resetPassword(email)
}

export const resetPasswordV2 = async (email: string, brandId: Id): Promise<BasicResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return CustomerService.resetPassword(email)
}

export const create = async (entity: Customer, firmId: Id, google?: boolean): Promise<CreateCustomerResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.create(entity, google)
}
export const createV2 = async (entity: Customer, brandId: Id, google?: boolean): Promise<CreateCustomerResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return CustomerService.create(entity, google)
}

export const updateCustomer = async (customer: Customer | null, isUsingNewBrandAuthSystem: boolean, brand: Firm | null, firm: Firm | null): Promise<BasicResponse | undefined> => {
  if (isUsingNewBrandAuthSystem && brand?.id && customer?.uuid) {
    return updateV2(customer.uuid, customer, brand.id)
  }

  if (firm?.id && customer?.id) {
    return update(customer.id, customer, firm.id)
  }
}

export const update = async (customerId: Id, entity: Customer, firmId: Id): Promise<BasicResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  delete entity.addresses

  return CustomerService.update(customerId, entity)
}
export const updateV2 = async (customerUUID: string, entity: Customer, brandId: Id): Promise<BasicResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  delete entity.addresses

  return CustomerService.updateV2(customerUUID, entity)
}

export const updatePushNotificationsState = async (firmId: Id, customerId: Id, allowed: boolean): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.updatePushNotificationsState(customerId, allowed)
}

export const updatePushNotificationsStateV2 = async (brandId: Id, customerUUID: string, allowed: boolean): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', brandId.toString())

  return CustomerService.updatePushNotificationsStateV2(customerUUID, allowed)
}

export const saveDeviceToken = async (customerId: Id, firm: Firm, firmId: Id, token: string): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  if (firmId !== firm.id) {
    ApiConfiguration.addOrReplaceHeader('storeId', firm.uuid)
  }

  return CustomerService.saveDeviceToken(customerId, token)
}

export const checkLocation = async (customerUUID: string, locationId: Id, brandId: Id): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())
  ApiConfiguration.addOrReplaceHeader('firmId', locationId.toString())

  return CustomerService.checkLocation(customerUUID)
}

export const linkFirm = async (customerId: Id, firmId: Id): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmid', firmId.toString())

  return CustomerService.linkFirm(customerId)
}

export const checkEmailV2 = async (brandId: Id, email: string, google?: boolean): Promise<CustomerAuthBaseResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return CustomerService.checkEmailV2(email, google)
}

export const checkEmail = async (firmId: Id, email: string, google?: boolean): Promise<CustomerAuthBaseResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.checkEmail(email, google)
}

export const checkActivation = async (customerId: Id, id: Id): Promise<CheckActivationResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', id.toString())
  ApiConfiguration.addOrReplaceHeader('brandId', id.toString())

  return CustomerService.checkActivation(customerId)
}

export const markMessageAsRead = async (customerId: Id, messageId: number | string): Promise<unknown> => {
  clearHeaders()

  return CustomerService.markMessageAsRead(customerId, messageId)
}

export const fetchCustomerAddresses = async (customerId: Id, customerUUID: string): Promise<Address[]> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return CustomerService.getCustomerAddresses(customerUUID)
}

export const updateCustomerAddress = async (customerId: Id, customerUUID: string, address: Address): Promise<Address> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return CustomerService.updateCustomerAddress(customerUUID, address)
}

export const addCustomerAddress = async (customerId: Id, customerUUID: string, address: Address): Promise<Address> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return CustomerService.addCustomerAddress(customerUUID, address)
}

export const updateBusinessDetails = async (customerId: Id, customerUUID: string, firmId: Id, businessDetails: BusinessDetails): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.updateBusinessDetails(customerUUID, businessDetails)
}

export const deleteCustomer = async (customerId: Id, firmId: Id, customerUUID: string) => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return CustomerService.deleteCustomer(customerUUID)
}

export const deleteCustomerV2 = async (customerId: Id, brandId: Id, customerUUID: string) => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return CustomerService.deleteCustomer(customerUUID)
}

export const getLoyaltyHistory = async (firmId?: Id, brandId?: Id): Promise<LoyaltyHistory[]> => {
  clearHeaders()
  firmId && ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  brandId && ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return CustomerService.getLoyaltyHistory()
}

const clearHeaders = (): void => {
  [
    'customerId',
    'firmId',
    'storeId'
  ].forEach((key: string) => {
    ApiConfiguration.deleteHeader(key)
  })
}
