import { cardContentClasses, SxProps } from '@mui/material'
import { ProductItemCardActionsProps } from '~/src/components/products/product-item/ProductItemCardActions'
import { ProductItemCardContentProps } from '~/src/components/products/product-item/ProductItemCardContent'
import StylesProductItemServiceInterface
  from '~/src/components/products/product-item/styles/StylesProductItemServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { ProductUnitPriceProps } from '~/src/components/products/product-unit-price'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { SystemCssProperties } from '@mui/system'
import { ProductCardThemeComponent } from '@eo-storefronts/eo-core'

export default class StylesProductItemServiceStyleOne extends TemplateServiceStyleBase implements StylesProductItemServiceInterface {
  public getCardLayout(showImage: boolean, productCardOptions?: ProductCardThemeComponent): SxProps {
    return {
      display: 'grid',
      width: 'inherit',
      ...this._getGridSxProps(showImage, productCardOptions),
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      cursor: 'pointer',
      overflow: 'initial !important',
      transition: 'var(--ease-in-out)',
      '&:hover': {
        transform: 'scale(1.025)'
      }
    }
  }

  public getCardMediaStyle(): SxProps {
    return {
      gridArea: 'product-image',
      backgroundColor: 'wells.main',
      color: 'inherit',
      width: '100%'
    }
  }

  public getCardContentStyle(): SxProps {
    return {
      gridArea: 'product-content',
      color: 'inherit',
      width: '100%',
      p: 1,
      [`&.${cardContentClasses.root}:last-child`]: {
        paddingBottom: 1
      },
      [this.muiTheme.breakpoints.only('xs')]: {
        py: 1
      }
    }
  }

  public getProductItemCardContentProps(): Pick<ProductItemCardContentProps, 'showDescription'> {
    return {
      showDescription: false
    }
  }

  public getCardContentTitleSx(): SxProps {
    return {
      color: 'inherit',
      [this.muiTheme.breakpoints.only('xs')]: {
        fontWeight: 'bold'
      },
      wordBreak: 'break-word'
    }
  }

  public getCardContentTitleVariant(): OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> {
    return this.layout?.isMobile ? 'body1' : 'h6'
  }

  public getCardActionsStyle(): SxProps {
    return {
      gridArea: 'product-actions',
      mt: 'auto',
      [this.muiTheme.breakpoints.only('xs')]: {
        pt: 0
      }
    }
  }

  public getProductActionsProps(): Pick<ProductItemCardActionsProps, 'showAddToCartButton'> {
    return {
      showAddToCartButton: true
    }
  }

  public getProductPriceStyle(free: boolean): SxProps {
    return {
      color: free ? 'success.main' : 'inherit',
      '&>span:last-child': {
        display: 'none'
      }
    }
  }

  public getAddProductButtonStyle(): SxProps {
    if (!this.getProductActionsProps().showAddToCartButton) {
      return {}
    }

    return {
      display: 'none',
      minWidth: 'auto',
      p: '0.5rem',
      borderRadius: 'var(--btn-border-radius)'
    }
  }

  public getProductUnitPriceProps(): Pick<ProductUnitPriceProps, 'displayPromotion'> {
    return {}
  }

  private _getGridSxProps(showImage: boolean, productCardOptions?: ProductCardThemeComponent): Pick<SystemCssProperties, 'gridTemplateAreas' | 'gridTemplateColumns' | 'gridTemplateRows'> {
    if (showImage) {
      return {
        gridTemplateAreas:
          '"product-image"' +
          '"product-content"' +
          '"product-actions"',
        gridTemplateRows: 'max-content auto max-content',
        gridTemplateColumns: '1fr'
      }
    }

    if (productCardOptions?.show_img
      && !productCardOptions?.show_empty_product_image_placeholder) {
      return {
        gridTemplateAreas:
          '"."' +
          '"product-content"' +
          '"product-actions"',
        gridTemplateRows: 'auto max-content max-content',
        gridTemplateColumns: '1fr'
      }
    }

    return {
      gridTemplateAreas:
        '"product-content"' +
        '"product-content"' +
        '"product-actions"',
      gridTemplateRows: 'auto max-content max-content',
      gridTemplateColumns: '1fr'
    }
  }
}
