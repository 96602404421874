import { Box, BoxProps } from '@mui/material'
import { FC, Suspense, SyntheticEvent, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { SHOW_PRODUCT_DEFAULT_IMG_SELECTOR } from '~/src/stores/theme'
import { Constants } from '~/src/helpers/constants'

interface Props extends Omit<BoxProps<'img'>, 'component' | 'draggable' | 'loading'> {
  Component?: FC<any>,
  isDefault?: boolean,
}

const Image = ({ Component = Box, isDefault = false, onError, ...props }: Props) => {
  const [ imgNotFound, setImgNotFound ] = useState<boolean>(false)
  const showDefaultImg = useEoValue(SHOW_PRODUCT_DEFAULT_IMG_SELECTOR)

  const _handleOnError = (evt: SyntheticEvent<HTMLImageElement>) => {
    setImgNotFound(true)
    onError && onError(evt)
  }

  if (imgNotFound
    || !props.src
    || (!showDefaultImg && isDefault)) {
    return (
      <Component
        {...props}
        component='img'
        alt='onError_placeholder_img'
        src={Constants.TRANSPARENT_IMG_SRC}
      />
    )
  }

  return (
    <Suspense fallback={
      <Component
        component='img'
        draggable={false}
        {...props}
        src={Constants.TRANSPARENT_IMG_SRC}
        alt={'placeholder-img'}
      />
    }>
      <Component
        component='img'
        draggable={false}
        loading='lazy'
        onError={_handleOnError}
        {...props}
      />
    </Suspense>

  )
}

export default Image
