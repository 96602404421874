import { Box, Button } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  onAccept(): void,
}

const EatInModalActions = ({ onAccept }: Props) => {
  const { t } = useTranslations()

  return (
    <Box sx={{
      textAlign: 'right'
    }}>
      <Button
        onClick={onAccept}
        disableElevation
        variant='contained'
      >
        {t('global.accept')}
      </Button>
    </Box>
  )
}

export default EatInModalActions
