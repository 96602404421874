import { useEoValue } from '~/src/hooks/useEoState'
import { DOCUMENT_TITLE_SELECTOR, FAVICON_SELECTOR } from '~/src/stores/layout'
import { Helmet } from 'react-helmet'

const HtmlMeta = () => {
  const title = useEoValue(DOCUMENT_TITLE_SELECTOR)
  const favicon = useEoValue(FAVICON_SELECTOR)

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={favicon} />
      <title>{title}</title>
    </Helmet>
  )
}

export default HtmlMeta
