import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  loading: boolean,
}

const ForgotPasswordActions = ({ loading }: Props) => {
  const { t } = useTranslations()

  return (
    <Button
      loading={loading}
      variant='contained'
      type='submit'
      sx={{
        mt: 2,
        width: '100%',
        fontWeight: 'bold',
        fontSize: '1em'
      }}
    >
      {t('actions.send')}
    </Button>
  )
}

export default ForgotPasswordActions
