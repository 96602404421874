import LayoutSearchServiceStyleOne from '~/src/pages/search/layout/LayoutSearchServiceStyleOne'
import LayoutSearchServiceStyleTwo from '~/src/pages/search/layout/LayoutSearchServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import LayoutSearchServiceStyleThree from '~/src/pages/search/layout/LayoutSearchServiceStyleThree'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getSearchLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_THREE:
      return new LayoutSearchServiceStyleThree().getLayout(getLayoutArgs)
    case TemplateEnum.STYLE_TWO:
      return new LayoutSearchServiceStyleTwo().getLayout()
    default:
      return new LayoutSearchServiceStyleOne().getLayout()
  }
}

export default getSearchLayout
