import { FormField, FormFieldOptionType } from '@eo-storefronts/eo-core'
import { FormValidationOptions } from '~/src/types/forms/FormValidationOptions'
import ValidatorUtils from '~/src/utils/ValidatorUtils'
import { BaseSchema, StringSchema } from 'yup'

export default class FormFieldsService {
  private readonly _fields: FormField[]

  constructor(formFields: FormField[]) {
    this._fields = formFields
  }

  public is(fieldName: string, option: FormFieldOptionType): boolean {
    const field: FormField | undefined = this._getField(fieldName)

    if (!field) {
      return false
    }

    return field.option === option
  }

  public isAll(option: FormFieldOptionType): boolean {
    return this._fields.every((field: FormField) => field.option === option)
  }

  public getPhoneFieldValidator(fieldName: string, options: FormValidationOptions<StringSchema>): StringSchema {
    const { baseSchema, requiredMessage, invalidFormatMessage } = options

    if (this.is(fieldName, 'required')) {
      return baseSchema
        .min(5, requiredMessage)
        .test('isNumberValid', invalidFormatMessage!, ValidatorUtils.isPhoneNumberValid)
    }

    if (this.is(fieldName, 'hidden')) {
      return baseSchema.notRequired()
    }

    return baseSchema.test(
      'isNumberValid',
      invalidFormatMessage!,
      (value: string | undefined) => {
        if (!value || value === '+') {
          return true
        }

        return ValidatorUtils.isPhoneNumberValid(value)
      }
    )
  }

  public getFieldRequiredValidator<T extends BaseSchema>(fieldName: string, options: FormValidationOptions<T>): T {
    const { baseSchema, requiredMessage } = options

    if (this.is(fieldName, 'required')) {
      return baseSchema.required(requiredMessage)
    }

    return baseSchema.notRequired()
  }

  private _getField = (fieldName: string): FormField | undefined => {
    return this._fields.find((field: FormField) => field.key === fieldName)
  }
}
