import { StatusBar, Style } from '@capacitor/status-bar'
import { Firm, TemplateEnum, Theme } from '@eo-storefronts/eo-core'
import {
  badgeClasses,
  buttonClasses,
  darken,
  inputBaseClasses,
  inputLabelClasses,
  lighten,
  svgIconClasses,
  Theme as MUITheme,
  useTheme as useMUITheme
} from '@mui/material'
import { useEffect } from 'react'
import { useEoState } from '~/src/hooks/useEoState'
import { THEME_STATE } from '~/src/stores/theme'
import CssUtils from '~/src/utils/CssUtils'

interface ReturnsType {
  set(firm: Firm | null, fallbackFirm: Firm | null | undefined): Promise<void>,
}

const _setFont = async (name: string, url: string): Promise<void> => {
  const font = new FontFace(name, `url(${url})`)
  document.fonts.add(font)
  await font.load()
}

const _getThemeKey = (theme: Theme | undefined, fallbackTheme: Theme | undefined, key: keyof Theme): string => {
  if (theme && theme[key]) {
    return theme[key] as string
  }

  if (fallbackTheme && fallbackTheme[key]) {
    return fallbackTheme[key] as string
  }

  return ''
}

const _getStatusBarBackgroundColor = (firm: Firm | null, fallbackFirm: Firm | null | undefined): keyof Theme => {
  const style = firm?.settings.theme.pages?.location || fallbackFirm?.settings.theme.pages?.location || TemplateEnum.STYLE_ONE

  if (style === TemplateEnum.STYLE_TWO) {
    return 'main_gradient_color_from'
  }

  return 'main_color'
}

const _updateStatusBarTextColor = async (
  firm: Firm | null,
  fallbackFirm: Firm | null | undefined,
  lightOrDark: 'light' | 'dark'
): Promise<void> => {
  try {
    await StatusBar.setBackgroundColor({
      color: _getThemeKey(
        firm?.settings.theme,
        fallbackFirm?.settings.theme,
        _getStatusBarBackgroundColor(firm, fallbackFirm)
      )
    })
  } catch (e) { /* Ignore */
  }

  if (lightOrDark === 'light') {
    return StatusBar.setStyle({ style: Style.Light })
  }

  return StatusBar.setStyle({ style: Style.Dark })
}

const _setCustomFonts = async (theme: Theme | undefined, fallbackTheme: Theme | undefined): Promise<void> => {
  const fontHeader = _getThemeKey(theme, fallbackTheme, 'font_header_title')
  const fontGeneral = _getThemeKey(theme, fallbackTheme, 'font_general')

  if (fontHeader) {
    await _setFont('TitleFont', fontHeader)
  }

  if (fontGeneral) {
    await _setFont('GeneralFont', fontGeneral)
  }
}

export const shadify = (color: string, percentage = 10): string => {
  if (CssUtils.isColorLightOrDark(color) === 'light') {
    return darken(color, (percentage / 2) / 100)
  }

  return lighten(color, percentage / 100)
}

const createCssVariables = (muiTheme: MUITheme, theme: Theme | undefined, fallbackTheme: Theme | undefined): void => {
  document.documentElement.style.setProperty('--color-primary', CssUtils.hexToRGB(_getThemeKey(theme, fallbackTheme, 'theme_color')))
  document.documentElement.style.setProperty('--color-container', _getThemeKey(theme, fallbackTheme, 'main_gradient_color_from'))
  document.documentElement.style.setProperty('--btn-border-radius', _getThemeKey(theme, fallbackTheme, 'button_radius'))
  document.documentElement.style.setProperty('--container-background', _getThemeKey(theme, fallbackTheme, 'main_color'))
  document.documentElement.style.setProperty('--container-contrast', _getThemeKey(theme, fallbackTheme, 'main_color_contrast_color'))
  document.documentElement.style.setProperty('--ease-in-out', `all ${muiTheme.transitions.duration.standard}ms ${muiTheme.transitions.easing.easeInOut}`)
  document.documentElement.style.setProperty('--ellipsis-lines', '3')
  document.documentElement.style.setProperty('--background-from', _getThemeKey(theme, fallbackTheme, 'main_gradient_color_from'))
  document.documentElement.style.setProperty('--background-to', _getThemeKey(theme, fallbackTheme, 'main_gradient_color_to'))
  document.documentElement.style.setProperty('--page-height', `${window.innerHeight}px`)
}

const initWindowResize = () => {
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--page-height', `${window.innerHeight}px`)
  })
}

const useTheme = (): ReturnsType => {
  const muiTheme = useMUITheme()
  const [ theme, setTheme ] = useEoState(THEME_STATE)

  const set = async (firm: Firm | null, fallbackFirm: Firm | null | undefined): Promise<void> => {
    if (!firm && !fallbackFirm) {
      return
    }

    const theme = firm?.settings.theme
    const fallbackTheme = fallbackFirm?.settings.theme

    const lightOrDark: 'light' | 'dark' = CssUtils.isColorLightOrDark(_getThemeKey(theme, fallbackTheme, 'main_gradient_color_from'))
    const containerLightOrDark: 'light' | 'dark' = CssUtils.isColorLightOrDark(_getThemeKey(theme, fallbackTheme, 'container_color'))
    const contentLightOrDark: 'light' | 'dark' = CssUtils.isColorLightOrDark(_getThemeKey(theme, fallbackTheme, 'content_color'))

    try {
      await _updateStatusBarTextColor(firm, fallbackFirm, lightOrDark)
    } catch (e) { /* Ignore */
    }

    setTheme((state) => (
      {
        ...state,
        elevation: Number(_getThemeKey(theme, fallbackTheme, 'elevation')) || 1,
        palette: {
          mode: lightOrDark,
          primary: muiTheme.palette.augmentColor({
            color: {
              main: _getThemeKey(theme, fallbackTheme, 'theme_color'),
              contrastText: _getThemeKey(theme, fallbackTheme, 'theme_color_contrast_color')
            }
          }),
          secondary: muiTheme.palette.augmentColor({
            color: {
              main: _getThemeKey(theme, fallbackTheme, 'container_color'),
              contrastText: _getThemeKey(theme, fallbackTheme, 'container_contrast_color')
            }
          }),
          background: {
            main: _getThemeKey(theme, fallbackTheme, 'main_gradient_color_from'),
            from: _getThemeKey(theme, fallbackTheme, 'main_gradient_color_from'),
            to: _getThemeKey(theme, fallbackTheme, 'main_gradient_color_to'),
            contrastText: _getThemeKey(theme, fallbackTheme, 'main_gradient_contrast_color')
          },
          container: muiTheme.palette.augmentColor({
            color: {
              main: _getThemeKey(theme, fallbackTheme, 'main_color'),
              contrastText: _getThemeKey(theme, fallbackTheme, 'main_color_contrast_color')
            }
          }),
          tab: muiTheme.palette.augmentColor({
            color: {
              main: _getThemeKey(theme, fallbackTheme, 'tab_color'),
              contrastText: _getThemeKey(theme, fallbackTheme, 'tab_contrast_color')
            }
          }),
          success: {
            main: _getThemeKey(theme, fallbackTheme, 'success_gradient_color_from'),
            from: _getThemeKey(theme, fallbackTheme, 'success_gradient_color_from'),
            to: _getThemeKey(theme, fallbackTheme, 'success_gradient_color_to'),
            contrastText: _getThemeKey(theme, fallbackTheme, 'success_gradient_contrast_color')
          },
          wells: muiTheme.palette.augmentColor({
            color: {
              main: _getThemeKey(theme, fallbackTheme, 'well_color'),
              contrastText: _getThemeKey(theme, fallbackTheme, 'well_contrast_color')
            }
          }),
          text: {
            white: '#ffffff',
            black: '#000000',
            light: _getThemeKey(theme, fallbackTheme, 'container_color'),
            dark: _getThemeKey(theme, fallbackTheme, 'container_contrast_color')
          },
          content: muiTheme.palette.augmentColor({
            color: {
              main: _getThemeKey(theme, fallbackTheme, 'content_color'),
              contrastText: _getThemeKey(theme, fallbackTheme, 'content_contrast_color')
            }
          }),
          header: muiTheme.palette.augmentColor({
            color: {
              main: theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color'),
              contrastText: theme?.components?.header.color || _getThemeKey(theme, fallbackTheme, 'container_contrast_color')
            }
          })
        },
        components: {
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                fontSize: '1.2857142857142856rem !important'
              }
            }
          },
          MuiTextField: {
            variants: [
              {
                props: { color: 'container' },
                style: {
                  [`.${inputLabelClasses.root}`]: {
                    color: containerLightOrDark === 'light' ? 'black' : 'white'
                  },
                  [`.${inputBaseClasses.root}`]: {
                    [`&.${inputBaseClasses.formControl}`]: {
                      backgroundColor:
                        containerLightOrDark === 'light' ?
                          lighten(_getThemeKey(theme, fallbackTheme, 'container_color'), .1)
                          : darken(_getThemeKey(theme, fallbackTheme, 'container_color'), .1)
                    },
                    input: {
                      backgroundColor: 'transparent'
                    },
                    color: containerLightOrDark === 'light' ? 'black' : 'white',
                    fieldset: {
                      // backgroundColor: containerLightOrDark === 'light' ? 'white' : 'black',
                      backgroundColor: 'transparent',
                      borderColor: containerLightOrDark === 'light' ? muiTheme.palette.grey[800] : muiTheme.palette.grey[100]
                    },
                    '&:hover': {
                      fieldset: {
                        borderColor: _getThemeKey(theme, fallbackTheme, 'theme_color')
                      }
                    },
                    svg: {
                      color: containerLightOrDark === 'light' ? 'black' : 'white'
                    }
                  }
                }
              },
              {
                props: { color: 'content' },
                style: {
                  [`.${inputLabelClasses.root}`]: {
                    color: contentLightOrDark === 'light' ? 'black' : 'white'
                  },
                  [`.${inputBaseClasses.root}`]: {
                    [`&.${inputBaseClasses.formControl}`]: {
                      backgroundColor:
                        contentLightOrDark === 'light' ?
                          darken(_getThemeKey(theme, fallbackTheme, 'content_color'), .1)
                          : lighten(_getThemeKey(theme, fallbackTheme, 'content_color'), .1)
                    },
                    input: {
                      backgroundColor: 'transparent'
                    },
                    color: contentLightOrDark === 'light' ? 'black' : 'white',
                    fieldset: {
                      // backgroundColor: containerLightOrDark === 'light' ? 'white' : 'black',
                      backgroundColor: 'transparent',
                      borderColor: contentLightOrDark === 'light' ? darken(_getThemeKey(theme, fallbackTheme, 'content_color'), .3) : lighten(_getThemeKey(theme, fallbackTheme, 'content_color'), .3)
                    },
                    '&:hover': {
                      fieldset: {
                        borderColor: _getThemeKey(theme, fallbackTheme, 'theme_color')
                      }
                    },
                    svg: {
                      color: contentLightOrDark === 'light' ? 'black' : 'white'
                    }
                  }
                }
              }
            ]
          },
          MuiButton: {
            styleOverrides: {
              root: {
                [`.${buttonClasses.startIcon}, .${buttonClasses.endIcon}`]: {
                  [`>.${svgIconClasses.root}`]: {
                    fontSize: '1.2857142857142856rem !important'
                  }
                }
              }
            },
            variants: [
              {
                props: { color: 'header', useAutomaticShading: true },
                style: {
                  color: `${theme?.components?.header.color || _getThemeKey(theme, fallbackTheme, 'container_contrast_color')} !important`,
                  backgroundColor: `${shadify(theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color'))} !important`,
                  borderColor: `${theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color')} !important`,
                  ['&:hover']: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')}`
                  },
                  [`&.${buttonClasses.disabled}`]: {
                    color: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              },
              {
                props: { color: 'container', useAutomaticShading: true },
                style: {
                  color: `${_getThemeKey(theme, fallbackTheme, 'container_contrast_color')} !important`,
                  backgroundColor: `${shadify(_getThemeKey(theme, fallbackTheme, 'container_color'))} !important`,
                  borderColor: `${_getThemeKey(theme, fallbackTheme, 'container_color')} !important`,
                  ['&:hover']: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')}`
                  },
                  [`&.${buttonClasses.disabled}`]: {
                    color: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              },
              {
                props: { color: 'container' },
                style: {
                  [`&.${buttonClasses.disabled}`]: {
                    color: containerLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: containerLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              },
              {
                props: { color: 'content' },
                style: {
                  color: _getThemeKey(theme, fallbackTheme, 'content_color'),
                  backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                  border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_color')}`,
                  ['&:hover']: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')}`
                  },
                  [`&.${buttonClasses.disabled}`]: {
                    color: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              }
            ]
          },
          MuiIconButton: {
            variants: [
              {
                props: { color: 'header', useAutomaticShading: true },
                style: {
                  color: `${theme?.components?.header.color || _getThemeKey(theme, fallbackTheme, 'container_contrast_color')} !important`,
                  backgroundColor: `${shadify(theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color'))} !important`,
                  borderColor: `${theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color')} !important`,
                  ['&:hover']: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')}`
                  },
                  [`&.${buttonClasses.disabled}`]: {
                    color: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              },
              {
                props: { color: 'container', useAutomaticShading: true },
                style: {
                  color: `${_getThemeKey(theme, fallbackTheme, 'container_contrast_color')} !important`,
                  backgroundColor: `${shadify(_getThemeKey(theme, fallbackTheme, 'container_color'))} !important`,
                  borderColor: `${_getThemeKey(theme, fallbackTheme, 'container_color')} !important`,
                  ['&:hover']: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')}`
                  },
                  [`&.${buttonClasses.disabled}`]: {
                    color: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              },
              {
                props: { color: 'container' },
                style: {
                  [`&.${buttonClasses.disabled}`]: {
                    color: containerLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: containerLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              },
              {
                props: { color: 'content' },
                style: {
                  color: _getThemeKey(theme, fallbackTheme, 'content_color'),
                  backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                  border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_color')}`,
                  ['&:hover']: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    border: `1px solid ${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')}`
                  },
                  [`&.${buttonClasses.disabled}`]: {
                    color: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
                    backgroundColor: contentLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                  }
                }
              }
            ]
          },
          MuiBadge: {
            variants: [
              {
                props: { color: 'header', useAutomaticShading: true },
                style: {
                  [`.${badgeClasses.badge}`]: {
                    color: theme?.components?.header.color || _getThemeKey(theme, fallbackTheme, 'container_contrast_color'),
                    backgroundColor: shadify(theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color')),
                    borderColor: theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color')
                  }
                }
              },
              {
                props: { color: 'content', useAutomaticShading: true },
                style: {
                  [`.${badgeClasses.badge}`]: {
                    color: _getThemeKey(theme, fallbackTheme, 'content_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'content_contrast_color'),
                    borderColor: theme?.components?.header.background_color
                  }
                }
              },
              {
                props: { color: 'container', useAutomaticShading: true },
                style: {
                  [`.${badgeClasses.badge}`]: {
                    color: _getThemeKey(theme, fallbackTheme, 'container_color'),
                    backgroundColor: _getThemeKey(theme, fallbackTheme, 'container_contrast_color'),
                    borderColor: theme?.components?.header.background_color
                  }
                }
              }
            ]
          },
          MuiAvatar: {
            variants: [
              {
                props: { color: 'header', useAutomaticShading: true },
                style: {
                  color: theme?.components?.header.color || _getThemeKey(theme, fallbackTheme, 'container_contrast_color'),
                  backgroundColor: shadify(theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color')),
                  borderColor: theme?.components?.header.background_color || _getThemeKey(theme, fallbackTheme, 'container_color')
                }
              },
              {
                props: { color: 'content', useAutomaticShading: true },
                style: {
                  color: `${_getThemeKey(theme, fallbackTheme, 'content_contrast_color')} !important`,
                  backgroundColor: `${shadify(_getThemeKey(theme, fallbackTheme, 'content_color'))} !important`,
                  borderColor: 'transparent',
                  borderWidth: '2px'
                }
              },
              {
                props: { color: 'container', useAutomaticShading: true },
                style: {
                  color: `${_getThemeKey(theme, fallbackTheme, 'container_contrast_color')} !important`,
                  backgroundColor: `${shadify(_getThemeKey(theme, fallbackTheme, 'container_color'))} !important`,
                  borderColor: 'transparent',
                  borderWidth: '2px'
                }
              }
            ]
          }
        }
      }
    ))
    createCssVariables(muiTheme, theme, fallbackTheme)
    initWindowResize()

    await _setCustomFonts(theme, fallbackTheme)
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      (window as any).theme = theme
    }
  }, [ theme ])

  return { set }
}

export default useTheme
