import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import { Box, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

const CartEmpty = () => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
        p: 2
      }}
    >
      <ShoppingCartOutlinedIcon
        color='inherit'
        sx={{
          fontSize: '4rem'
        }}
      />
      <Typography
        component='p'
        sx={{
          textAlign: 'center',
          color: 'inherit'
        }}
      >
        {t('cart.empty.oops')} <br />
        {t('cart.empty.advice')}
      </Typography>
    </Box>
  )
}

export default CartEmpty
