import { Link, Typography } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

const LoginFormDialogActivator = () => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <Typography
      variant='body2'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1
      }}
    >
      {t('delivery_methods.login_question')}
      <Link
        component='button'
        variant='body2'
        onClick={responsiveDialogContext.toggleOpen}
      >
        {t('auth.log_in')}
      </Link>
    </Typography>
  )
}

export default LoginFormDialogActivator
