import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface ReturnsType {
  offset: number | string,
}

const useGetFooterOffset = (forFooterElement = false): ReturnsType => {
  const [ offset, setOffset ] = useState<number | string>(0)
  const { mobileNavigation, footer } = useEoValue(LAYOUT_STATE)

  const getBottomOffset = (): number | string => {
    const footerHTMLElement: HTMLElement | undefined = document.getElementsByTagName('footer')[0]
    const mobileNavigationHTMLElement: HTMLElement | null = document.getElementById('mobile-navigation')

    if (footer.display || mobileNavigation.display) {
      let calculatedOffset = 0

      if (footerHTMLElement && !forFooterElement) {
        calculatedOffset += footerHTMLElement.getBoundingClientRect().height
      }

      if (mobileNavigationHTMLElement) {
        calculatedOffset += mobileNavigationHTMLElement.getBoundingClientRect().height
      }

      return `${calculatedOffset}px`
    }

    return 0
  }

  useEffect(() => {
    setOffset(getBottomOffset())
  }, [ footer.display ])


  return { offset }
}

export default useGetFooterOffset
