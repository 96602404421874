import DialogStyled from '~/src/components/mui-wrappers/styled/dialog'
import { ResponsiveDialogEventType } from '~/src/types/ResponsiveDialogEventType'
import { DialogOverriddenProps, ResponsiveDialogWrapperProps } from '~/src/types/ResponsiveDialogWrapperProps'

const DialogWrapper = ({
  open,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOpen,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ModalProps,
  onClose,
  maxWidth,
  fullWidth,
  ...otherProps
}: ResponsiveDialogWrapperProps) => (
  <DialogStyled
    open={open}
    onClose={($event: ResponsiveDialogEventType) => {
      onClose && onClose($event)
    }}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    {...otherProps as DialogOverriddenProps} />
)
export default DialogWrapper
