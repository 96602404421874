import StylesMenuServiceInterface from '~/src/components/mui-wrappers/styled/menu/styles/StylesMenuServiceInterface'
import StylesMenuServiceStyleOne from '~/src/components/mui-wrappers/styled/menu/styles/StylesMenuServiceStyleOne'
import StylesMenuServiceStyleTwo from '~/src/components/mui-wrappers/styled/menu/styles/StylesMenuServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getMenuTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesMenuServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesMenuServiceStyleTwo(muiTheme)
    default:
      return new StylesMenuServiceStyleOne(muiTheme)
  }
}

export default getMenuTemplateService
