import { useEoValue } from '~/src/hooks/useEoState'
import { ANNOUNCEMENTS } from '~/src/stores/announcements'
import { AnnouncementsService } from '@eo-storefronts/eo-core'

const useFirmAnnouncementsService = (): AnnouncementsService => {
  const announcements = useEoValue(ANNOUNCEMENTS)
  
  return new AnnouncementsService(announcements)
}

export default useFirmAnnouncementsService
