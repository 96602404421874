import getHeaderLanguageSelectorTemplateService
  from '~/src/components/layout/header/header-language-selector/styles/GetHeaderLanguageSelectorTemplateService'
import StylesHeaderLanguageSelectorServiceInterface
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import { useEoValue } from '~/src/hooks/useEoState'
import { Theme } from '@eo-storefronts/eo-core'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'

const useGetHeaderLanguageSelectorTemplate = (): StylesHeaderLanguageSelectorServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('header')
  const firmTheme = useEoValue<Theme | undefined>(FIRM_THEME_SELECTOR)

  return getHeaderLanguageSelectorTemplateService(componentStyle, muiTheme, firmTheme)
}

export default useGetHeaderLanguageSelectorTemplate
