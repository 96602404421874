import { Box } from '@mui/material'
import AuthSelectionOption from '~/src/components/auth/auth-selection/AuthSelectionOption'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'

const AuthSelectionOptions = () => {
  const { t } = useTranslations()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }}>
      <AuthSelectionOption
        link={routes.LOGIN}
        text={t('extra_info.guest.login_to_order')}
        variant='contained'
      />

      <AuthSelectionOption
        link={routes.EXTRA_INFO_GUEST}
        text={t('extra_info.guest.continue_as_guest')}
        color='secondary'
        variant='contained'
      />
    </Box>
  )
}

export default AuthSelectionOptions
