import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'
import StylesLayoutServiceInterface from '~/src/components/layout/styles/StylesLayoutServiceInterface'
import StylesLayoutServiceStyleOne from '~/src/components/layout/styles/StylesLayoutServiceStyleOne'
import StylesLayoutServiceStyleThree from '~/src/components/layout/styles/StylesLayoutServiceStyleThree'
import StylesLayoutServiceStyleTwo from '~/src/components/layout/styles/StylesLayoutServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { LayoutState } from '~/src/stores/layout'

const getLayoutTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme, layout?: LayoutState): StylesLayoutServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLayoutServiceStyleTwo(muiTheme, firmTheme, layout)
    case TemplateEnum.STYLE_THREE:
      return new StylesLayoutServiceStyleThree(muiTheme, firmTheme, layout)
    default:
      return new StylesLayoutServiceStyleOne(muiTheme, firmTheme, layout)
  }
}

export default getLayoutTemplateService
