import { Firm } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useTranslations } from '~/src/hooks/useTranslations'
import NumberUtils from '~/src/utils/NumberUtils'

interface Props {
  price: number,
  displayZeroAmount?: boolean,
  displayZeroAsFree?: boolean,
}

const Price = ({ price, displayZeroAmount = true, displayZeroAsFree = false }: Props) => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const { t } = useTranslations()

  if (!firm) {
    return null
  }

  if (!displayZeroAmount && price === 0) {
    return null
  }

  if (displayZeroAsFree && price === 0) {
    return <>{t('global.free')}</>
  }

  return (
    <>{firm?.settings.currency?.symbol}&nbsp;{NumberUtils.toFixed(price, 2)}</>
  )
}

export default Price
