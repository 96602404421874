import SocialLoginBtn from '~/src/components/auth/social-logins/SocialLoginBtn'
import GoogleColoredIcon from '~/src/components/mui-wrappers/GoogleColoredIcon'
import useSocialSignIn from '~/src/hooks/auth/useSocialSignIn'
import { useTranslations } from '~/src/hooks/useTranslations'

const GoogleLoginBtn = () => {
  const { login, loading } = useSocialSignIn('google')
  const { t } = useTranslations()

  const handleOnClick = async () => {
    await login()
  }

  return (
    <SocialLoginBtn
      socialType='google'
      startIcon={<GoogleColoredIcon />}
      onClick={handleOnClick}
      loading={loading}
      sx={{
        color: 'text.black'
      }}
    >
      {t('auth.sign_in_with_google')}
    </SocialLoginBtn>
  )
}

export default GoogleLoginBtn
