import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import AddressDialogTitle from '~/src/components/customers/addresses/AddressDialogTitle'
import ChooseAddress from '~/src/components/customers/addresses/ChooseAddress'
import DeliveryAddressButton from '~/src/components/delivery-methods/DeliveryAddressButton'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'

const DeliveryAddress = (props?: ResponsiveDialogProps) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  return (
    <ResponsiveDialog
      activator={loading ? <CircularProgress size={20} /> : <DeliveryAddressButton />}
      maxWidth='sm'
      fullWidth
      Title={<AddressDialogTitle />}
      {...props}
    >
      <ChooseAddress
        loading={loading}
        setLoading={setLoading}
        selected={checkoutForm.delivery_method.address}
      />
    </ResponsiveDialog>
  )
}

export default DeliveryAddress
