import { Box, List } from '@mui/material'
import SideDrawerNavigationItem
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item'
import SideDrawerNavigationCustomLinks
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/SideDrawerNavigationCustomLinks'
import useGetSideDrawerNavigationItemsTemplate
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/styles/useGetSideDrawerNavigationItemsTemplate'
import SideBarListItem from '~/src/types/SideBarListItem'
import useGetSideDrawerNavigationItems from '~/src/hooks/router/navigation/useGetSideDrawerNavigationItems'

const SideDrawerNavigationItems = () => {
  const styles = useGetSideDrawerNavigationItemsTemplate()
  const navItems: SideBarListItem[] = useGetSideDrawerNavigationItems()

  return (
    <List sx={styles.getListSx()}>
      <Box sx={styles.getNavigationItemsSx()}>
        {navItems.map((item: SideBarListItem) => (
          <SideDrawerNavigationItem
            item={item}
            key={item.label}
          />
        ))}

        <SideDrawerNavigationCustomLinks />
      </Box>
    </List>
  )
}

export default SideDrawerNavigationItems
