import StylesLinkTabServiceInterface
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceInterface'
import StylesLinkTabServiceStyleOne
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceStyleOne'
import StylesLinkTabServiceStyleThree
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceStyleThree'
import StylesLinkTabServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLinkTabTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLinkTabServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLinkTabServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesLinkTabServiceStyleThree(muiTheme)
    default:
      return new StylesLinkTabServiceStyleOne(muiTheme)
  }
}

export default getLinkTabTemplateService
