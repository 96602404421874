import { Location } from '@eo-storefronts/eo-core'
import { Skeleton } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  location?: Location,
  TitleProps?: TypographyProps,
  isLoading?: boolean,
}

const LocationName = ({ location, isLoading = false, TitleProps = {} }: Props) => {
  if (isLoading || !location) {
    return (
      <Skeleton
        variant='text'
        sx={{ fontSize: '1.8rem' }}
      />
    )
  }

  return (
    <Title
      variant='h4'
      {...TitleProps}>
      {location.name}
    </Title>
  )
}

export default LocationName
