import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { Box, MobileStepper, mobileStepperClasses } from '@mui/material'
import { useContext, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { ANNOUNCEMENTS } from '~/src/stores/announcements'
import { Announcement as AnnouncementType } from '@eo-storefronts/eo-core'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import Announcement from '~/src/components/announcements/announcement'
import { LAYOUT_STATE } from '~/src/stores/layout'
import NextAnnouncementButton from '~/src/components/announcements/buttons/next'
import SkipAnnouncementsButton from '~/src/components/announcements/buttons/skip'
import useFirmAnnouncementsService from '~/src/hooks/firm/useFirmAnnouncementsService'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Announcements = () => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const announcements = useEoValue(ANNOUNCEMENTS)
  const announcementsService = useFirmAnnouncementsService()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const [ activeStep, setActiveStep ] = useState(0)

  const handleOnClose = () => {
    void announcementsService.markAllAsSeen()
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
  }

  const handleNext = () => {
    if (activeStep === announcements.length - 1) {
      handleOnClose()
      return
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <Box
      id='firm_announcements'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: isMobile ? '100%' : '400px',
        height: '100%',
        '> div:first-of-type': {
          height: 'inherit'
        }
      }}
    >
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={10000}
      >
        {announcements.map((announcement: AnnouncementType) => (
          <Announcement
            key={announcement.id}
            announcement={announcement}
          />
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={announcements.length}
        position='static'
        activeStep={activeStep}
        sx={{
          backgroundColor: 'transparent',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          p: 3,
          [`.${mobileStepperClasses.dots}`]: {
            justifyContent: 'center'
          }
        }}
        nextButton={<NextAnnouncementButton onClick={handleNext} />}
        backButton={<SkipAnnouncementsButton onClick={handleOnClose} />}
      />
    </Box>
  )
}

export default Announcements
