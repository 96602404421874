import Providers from '~/src/components/providers/Providers'
import Layout from '~/src/components/layout'
import LoaderRoot from '~/src/components/root/LoaderRoot'
import DialogsRoot from '~/src/components/root/DialogsRoot'
import AppOutlet from '~/src/components/AppOutlet'
import HtmlMeta from '~/src/components/root/HtmlMeta'
import useManifest from '~/src/hooks/useManifest'

const App = () => {
  useManifest()

  return (
    <Providers>
      <Layout>
        <AppOutlet />
      </Layout>
      <HtmlMeta />
      <LoaderRoot/>
      <DialogsRoot/>
    </Providers>
  )
}

export default App
