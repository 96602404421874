import FacebookIcon from '@mui/icons-material/Facebook'
import SocialLoginBtn from '~/src/components/auth/social-logins/SocialLoginBtn'
import useSocialSignIn from '~/src/hooks/auth/useSocialSignIn'
import { useTranslations } from '~/src/hooks/useTranslations'

const FacebookLoginBtn = () => {
  const { login, loading } = useSocialSignIn('facebook')
  const { t } = useTranslations()

  const handleOnClick = async () => {
    await login()
  }

  return (
    <SocialLoginBtn
      socialType='facebook'
      startIcon={<FacebookIcon />}
      onClick={handleOnClick}
      loading={loading}
    >
      {t('auth.sign_in_with_facebook')}
    </SocialLoginBtn>
  )
}

export default FacebookLoginBtn
