import { LayoutState } from '~/src/stores/layout'
import LayoutServiceInterface from '~/src/utils/layout/LayoutServiceInterface'

export default class LayoutTermsAndConditionsServiceStyleOne implements LayoutServiceInterface {
  public getLayout(): Partial<LayoutState> {
    return {
      header: {
        display: true,
        displayMenuBurger: false,
        displayCart: false,
        displayCurrentFirm: false,
        displayDeliveryOptions: false,
        displayLanguagesSelector: false,
        displayUserActions: false,
        displayProductSearch: false,
        displayLocationSearch: false,
        logo: {
          disableLink: false
        },
        title: '',
        type: 'default'
      },
      subHeader: {
        display: false,
        variant: 'default'
      },
      content: {
        displayNotification: false
      },
      mainSideBar: {
        display: false,
        displayCart: false,
        displayDeliveryOptions: false,
        displayOrderSummary: false
      },
      footer: {
        display: false,
        displayGoToCartBtn: false,
        displayGoToCheckoutBtn: false,
        displayInformations: false,
        displayLinks: false
      },
      mobileNavigation: {
        display: false
      }
    }
  }
}
