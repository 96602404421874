import StylesSwitchServiceInterface
  from '~/src/components/mui-wrappers/styled/toggle/styles/StylesSwitchServiceInterface'
import StylesSwitchServiceStyleOne from '~/src/components/mui-wrappers/styled/toggle/styles/StylesSwitchServiceStyleOne'
import StylesSwitchServiceStyleThree
  from '~/src/components/mui-wrappers/styled/toggle/styles/StylesSwitchServiceStyleThree'
import StylesSwitchServiceStyleTwo from '~/src/components/mui-wrappers/styled/toggle/styles/StylesSwitchServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getSwitchTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesSwitchServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesSwitchServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesSwitchServiceStyleThree(muiTheme)
    default:
      return new StylesSwitchServiceStyleOne(muiTheme)
  }
}

export default getSwitchTemplateService
