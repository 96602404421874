import { Box, Container, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import useGetNotFoundTemplate from '~/src/pages/not-found/styles/useGetNotFoundTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const NotFound = () => {
  const pageName: keyof typeof RoutesEnum = 'NOT_FOUND'
  const { t } = useTranslations()
  const styles = useGetNotFoundTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box
        sx={styles.getPageSx()}
      >
        <GoBackToTheShopButton />

        <Container sx={styles.getContainerSx()}>
          <Title
            variant='h3'
            sx={styles.getTitleSx()}
          >
            {t('404.title')}
          </Title>

          <Typography component='small'>
            {t('404.description')}
          </Typography>

          <GoBackToTheShopButton
            color='primary'
            variant='contained'
            sx={styles.getContainerActionButtonSx()}
          />
        </Container>
      </Box>
    </SlideFromRight>
  )
}

export default NotFound
