import { ServiceFee as ServiceFeeInterface } from '@eo-storefronts/eo-core'
import Fee from '~/src/components/fees/Fee'
import useGetFeePrice from '~/src/hooks/fees/useGetFeePrice'

interface Props {
  fee: ServiceFeeInterface,
}

const ServiceFee = ({ fee }: Props) => {
  const feePrice = useGetFeePrice(fee)

  return (
    <Fee
      label={fee.name}
      value={feePrice}
      displayZeroAsFree
    />
  )
}

export default ServiceFee
