import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'
import ShoppingCartActionsLoggedOut from '~/src/components/cart/shopping-cart-actions/ShoppingCartActionsLoggedOut'
import ShoppingCartActionsLoggedIn from '~/src/components/cart/shopping-cart-actions/ShoppingCartActionsLoggedIn'

const ShoppingCartActions = () => {
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)
  const totalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)

  if (!totalItems) {
    return null
  }

  if (!isLoggedIn) {
    return <ShoppingCartActionsLoggedOut />
  }

  return <ShoppingCartActionsLoggedIn />
}

export default ShoppingCartActions
