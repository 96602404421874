import { IndexDBService } from '@eo-storefronts/eo-core'

const indexDbPersist = <T>(key: string) => {
  const indexDBService = new IndexDBService()

  return ({ onSet }: any) => {
    onSet((newValue: T) => {
      void indexDBService.set(`${key}-time`, Date.now())
      void indexDBService.set(key, newValue)
    })
  }
}

export {
  indexDbPersist
}
