import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff'
import { Box, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

const NoConnectionLoader = () => {
  const { t } = useTranslations()

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <SignalCellularOffIcon fontSize='large' />
      <Typography sx={{
        fontWeight: 'bold'
      }}>
        {t('global.no_connection')}
      </Typography>
    </Box>
  )
}

export default NoConnectionLoader
