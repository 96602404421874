import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useEoValue } from '~/src/hooks/useEoState'
import { ANNOUNCEMENTS } from '~/src/stores/announcements'
import { LAYOUT_STATE } from '~/src/stores/layout'
import Announcements from '~/src/components/announcements'
import AnnouncementsDialogTitle from '~/src/components/announcements/dialog/title'
import useFirmAnnouncementsService from '~/src/hooks/firm/useFirmAnnouncementsService'

const AnnouncementsDialog = () => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const announcements = useEoValue(ANNOUNCEMENTS)
  const announcementsService = useFirmAnnouncementsService()

  const handleOnClose = () => {
    void announcementsService.markAllAsSeen()
  }

  return (
    <ResponsiveDialog
      openByDefault={!!announcements.length}
      showCloseButton={!isMobile}
      responsive={false}
      contentSx={{
        pt: '0 !important',
        pb: '0 !important',
        pr: '0 !important',
        pl: '0 !important'
      }}
      onClose={handleOnClose}
      Title={<AnnouncementsDialogTitle />}
    >
      <Announcements />
    </ResponsiveDialog>
  )
}

export default AnnouncementsDialog
