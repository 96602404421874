import { switchClasses } from '@mui/material'
import StylesSwitchServiceInterface
  from '~/src/components/mui-wrappers/styled/toggle/styles/StylesSwitchServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSwitchServiceStyleThree extends TemplateServiceStyleBase implements StylesSwitchServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      padding: '6px 6px',
      [`.${switchClasses.track}`]: {
        borderRadius: '25px'
      },
      [`.${switchClasses.switchBase}.${switchClasses.checked}+.${switchClasses.track}`]: {
        backgroundColor: this.muiTheme.palette.primary.main,
        opacity: '1 !important'
      },
      [`.${switchClasses.switchBase}`]: {
        [`&.${switchClasses.checked}`]: {
          [`.${switchClasses.thumb}`]: {
            color: this.muiTheme.palette.primary.contrastText
          }
        }
      }
    }
  }
}
