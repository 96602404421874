import { CustomLink, CustomLinkPositionType } from '@eo-storefronts/eo-core'
import { createSelectorFamily, createState } from '~/src/hooks/useEoState'

export const CUSTOM_LINKS_STATE = createState<CustomLink[]>({
  key: 'customerLinksState',
  default: []
})

export const GET_CUSTOM_LINKS_BY_POSITION_SELECTOR = createSelectorFamily<CustomLink[], CustomLinkPositionType>({
  key: 'getCustomLinksByPositionSelector',
  get: (position: CustomLinkPositionType) => ({ get }): CustomLink[] => {
    const customLinks: CustomLink[] = get(CUSTOM_LINKS_STATE)

    if (!customLinks) {
      return []
    }

    return customLinks.filter((customLink: CustomLink) => customLink.position === position)
  }
})
