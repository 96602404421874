import { GetTimeslotsResponse } from '@eo-storefronts/eo-core'
import { isOffline } from '~/src/services/OpeningHourService'
import { fetchFirmTimeslots } from '~/src/services/FirmService'
import { useProductsAndCategoriesFromCart } from '~/src/hooks/cart/useProductsAndCategoriesFromCart'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR } from '~/src/stores/checkout'

interface ReturnsType {
  fetchTimeslots(): Promise<GetTimeslotsResponse | null>,
}

const useFetchTimeslots = (): ReturnsType => {
  const { getProductsIds, getCategoriesIds } = useProductsAndCategoriesFromCart()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const pickupPoint = useEoValue(CHECKOUT_PICKUP_POINT_SELECTOR)

  const fetchTimeslots = async (): Promise<GetTimeslotsResponse | null> => {
    if (
      !firm
      || isOffline(firm)
      || !checkoutForm.delivery_method.method
      || !checkoutForm.delivery_method.time
    ) {
      return null
    }

    return fetchFirmTimeslots(
      firm.id,
      new Date(checkoutForm.delivery_method.time),
      checkoutForm.delivery_method.method,
      getProductsIds(),
      getCategoriesIds(),
      pickupPoint && !pickupPoint.main_branch ? pickupPoint.id : undefined
    )
  }

  return { fetchTimeslots }
}

export default useFetchTimeslots
