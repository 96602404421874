import { parsePhoneNumber } from 'libphonenumber-js/max'

export default class ValidatorUtils {
  public static isPhoneNumberValid(value: string | undefined): boolean {
    if (!value) {
      return false
    }

    try {
      return parsePhoneNumber(value).isValid()
    } catch (e) {
      return false
    }
  }
}
