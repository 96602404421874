import getFooterTemplateService from '~/src/components/layout/footer/styles/GetFooterTemplateService'
import StylesFooterServiceInterface from '~/src/components/layout/footer/styles/StylesFooterServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetFooterTemplate = (): StylesFooterServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getFooterTemplateService(pageStyle, muiTheme)
}

export default useGetFooterTemplate
