import { SxProps } from '@mui/material'
import StylesSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSubHeaderServiceStyleOne extends TemplateServiceStyleBase implements StylesSubHeaderServiceInterface {
  public getLinkSx(): SxProps {
    return {
      transition: 'var(--ease-in-out)',
      py: 1.5,
      '&:hover': {
        opacity: 0.5
      }
    }
  }

  public getHeaderSubHeaderSx(headerHeight: number): SxProps {
    return {
      position: 'sticky',
      top: `${Math.floor(headerHeight)}px`,
      borderTop: '1px solid',
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      zIndex: 3,
      maxWidth: '100vw',
      display: 'flex',
      gap: 1,
      overflow: 'hidden',
      [this.muiTheme.breakpoints.down('md')]: {
        px: 0,
        transition: 'all 0.25s ease-in-out',
        top: -1,
        width: '100%',
        '&.is-pinned': {
          pt: `calc(${this.muiTheme.spacing(1)} + var(--eo-safe-area-top))`,
          transition: 'all 0.25s ease-in'
        }
      }
    }
  }
}

