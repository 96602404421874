import DeliveryMethodsSummaryMethodInformationsPickup
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-informations/DeliveryMethodsSummaryMethodInformationsPickup'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import DeliveryMethodsSummaryMethodInformationsDelivery
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-informations/DeliveryMethodsSummaryMethodInformationsDelivery'
import SlideFromContainer from '~/src/components/mui-wrappers/animations/SlideFromContainer'

interface Props {
  showAnimation: boolean,
}

const DeliveryMethodsSummaryMethodInformations = ({ showAnimation }: Props) => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  if (showAnimation) {
    if (checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY) {
      return (
        <SlideFromContainer
          direction={'down'}
          in={showAnimation}>
          <DeliveryMethodsSummaryMethodInformationsDelivery/>
        </SlideFromContainer>
      )
    }

    if (checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP) {
      return (
        <SlideFromContainer
          direction={'down'}
          in={showAnimation}>
          <DeliveryMethodsSummaryMethodInformationsPickup/>
        </SlideFromContainer>
      )
    }
  }

  return null
}

export default DeliveryMethodsSummaryMethodInformations
