import { MouseEvent } from 'react'
import CartTotalPrice from '~/src/components/cart/CartTotalPrice'
import { ButtonProps } from '~/src/components/mui-wrappers/buttons/Button'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'

interface Props extends Omit<ButtonProps, 'disabled' | 'children'> {
  text: string,
  disabled?: boolean,
  link?: string,
  onClick?(event: MouseEvent): void,
}

const FooterGoToButton = ({ link, text, disabled = false, onClick, ...btnProps }: Props) => {
  return (
    <ButtonLink
      displayIcon={false}
      linkProps={{
        disabled,
        href: link,
        resolve: true,
        className: 'eo-w-full'
      }}
      variant='contained'
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        fontSize: '1rem',
        fontWeight: 700
      }}
      disabled={disabled}
      onClick={onClick}
      {...btnProps}
    >
      <span>{text}</span>
      <CartTotalPrice />
    </ButtonLink>
  )
}

export default FooterGoToButton

