import { Box } from '@mui/material'
import { MouseEvent, useEffect, useState } from 'react'
import DeliveryMethodsTimeButtonGroup from '~/src/components/delivery-methods/DeliveryMethodsTimeButtonGroup'
import DeliveryMethodsTimePicker from '~/src/components/delivery-methods/DeliveryMethodsTimePicker'
import { useDeliveryMethodsTime } from '~/src/hooks/delivery-methods/useDeliveryMethodsTime'
import { useEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

const DeliveryMethodsTimeContainer = () => {
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { canOrderAsap } = useDeliveryMethodsTime()
  const [ selectedButtonValue, setSelectedButtonValue ] = useState<string>('')

  const _updateCheckoutFormAsap = (asap: boolean) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        asap
      }
    }))
  }

  const handleOnChange = (event: MouseEvent, value: string) => {
    if (!value) {
      return
    }

    setSelectedButtonValue(value)
    _updateCheckoutFormAsap(value === 'asap')
  }

  useEffect(() => {
    if (checkoutForm.delivery_method.time !== checkoutForm.delivery_method.minDate) {
      setSelectedButtonValue('pick')
      return
    }

    if (canOrderAsap) {
      _updateCheckoutFormAsap(true)
      setSelectedButtonValue('asap')
      return
    }

    _updateCheckoutFormAsap(false)
    setSelectedButtonValue('pick')
  }, [ canOrderAsap, checkoutForm.delivery_method.minDate ])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        flexShrink: 0,
        flexWrap: 'wrap'
      }}
    >
      <DeliveryMethodsTimeButtonGroup onChange={handleOnChange} value={selectedButtonValue} />
      {selectedButtonValue === 'pick' && <DeliveryMethodsTimePicker />}
    </Box>
  )
}

export default DeliveryMethodsTimeContainer
