import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'

interface ReturnsType {
  log(promotionName: string): void,
}

const useLogSelectPromotion = (): ReturnsType => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()

  const log = (promotionName: string): void => {
    logGoogleAnalyticEvent('select_promotion', {
      promotion_name: promotionName
    })

    logFacebookAnalyticEvent('SelectPromotion', {
      content_name: promotionName
    }, true)
  }

  return { log }
}

export default useLogSelectPromotion
