import { Box } from '@mui/material'
import FirmInfoContainer from '~/src/components/firms/FirmInfoContainer'
import HeaderCurrentFirmTitle from '~/src/components/layout/header/header-current-firm-title'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import { ReactNode } from 'react'

interface Props {
  activator?: ReactNode,
}

const FirmInfoDialog = ({ activator }: Props) => (
  <ResponsiveDialog
    Title={<GoBackToTheShopButton dialog={true} />}
    activator={activator ?? <HeaderCurrentFirmTitle />}
    maxWidth='lg'
  >
    <Box>
      <FirmInfoContainer />
    </Box>
  </ResponsiveDialog>
)

export default FirmInfoDialog
