import { MarketplaceCategory } from '@eo-storefronts/eo-core'
import { createSelectorFamily, createState } from '~/src/hooks/useEoState'

const key = 'marketplaceCategoriesState'

export const MARKETPLACE_CATEGORIES_STATE = createState<MarketplaceCategory[]>({
  key,
  default: []
})

export const MARKETPLACE_CATEGORIES_BY_UUID_SELECTOR = createSelectorFamily<MarketplaceCategory | undefined, string>({
  key: 'MarketplaceCategoryByUUID',
  get: (uuid: string) => ({ get }) => {
    const categories = get(MARKETPLACE_CATEGORIES_STATE)

    return categories.find((category: MarketplaceCategory) => category.uuid === uuid)
  }
})
