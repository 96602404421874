import StylesPopoverServiceInterface
  from '~/src/components/mui-wrappers/styled/popover/styles/StylesPopoverServiceInterface'
import StylesPopoverServiceStyleOne
  from '~/src/components/mui-wrappers/styled/popover/styles/StylesPopoverServiceStyleOne'
import StylesPopoverServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/popover/styles/StylesPopoverServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getPopoverTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesPopoverServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesPopoverServiceStyleTwo(muiTheme)
    default:
      return new StylesPopoverServiceStyleOne(muiTheme)
  }
}

export default getPopoverTemplateService
