import StylesSideDrawerNavigationItemsServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/styles/StylesSideDrawerNavigationItemsServiceInterface'
import StylesSideDrawerNavigationItemsServiceStyles
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/styles/StylesSideDrawerNavigationItemsServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getSideDrawerNavigationItemsTemplateService = (style: string | null, muiTheme: CustomMuiTheme): StylesSideDrawerNavigationItemsServiceInterface => {
  return new StylesSideDrawerNavigationItemsServiceStyles(muiTheme)
}

export default getSideDrawerNavigationItemsTemplateService
