import { Address } from '@eo-storefronts/eo-core'

interface Props {
  address: Address,
}

const InlineAddress = ({ address }: Props) => {
  const { house_number, street, zip_code, locality } = address

  return <>{`${house_number}, ${street}, ${zip_code} - ${locality}`}</>
}

export default InlineAddress
