import { DateUtils, Location, OpeningHour } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import useOpeningHourSentence from '~/src/hooks/firm/useOpeningHourSentence'

interface Props {
  location: Location,
  date: Date,
}

const LocationOpeningHour = ({ location, date }: Props) => {
  const { sentence: getOpeningHourSentence } = useOpeningHourSentence()
  const day = DateUtils.getDayIdLikeMomentJs(date)
  const openingHour = location.opening_hours?.find((openingHour: OpeningHour) => openingHour.day_id === day)

  if (!openingHour) {
    return null
  }

  return (
    <Typography
      variant='body2'
      gutterBottom
    >
      {getOpeningHourSentence(openingHour, 'short')}
    </Typography>
  )
}

export default LocationOpeningHour
