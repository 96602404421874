import getHeaderCurrentFirmTemplateService
  from '~/src/components/layout/header/header-current-firm/styles/GetHeaderCurrentFirmTemplateService'
import StylesHeaderCurrentFirmServiceInterface
  from '~/src/components/layout/header/header-current-firm/styles/StylesHeaderCurrentFirmServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useGetHeaderCurrentFirmTemplate = (): StylesHeaderCurrentFirmServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('header')

  return getHeaderCurrentFirmTemplateService(componentStyle, muiTheme)
}

export default useGetHeaderCurrentFirmTemplate
