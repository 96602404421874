import { CustomDay, DeliveryMethodNames, Firm } from '@eo-storefronts/eo-core'
import { CheckoutFormState } from '~/src/stores/checkout'

const hasOrderTimeslots = (firm: Firm, checkoutFormState: CheckoutFormState): boolean => {
  if (
    checkoutFormState.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
    || !checkoutFormState.delivery_method.method
  ) {
    return false
  }

  if (
    checkoutFormState.delivery_method.pickupPoint
    && !checkoutFormState.delivery_method.pickupPoint.main_branch
    && checkoutFormState.delivery_method.method === DeliveryMethodNames.PICKUP
  ) {
    return checkoutFormState.delivery_method.pickupPoint.use_timeslots
  }

  return firm.settings.delivery_methods[checkoutFormState.delivery_method.method]?.order_timeslots.active || false
}

const hasCustomDays = (firm: Firm, method: DeliveryMethodNames): boolean => {
  if (method === DeliveryMethodNames.ON_THE_SPOT) {
    return false
  }

  return firm.settings.delivery_methods[method]?.has_custom_days || false
}

const getCustomDays = (firm: Firm, method: DeliveryMethodNames): CustomDay[] => {
  if (method === DeliveryMethodNames.ON_THE_SPOT) {
    return []
  }

  return firm.settings.delivery_methods[method]?.custom_days || []
}

const isLimitedToSameDayOrdering = (firm: Firm, method: DeliveryMethodNames): boolean => {
  if (method === DeliveryMethodNames.ON_THE_SPOT) {
    return true
  }

  return firm.settings.delivery_methods[method]?.orders_only_during_opening_hours || firm.settings.delivery_methods[method]?.orders_only_during_operational_hours || false
}

const mustShowAsapButtonOnly = (firm: Firm, method: DeliveryMethodNames): boolean => {
  if (method === DeliveryMethodNames.ON_THE_SPOT) {
    return true
  }

  return firm.settings.delivery_methods[method]?.orders_only_during_operational_hours || false
}

export {
  hasOrderTimeslots,
  hasCustomDays,
  getCustomDays,
  isLimitedToSameDayOrdering,
  mustShowAsapButtonOnly
}
