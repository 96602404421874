import { CreateCustomerResponse, Customer, Firm } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { create, createV2 } from '~/src/services/CustomerService'

interface ReturnsType {
  createCustomer(customer: Customer, isGoogleCustomer: boolean): Promise<CreateCustomerResponse>,
}

const useCreateCustomer = (): ReturnsType => {
  const brand: Firm | null = useEoValue(BRAND_STATE)
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const isUsingNewBrandAuthSystem: boolean = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)

  const createCustomer = async (customer: Customer, isGoogleCustomer: boolean): Promise<CreateCustomerResponse> => {
    if (isUsingNewBrandAuthSystem) {
      return createV2(customer, brand!.id, isGoogleCustomer)
    }

    return create(customer, firm!.id, isGoogleCustomer)
  }

  return { createCustomer }
}

export default useCreateCustomer
