import getShoppingCartActionsTemplateService
  from '~/src/components/cart/shopping-cart-actions/styles/GetShoppingCartActionsTemplateService'
import StylesShoppingCartActionsServiceInterface
  from '~/src/components/cart/shopping-cart-actions/styles/StylesShoppingCartActionsServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartActionsTemplate = (): StylesShoppingCartActionsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartActionsTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartActionsTemplate
