import { Box } from '@mui/material'
import useProductUnitPriceTemplateService
  from '~/src/components/products/product-unit-price/styles/useProductUnitPriceTemplateService'
import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import ProductPromotionalPrice from '~/src/components/products/product-unit-price/ProductPromotionalPrice'
import ProductUnit from '~/src/components/products/product-unit-price/ProductUnit'
import ProductPrice from '~/src/components/products/product-unit-price/ProductPrice'
import { DisplayPromotionType } from '~/src/types/DisplayPromotionType'

export interface ProductUnitPriceProps {
  product: Product,
  displayPromotion?: DisplayPromotionType,
  containerTemplate?: TemplateEnum,
}

const ProductUnitPrice = ({ product, containerTemplate, displayPromotion = 'inline' }: ProductUnitPriceProps) => {
  const styles = useProductUnitPriceTemplateService(containerTemplate)

  return (
    <Box sx={styles.getContainerSx()}>
      <Box sx={{
        display: 'inline-block',
        position: 'relative'
      }}>
        <ProductPrice product={product} />
        <ProductPromotionalPrice
          product={product}
          displayPromotion={displayPromotion}
          containerTemplate={containerTemplate}
        />
      </Box>
      <ProductUnit unit={product.unit} />
    </Box>
  )
}

export default ProductUnitPrice
