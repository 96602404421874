import StylesMarketplaceSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceInterface'
import StylesMarketplaceSubHeaderServiceStyleOne
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceStyleOne'
import StylesMarketplaceSubHeaderServiceStyleThree
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceStyleThree'
import StylesMarketplaceSubHeaderServiceStyleTwo
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getMarketplaceSubHeaderTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesMarketplaceSubHeaderServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesMarketplaceSubHeaderServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesMarketplaceSubHeaderServiceStyleThree(muiTheme)
    default:
      return new StylesMarketplaceSubHeaderServiceStyleOne(muiTheme)
  }
}

export default getMarketplaceSubHeaderTemplateService
