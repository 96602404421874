import { DateUtils, DeliveryMethod, DeliveryMethodNames, Interval, OpeningHour } from '@eo-storefronts/eo-core'
import { useCanOrderOnDay } from '~/src/hooks/delivery-methods/useCanOrderOnDay'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { isOpenAM } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  updateCheckoutTime(date: Date): void,
  updateCheckoutTimeWithInterval(interval: Interval): void,
}

const useUpdateCheckoutTime = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { canOrderOnDay } = useCanOrderOnDay()

  const updateCheckoutTime = (date: Date) => {
    const dayId = DateUtils.getDayIdLikeMomentJs(date)
    let time = checkoutForm.delivery_method.minDate

    const deliveryMethod = firm!.settings.delivery_methods[checkoutForm.delivery_method.method!] as DeliveryMethod

    if (!canOrderOnDay(date)) {
      return
    }

    if (deliveryMethod.order_timeslots.active) {
      time = DateUtils.momentFormat(date)
    } else if (
      time
      && DateUtils.momentFormat(date, 'DM') !== DateUtils.momentFormat(new Date(time), 'DM')
    ) {
      let period
      
      if (
        checkoutForm.delivery_method.pickupPoint
        && checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP
        && !checkoutForm.delivery_method.pickupPoint.use_pickup_hours
      ) {
        period = checkoutForm.delivery_method.pickupPoint.periods['pickup_hours'].find((p: OpeningHour) => p.day_id === dayId)
      } else {
        const hoursKey = checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY ? 'delivery_hours' : 'pickup_hours'
        period = firm!.settings.periods[hoursKey].find((p: OpeningHour) => p.day_id === dayId)
      }

      if (!period) {
        return
      }

      const periodTime = isOpenAM(period) ? period.am! : period.pm!

      const [ hours, minutes ] = periodTime.from_time.split(':')
      date.setHours(Number(hours))
      date.setMinutes(Number(minutes))

      time = DateUtils.momentFormat(date)
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        time
      }
    }))
  }

  const updateCheckoutTimeWithInterval = (interval: Interval) => {
    if (!checkoutForm.delivery_method.time) {
      return
    }

    const [ hours, minutes ] = interval.time.split(':')
    const date = new Date(checkoutForm.delivery_method.time)
    date.setHours(Number(hours))
    date.setMinutes(Number(minutes))

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        time: DateUtils.momentFormat(date)
      }
    }))
  }

  return {
    updateCheckoutTime,
    updateCheckoutTimeWithInterval
  }
}

export {
  useUpdateCheckoutTime
}
