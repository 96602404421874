import { Typography } from '@mui/material'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { useTranslations } from '~/src/hooks/useTranslations'
import HtmlFireIcon from '~/src/components/icons/html-fire'

const FirmLoyaltyRewardPointsLeftToEarn = () => {
  const { t } = useTranslations()
  const loyaltyService = useLoyaltyService()
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)
  const pointsLeftToEarn = loyaltyService.getPointsLeftToEarn()

  if (!isLoggedIn) {
    return null
  }

  if (pointsLeftToEarn <= 0) {
    return (
      <Typography>
        <HtmlFireIcon /> {t('profile.loyalty.added_on_the_next_order')}
      </Typography>
    )
  }

  return (
    <Typography>
      {pointsLeftToEarn} {t('profile.loyalty.togo')}
    </Typography>
  )
}

export default FirmLoyaltyRewardPointsLeftToEarn
