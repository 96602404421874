import { Box } from '@mui/material'
import DeliveryMethodsSummary from '~/src/components/delivery-methods/DeliveryMethodsSummary'
import Divider from '~/src/components/mui-wrappers/Divider'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useDeliveryMethodService from '~/src/hooks/delivery-methods/useDeliveryMethodService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'

const ShoppingCartDeliveryMethodsSummary = () => {
  const layout = useEoValue(LAYOUT_STATE)
  const { isMethodInactive } = useDeliveryMethodService()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const styles = useGetShoppingCartTemplate()

  if (layout.mainSideBar.displayDeliveryOptions) {
    return null
  }

  return (
    <Box sx={styles.getDeliveryMethodsContainerSxProps()}>
      <DeliveryMethodsSummary />
      {!isMethodInactive(checkoutForm.delivery_method.method) && <Divider sx={styles.getDividerSxProps()} />}
    </Box>
  )
}

export default ShoppingCartDeliveryMethodsSummary
