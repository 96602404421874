import { DateUtils } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface State {
  canOrderAsap: boolean,
}

type ReturnsType = State

const useDeliveryMethodsTime = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const [ state, setState ] = useState<State>({
    canOrderAsap: false
  })

  useEffect(() => {
    if (!firm) {
      return
    }

    if (
      checkoutForm.delivery_method.time
      && !DateUtils.isSameDate(new Date(), new Date(checkoutForm.delivery_method.time))
    ) {
      setState({
        canOrderAsap: false
      })
      return
    }

    setState({
      canOrderAsap: firm.settings.theme.flow?.ordering.asap || false
    })
  }, [ firm, checkoutForm.delivery_method.time ])

  return state
}

export {
  useDeliveryMethodsTime
}
