import {
  Address,
  ApiConfiguration,
  BasicResponse,
  Coupon,
  CreateOrderPayload,
  FirstAvailableFulfilmentTime,
  GetFirstAvailableFulfilmentTimePayload,
  Id,
  OrderCalculateTime,
  OrderDeliveryCost,
  OrderService,
  OrderStatusType,
  OrderSummary,
  Product
} from '@eo-storefronts/eo-core'
import GetOrderDetailsResponse from '@eo-storefronts/eo-core/dist/models/endpoints/order/GetOrderDetailsResponse'

export const createOrder = async (order: CreateOrderPayload, firmId: Id, storeId: Id, customerId: Id, uuid: string): Promise<BasicResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('storeId', storeId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('uuid', uuid.toString())

  return OrderService.create(order)
}

export const fetchOrder = async (orderId: Id, firmId: Id, storeId: string, customerId: Id, uuid: string): Promise<GetOrderDetailsResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('storeId', storeId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('uuid', uuid.toString())

  return OrderService.getDetails(orderId)
}

export const fetchPastOrders = async (firmId: Id, storeId: string, customerId: Id): Promise<OrderSummary[]> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('storeId', storeId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return OrderService.getPastOrders()
}

export const fetchPendingOrders = async (firmId: Id, storeId: string, customerId: Id): Promise<OrderSummary[]> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('storeId', storeId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return OrderService.getPendingOrders()
}

export const fetchStorefrontsOrders = async (shopId: Id): Promise<OrderSummary[]> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('brandId', shopId.toString())

  return OrderService.getStorefrontOrders()
}

export const getFirstAvailableFulfilmentTime = async (firmId: Id, customerId: Id, order: GetFirstAvailableFulfilmentTimePayload): Promise<FirstAvailableFulfilmentTime> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return OrderService.getFirstAvailableFulfilmentTime(order)
}

export const calculateTime = async (firmId: Id, customerId: Id, order: unknown): Promise<OrderCalculateTime> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return OrderService.calculateTime(order)
}

export const calculateDelivery = async (firmId: Id, customerId: Id, products: Product[], totalPrice: number, address: Address): Promise<OrderDeliveryCost> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return OrderService.calculateOrderDelivery({
    products,
    total_price: totalPrice,
    delivery_method: 'delivery',
    delivery_street: address.street,
    delivery_street_nr: address.house_number,
    delivery_zipcode: address.zip_code,
    delivery_locality: address.locality,
    delivery_country_id: address.country.id
  })
}

export const fetchDeliveryAddressCosts = async (firmId: Id, address: Address): Promise<any> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return OrderService.fetchDeliveryAddressCosts(address)
}

export const confirmTime = async (firmId: Id, customerId: Id, order: unknown): Promise<OrderCalculateTime> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return OrderService.confirmTime(order)
}

export const checkCoupon = async (firmId: Id, customerId: Id, code: string, total: number): Promise<Coupon> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  if (Number(customerId) !== 0) {
    ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  }

  return OrderService.checkCoupon(code, total)
}

const clearHeaders = (): void => {
  [ 'firmId', 'customerId', 'uuid', 'storeId' ].forEach((key: string) => {
    ApiConfiguration.deleteHeader(key)
  })
}

export const orderIsReceived = (status: OrderStatusType): boolean => {
  return OrderService.orderIsReceived(status)
}

export const orderIsReady = (status: OrderStatusType): boolean => {
  return OrderService.orderIsReady(status)
}

export const orderIsPickedUp = (status: OrderStatusType): boolean => {
  return OrderService.orderIsPickedUp(status)
}
