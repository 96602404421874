import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { BottomNavigationActionProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import Params from '~/src/router/enums/params.enum'
import { buildUrl } from '~/src/helpers/router.helper'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { AUTHENTICATION_STATE, IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { MARKETPLACE_CATEGORIES_STATE } from '~/src/stores/marketplace/categories'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import { FIRM_SELECTOR, SELECTED_SHOP_STATE } from '~/src/stores/firm'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { useLocation } from 'react-router-dom'

const useGetNavigationItems = (navbarType: 'headerNav' | 'bottomNav'): Array<CustomNavItemProps | BottomNavigationActionProps> => {
  const { pathname } = useLocation()
  const { t } = useTranslations()
  const [ items, setItems ] = useState<Array<CustomNavItemProps | BottomNavigationActionProps>>([])
  const firm = useEoValue(FIRM_SELECTOR)
  const customer = useEoValue(CUSTOMER_STATE)
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const selectedShop = useEoValue(SELECTED_SHOP_STATE)
  const { loggedInAs } = useEoValue(AUTHENTICATION_STATE)
  const isLoggedInAsCustomer = useEoValue(IS_LOGGED_IN_SELECTOR)
  const marketplaceCategories = useEoValue(MARKETPLACE_CATEGORIES_STATE)
  const lang = useEoValue(LANGUAGE_STATE)

  const _generateProfileLabel = (): string => {
    if (isLoggedInAsCustomer && customer?.first_name) {
      return customer.first_name
    }

    return t('global.login')
  }

  const _generateProfileHref = (): string => {
    if (isLoggedInAsCustomer) {
      return `${RoutesEnum.PROFILE}/${RoutesEnum.ACCOUNT}`
    }

    return buildUrl(RoutesEnum.LOGIN, new Map<Params, keyof typeof RoutesEnum>([
      [ Params.REDIRECT_TO, 'ACCOUNT' ]
    ]))
  }

  const _generateMapRoute = (): string => {
    if (!marketplaceCategories?.length) {
      return ''
    }

    return buildUrl(
      `${RoutesEnum.CATEGORY}/${marketplaceCategories[0].uuid}${RoutesEnum.LOCATIONS}`,
      new Map<Params, string>([
        [ Params.VIEW_TYPE, 'map' ]
      ])
    )
  }

  const getLocationNavItems = (): Array<CustomNavItemProps | BottomNavigationActionProps> => {
    if (navbarType === 'headerNav') {
      return [
        {
          label: t('header.nav.home'),
          value: 'home',
          icon: <HomeOutlinedIcon/>,
          iconPosition: 'start',
          href: RoutesEnum.MENU,
          resolve: true
        },
        {
          label: t('header.nav.orders'),
          value: 'orders',
          icon: <ReceiptLongOutlinedIcon/>,
          iconPosition: 'start',
          href: isLoggedInAsCustomer ? RoutesEnum.ORDERS : `${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}`,
          resolve: true
        },
        {
          label: t('header.nav.account'),
          value: 'profile',
          icon: <PermIdentityOutlinedIcon/>,
          iconPosition: 'start',
          href: _generateProfileHref(),
          resolve: true
        },
        {
          label: t('header.nav.menu'),
          value: 'menu',
          icon: <MenuOutlinedIcon/>,
          iconPosition: 'start'
        }
      ]
    }

    return [
      {
        label: t('header.nav.home'),
        value: 'home',
        icon: <HomeOutlinedIcon/>,
        href: RoutesEnum.MENU,
        resolve: true
      },
      {
        label: t('header.nav.orders'),
        value: 'orders',
        icon: <ReceiptLongOutlinedIcon/>,
        href: isLoggedInAsCustomer ? RoutesEnum.ORDERS : `${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}`,
        resolve: true
      },
      {
        label: t('header.nav.cart'),
        value: 'cart',
        icon: <ShoppingCartOutlinedIcon/>
      },
      {
        label: t('header.nav.account'),
        value: 'profile',
        icon: <PermIdentityOutlinedIcon/>,
        href: _generateProfileHref(),
        resolve: true
      },
      {
        label: t('header.nav.menu'),
        value: 'menu',
        icon: <MenuOutlinedIcon/>
      }
    ]
  }

  const getLocatorNavItems = (): Array<CustomNavItemProps | BottomNavigationActionProps> => {
    return [
      {
        label: _generateProfileLabel(),
        value: 'profile',
        icon: <PermIdentityOutlinedIcon/>,
        iconPosition: 'start',
        href: _generateProfileHref(),
        resolve: true
      }
    ]
  }

  const getMarketplaceNavItems = (): Array<CustomNavItemProps | BottomNavigationActionProps> => {
    if (navbarType === 'headerNav') {
      return [
        {
          label: t('header.nav.discover'),
          value: 'explore',
          icon: <ExploreOutlinedIcon/>,
          iconPosition: 'start',
          href: RoutesEnum.EXPLORER,
          resolve: true
        },
        {
          label: t('header.nav.categories'),
          value: 'categories',
          icon: <DashboardOutlinedIcon/>,
          iconPosition: 'start',
          href: RoutesEnum.CATEGORIES,
          resolve: true
        },
        {
          label: t('header.nav.map'),
          value: 'map',
          icon: <MapOutlinedIcon/>,
          iconPosition: 'start',
          href: _generateMapRoute(),
          resolve: true
        },
        {
          label: t('header.nav.orders'),
          value: 'orders',
          icon: <ReceiptLongOutlinedIcon/>,
          iconPosition: 'start',
          href: RoutesEnum.ORDERS,
          resolve: true
        },
        {
          label: t('header.nav.me'),
          value: 'profile',
          icon: <PermIdentityOutlinedIcon/>,
          iconPosition: 'start',
          href: _generateProfileHref(),
          resolve: true
        }
      ]
    }

    return [
      {
        label: t('header.nav.discover'),
        value: 'explore',
        icon: <ExploreOutlinedIcon/>,
        showLabel: true,
        iconPosition: 'start',
        href: RoutesEnum.EXPLORER,
        resolve: true
      },
      {
        label: t('header.nav.categories'),
        value: 'categories',
        icon: <DashboardOutlinedIcon/>,
        showLabel: true,
        iconPosition: 'start',
        href: RoutesEnum.CATEGORIES,
        resolve: true
      },
      {
        label: t('header.nav.map'),
        value: 'map',
        icon: <MapOutlinedIcon/>,
        showLabel: true,
        iconPosition: 'start',
        href: _generateMapRoute(),
        resolve: true
      },
      {
        label: t('header.nav.me'),
        value: 'profile',
        icon: <PermIdentityOutlinedIcon/>,
        showLabel: true,
        iconPosition: 'start',
        href: _generateProfileHref(),
        resolve: true
      }
    ]
  }

  const getNavItems = (): Array<CustomNavItemProps | BottomNavigationActionProps> => {
    if (isOnMarketplacePage) {
      return getMarketplaceNavItems()
    }

    if (firm?.is_multifirm
      && !selectedShop
      && !isOnMarketplacePage) {
      return getLocatorNavItems()
    }

    return getLocationNavItems()
  }

  useEffect(() => {
    setItems(
      getNavItems()
        .filter((item: CustomNavItemProps | BottomNavigationActionProps) => {
          if (item.value === 'orders' && !loggedInAs) {
            return
          }

          return item
        }))
  }, [
    loggedInAs,
    lang,
    pathname,
    isOnMarketplacePage,
    selectedShop,
    isLoggedInAsCustomer,
    customer
  ])

  return items
}

export default useGetNavigationItems
