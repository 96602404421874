import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryEditButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/StylesDeliveryMethodsSummaryEditButtonServiceInterface'
import { SvgIconProps } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'

export default class StylesDeliveryMethodsSummaryEditButtonServiceStyleTwo extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryEditButtonServiceInterface {
  getButtonColor(): Pick<ButtonProps, 'color'> {
    return {
      color: 'inherit'
    }
  }

  getButtonVariant(): Pick<ButtonProps, 'variant'> {
    return {
      variant: 'outlined'
    }
  }

  getButtonProps(): ButtonProps {
    return {
      ...this.getButtonColor(),
      ...this.getButtonVariant()
    }
  }

  getEditOutlinedIconProps(): SvgIconProps {
    return {}
  }
}
