import getProductItemTemplateService from '~/src/components/products/product-item/styles/GetProductItemTemplateService'
import StylesProductItemServiceInterface
  from '~/src/components/products/product-item/styles/StylesProductItemServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetProductItemTemplate = (containerTemplate?: TemplateEnum): StylesProductItemServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const layout = useEoValue(LAYOUT_STATE)

  return getProductItemTemplateService(containerTemplate, muiTheme, firmTheme, layout)
}

export default useGetProductItemTemplate
