import StylesSideDrawerNavigationItemServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/styles/StylesSideDrawerNavigationItemServiceInterface'
import StylesSideDrawerNavigationItemServiceStyles
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/styles/StylesSideDrawerNavigationItemServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getSideDrawerNavigationItemTemplateService = (style: string | null, muiTheme: CustomMuiTheme): StylesSideDrawerNavigationItemServiceInterface => {
  return new StylesSideDrawerNavigationItemServiceStyles(muiTheme)
}

export default getSideDrawerNavigationItemTemplateService
