import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button, { ButtonProps } from '~/src/components/mui-wrappers/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { ReactNode } from 'react'

interface Props extends Omit<ButtonProps, 'children'> {
  href?: string,
  children?: ReactNode,
}

const GoBackButton = ({ children, href, small = false, ...buttonProps }: Props) => {
  const navigate = useNavigate()
  const { push } = useRouterPush()

  const handleOnClick = () => {
    if (href) {
      push(href)

      return
    }

    navigate(-1)
  }

  return (
    <Button
      small={small}
      startIcon={<ArrowBackIcon />}
      variant='outlined'
      onClick={handleOnClick}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

export default GoBackButton
