import StylesLinkTabServiceInterface
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLinkTabServiceStyleThree extends TemplateServiceStyleBase implements StylesLinkTabServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      gap: this.muiTheme.spacing(2),
      maxWidth: '100%',
      transition: 'var(--ease-in-out)',
      borderRadius: '5px',
      minHeight: '40px',
      fontWeight: 500,
      fontSize: '1em',
      textTransform: 'none',
      marginLeft: this.muiTheme.spacing(0.5),
      marginRight: this.muiTheme.spacing(0.5),
      backgroundColor: (this.muiTheme.palette as any).container.main,
      color: (this.muiTheme.palette as any).container.contrastText,
      border: `solid 2px ${this.muiTheme.palette.primary.main}BF`,
      paddingTop: this.muiTheme.spacing(0.1),
      paddingBottom: this.muiTheme.spacing(0.1),
      paddingLeft: this.muiTheme.spacing(1),
      paddingRight: this.muiTheme.spacing(0.5),
      svg: {
        alignSelf: 'start',
        marginRight: '0 !important'
      },
      '&.Mui-selected': {
        color: (this.muiTheme.palette as any).primary.contrastText,
        backgroundColor: this.muiTheme.palette.primary.main
      },
      '&:not(.Mui-selected):hover': {
        opacity: 0.65,
        color: (this.muiTheme.palette as any).primary.contrastText,
        backgroundColor: this.muiTheme.palette.primary.main
      },
      [this.muiTheme.breakpoints.up('xl')]: {
        minHeight: '50px'
      }
    }
  }
}
