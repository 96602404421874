import { SxProps } from '@mui/material'
import FirmOfflineBanner from '~/src/components/firms/FirmOfflineBanner'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface Props {
  sx?: SxProps,
}

const FirmClosed = ({ sx }: Props) => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)

  if (firm?.settings.is_offline) {
    return <FirmOfflineBanner sx={sx} />
  }

  return null
}

export default FirmClosed
