import { Box } from '@mui/material'
import DeliveryMethodsSwitch from '~/src/components/delivery-methods/DeliveryMethodsSwitch'
import DeliveryMethodSummaryContent
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const DeliveryMethodsSummaryContainerStyleOne = () => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 1.5,
        [muiTheme.breakpoints.down('md')]: {
          gap: 1
        }
      }}
    >
      <Box sx={{
        [muiTheme.breakpoints.down('md')]: {
          textAlign: 'center'
        }
      }}>
        <DeliveryMethodsSwitch />
      </Box>
      <DeliveryMethodSummaryContent />
    </Box>
  )
}

export default DeliveryMethodsSummaryContainerStyleOne
