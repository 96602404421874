import { CustomLink } from '@eo-storefronts/eo-core'
import PublicIcon from '@mui/icons-material/Public'
import SideDrawerNavigationItem
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/side-drawer-navigation-item/index'
import { useEoValue } from '~/src/hooks/useEoState'
import { GET_CUSTOM_LINKS_BY_POSITION_SELECTOR } from '~/src/stores/custom-links'
import SideBarListItem from '~/src/types/SideBarListItem'

const SideDrawerNavigationCustomLinks = () => {
  const sideDrawerCustomLinks = useEoValue(GET_CUSTOM_LINKS_BY_POSITION_SELECTOR('side-drawer'))

  return (
    <>
      {sideDrawerCustomLinks
        .map((customLink: CustomLink) => ({
          href: customLink.link,
          label: customLink.label,
          resolve: false,
          startAdornment: <PublicIcon />
        }) as SideBarListItem)
        .map((item: SideBarListItem) => (
          <SideDrawerNavigationItem
            item={item}
            key={item.label}
          />
        ))}
    </>
  )
}

export default SideDrawerNavigationCustomLinks
