import getHeaderTemplateService from '~/src/components/layout/header/styles/GetHeaderTemplateService'
import StylesHeaderServiceInterface from '~/src/components/layout/header/styles/StylesHeaderServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { Theme } from '@eo-storefronts/eo-core'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetHeaderTemplate = (): StylesHeaderServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('header')
  const firmTheme = useEoValue<Theme | undefined>(FIRM_THEME_SELECTOR)
  const layout = useEoValue(LAYOUT_STATE)

  return getHeaderTemplateService(componentStyle, muiTheme, firmTheme, layout)
}

export default useGetHeaderTemplate
