import { DateUtils, Interval } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { INTERVALS_FOR_TIMESLOT_ID_SELECTOR, INTERVALS_STATE } from '~/src/stores/timeslots'
import { useUpdateCheckoutTime } from '~/src/hooks/checkout/useUpdateCheckoutTime'
import useFetchTimeslotIntervals from '~/src/hooks/delivery-methods/useFetchTimeslotIntervals'

interface ReturnsType {
  loading: boolean,
}

const useTimeslotIntervals = (): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const intervalsForTimeslotId = useEoValue(INTERVALS_FOR_TIMESLOT_ID_SELECTOR)
  const setLocatorState = useSetEoState(LOCATOR_STATE)
  const [ loading, setLoading ] = useState<boolean>(false)
  const { updateCheckoutTimeWithInterval } = useUpdateCheckoutTime()
  const { fetchTimeslotsIntervals } = useFetchTimeslotIntervals()

  const _updateCheckoutFormTime = () => {
    if (!intervalsForTimeslotId[0]) {
      return
    }

    updateCheckoutTimeWithInterval(intervalsForTimeslotId[0])
  }

  useAsyncEffect(async () => {
    setLoading(true)

    if (!intervalsForTimeslotId.length && checkoutForm.delivery_method.timeslotId) {
      const { intervals } = await fetchTimeslotsIntervals()
      const availableIntervals = intervals
        .filter((interval: Interval) => interval.available && !interval.blocked)

      setIntervals((state: Record<number, Interval[]>) => ({
        ...state,
        [Number(checkoutForm.delivery_method.timeslotId)]: availableIntervals || []
      }))
    }

    setLoading(false)
  }, [ checkoutForm.delivery_method.timeslotId ])

  useEffect(() => {
    setLocatorState((state: LocatorState) => ({
      ...state,
      isLoading: loading
    }))
  }, [ loading ])

  useEffect(() => {
    if (!checkoutForm.delivery_method.time) {
      return
    }

    const time = DateUtils.formatTime(new Date(checkoutForm.delivery_method.time))
    const foundInIntervals = intervalsForTimeslotId.find((i: Interval) => i.time === time)

    if (!foundInIntervals) {
      _updateCheckoutFormTime()
    }
  }, [ intervalsForTimeslotId ])

  return { loading }
}

export {
  useTimeslotIntervals
}
