import { DateUtils } from '@eo-storefronts/eo-core'
import { TextFieldProps } from '@mui/material'
import { LocalizationProvider, MobileDatePicker, PickersDayProps } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useUpdateCheckoutTime } from '~/src/hooks/checkout/useUpdateCheckoutTime'
import useDateFnsLocale from '~/src/hooks/delivery-methods/useDateFnsLocale'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import DeliveryMethodsDatePickerInput
  from '~/src/components/delivery-methods/delivery-methods-date-picker/DeliveryMethodsDatePickerInput'
import DeliveryMethodsDatePickerDay
  from '~/src/components/delivery-methods/delivery-methods-date-picker/DeliveryMethodsDatePickerDay'
import DeliveryMethodDatePickerToolbar from '~/src/components/delivery-methods/delivery-method-date-picker-toolbar'

const DeliveryMethodsDatePicker = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { updateCheckoutTime } = useUpdateCheckoutTime()
  const { adapterLocale, localeText } = useDateFnsLocale()

  const handleOnChange = (value: Date|null) => {
    if (!value) {
      return
    }

    updateCheckoutTime(value)
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
      localeText={localeText}
    >
      <MobileDatePicker
        onChange={handleOnChange}
        value={checkoutForm.delivery_method.time}
        renderInput={(props: TextFieldProps) => <DeliveryMethodsDatePickerInput {...props} />}
        renderDay={(day: Date, _value: Date[], props: PickersDayProps<Date>) => (
          <DeliveryMethodsDatePickerDay key={day.getUTCDate()} day={day} PickerDayProps={props} />
        )}
        ToolbarComponent={() => <DeliveryMethodDatePickerToolbar time={checkoutForm.delivery_method.time as string} />}
        minDate={new Date(checkoutForm.delivery_method.minDate as string)}
        maxDate={DateUtils.addMonths(2)}
      />
    </LocalizationProvider>
  )
}

export default DeliveryMethodsDatePicker
