import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'
import { Product } from '@eo-storefronts/eo-core'
import useDialog from '~/src/hooks/useDialog'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useEoValue } from '~/src/hooks/useEoState'
import { lazy, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { NAVIGATE_BY_CATEGORIES_SELECTOR } from '~/src/stores/theme'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SubProductTitle from '~/src/components/products/modals/sub-products/title'
import ProductsListModal from '~/src/components/products/modals/products-list'
import ProductConfigurationCloseButton
  from '~/src/components/products/modals/configuration/ProductConfigurationCloseButton'

const ProductDetails = lazy(async () => import('~/src/components/products/product-details'))

export interface ProductConfigurationModalProps extends ResponsiveDialogProps {
  product: Product,
}

const ProductConfigurationModal = ({
  openByDefault = true,
  maxWidth = 'lg',
  fullWidth = true,
  responsive = false,
  product,
  onClose,
  ...otherProps
}: ProductConfigurationModalProps) => {
  const { dismissAll } = useDialog()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const location = useLocation()
  const navigateOnCategory = useEoValue<boolean>(NAVIGATE_BY_CATEGORIES_SELECTOR)

  const _handleOnClose = () => {
    dismissAll()

    if ((location.state?.location?.pathname || '').includes(RoutesEnum.SEARCH)) {
      push(location.state?.location?.pathname, { modal: true })
    } else if (!navigateOnCategory) {
      push(resolve(RoutesEnum.MENU), { modal: true })
    } else {
      push(resolve(`${RoutesEnum.CATEGORY}/${product.categoryId}${RoutesEnum.PRODUCTS}`), { modal: true })
    }
  }

  if (product.subProductsId.length) {
    return (
      <ProductsListModal
        openByDefault={openByDefault}
        onClose={onClose ?? _handleOnClose}
        Title={<SubProductTitle />}
        productIds={product.subProductsId}
      />
    )
  }

  return (
    <ResponsiveDialog
      openByDefault={openByDefault}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      responsive={responsive}
      showCloseButton={false}
      onClose={onClose ?? _handleOnClose}
      contentSx={{
        position: 'relative',
        p: 0
      }}
      {...otherProps}
    >
      <ProductConfigurationCloseButton />
      <ProductDetails product={product} />
    </ResponsiveDialog>
  )
}

export default memo(ProductConfigurationModal)
