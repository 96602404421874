import {
  ApiConfiguration,
  DateUtils,
  DeliveryMethodNames,
  FirstAvailableFulfilmentTime,
  Id,
  PickupPoint
} from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { getFirstAvailableFulfilmentTime } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'

interface ReturnsType {
  calculateFirstAvailableTime(
    firmId: Id,
    deliveryMethod: DeliveryMethodNames | null,
    pickupPoint: PickupPoint | null,
    products: Id[],
    categories: string[]
  ): Promise<FirstAvailableFulfilmentTime | undefined>,
}

const useGetFirstAvailableFulfilmentTime = (): ReturnsType => {
  const auth = useEoValue(AUTHENTICATION_STATE)

  const calculateFirstAvailableTime = async (
    firmId: Id,
    deliveryMethod: DeliveryMethodNames | null,
    pickupPoint: PickupPoint | null,
    products: Id[],
    categories: string[]
  ): Promise<FirstAvailableFulfilmentTime | undefined> => {
    if (!firmId || !deliveryMethod) {
      return
    }

    if (
      (auth.customer?.id && auth.customer.token)
      && ApiConfiguration.getHeader('customerToken') !== auth.customer.token
    ) {
      ApiConfiguration.addOrReplaceHeader('customerToken', auth.customer.token)
    } else if (
      (auth.guest?.id && auth.guest.token)
      && ApiConfiguration.getHeader('customerToken') !== auth.guest.token
    ) {
      ApiConfiguration.addOrReplaceHeader('customerToken', auth.guest.token)
    }

    if (deliveryMethod === DeliveryMethodNames.ON_THE_SPOT) {
      return {
        timestamp: DateUtils.momentFormat(new Date())
      }
    }

    return getFirstAvailableFulfilmentTime(
      firmId,
      auth.guest?.id || auth.customer?.id || 0,
      {
        categories,
        delivery_method: deliveryMethod,
        products,
        firm_pickup_point_id: pickupPoint?.id
      }
    )
  }

  return {
    calculateFirstAvailableTime
  }
}

export default useGetFirstAvailableFulfilmentTime
