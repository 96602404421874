import { BasicResponse, HttpError } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { resetPassword, resetPasswordV2 } from '~/src/services/CustomerService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  reset(email: string): Promise<BasicResponse | undefined>,

  loading: boolean,
  error: HttpError | unknown | undefined,
}

const useResetPassword = (): ReturnsType => {
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)
  const isUsingNewBrandAuthSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  const reset = async (email: string) => {
    setLoading(true)
    setError(undefined)

    try {
      let response: BasicResponse | undefined

      if (isUsingNewBrandAuthSystem && brand?.id) {
        response = await resetPasswordV2(email, brand.id)
      } else if (firm?.id) {
        response = await resetPassword(email, firm.id)
      }

      enqueueSnackbar(t('auth.check_mailbox'), { variant: 'success' })

      return Promise.resolve(response)
    } catch (error: HttpError | unknown) {
      setError(error)
      enqueueSnackbar(t('errors.default_error'), { variant: 'error' })

      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  return { reset, loading, error }
}

export default useResetPassword
