import { createContext } from 'react'

interface ResponsiveDialogContextInterface {
  state: boolean,
  toggle?(): void,
  toggleOpen?(): void,
  toggleClose?(value?: unknown): void,
}

const ResponsiveDialogContext = createContext<ResponsiveDialogContextInterface>({
  state: false,
  toggle: undefined,
  toggleOpen: undefined,
  toggleClose: undefined
})

export default ResponsiveDialogContext
export type { ResponsiveDialogContextInterface }
