import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import Link from '~/src/components/mui-wrappers/Link'
import ProductItem from '~/src/components/products/product-item'
import ProductLoyaltyChip from '~/src/components/products/product-loyalty-chip'
import { useProductAvailable } from '~/src/hooks/products/useProductAvailable'
import { useEoValue } from '~/src/hooks/useEoState'
import routes from '~/src/router/enums/routes.enum'
import { PRODUCT_ID_STATE } from '~/src/stores/product'
import useDialog from '~/src/hooks/useDialog'
import { useEffect } from 'react'
import ProductAvailabilityMessage from '~/src/components/products/ProductAvailabilityMessage'
import ProductConfigurationModal, {
  ProductConfigurationModalProps
} from '~/src/components/products/modals/configuration'
import { NAVIGATE_BY_PRODUCT_MODAL_SELECTOR } from '~/src/stores/theme'
import { useLocation } from 'react-router-dom'


interface Props {
  product: Product,
  containerTemplate?: TemplateEnum,
}

/**
 * The automatic open of the product dialog will be resolved thanks to the useRouterSlugEffect
 * @param {Product} product
 * @param {TemplateEnum | undefined} containerTemplate
 * @returns {JSX.Element}
 * @constructor
 */
const ProductListItem = ({ product, containerTemplate }: Props) => {
  const href = `${routes.CATEGORY}/${product.categoryId}${routes.PRODUCT}/${product.id}`
  const { available } = useProductAvailable(product)
  const { dismissAll, present } = useDialog()
  const navigateByModal = useEoValue<boolean>(NAVIGATE_BY_PRODUCT_MODAL_SELECTOR)
  const productId = useEoValue(PRODUCT_ID_STATE)
  const location = useLocation()

  const _openProductDialog = () => {
    if (!navigateByModal) {
      return
    }

    if (product.parentProductsId.length) {
      dismissAll()
    }

    // @ts-ignore
    present<ProductConfigurationModalProps>(ProductConfigurationModal, `${product.id}-dialog`, { product, href })
  }

  useEffect(() => {
    if (product.id.toString() !== productId?.toString()) {
      return
    }

    _openProductDialog()
  }, [ productId ])

  return (
    <>
      <ProductAvailabilityMessage product={product} />
      <ProductLoyaltyChip product={product} />
      <Link
        href={href}
        removeBaseLinkStyle
        state={{
          modal: navigateByModal,
          location: location
        }}
        className='eo-h-full eo-w-full'
        sx={{
          filter: !available ? 'grayscale(1) opacity(.5)' : ''
        }}
      >
        <ProductItem
          product={product}
          containerTemplate={containerTemplate}
        />
      </Link>
    </>
  )
}

export default ProductListItem
