import { bottomNavigationActionClasses, SxProps } from '@mui/material'
import StylesMobileNavigationServiceInterface
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceInterface'
import CssUtils from '~/src/utils/CssUtils'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMobileNavigationServiceStyleThree extends TemplateServiceStyleBase implements StylesMobileNavigationServiceInterface {
  public getBottomNavigationSx(itemsNumber?: number): SxProps {
    return {
      zIndex: 3,
      background: 'var(--background-from) linear-gradient(var(--background-from), var(--background-to))',
      color: (this.muiTheme.palette.background as any).contrastText,
      boxShadow: this.muiTheme.shadows[6],
      'a:last-of-type': {
        button: {
          borderRight: 'none !important'
        }
      },
      [this.muiTheme.breakpoints.up('sm')]: {
        boxShadow: this.muiTheme.shadows[2],
        margin: 'auto',
        mb: this.muiTheme.spacing(2),
        width: `calc(80px * ${itemsNumber})`,
        borderRadius: '20px',
        'a:first-of-type': {
          button: {
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px'
          }
        },
        'a:last-of-type': {
          button: {
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px'
          }
        }
      },
      [this.muiTheme.breakpoints.down('sm')]: {
        borderRadius: 0,
        width: '100vw',
        maxWidth: '100vw',
        overflowX: 'hidden',
        height: 'calc(56px + var(--eo-safe-area-bottom))',
        '> *': {
          flex: '1 1 0',
          minWidth: 'unset',
          maxWidth: 'unset',
          button: {
            minWidth: 'inherit',
            maxWidth: 'inherit'
          }
        }
      }
    }
  }

  public getBottomNavigationActionSx(): SxProps {
    return {
      p: 0,
      gap: 0.5,
      fontSize: '0.75rem',
      svg: {
        width: '1.75rem',
        height: '1.75rem'
      },
      span: {
        fontSize: 'inherit'
      },
      borderRight: 'solid 2px',
      borderColor: CssUtils.addTransparencyToHexColor((this.muiTheme.palette.background as any).contrastText, 80),
      pb: 'var(--eo-safe-area-bottom)',
      [`&.${bottomNavigationActionClasses.selected}`]: {
        backgroundColor: 'primary.main',
        color: 'primary.contrastText'
      }
    }
  }
}
