import {
  EoInjectable,
  FindFirmArgType,
  Firm,
  PlatformUtils
} from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'
import Environment from '~/src/config/environment'
import { fetchFirm } from '~/src/services/FirmService'
import { App } from '@capacitor/app'

@EoInjectable()
export default class FirmResolver {
  private _firm: Firm | null = null

  public async resolve(domain: string): Promise<void> {
    if (EnvUtils.isDeviceWeb) {
      domain = process.env.NODE_ENV === 'development' ? Environment.getLocalhost() : domain

      await this.fetchFirm({
        host: domain
      })
    } else {
      let applicationId: string = Environment.getLocalhost()

      if (PlatformUtils().isCapacitorNative) {
        const { id } = await App.getInfo()
        applicationId = id
      }

      if (window?.Cypress) {
        applicationId = 'localhost'
      }

      await this.fetchFirm({
        applicationId
      })
    }
  }

  public async resolveFirmByName(sitename: string): Promise<void> {
    if (!this._firm) {
      return
    }

    const childFirm = this._firm.locations?.find((f: Firm) => f.sitename === sitename)

    if (!childFirm) {
      const firm = await fetchFirm({
        sitename
      })

      this._firm.locations ||= []
      this._firm.locations.push(firm)
    }
  }

  public getFirm(): Firm | null {
    return this._firm
  }

  private async fetchFirm(args: FindFirmArgType): Promise<void> {
    this._firm = await fetchFirm(args)
  }
}
