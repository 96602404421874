import { SxProps } from '@mui/material'
import StylesFooterServiceInterface from '~/src/components/layout/footer/styles/StylesFooterServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesFooterServiceStyleThree extends TemplateServiceStyleBase implements StylesFooterServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateAreas: '"left . right"',
      gridTemplateColumns: 'max-content auto max-content',
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2,
        gridTemplateAreas: '"right" "left"',
        gridRowGap: this.muiTheme.spacing(2),
        gridTemplateColumns: '1fr',
        justifyContent: 'center'
      }
    }
  }
}
