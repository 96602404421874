import useFetchCustomer from '~/src/hooks/customer/useFetchCustomer'
import useFetchCustomerAddresses from '~/src/hooks/customer/useFetchCustomerAddresses'
import useCheckLocation from '~/src/hooks/customer/useCheckLocation'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import {
  API_TOKEN_STATE,
  AUTHENTICATION_STATE,
  AuthenticationState,
  BaseAuthenticationState,
  IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR
} from '~/src/stores/authentication'
import { CUSTOMER_LOADING_STATE, CUSTOMER_STATE, CustomerLoadingState } from '~/src/stores/customer'
import { Customer, Firm } from '@eo-storefronts/eo-core'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import useLinkFirm from '~/src/hooks/customer/useLinkFirm'
import { useCallback } from 'react'

interface ReturnType {
  setCustomer(auth?: Partial<AuthenticationState>, force?: boolean): Promise<Customer|null>,
}

const useSetCustomerState = (): ReturnType => {
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const apiToken = useEoValue<BaseAuthenticationState | null>(API_TOKEN_STATE)
  const firm: Firm | null = useEoValue<Firm | null>(FIRM_SELECTOR)
  const [ customer, setCustomerState ] = useEoState(CUSTOMER_STATE)
  const setCustomerLoadingState = useSetEoState<CustomerLoadingState>(CUSTOMER_LOADING_STATE)
  const { check: doLinkLocation } = useCheckLocation()
  const { fetch: fetchCustomer } = useFetchCustomer()
  const { doLinkFirm } = useLinkFirm()
  const { fetch: doFetchCustomerAddresses } = useFetchCustomerAddresses()
  const isUsingNewBrandAuthSystem: boolean = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)

  const setCustomer = useCallback(async (auth: Partial<AuthenticationState> = authState, force = false): Promise<Customer|null> => {
    if (!apiToken?.token || !auth.customer?.id || !auth.customer.token) {
      return null
    }

    await doLinkLocation(firm?.id, auth)
    await doLinkFirm(firm?.id, auth)

    if (customer && !force) {
      return null
    }

    setCustomerLoadingState((state: CustomerLoadingState) => ({
      ...state,
      customer: true
    }))

    const fetchedCustomer: Customer | undefined = await fetchCustomer(isUsingNewBrandAuthSystem ? auth.customer.uuid : auth.customer.id)

    if (fetchedCustomer) {
      const addresses = await doFetchCustomerAddresses(fetchedCustomer)

      setCustomerState({
        ...fetchedCustomer,
        addresses
      })
    }

    setCustomerLoadingState((state: CustomerLoadingState) => ({
      ...state,
      customer: false
    }))

    return fetchedCustomer ?? null
  }, [ authState, apiToken, firm ])

  return { setCustomer }
}

export default useSetCustomerState
