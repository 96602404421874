import { Announcement as AnnouncementType } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import Image from '~/src/components/mui-wrappers/Image'

interface Props {
  announcement: AnnouncementType,
}

const Announcement = ({ announcement }: Props) => {
  const { isMobile } = useEoValue(LAYOUT_STATE)

  return (
    <>
      <Image
        sx={{
          backgroundColor: 'secondary.dark',
          width: isMobile ? '100%' : '400px',
          height: isMobile ? 'calc(100vw/400*225)' : '225px'
        }}
        src={announcement.image}
        alt={announcement.title}
      />
      <Title
        sx={{
          fontSize: '1.3rem',
          px: 3,
          pt: 2
        }}
      >
        {announcement.title}
      </Title>
    </>
  )
}

export default Announcement
