import { DeliveryMethodNames, PickupPoint } from '@eo-storefronts/eo-core'
import PlaceOutlined from '@mui/icons-material/PlaceOutlined'
import { inputBaseClasses, Typography } from '@mui/material'
import { SyntheticEvent } from 'react'
import DeliveryPickupPoints from '~/src/components/delivery-methods/DeliveryPickupPoints'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const DeliveryMethodsSummaryMethodInformationsPickup = () => {
  const muiTheme = useCustomMuiTheme()
  const firm = useEoValue(FIRM_SELECTOR)

  const _handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  if (!firm?.settings?.delivery_methods[DeliveryMethodNames.PICKUP]?.pickup_points?.length) {
    return null
  }

  return (
    <DeliveryPickupPoints
      variant={'filled'}
      color={'primary'}
      onClick={_handleOnClick}
      SelectProps={{
        native: false,
        startAdornment: <PlaceOutlined/>,
        renderValue: (selected: unknown) => {
          const pickupPointFound: PickupPoint | undefined = firm!.settings!.delivery_methods[DeliveryMethodNames.PICKUP]!.pickup_points.find((point) => point.id === selected)

          if (!pickupPointFound) {
            return null
          }

          return (
            <Typography
              component='span'
              variant='body2'
              sx={{
                whiteSpace: 'pre-line'
              }}>
              {pickupPointFound.name}
            </Typography>
          )
        }
      }}
      sx={{
        width: '100%',
        backgroundColor: 'unset',
        borderRadius: 1,
        [`& > .${inputBaseClasses.root}, .${inputBaseClasses.focused}`]: {
          pl: `calc(${muiTheme.spacing(2)} + 2px)`,
          backgroundColor: `${muiTheme.palette.primary.main} !important`,
          borderRadius: 'inherit',
          color: `${(muiTheme.palette.primary as any).contrastText} !important`,
          transition: 'var(--ease-in-out)',
          ['svg:first-of-type']: {
            pr: .5
          },
          '&:hover': {
            backgroundColor: `${muiTheme.palette.primary.dark} !important`
          },
          'div[role="button"]': {
            py: '6px',
            '&:focus': {
              backgroundColor: 'unset'
            }
          }
        }
      }}
    />
  )
}

export default DeliveryMethodsSummaryMethodInformationsPickup
