import { Box } from '@mui/material'
import DeliveryMethodsTimeslots from '~/src/components/delivery-methods/DeliveryMethodsTimeslots'
import DeliveryMethodsTimeslotsIntervals from '~/src/components/delivery-methods/DeliveryMethodsTimeslotsIntervals'
import { useTimeslots } from '~/src/hooks/delivery-methods/useTimeslots'
import { useTimeslotIntervals } from '~/src/hooks/delivery-methods/useTimeslotIntervals'

const DeliveryMethodsTimeslotsContainer = () => {
  const { loading: loadingTimeslots } = useTimeslots()
  const { loading: loadingIntervals } = useTimeslotIntervals()
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DeliveryMethodsTimeslots loading={loadingTimeslots}/>
      <DeliveryMethodsTimeslotsIntervals loading={loadingIntervals}/>
    </Box>
  )
}

export default DeliveryMethodsTimeslotsContainer
