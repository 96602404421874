import { Firm, Tracking, TrackingField } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const useInitGoogleAnalytics = (): void => {
  const firm = useEoValue(FIRM_SELECTOR)

  const _getGoogleAnalyticsId = (firm: Firm | null): string | undefined => firm
    ?.settings
    ?.tracking
    ?.find((tracking: Tracking) => tracking.type === 'GOOGLE_ANALYTICS')
    ?.fields.find((field: TrackingField) => field.id === 'google_analytics_id')
    ?.value


  useEffect(() => {
    ReactGA.reset()

    if (!firm) {
      return
    }

    const googleAnalyticsId: string | undefined = _getGoogleAnalyticsId(firm)

    if (!googleAnalyticsId) {
      return
    }

    ReactGA.initialize(googleAnalyticsId)
  }, [ firm ])
}

export default useInitGoogleAnalytics
