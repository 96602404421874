import LayoutCategoriesServiceStyleOne from '~/src/pages/categories/layout/LayoutCategoriesServiceStyleOne'
import LayoutCategoriesServiceStyleThree from '~/src/pages/categories/layout/LayoutCategoriesServiceStyleThree'
import LayoutCategoriesServiceStyleTwo from '~/src/pages/categories/layout/LayoutCategoriesServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getCategoriesLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutCategoriesServiceStyleTwo().getLayout(getLayoutArgs)
    case TemplateEnum.STYLE_THREE:
      return new LayoutCategoriesServiceStyleThree().getLayout()
    default:
      return new LayoutCategoriesServiceStyleOne().getLayout()
  }
}

export default getCategoriesLayout
