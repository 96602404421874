import { Constants } from '~/src/helpers/constants'
import MotionBox from '~/src/components/mui-wrappers/animations/motion/MotionBox'
import { BoxProps } from '@mui/material'
import { MotionProps } from 'framer-motion'
import { PlatformUtils } from '@eo-storefronts/eo-core'

type Props = {
  pathname?: string,
  x?: string | number,
  animateOnDesktop?: boolean,
} & Omit<BoxProps, 'ref'> & MotionProps

const SlideFromRight = (
  {
    children,
    animateOnDesktop = false,
    pathname,
    x = '50vw',
    transition = {
      duration: Constants.MOTION_ANIMATION_DURATION
    },
    ...otherProps
  }: Props
) => {
  if (!animateOnDesktop && PlatformUtils().isDesktop) {
    return <>{children}</>
  }

  return (
    <MotionBox
      key={pathname}
      initial={{ x, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: `-${x}`, opacity: 0 }}
      transition={transition}
      {...otherProps}
    >
      {children}
    </MotionBox>
  )
}

export default SlideFromRight
