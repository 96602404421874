import { CustomContentProps, SnackbarContent } from 'notistack'
import { forwardRef } from 'react'
import SnackbarStyled from '~/src/components/mui-wrappers/styled/snackbar'

const Snackbar = forwardRef<HTMLDivElement, CustomContentProps>(({ message, variant }, ref) => {
  return (
    <SnackbarContent ref={ref}>
      <SnackbarStyled
        // @ts-ignore
        message={message}
        variant={variant}
      />
    </SnackbarContent>
  )
})

Snackbar.displayName = 'EoSnackbar'

export default Snackbar
