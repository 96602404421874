import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryAddressButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/StylesDeliveryMethodsSummaryAddressButtonServiceInterface'
import { BoxProps, SvgIconProps, SxProps } from '@mui/material'
import { Address } from '@eo-storefronts/eo-core'
import { ButtonProps } from '@mui/material/Button/Button'

export default class StylesDeliveryMethodsSummaryAddressButtonServiceStyleOne extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryAddressButtonServiceInterface {
  getButtonIconContainerSx(): SxProps {
    return {
      display: 'flex',
      alignItems: 'center'
    }
  }

  getAddIconProps(): SvgIconProps {
    return {
      color: 'inherit'
    }
  }

  getLocationOnOutlinedIconProps(): SvgIconProps {
    return {
      color: 'inherit'
    }
  }

  getButtonProps(address: Address | null): ButtonProps {
    return {
      ...this.getButtonColor(address),
      ...this.getButtonVariant(),
      sx: this.getButtonSx()
    }
  }

  getButtonColor(address: Address | null): Pick<ButtonProps, 'color'> {
    return {
      color: !address ? 'error' : 'inherit'
    }
  }

  getButtonSx(): SxProps {
    return {
      textTransform: 'none'
    }
  }

  getButtonVariant(): Pick<ButtonProps, 'variant'> {
    return {
      variant: 'outlined'
    }
  }

  getLabelContainerProps(): BoxProps {
    return {
      sx: this.getLabelContainerSx(),
      component: 'span'
    }
  }

  getLabelContainerSx(): SxProps {
    return {
      ml: 1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      textAlign: 'left',
      lineHeight: 'initial'
    }
  }
}
