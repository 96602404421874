import { Filter, FilterOption, Id, Location, MarketplaceCategory, MarketplaceService } from '@eo-storefronts/eo-core'

export const fetchSuggestedLocations = async (id: Id): Promise<Location[]> => {
  return MarketplaceService.getSuggestedLocations(id)
}

export const fetchMarketplaceCategories = async (id: Id): Promise<MarketplaceCategory[]> => {
  return MarketplaceService.getMarketplaceCategories(id)
}

export const fetchMarketplaceLocation = async (id: Id, locationId: Id): Promise<Location> => {
  return MarketplaceService.getMarketplaceLocation(id, locationId)
}

export const fetchMarketplaceLocations = async (id: Id, categoryId: Id | undefined, searchValue: string, filters: FilterOption[]): Promise<Location[]> => {
  return MarketplaceService.getMarketplaceLocations(id, categoryId, searchValue, filters)
}

export const fetchFilters = async (id: Id, categoryUUID: string): Promise<Array<Filter>> => {
  return MarketplaceService.getMarketplaceFilters(id, categoryUUID)
}
