import { SxProps } from '@mui/material'
import StylesShoppingCartItemServiceInterface
  from '~/src/components/cart/shopping-cart-item/styles/StylesShoppingCartItemServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartItemServiceStyleOne extends TemplateServiceStyleBase implements StylesShoppingCartItemServiceInterface {
  public getContainerSxProps(): SxProps {
    return { display: 'contents' }
  }

  public getProductLabelSxProps(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'row',
      gap: 1,
      alignItems: 'center'
    }
  }

  public getProductNameSxProps(): SxProps {
    return {
      wordBreak: 'break-word'
    }
  }

  public getQuantitySelectorSxProps(isCoupon: boolean): SxProps {
    return {
      ml: 1,
      visibility: isCoupon ? 'hidden' : 'visible'
    }
  }

  public getPriceContainerSxProps(): SxProps {
    return {
      ml: 1,
      textAlign: 'right'
    }
  }

  public getModifiersSxProps(amountOfSpans: number): SxProps {
    return {
      pr: 2,
      gridColumnStart: `span ${amountOfSpans}`
    }
  }

  public getDeleteButtonSxProps(): SxProps {
    return {
      color: 'secondary.contrastText'
    }
  }
}
