import { CardMedia as MUICardMedia, CardMediaProps } from '@mui/material'
import { useState } from 'react'
import Image from '~/src/components/mui-wrappers/Image'
import { Constants } from '~/src/helpers/constants'

interface Props extends Omit<CardMediaProps<'img'>, 'image'>{
  isDefault?: boolean,
}

const CardMedia = ({ isDefault, src, sx, ...otherProps }: Props) => {
  const [ imgNotFound, setImgNotFound ] = useState<boolean>(false)

  const handleOnError = () => {
    setImgNotFound(true)
  }

  if (imgNotFound) {
    return (
      <MUICardMedia
        component='img'
        alt={'Image placeholder'}
        src={Constants.TRANSPARENT_IMG_SRC}
        sx={{
          aspectRatio: '1/1',
          ...sx
        }}
        {...otherProps}
      />
    )
  }

  return (
    <Image
      Component={MUICardMedia}
      onError={handleOnError}
      src={src}
      alt={''}
      isDefault={isDefault}
      sx={{
        aspectRatio: '1/1',
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default CardMedia
