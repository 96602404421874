import { Location } from '@eo-storefronts/eo-core'
import { createState } from '~/src/hooks/useEoState'

export const MARKETPLACE_LOCATION_SELECTED_STATE = createState<Location | undefined>({
  key: 'locationSelectedState',
  default: undefined
})

export const MARKETPLACE_LOCATIONS_STATE = createState<Location[]>({
  key: 'locationsState',
  default: []
})

export const MARKETPLACE_IS_FETCHING_LOCATIONS_STATE = createState<boolean>({
  key: 'isFetchingLocationsState',
  default: false
})
