import { useEoValue } from '~/src/hooks/useEoState'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import useFetchLastModification from '~/src/hooks/firm/useFetchLastModification'
import { APP_STATE } from '~/src/stores/app'
import { useLocation } from 'react-router-dom'

const useFetchLastModificationEffect = () => {
  const location = useLocation()
  const { isActive } = useEoValue(APP_STATE)
  const { fetch: fetchLastModifications } = useFetchLastModification()

  useAsyncEffect(async () => {
    if (!isActive) {
      return
    }

    await fetchLastModifications()
  }, [ location, isActive ])
}

export default useFetchLastModificationEffect
