import { createContext, ReactNode, useState } from 'react'
import ConfirmResponsiveDialog from '~/src/components/confirm/ConfirmResponsiveDialog'

interface Props {
  children: ReactNode,
}

interface State {
  message: string | null,
  onConfirm: (() => void) | null,
  onCancel: (() => void) | null,
}

type ConfirmDialogContextOptions = Partial<State>

interface ConfirmDialogContextInterface {
  triggerConfirmDialog(options: ConfirmDialogContextOptions): void,
}

const ConfirmDialogContext = createContext<ConfirmDialogContextInterface>({
  triggerConfirmDialog: () => {
    throw new Error('ConfirmDialogContext.triggerConfirmDialog not implemented')
  }
})

const ConfirmDialogProvider = ({ children }: Props) => {
  const [ state, setState ] = useState<State>({
    message: null,
    onConfirm: null,
    onCancel: null
  })

  const triggerConfirmDialog = (options: ConfirmDialogContextOptions) => {
    setState((state: State) => ({
      ...state,
      ...options
    }))
  }

  const _resetState = () => {
    setState({
      message: null,
      onConfirm: null,
      onCancel: null
    })
  }

  const handleOnCancel = () => {
    state.onCancel && state.onCancel()
    _resetState()
  }

  const handleOnConfirm = () => {
    state.onConfirm && state.onConfirm()
    _resetState()
  }

  return (
    <ConfirmDialogContext.Provider value={{ triggerConfirmDialog }}>
      {children}
      {state.message && (
        <ConfirmResponsiveDialog
          message={state.message}
          onConfirm={handleOnConfirm}
          onCancel={handleOnCancel}
        />
      )}
    </ConfirmDialogContext.Provider>
  )
}

export default ConfirmDialogProvider
export { ConfirmDialogContext }
export type { ConfirmDialogContextOptions }
