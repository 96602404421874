import { DateUtils, DeliveryMethodNames, Firm, Timeslot } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

interface Props {
  timeslot: Timeslot,
}

const DeliveryMethodsTimeslotOption = ({ timeslot }: Props) => {
  const firm = useEoValue<Firm|null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)

  if (!firm || !checkoutForm.delivery_method.method) {
    return null
  }

  if (
    checkoutForm.delivery_method.method !== DeliveryMethodNames.ON_THE_SPOT
    && firm.settings.delivery_methods[checkoutForm.delivery_method.method]?.orders_only_during_operational_hours
    && !DateUtils.timeIsBetween(
      DateUtils.formatTime(new Date()),
      {
        start: timeslot.from_time,
        end: timeslot.to_time
      }
    )
  ) {
    return null
  }

  return (
    <option value={timeslot.id}>{`${timeslot.from_time} - ${timeslot.to_time}`}</option>
  )
}

export default DeliveryMethodsTimeslotOption
