import { Location } from '@eo-storefronts/eo-core'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { Skeleton } from '@mui/material'
import IconLink from '~/src/components/utils/links/IconLink'
import LanguageIcon from '@mui/icons-material/Language'

interface Props {
  location?: Location,
  isLoading?: boolean,
}

const LocationContact = ({ isLoading = false, location }: Props) => {
  if (isLoading || !location) {
    return (
      <>
        <Skeleton
          variant='text'
          sx={{ fontSize: '1rem' }}
        />
        <Skeleton
          variant='text'
          sx={{ fontSize: '1rem' }}
        />
      </>
    )
  }

  const { phone, website } = location

  if (!phone && !website) {
    return null
  }

  return (
    <>
      {phone && <IconLink
        startIcon={<LocalPhoneIcon />}
        underline='hover'
        href={`tel:${phone}`}
        color='inherit'
      >
        {phone}
      </IconLink>}

      {website && <IconLink
        startIcon={<LanguageIcon />}
        underline='hover'
        href={website}
        target='_blank'
        color='inherit'
      >
        {website}
      </IconLink>}
    </>
  )
}

export default LocationContact
