import { DateUtils, DeliveryMethod, DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useCanOrderOnDay } from '~/src/hooks/delivery-methods/useCanOrderOnDay'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { isOpened } from '~/src/services/OpeningHourService'

interface State {
  canToday: boolean,
  canTomorrow: boolean,
  canPick: boolean,
}

interface ReturnsType extends State {
  canOrderOnDay(date: Date): boolean,
}

const useDeliveryMethodsDate = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { canOrderOnDay } = useCanOrderOnDay()
  const [ state, setState ] = useState<State>({
    canToday: false,
    canTomorrow: false,
    canPick: false
  })

  useEffect(() => {
    if (
      !firm
      || !checkoutForm.delivery_method.method
      || checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
      || !checkoutForm.delivery_method.minDate
    ) {
      return
    }

    const deliveryMethod = firm.settings.delivery_methods[checkoutForm.delivery_method.method] as DeliveryMethod

    if (
      deliveryMethod.orders_only_during_opening_hours
      && (
        checkoutForm.delivery_method.method !== DeliveryMethodNames.PICKUP
        || !checkoutForm.delivery_method.pickupPoint
        || checkoutForm.delivery_method.pickupPoint.main_branch
      )
    ) {
      setState({
        canToday: isOpened(firm.settings.periods.opening_hours) && canOrderOnDay(new Date()),
        canTomorrow: false,
        canPick: false
      })

      return
    }

    setState({
      canToday: canOrderOnDay(new Date()),
      canTomorrow: canOrderOnDay(DateUtils.addDays(1)),
      canPick: true
    })
  }, [ firm, checkoutForm ])

  return {
    ...state,
    canOrderOnDay
  }
}

export {
  useDeliveryMethodsDate
}
