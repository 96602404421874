import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryContentServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/StylesDeliveryMethodsSummaryContentServiceInterface'
import { BoxProps } from '@mui/material'

export default class StylesDeliveryMethodsSummaryContentServiceStyleOne extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryContentServiceInterface {
  getDeliveryMethodsSummaryContainerProps(): BoxProps {
    return {}
  }
}
