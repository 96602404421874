import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, SxProps, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { OverridableStringUnion } from '@mui/types'
import PrimaryButton from '~/src/components/mui-wrappers/buttons/PrimaryButton'
import { Unit } from '@eo-storefronts/eo-core'
import ProductQuantity from '~/src/components/products/ProductQuantity'

interface Props {
  textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
  sx?: SxProps,
  size?: 'small' | 'medium' | 'large',
  rounded?: boolean,
  disabled?: boolean,
  disableMinus?: boolean,
  quantity: number,
  unitInterval?: number,
  min?: number,
  max?: number,
  unit?: Unit | null,
  quantityBtnSx?: SxProps,
  onUpdate(quantity: number): void,
}

const QuantitySelector = ({
  size = 'medium',
  textVariant = 'body2',
  rounded = false,
  disabled = false,
  sx,
  quantity,
  unit,
  unitInterval = 1,
  disableMinus = quantity === 1,
  min = unitInterval,
  max,
  quantityBtnSx,
  onUpdate
}: Props) => {
  const handleOnAdd = () => {
    let result: number = quantity + unitInterval

    result = parseFloat(result.toFixed(3))

    onUpdate((max && result > max) ? max : result)
  }

  const handleOnRemove = () => {
    let result: number = quantity - unitInterval

    result = parseFloat(result.toFixed(3))

    onUpdate(result < min ? min : result)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'max-content auto max-content',
        alignItems: 'center',
        ...sx
      }}
    >
      <PrimaryButton
        id='quantity_selector__minus_btn'
        size={size}
        disabled={disableMinus || quantity <= min}
        color='container'
        onClick={handleOnRemove}
        disableFocusRipple
        sx={{
          borderRadius: rounded ? '50%' : 'var(--btn-border-radius)',
          ...quantityBtnSx
        }}
      >
        <RemoveIcon fontSize={size} />
      </PrimaryButton>

      <Typography
        sx={{ mx: 1, textAlign: 'center' }}
        variant={textVariant}
      >
        <ProductQuantity quantity={quantity} unit={unit} />
      </Typography>

      <PrimaryButton
        id='quantity_selector__plus_btn'
        size={size}
        disabled={disabled || !!max && quantity >= max}
        onClick={handleOnAdd}
        sx={{
          borderRadius: rounded ? '50%' : 'var(--btn-border-radius)',
          ...quantityBtnSx
        }}
      >
        <AddIcon fontSize={size} />
      </PrimaryButton>
    </Box>
  )
}

export default QuantitySelector
