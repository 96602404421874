const TakeawayIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 44 44'
    width='1em'
    height='1em'>
    <path
      fill='#fb8c00'
      d='M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28c2.209,0,4,1.791,4,4v28	C42,40.209,40.209,42,38,42z'/>
    <path
      fill='#fff'
      d='M23.096,10.263c0.52-0.348,1.199-0.348,1.719,0c1.325,0.834,2.597,1.75,3.807,2.743	c0,0,0.33,0.228,0.339-0.159c-0.033-0.5-0.003-1.002,0.088-1.494c0.1-0.263,0.351-0.438,0.633-0.44c0,0,1.495,0.075,2.444,0.202	c0.424,0.056,0.755,0.396,0.8,0.822c0,0,0.611,4.071,0.748,5.539c0,0,0.118,0.748,1.2,2.013c0,0,2.198,3.078,2.453,3.666	c0,0,0.51,1.064-0.589,1.209c0,0-2.005,0.202-2.198,0.246c-0.27,0.032-0.465,0.274-0.44,0.545c0,0-0.105,7.082-0.624,10.485	c0,0-0.136,1.35-0.879,1.319c0,0-2.062-0.066-2.541-0.053c0,0-0.206,0-0.176-0.277c0,0,1.05-11.043,0.339-16.626	c0,0-0.031-0.721-0.58-0.879c0,0-0.501-0.202-0.98,0.44c-2.079,2.911-3.218,6.389-3.262,9.966c0,0-0.057,1.759,0.211,2.198	c0,0,0.166,0.308,1.02,0.378l1.081,0.145c0,0,0.198,0,0.18,0.263c0,0-0.246,3.24-0.316,3.816c-0.005,0.132-0.031,0.26-0.08,0.382	c0,0-0.039,0.11-0.378,0.097c0,0-4.765-0.035-5.302,0c0,0-0.224,0-0.272-0.118c-0.048-0.119-0.33-4.181-0.321-4.757	c-0.009-0.12,0.058-0.232,0.167-0.282c0.737-0.465,1.222-1.239,1.319-2.105c0.058-0.616,0.066-1.235,0.026-1.851	c0,0,0.176-6.211,0.22-7.21c0,0,0.035-0.339-0.405-0.399c-0.22-0.052-0.44,0.084-0.492,0.303v0.035c0,0.035-0.307,4.097-0.251,5.715	c0,0,0.106,1.059-0.628,1.081c0,0-0.712,0.106-0.748-0.778c0,0,0.053-3.983,0.228-5.952c0.001-0.225-0.167-0.415-0.391-0.44	c-0.233-0.032-0.45,0.125-0.492,0.356c-0.003,0.011-0.003,0.023,0,0.035c0,0-0.308,3.812-0.251,5.826c0,0,0.088,0.976-0.69,0.94	c0,0-0.651,0.075-0.685-0.791c0,0,0.176-5.455,0.224-5.877c-0.012-0.215-0.177-0.39-0.391-0.413h-0.044	c-0.228-0.019-0.432,0.139-0.471,0.365c-0.001,0.013-0.001,0.026,0,0.039c0,0-0.289,6.524-0.25,8.44c0,0-0.026,1.97,1.428,2.77	c0,0,0.215,0.123,0.22,0.312c0,0,0.163,3.46,0.312,4.577c0,0,0.053,0.268-0.184,0.268L15.328,37	c-0.398-0.002-0.726-0.315-0.748-0.712c-0.474-3.666-0.7-7.36-0.676-11.056c0.027-0.283-0.162-0.541-0.44-0.602	c0,0-1.873-0.171-2.326-0.285c-0.416-0.108-0.666-0.533-0.558-0.949c0.018-0.07,0.046-0.137,0.083-0.199	C13.706,17.947,17.971,13.51,23.096,10.263L23.096,10.263z'/>
  </svg>
)

export default TakeawayIcon
