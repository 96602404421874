import AuthSelectionOptions from '~/src/components/auth/auth-selection/AuthSelectionOptions'
import FooterGoToCheckoutButton from '~/src/components/layout/footer/FooterGoToCheckoutButton'
import SwipeAbleDrawer from '~/src/components/mui-wrappers/swipeable-drawer'

const FooterGoToCheckout = () =>  (
  <SwipeAbleDrawer
    anchor='bottom'
    activator={<FooterGoToCheckoutButton />}
  >
    <AuthSelectionOptions />
  </SwipeAbleDrawer>
)

export default FooterGoToCheckout
