import { Box, BoxProps } from '@mui/material'
import InlineAddress from '~/src/components/customers/addresses/InlineAddress'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeliveryMethodSummaryAddressButtonLabel = (props: BoxProps) => {
  const { t } = useTranslations()
  const customer = useEoValue(CUSTOMER_STATE)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { address } = checkoutForm.delivery_method

  return (
    <Box {...props}>
      {(!address && !customer) && t('delivery_methods.add_address')}
      {(!address && customer) && t('delivery_methods.choose_address')}
      {address && <InlineAddress address={address}/>}
    </Box>
  )
}

export default DeliveryMethodSummaryAddressButtonLabel
