import { Alert as MuiAlert, paperClasses, styled } from '@mui/material'

const Alert = styled(MuiAlert)(({ theme }) => ({
  [`&.${paperClasses.root}`]: {
    boxShadow: 'unset !important',
    paddingBottom: `${theme.spacing(1)} !important`,
    display: 'flex',
    alignItems: 'center'
  }
}))

export default Alert
