import { Box } from '@mui/material'
import FooterBackBtn from '~/src/components/layout/footer/FooterBackBtn'
import FooterGoToCart from '~/src/components/layout/footer/FooterGoToCart'
import FooterGoToCheckout from '~/src/components/layout/footer/FooterGoToCheckout'
import FooterInformations from '~/src/components/layout/footer/FooterInformations'
import FooterLinks from '~/src/components/layout/footer/FooterLinks'
import useGetFooterTemplate from '~/src/components/layout/footer/styles/useGetFooterTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { BACK_BUTTON_STATE, BackButtonState } from '~/src/stores/back-button'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'

const Footer = () => {
  const { footer, isMobile } = useEoValue<LayoutState>(LAYOUT_STATE)
  const backBtn = useEoValue<BackButtonState | null>(BACK_BUTTON_STATE)
  const styles = useGetFooterTemplate()

  if (!footer.display) {
    return null
  }

  return (
    <Box sx={styles.getContainerSx()}>
      <Box sx={{ gridArea: 'left' }}>
        {(backBtn && !isMobile) && <FooterBackBtn />}
        {footer.displayLinks && <FooterLinks />}
      </Box>

      <Box sx={{ gridArea: 'right' }}>
        {footer.displayGoToCheckoutBtn && <FooterGoToCheckout />}
        {footer.displayGoToCartBtn && <FooterGoToCart />}
        {footer.displayInformations && <FooterInformations />}
      </Box>
    </Box>
  )
}

export default Footer
