import { MouseEvent } from 'react'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { dialogContentClasses, dialogTitleClasses, SxProps } from '@mui/material'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'
import { Id } from '@eo-storefronts/eo-core'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import ProductModalListContainer from '~/src/components/products/modals/products-list/container'

export interface ProductListModalProps extends ResponsiveDialogProps {
  productIds: Id[],
  onProductChoose?(e: MouseEvent<HTMLElement>, productId: string): void,
  listSx?: {
    GridSx?: SxProps,
    ItemsSx?: SxProps,
  },
}

const ProductsListModal = ({
  openByDefault,
  onClose,
  productIds,
  onProductChoose,
  listSx,
  ...otherProps
}: ProductListModalProps) => {
  const theme = useCustomMuiTheme()

  return (
    <ResponsiveDialog
      openByDefault={openByDefault}
      onClose={onClose}
      sx={{
        [`.${dialogTitleClasses.root}`]: {
          backgroundColor: 'var(--background-from)'
        },
        [theme.breakpoints.down('md')]: {
          [`.${dialogContentClasses.root}`]: {
            px: 0
          }
        }
      }}
      contentSx={{
        position: 'relative',
        p: 4,
        backgroundColor: 'var(--background-from)'
      }}
      maxWidth={'sm'}
      fullWidth
      {...otherProps}
    >
      <ProductModalListContainer
        ids={productIds}
        onProductChoose={onProductChoose}
        listSx={listSx}
      />
    </ResponsiveDialog>
  )
}

export default ProductsListModal
