import StylesHeaderCartServiceInterface
  from '~/src/components/layout/header/header-cart/styles/StylesHeaderCartServiceInterface'
import StylesHeaderCartServiceStyleOne
  from '~/src/components/layout/header/header-cart/styles/StylesHeaderCartServiceStyleOne'
import StylesHeaderCartServiceStyleTwo
  from '~/src/components/layout/header/header-cart/styles/StylesHeaderCartServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getHeaderCartTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesHeaderCartServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesHeaderCartServiceStyleTwo(muiTheme)
    default:
      return new StylesHeaderCartServiceStyleOne(muiTheme)
  }
}

export default getHeaderCartTemplateService
