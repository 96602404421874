import { SxProps } from '@mui/material'
import StylesHeaderCartServiceInterface
  from '~/src/components/layout/header/header-cart/styles/StylesHeaderCartServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderCartServiceStyleOne extends TemplateServiceStyleBase implements StylesHeaderCartServiceInterface {
  public getLinkSx(): SxProps {
    return {
      gap: 0.5
    }
  }

  public getLabelSx(): SxProps {
    return {
      display: 'inline-flex',
      gap: 0.5
    }
  }
}
