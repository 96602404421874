import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesExpandableSearchButtonServiceInterface
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/StylesExpandableSearchButtonServiceInterface'
import { inputBaseClasses } from '@mui/material'

export default class StylesExpandableSearchButtonServiceStyleThree extends TemplateServiceStyleBase implements StylesExpandableSearchButtonServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      '--font-size': '1em',
      '--base-size': '40px',
      height: 'var(--base-size)',
      minWidth: 'var(--base-size)',
      borderRadius: '12px',
      backgroundColor: (this.muiTheme.palette as any).container.main,
      color: (this.muiTheme.palette as any).container.contrastText,
      transition: 'var(--ease-in-out)',
      overflow: 'hidden',
      button: {
        padding: 0,
        width: 'var(--base-size)',
        height: 'var(--base-size)',
        svg: {
          fontSize: 'var(--font-size)'
        }
      },
      input: {
        marginLeft: this.muiTheme.spacing(1),
        paddingLeft: this.muiTheme.spacing(1),
        borderRadius: `calc(12px - ${this.muiTheme.spacing(.5)})`
      },
      '&.collapsed': {
        width: 'var(--base-size)',
        paddingRight: 0,
        '&:hover': {
          opacity: .65,
          color: (this.muiTheme.palette as any).primary.contrastText,
          backgroundColor: this.muiTheme.palette.primary.main,
          [`div.${inputBaseClasses.root}`]: {
            'button:first-of-type': {
              color: (this.muiTheme.palette as any).primary.contrastText
            }
          }
        }
      },
      '&.expanded': {
        width: '300px',
        minWidth: '300px',
        color: (this.muiTheme.palette as any).primary.contrastText,
        backgroundColor: this.muiTheme.palette.primary.main,
        input: {
          color: (this.muiTheme.palette as any).primary.main,
          backgroundColor: this.muiTheme.palette.primary.contrastText,
          '::placeholder': {
            color: (this.muiTheme.palette as any).primary.main
          }
        }
      },
      [this.muiTheme.breakpoints.down('sm')]: {
        '&.expanded': {
          minWidth: `calc(100% - ${this.muiTheme.spacing(2)})`,
          marginRight: this.muiTheme.spacing(2)
        }
      },
      [this.muiTheme.breakpoints.up('2k')]: {
        '--base-size': 'calc(40px + var(--font-size) / 2)',
        // This fixes the alignment due to px being calculated
        button: {
          marginLeft: '-1px',
          marginTop: '1px'
        }
      }
    }
  }
}
