import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesShoppingCartServiceInterface
  from '~/src/components/cart/shopping-cart/styles/StylesShoppingCartServiceInterface'
import StylesShoppingCartServiceStyles from '~/src/components/cart/shopping-cart/styles/StylesShoppingCartServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getShoppingCartTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesShoppingCartServiceInterface => {
  return new StylesShoppingCartServiceStyles(muiTheme)
}

export default getShoppingCartTemplateService
