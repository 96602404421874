import PageMap from '~/src/router/utils/default-page-resolver/types/PageMap'
import { TemplatePages } from '~/src/types/Templates'

interface Condition {
  key: string,
  pass: boolean,
}

export default abstract class DefaultPageResolver {
  protected pagesMapping: PageMap[] = []
  protected defaultPage: TemplatePages = 'locator'

  public resolve(pathname: string, conditions?: Condition[]): TemplatePages {
    for (const mapping of this.pagesMapping) {
      if (!pathname.includes(mapping.includes)) {
        continue
      }

      if (mapping.key) {
        const result = conditions?.find((condition: Condition) => condition.key === mapping.key)

        if (result?.pass) {
          continue
        }
      }

      return this.pagesMapping.find((m: PageMap) => m.page === mapping.page)?.page || this.defaultPage
    }

    return this.defaultPage
  }
}

