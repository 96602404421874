import { createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { NewsFeed } from '@eo-storefronts/eo-core'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'

export const NEWS_STATE = createState<NewsFeed[]>({
  key: 'newsState',
  default: []
})

export const SELECTED_NEWS_STATE = createState<NewsFeed | null>({
  key: 'selectedNews',
  default: null
})

export const MARKETPLACE_EXPLORER_NEWS_SELECTOR_FAMILY = createSelectorFamily<NewsFeed[], boolean>({
  key: 'explorerNewsSelector',
  get: (downMd: boolean) => ({ get }): NewsFeed[] => {
    const news: NewsFeed[] = get<NewsFeed[]>(NEWS_STATE)
    const { isMobile }: LayoutState = get<LayoutState>(LAYOUT_STATE)

    if (!news?.length) {
      return []
    }

    if (isMobile && downMd) {
      return news.slice(0, 2)
    }

    return news
  }
})

export const NEWS_FEED_BY_ID_SELECTOR_FAMILY = createSelectorFamily<NewsFeed | undefined, string | undefined>({
  key: 'newFeedByIdSelectorFamily',
  get: (id?: string) => ({ get }): NewsFeed | undefined => {
    return get(NEWS_STATE).find((item: NewsFeed) => item.id.toString() === id)
  }
})
