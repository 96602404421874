import { Typography } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import { useEoValue } from '~/src/hooks/useEoState'
import { GET_CUSTOMER_QUICK_AUTH_ID_SELECTOR } from '~/src/stores/customer'

const ProfileQuickAuthIdAsText = (props: TypographyProps) => {
  const value = useEoValue(GET_CUSTOMER_QUICK_AUTH_ID_SELECTOR)

  if (!value) {
    return null
  }

  return (
    <Typography {...props}>{value}</Typography>
  )
}

export default ProfileQuickAuthIdAsText
