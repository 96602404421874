import { useEoValue } from '~/src/hooks/useEoState'
import { GUEST_FORM_FIELDS_STATE } from '~/src/stores/guest-form'
import useFormFields from '~/src/hooks/forms/useFormFields'

const useGuestFormFields = () => {
  const formFields = useEoValue(GUEST_FORM_FIELDS_STATE)

  return useFormFields(formFields)
}

export default useGuestFormFields
