import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import DevModeDialogActions from '~/src/components/layout/dev-mode/dialog/DevModeDialogActions'
import DevModeDialogContent from '~/src/components/layout/dev-mode/dialog/DevModeDialogContent'
import { useFormik } from 'formik'
import { Firm, Theme } from '@eo-storefronts/eo-core'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR, SELECTED_SHOP_STATE } from '~/src/stores/firm'
import { Box } from '@mui/material'
import { BRAND_STATE } from '~/src/stores/brand'
import useDialog from '~/src/hooks/useDialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

export type ThemeColorsFormInterface = Pick<Theme, 'container_color' | 'container_contrast_color' | 'main_color' | 'main_color_contrast_color' | 'main_gradient_color_from' | 'main_gradient_color_to' | 'main_gradient_contrast_color' | 'success_gradient_color_from' | 'success_gradient_color_to' | 'success_gradient_contrast_color' | 'tab_color' | 'tab_contrast_color' | 'theme_color' | 'theme_color_contrast_color' | 'well_color' | 'well_contrast_color' | 'content_color' | 'content_contrast_color'>


const DevModeDialog = () => {
  const { dismiss } = useDialog()
  const defaultColor = '#ffffff'
  const [ brand, setBrand ] = useEoState(BRAND_STATE)
  const selectedShopName = useEoValue(SELECTED_SHOP_STATE)
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const form = useFormik<ThemeColorsFormInterface>({
    initialValues: {
      container_color: firmTheme?.container_color || defaultColor,
      container_contrast_color: firmTheme?.container_contrast_color || defaultColor,
      main_color: firmTheme?.main_color || defaultColor,
      main_color_contrast_color: firmTheme?.main_color_contrast_color || defaultColor,
      main_gradient_color_from: firmTheme?.main_gradient_color_from || defaultColor,
      main_gradient_color_to: firmTheme?.main_gradient_color_to || defaultColor,
      main_gradient_contrast_color: firmTheme?.main_gradient_contrast_color || defaultColor,
      success_gradient_color_from: firmTheme?.success_gradient_color_from || defaultColor,
      success_gradient_color_to: firmTheme?.success_gradient_color_to || defaultColor,
      success_gradient_contrast_color: firmTheme?.success_gradient_contrast_color || defaultColor,
      tab_color: firmTheme?.tab_color || defaultColor,
      tab_contrast_color: firmTheme?.tab_contrast_color || defaultColor,
      theme_color: firmTheme?.theme_color || defaultColor,
      theme_color_contrast_color: firmTheme?.theme_color_contrast_color || defaultColor,
      well_color: firmTheme?.well_color || defaultColor,
      well_contrast_color: firmTheme?.well_contrast_color || defaultColor,
      content_color: firmTheme?.content_color || defaultColor,
      content_contrast_color: firmTheme?.content_contrast_color || defaultColor
    },
    onSubmit: (values: ThemeColorsFormInterface) => {
      if (!brand) {
        closeDialog()
        return
      }

      let updatedBrand: Firm = brand

      if (selectedShopName) {
        const locationIndex = brand?.locations?.findIndex((shop: Firm) => shop.sitename === selectedShopName)

        if (!locationIndex || locationIndex === -1 || !brand.locations) {
          closeDialog()
          return
        }

        updatedBrand = {
          ...brand,
          locations: [
            ...brand.locations.slice(0, locationIndex),
            {
              ...brand.locations[locationIndex],
              settings: {
                ...brand.locations[locationIndex].settings ?? {},
                theme: {
                  ...brand.locations[locationIndex].settings.theme,
                  ...values
                }
              }
            },
            ...brand.locations.slice(locationIndex + 1)
          ]
        }
      } else {
        updatedBrand = {
          ...brand,
          settings: {
            ...brand.settings ?? {},
            theme: {
              ...brand.settings.theme,
              ...values
            }
          }
        }
      }

      setBrand(updatedBrand)
      closeDialog()
    }
  })

  const closeDialog = () => {
    dismiss(DialogNameEnum.DEV_MODE)
  }

  return (
    <ResponsiveDialog
      openByDefault
      name={DialogNameEnum.DEV_MODE}
      Title={<span>Developer options</span>}
      fullWidth
      maxWidth='lg'
      sx={{
        'input[type="color"]': {
          padding: '0 !important',
          '-webkit-appearance': 'none',
          border: 'none',
          height: '32px'
        },
        'input[type="color"]::-webkit-color-swatch-wrapper': {
          padding: 0,
          borderRadius: '25px !important'
        },
        'input[type="color"]::-webkit-color-swatch': {
          border: 'none',
          borderRadius: '.5em !important'
        }
      }}
    >
      <Box
        component='form'
        onSubmit={form.handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        <DevModeDialogContent form={form}/>
        <DevModeDialogActions form={form}/>
      </Box>
    </ResponsiveDialog>
  )
}

export default DevModeDialog
