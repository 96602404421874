import CustomerLoyaltyCurrentPoints from '~/src/components/profile/account/loyalty/current-points'
import FirmLoyaltyRewards from '~/src/components/profile/account/loyalty/rewards'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'

const SideDrawerLoyalty = () => {
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()

  const _handleOnClick = () => {
    if (isLoggedIn) {
      push(resolve(`${RoutesEnum.PROFILE}/${RoutesEnum.LOYALTY}`))
    } else {
      push(resolve(RoutesEnum.LOGIN))
    }
  }

  if (!isLoyaltyEnabled) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        cursor: 'pointer'
      }}
      onClick={_handleOnClick}
    >
      <CustomerLoyaltyCurrentPoints />
      <FirmLoyaltyRewards />
    </Box>
  )
}

export default SideDrawerLoyalty
