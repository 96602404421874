import StylesShoppingCartTotalServiceInterface
  from '~/src/components/cart/shopping-cart-total/styles/StylesShoppingCartTotalServiceInterface'
import StylesShoppingCartTotalServiceStyleOne
  from '~/src/components/cart/shopping-cart-total/styles/StylesShoppingCartTotalServiceStyleOne'
import StylesShoppingCartTotalServiceStyleTwo
  from '~/src/components/cart/shopping-cart-total/styles/StylesShoppingCartTotalServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getShoppingCartTotalTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesShoppingCartTotalServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesShoppingCartTotalServiceStyleTwo(muiTheme)
    default:
      return new StylesShoppingCartTotalServiceStyleOne(muiTheme)
  }
}

export default getShoppingCartTotalTemplateService
