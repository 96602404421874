enum ProfileRoutesEnum {
  'PROFILE' = '/profile',
  'ACCOUNT' = 'account',
  'ADDRESS' = 'address',
  'ADDRESSES' = 'addresses',
  'MARKETING' = 'marketing',
  'LOYALTY' = 'loyalty',
  'DELETE_ACCOUNT' = ''
}

export default ProfileRoutesEnum
