import { SxProps } from '@mui/material'
import StylesHeaderMultiFirmSwitchServiceInterface
  from '~/src/components/layout/header/header-multi-firm-switch/styles/StylesHeaderMultiFirmSwitchServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderMultiFirmSwitchServiceStyleOne extends TemplateServiceStyleBase implements StylesHeaderMultiFirmSwitchServiceInterface {
  public getButtonSx(): SxProps {
    return {
      color: 'container.contrastText',
      transition: 'var(--ease-in-out)',
      textTransform: 'none',
      '&:hover': {
        opacity: 0.5
      }
    }
  }
}
