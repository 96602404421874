import StylesHeaderLanguageSelectorServiceInterface
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceInterface'
import StylesHeaderLanguageSelectorServiceStyleOne
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceStyleOne'
import StylesHeaderLanguageSelectorServiceStyleThree
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceStyleThree'
import StylesHeaderLanguageSelectorServiceStyleTwo
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'

const getHeaderLanguageSelectorTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme): StylesHeaderLanguageSelectorServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesHeaderLanguageSelectorServiceStyleTwo(muiTheme, firmTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesHeaderLanguageSelectorServiceStyleThree(muiTheme, firmTheme)
    default:
      return new StylesHeaderLanguageSelectorServiceStyleOne(muiTheme, firmTheme)
  }
}

export default getHeaderLanguageSelectorTemplateService
