import { chipClasses } from '@mui/material'
import StylesChipServiceInterface from '~/src/components/mui-wrappers/styled/chip/styles/StylesChipServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesChipServiceStyleOne extends TemplateServiceStyleBase implements StylesChipServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`.${chipClasses.label}`]: {
        lineHeight: 'normal'
      }
    }
  }
}
