const classNames = (obj: Record<string, boolean>) => {
  const classes: string[] = []

  for (const key in obj) {
    if (!obj[key]) {
      continue
    }

    classes.push(key)
  }

  return classes.join(' ')
}

export { classNames }
