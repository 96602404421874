import { Divider as MUIDivider, dividerClasses, styled } from '@mui/material'

const Divider = styled(MUIDivider)(({ theme }) => ({
  [`&.${dividerClasses.root}`]: {
    borderColor: 'inherit',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

export default Divider
