import LayoutCheckoutService from '~/src/pages/checkout/layout/LayoutCheckoutService'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getCheckoutLayout = (_: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  return new LayoutCheckoutService().getLayout(getLayoutArgs)
}

export default getCheckoutLayout
