import { Grid } from '@mui/material'
import { GridListItemProps } from '~/src/types/GridList'

const GridListItem = (props: GridListItemProps) => {
  return (
    <Grid
      item
      { ...props }
    >
      { props.children }
    </Grid>
  )
}

export default GridListItem
