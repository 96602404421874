import { EoInjectable } from '@eo-storefronts/eo-core'
import DefaultPageResolver from '~/src/router/utils/default-page-resolver/index'
import PageMap from '~/src/router/utils/default-page-resolver/types/PageMap'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { TemplatePages } from '~/src/types/Templates'

@EoInjectable()
export default class FirmDefaultPage extends DefaultPageResolver {
  protected readonly pagesMapping: PageMap[] = [
    {
      key: 'navigate-by-product',
      includes: RoutesEnum.MENU,
      page: 'products'
    },
    {
      key: 'navigate-by-categories',
      includes: RoutesEnum.MENU,
      page: 'categories'
    },
    {
      includes: RoutesEnum.CATEGORIES,
      page: 'categories'
    },
    {
      includes: RoutesEnum.CATEGORY,
      page: 'category'
    },
    {
      includes: RoutesEnum.PRIVACY_POLICY,
      page: 'privacyPolicy'
    },
    {
      includes: RoutesEnum.TERMS_AND_CONDITIONS,
      page: 'termsAndConditions'
    },
    {
      includes: RoutesEnum.SEARCH,
      page: 'search'
    },
    {
      includes: RoutesEnum.NOT_FOUND,
      page: 'notFound'
    },
    {
      includes: RoutesEnum.FORCE_UPDATE,
      page: 'update'
    },
    {
      includes: RoutesEnum.LOGIN,
      page: 'login'
    },
    {
      includes: RoutesEnum.REGISTER,
      page: 'register'
    },
    {
      includes: RoutesEnum.ACCOUNT_ACTIVATION,
      page: 'activation'
    },
    {
      includes: RoutesEnum.EXTRA_INFO_CUSTOMER,
      page: 'extraInfoCustomer'
    },
    {
      includes: RoutesEnum.EXTRA_INFO_GUEST,
      page: 'extraInfoGuest'
    },
    {
      includes: RoutesEnum.CART,
      page: 'cart'
    },
    {
      includes: RoutesEnum.CHECKOUT,
      page: 'checkout'
    },
    {
      includes: RoutesEnum.ORDERS,
      page: 'order'
    },
    {
      includes: RoutesEnum.PRODUCT,
      page: 'product'
    },
    {
      includes: RoutesEnum.PRODUCTS,
      page: 'products'
    },
    {
      includes: RoutesEnum.FIRM_INFO,
      page: 'firmInfos'
    },
    {
      includes: RoutesEnum.NEWS,
      page: 'news'
    },
    {
      includes: RoutesEnum.NEWS_DETAIL,
      page: 'newsDetails'
    },
    {
      includes: RoutesEnum.PROFILE,
      page: 'profile'
    }
  ]

  protected readonly defaultPage: TemplatePages = 'locator'
}
