import { Product } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useCategoryAvailable } from '~/src/hooks/categories/useCategoryAvailable'
import { useProductAvailableState } from '~/src/hooks/products/useProductAvailableState'

interface ReturnsType {
  available: boolean,
}

const useProductAvailable = (product: Product): ReturnsType => {
  const { available: categoryAvailable } = useCategoryAvailable(product.categoryId)
  const { state: productAvailableState } = useProductAvailableState(product)
  const [ available, setAvailable ] = useState<boolean>(true)

  useEffect(() => {
    setAvailable(categoryAvailable && productAvailableState.inStock && !productAvailableState.snoozed)
  }, [ productAvailableState, categoryAvailable ])

  return { available }
}

export {
  useProductAvailable
}
