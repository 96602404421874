import useInitFacebookPixel from '~/src/hooks/analytics/useInitFacebookPixel'
import useInitGoogleAnalytics from '~/src/hooks/analytics/useInitGoogleAnalytics'
import useInitGoogleTagManager from '~/src/hooks/analytics/useInitGoogleTagManager'

const useInitAnalyticsProviders = (): void => {
  useInitGoogleAnalytics()
  useInitGoogleTagManager()
  useInitFacebookPixel()
}

export default useInitAnalyticsProviders
