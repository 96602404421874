import { Box, Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import {
  LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR,
  LOYALTY_DISCOUNT_SELECTOR,
  LOYALTY_TYPE_IS_DISCOUNT_SELECTOR,
  LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR
} from '~/src/stores/loyalty'

const ShoppingCartLoyaltyDiscount = () => {
  const { t } = useTranslations()
  const loyaltyDiscountCanBeApplied = useEoValue(LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR)
  const loyaltyTypeIsDiscount = useEoValue(LOYALTY_TYPE_IS_DISCOUNT_SELECTOR)
  const loyaltyTypeIsFreeProduct = useEoValue(LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR)
  const loyaltyDiscount = useEoValue(LOYALTY_DISCOUNT_SELECTOR)

  if (!loyaltyDiscountCanBeApplied) {
    return null
  }

  if (loyaltyTypeIsFreeProduct) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <Typography>{t('shopping_cart.loyalty_discount')}</Typography>
      {loyaltyTypeIsDiscount && <Typography>{`- ${loyaltyDiscount}`}</Typography>}
    </Box>
  )
}

export default ShoppingCartLoyaltyDiscount
