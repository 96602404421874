import { SxProps, tabsClasses } from '@mui/material'
import StylesSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/location-sub-header/styles/StylesSubHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSubHeaderServiceStyleTwo extends TemplateServiceStyleBase implements StylesSubHeaderServiceInterface {
  public getLinkSx(): SxProps {
    return {
      transition: 'var(--ease-in-out)',
      py: 1.5,
      '&:hover': {
        opacity: 0.5
      }
    }
  }

  public getHeaderSubHeaderSx(headerHeight: number): SxProps {
    return {
      position: 'sticky',
      top: `${Math.floor(headerHeight)}px`,
      zIndex: 2,
      background: 'var(--background-from) linear-gradient(var(--background-from), var(--background-to))',
      backgroundAttachment: 'fixed',
      color: 'background.contrastText',
      pl: 12,
      pr: 2,
      py: 3,
      display: 'flex',
      gap: 1,
      overflow: 'hidden',
      [this.muiTheme.breakpoints.down('md')]: {
        transition: 'var(--ease-in-out)',
        top: -1,
        width: '100%',
        p: 0,
        py: 2,
        pl: 2,
        '&.is-pinned': {
          pt: `calc(${this.muiTheme.spacing(2)} + var(--eo-safe-area-top))`,
          transition: 'var(--ease-in-out)'
        },
        [`div.${tabsClasses.root}`]: {
          justifyContent: 'center',
          [`div.${tabsClasses.scroller}`]: {
            flex: 'unset'
          }
        }
      }
    }
  }
}
