import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Box, Button } from '@mui/material'
import DeliveryMethodsTimeSummary from '~/src/components/delivery-methods/DeliveryMethodsTimeSummary'
import EatInSummary from '~/src/components/delivery-methods/EatInSummary'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import useGetDeliveryMethodsSummaryMethodButtonTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/useGetDeliveryMethodsSummaryMethodButtonTemplate'

const DeliveryMethodsSummaryMethodButton = () => {
  const styles = useGetDeliveryMethodsSummaryMethodButtonTemplate()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const frozenDeliveryMethod = useEoValue(FROZEN_DELIVERY_METHOD_STATE)

  return (
    <Button {...styles.getButtonProps(frozenDeliveryMethod)}>
      <Box {...styles.getLabelContainerProps()}>
        {checkoutForm.delivery_method.method !== DeliveryMethodNames.ON_THE_SPOT && <DeliveryMethodsTimeSummary/>}
        {checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT && <EatInSummary/>}
      </Box>
    </Button>
  )
}

export default DeliveryMethodsSummaryMethodButton
