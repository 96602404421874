import { LayoutState } from '~/src/stores/layout'
import LayoutServiceInterface, { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

export default class LayoutSearchServiceStyleThree implements LayoutServiceInterface {
  public getLayout({ isMobile }: GetLayoutArgs): Partial<LayoutState> {
    return {
      header: {
        display: !isMobile,
        displayMenuBurger: false,
        displayCart: true,
        displayCurrentFirm: true,
        displayDeliveryOptions: false,
        displayLanguagesSelector: true,
        displayUserActions: true,
        displayProductSearch: true,
        displayLocationSearch: false,
        logo: {
          disableLink: false
        },
        title: '',
        type: 'navigation'
      },
      subHeader: {
        display: !isMobile,
        variant: 'default'
      },
      content: {
        displayNotification: true
      },
      mainSideBar: {
        display: true,
        displayCart: true,
        displayDeliveryOptions: true,
        displayOrderSummary: false
      },
      footer: {
        display: false,
        displayGoToCartBtn: false,
        displayGoToCheckoutBtn: false,
        displayInformations: false,
        displayLinks: false
      },
      mobileNavigation: {
        display: true
      }
    }
  }
}
