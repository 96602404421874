import { chipClasses } from '@mui/material'
import StylesChipServiceInterface from '~/src/components/mui-wrappers/styled/chip/styles/StylesChipServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesChipServiceStyleTwo extends TemplateServiceStyleBase implements StylesChipServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`.${chipClasses.label}`]: {
        lineHeight: 'normal'
      },
      [`& .${chipClasses.colorPrimary}`]: {
        backgroundColor: this.muiTheme.palette.primary.light
      },
      [`& .${chipClasses.colorSecondary}`]: {
        backgroundColor: this.muiTheme.palette.secondary.light
      },
      '& .MuiChip-colorSuccess': {
        backgroundColor: this.muiTheme.palette.success.light
      },
      '& .MuiChip-colorError': {
        backgroundColor: this.muiTheme.palette.error.light
      },
      '& .MuiChip-colorWarning': {
        backgroundColor: this.muiTheme.palette.warning.light
      },
      '& .MuiChip-colorInfo': {
        backgroundColor: this.muiTheme.palette.info.light
      }
    }
  }
}
