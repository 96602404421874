import { Box } from '@mui/material'
import DeliveryMethodsSummaryMethodSentenceMethod
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceMethod'
import DeliveryMethodsSummaryMethodSentenceTime
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceTime'
import DeliveryMethodsSummaryMethodSentenceDate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceDate'
import DeliveryMethodsSummaryMethodSentenceOnTheSpot
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceOnTheSpot'
import SlideFromContainer from '~/src/components/mui-wrappers/animations/SlideFromContainer'

interface Props {
  showAnimation: boolean,
}

const DeliveryMethodsSummaryMethodSentence = ({ showAnimation }: Props) => {
  return (
    <Box
      component={'div'}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        columnGap: .5,
        flexWrap: 'wrap',
        span: {
          display: 'inline-flex',
          alignItems: 'center',
          textAlign: 'left'
        }
      }}>
      <DeliveryMethodsSummaryMethodSentenceMethod/>
      {showAnimation && (
        <SlideFromContainer in direction={'right'}>
          <DeliveryMethodsSummaryMethodSentenceOnTheSpot/>
          <DeliveryMethodsSummaryMethodSentenceDate/>
          <DeliveryMethodsSummaryMethodSentenceTime/>
        </SlideFromContainer>
      )}
    </Box>
  )
}

export default DeliveryMethodsSummaryMethodSentence
