import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import ProductUnitPriceTemplateServiceInterface
  from '~/src/components/products/product-unit-price/styles/ProductUnitPriceTemplateServiceInterface'

export default class ProductUnitPriceTemplateServiceStyleOne extends TemplateServiceStyleBase implements ProductUnitPriceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      textAlign: 'center'
    }
  }

  public getPromotionalPriceSx(): SxProps {
    return { }
  }
}
