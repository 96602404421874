import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

interface ReturnsType {
  logEvent(optionsOrName: UaEventOptions | string, params?: unknown): void,
}

const useLogGoogleAnalyticEvent = (): ReturnsType => {
  const logEvent = (eventName: UaEventOptions | string, params?: unknown): void => {
    ReactGA.event(eventName, params)
  }

  return { logEvent }
}

export default useLogGoogleAnalyticEvent
