import { Box, SxProps } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  loading?: boolean,
  sx?: SxProps,
  onSave(): void,
  onCancel(): void,
}

const ChooseCustomerAddressActions = ({ loading, sx, onSave, onCancel }: Props) => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        ...sx
      }}
    >
      <Button
        loading={loading}
        variant='contained'
        disableElevation
        onClick={onSave}
      >
        {t('actions.save')}
      </Button>
      <Button
        variant='outlined'
        onClick={onCancel}
      >
        {t('actions.cancel')}
      </Button>
    </Box>
  )
}

export default ChooseCustomerAddressActions
