import ConfirmResponsiveDialogActions from '~/src/components/confirm/ConfirmResponsiveDialogActions'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'

interface Props {
  message: string | null,
  onConfirm: (() => void) | null,
  onCancel: (() => void) | null,
}

const ConfirmResponsiveDialog = ({ message, onConfirm, onCancel }: Props) => {
  return (
    <ResponsiveDialog
      openByDefault
      Actions={
        <ConfirmResponsiveDialogActions
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      }
    >
      {message}
    </ResponsiveDialog>
  )
}

export default ConfirmResponsiveDialog
