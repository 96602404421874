import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryAddressButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/StylesDeliveryMethodsSummaryAddressButtonServiceInterface'
import StylesDeliveryMethodsSummaryAddressButtonServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/StylesDeliveryMethodsSummaryAddressButtonServiceStyleTwo'
import StylesDeliveryMethodsSummaryAddressButtonServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/StylesDeliveryMethodsSummaryAddressButtonServiceStyleOne'

const getDeliveryMethodsSummaryAddressButtonTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryAddressButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryAddressButtonServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryAddressButtonServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryAddressButtonTemplateService
