import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryContentServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/StylesDeliveryMethodsSummaryContentServiceStyleTwo'
import StylesDeliveryMethodsSummaryContentServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/StylesDeliveryMethodsSummaryContentServiceStyleOne'
import StylesDeliveryMethodsSummaryContentServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/StylesDeliveryMethodsSummaryContentServiceInterface'

const getDeliveryMethodsSummaryContentTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryContentServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryContentServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryContentServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryContentTemplateService
