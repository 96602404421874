import { DateUtils, DeliveryMethodNames, FirstAvailableFulfilmentTime, Id } from '@eo-storefronts/eo-core'
import { useCallback, useRef } from 'react'
import useGetFirstAvailableFulfilmentTime from '~/src/hooks/delivery-methods/useGetFirstAvailableFulfilmentTime'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { CART_CATEGORIES_ID_SELECTOR, CART_PRODUCTS_ID_SELECTOR } from '~/src/stores/cart'
import deepEqual from '~/src/helpers/deepEqual'

interface ReturnsType {
  setFirstAvailableTime(force?: boolean): Promise<void>,
}

interface PreviousRunRefInterface {
  firmId: Id,
  products: Id[],
  categories: string[],
  pickupPointId: Id | null,
  deliveryMethod: string,
}

const useSetFirstAvailableTime = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { calculateFirstAvailableTime } = useGetFirstAvailableFulfilmentTime()
  const pickupPoint = useEoValue(CHECKOUT_PICKUP_POINT_SELECTOR)
  const categories = useEoValue(CART_CATEGORIES_ID_SELECTOR)
  const products = useEoValue(CART_PRODUCTS_ID_SELECTOR)
  const previousRun = useRef<PreviousRunRefInterface>({
    firmId: 0,
    products: [],
    categories: [],
    pickupPointId: null,
    deliveryMethod: ''
  })

  const _updatePreviousRun = () => {
    previousRun.current = {
      firmId: firm?.id || 0,
      products,
      categories,
      pickupPointId: checkoutForm.delivery_method.pickupPoint?.id || null,
      deliveryMethod: checkoutForm.delivery_method.method || ''
    }
  }

  const setFirstAvailableTime = useCallback(async (force = true): Promise<void> => {
    try {
      /**
       * Preventing same call to be triggered several times, unless we want it to
       */
      if (!firm) {
        previousRun.current.firmId = 0
        return
      }

      if (
        (
          checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP
          && (firm.settings.delivery_methods[DeliveryMethodNames.PICKUP]?.pickup_points || []).length
          && !pickupPoint
        )
        || (
          deepEqual(previousRun.current, {
            firmId: firm.id,
            products,
            categories,
            pickupPointId: checkoutForm.delivery_method.pickupPoint?.id || null,
            deliveryMethod: checkoutForm.delivery_method.method || ''
          }) && !force
        )
      ) {
        return
      }

      const firstAvailableTime: FirstAvailableFulfilmentTime | undefined = await calculateFirstAvailableTime(
        firm.id,
        checkoutForm.delivery_method.method,
        pickupPoint,
        products,
        categories
      )

      if (!firstAvailableTime?.timestamp) {
        return
      }

      /**
       * If the time returned by the API is below the current selected time; we do not update it
       * Unless we force it.
       */
      if (
        !force
        && previousRun.current.firmId === firm.id
        && checkoutForm.delivery_method.time
        && checkoutForm.delivery_method.method === previousRun.current.deliveryMethod
        && (
          !checkoutForm.delivery_method.pickupPoint?.id 
          || checkoutForm.delivery_method.pickupPoint?.id === previousRun.current.pickupPointId
        )
        && DateUtils.isAfter(
          new Date(checkoutForm.delivery_method.time),
          new Date(firstAvailableTime.timestamp)
        )
      ) {
        _updatePreviousRun()
        return
      }

      _updatePreviousRun()

      setCheckoutForm((state: CheckoutFormState) => {
        // Restoring the checkout form from the localState (payment failure or cancellation)
        if (previousRun.current.deliveryMethod === '' && state.delivery_method.minDate) {
          return state
        }

        return {
          ...state,
          delivery_method: {
            ...state.delivery_method,
            minDate: firstAvailableTime.timestamp,
            time: firstAvailableTime.timestamp
          }
        }
      })
    } catch (e) {
      // Ignore
    }
  }, [
    firm,
    products,
    categories,
    checkoutForm.delivery_method.method,
    pickupPoint
  ])

  return { setFirstAvailableTime }
}

export default useSetFirstAvailableTime
