import Title from '~/src/components/mui-wrappers/Title'
import { DateUtils } from '@eo-storefronts/eo-core'

interface Props {
  time: string,
}

const DeliveryMethodTimePickerToolbar = ({ time }: Props) => (
  <Title
    sx={{
      p: 0,
      pt: 2,
      textAlign: 'center'
    }}
    variant={'h3'}
  >
    {DateUtils.formatTime(new Date(time))}
  </Title>
)

export default DeliveryMethodTimePickerToolbar
