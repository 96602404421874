const TooGoodToGoIcon = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 2000 1666'
    height='1em'>
    <ellipse
      fill='#bdece5'
      cx='1337.11'
      cy='952.64'
      rx='828.95'
      ry='485.9'
      transform='translate(-259.54 1334.85) rotate(-48.93)'/>
    <ellipse
      fill='#79d0c6'
      cx='1023.89'
      cy='832.06'
      rx='489.71'
      ry='822.5'/>
    <ellipse
      fill='#45ada1'
      cx='663.91'
      cy='952.64'
      rx='485.9'
      ry='828.95'
      transform='translate(-462.48 670.6) rotate(-41.07)'/>
  </svg>
)

export default TooGoodToGoIcon
