import {
  styled,
  toggleButtonClasses,
  ToggleButtonGroup as MUIToggleButtonGroup,
  toggleButtonGroupClasses
} from '@mui/material'

const ToggleButtonGroup = styled(MUIToggleButtonGroup)(({ theme }) => ({
  [`&.${toggleButtonGroupClasses.root}`]: {
    backgroundColor: (theme.palette as any).tab.main,
    color: (theme.palette as any).tab.contrastText,
    borderRadius: 'var(--btn-border-radius)',
    [`&>.${toggleButtonClasses.root}`]: {
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  },
  [`&.${toggleButtonGroupClasses.grouped}`]: {
    border: 0,
    [`&.${toggleButtonClasses.selected}`]: {
      border: 0,
      borderRadius: 'var(--btn-border-radius)'
    },
    [`&.${toggleButtonClasses.disabled}`]: {
      border: 0
    }
  }
}))

export default ToggleButtonGroup
