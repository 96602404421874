import {
  FormControlLabel as MUIFormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
  styled
} from '@mui/material'
import { classNames } from '~/src/services/ClassNameService'

const FormControlLabelStyledComponent = styled(MUIFormControlLabel)(({ theme }) => ({
  [`&.${formControlLabelClasses.root}`]: {
    backgroundColor: (theme.palette.background as any).main,
    color: (theme.palette.background as any).contrastText,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${(theme.palette.background as any).main}`,
    paddingRight: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    transition: 'var(--ease-in-out)'
  },
  [`&.${formControlLabelClasses}-checked`]: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`
  }
}))

const FormControlLabel = ({ checked = false, ...otherProps }: FormControlLabelProps) => (
  <FormControlLabelStyledComponent
    checked={checked}
    className={classNames({
      [`${formControlLabelClasses.root}-checked`]: checked
    })}
    {...otherProps}
  />
)

export default FormControlLabel
