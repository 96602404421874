import { ApiConfiguration, Customer, Firm, Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { fetchCustomerById, fetchCustomerByUUID } from '~/src/services/CustomerService'
import { AUTHENTICATION_STATE, IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  fetch(customerIdOrUUID?: Id): Promise<Customer | undefined>,
}

const useFetchCustomer = (): ReturnsType => {
  const { customer } = useEoValue(AUTHENTICATION_STATE)
  const brand: Firm | null = useEoValue(BRAND_STATE)
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const isUsingNewBrandAuthSystem: boolean = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)

  const _fetchByUUID = async (uuid?: string): Promise<Customer | undefined> => {
    const id: string | undefined = uuid ?? customer?.uuid

    if (!brand || !id) {
      return
    }

    return fetchCustomerByUUID(id, brand.id)
  }

  const _fetchById = async (customerId?: Id): Promise<Customer | undefined> => {
    const id: Id | undefined = customerId ?? customer?.id

    if (!firm || !id || !ApiConfiguration.getHeader('Authorization')) {
      return
    }

    return fetchCustomerById(id, firm.id)
  }

  const fetch = async (customerIdOrUUID?: Id): Promise<Customer | undefined> => {
    if (isUsingNewBrandAuthSystem) {
      return _fetchByUUID(customerIdOrUUID as string | undefined)
    }

    return _fetchById(customerIdOrUUID)
  }

  return { fetch }
}

export default useFetchCustomer
