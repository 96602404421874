import DeliveryMethodsDateTime from '~/src/components/delivery-methods/DeliveryMethodsDateTime'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeliveryMethodsDateTimeContainer = () => {
  const { t } = useTranslations()

  return (
    <>
      <Title sx={{ mb: 1 }}>{`${t('global.when')}:`}</Title>
      <DeliveryMethodsDateTime />
    </>
  )
}

export default DeliveryMethodsDateTimeContainer
