import PersonIcon from '@mui/icons-material/Person'
import { ButtonProps } from '@mui/material/Button/Button'
import { ReactNode } from 'react'
import ButtonWithIcon from '~/src/components/mui-wrappers/buttons/ButtonWithIcon'
import Link from '~/src/components/mui-wrappers/Link'
import EoLinkProps from '~/src/types/EoLinkProps'

interface Props extends Omit<ButtonProps, 'component'> {
  linkProps: Partial<EoLinkProps>,
  loading?: boolean,
  children: ReactNode,
  enableResponsive?: boolean,
  displayIcon?: boolean,
  icon?: ReactNode,
}

const ButtonLink = ({ displayIcon = true, enableResponsive = false, color, linkProps, ...otherProps }: Props) => (
  <Link
    removeBaseLinkStyle
    color={color}
    {...linkProps}
  >
    <ButtonWithIcon
      startIcon={displayIcon && <PersonIcon />}
      color={color}
      enableResponsive={enableResponsive}
      {...otherProps}
    >
    </ButtonWithIcon>
  </Link>
)

export default ButtonLink
