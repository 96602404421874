import { Firm, Id, Product } from '@eo-storefronts/eo-core'
import { indexDbPersist } from '~/src/helpers/recoilPersist'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import CartService from '~/src/services/CartService'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import {
  CHECKOUT_FORM_STATE,
  CHECKOUT_PRODUCT_COUPON_AMOUNT,
  CHECKOUT_PRODUCT_LOYALTY_AMOUNT,
  GET_DELIVERY_COST,
  GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR
} from '~/src/stores/checkout'
import { FIRM_SELECTOR, GET_TOTAL_AMOUNT_OF_SERVICE_FEES_SELECTOR } from '~/src/stores/firm'
import { LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR, LOYALTY_TYPE_IS_DISCOUNT_SELECTOR } from '~/src/stores/loyalty'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRODUCT_COMMENT_STATE } from '~/src/stores/product-comment'
import deepEqual from '~/src/helpers/deepEqual'
import { getProductPrice } from '~/src/services/ProductService'
import { CartProduct } from '~/src/types/CartProduct'
import NumberUtils from '~/src/utils/NumberUtils'

const key = 'cartState'

export interface CartState {
  [key: Id]: CartProduct[],
}

export interface WithCartTotal {
  withServiceFee?: boolean,
  withCheckoutOptions?: boolean,
  withDeliveryCosts?: boolean,
  withLoyaltyDiscount?: boolean,
  withGlobalVoucherDiscount?: boolean,
  withProductVoucherDiscount?: boolean,
  withCategoryVoucherDiscount?: boolean,
}

export interface CartModifierStateInterface {
  id: string,
  quantity: number,
  type: string,
  action: 'add'|'rem'|'pre',
  modifierGroups: CartModifierGroupStateInterface[],
}

export interface CartModifierGroupStateInterface {
  id: string,
  modifiers: CartModifierStateInterface[],
}

export const CART_MODIFIER_GROUP_STATE = createState<CartModifierGroupStateInterface[]>({
  key: 'cartModifierGroupsState',
  default: []
})

export const CART_STATE = createState<CartState>({
  key,
  default: {},
  effects: [
    // persisted 1week
    indexDbPersist(key),
    // Backward compatibility
    ({ setSelf, onSet }) => {
      onSet((newCartValue) => {
        const nCart: CartState = {}
        const firmIds = Object.keys(newCartValue)
        let mustUpdate = false

        for (const firmId of firmIds) {
          if (newCartValue[firmId].length === 0) {
            continue
          }

          if ('ingredient_groups' in newCartValue[firmId][0]) {
            mustUpdate = true
            nCart[firmId] = []
          } else {
            nCart[firmId] = [ ...newCartValue[firmId] ]
          }
        }

        if (mustUpdate) {
          setSelf(nCart)
        }
      })
    }
  ]
})

export const CART_OF_FIRM_SELECTOR = createSelector<CartProduct[]>({
  key: 'cartOfFirmSelector',
  get: ({ get }) => {
    const firmSelected: Firm | null = get(FIRM_SELECTOR)
    const carts: CartState | null = get(CART_STATE)

    if (!firmSelected || !carts || !carts[firmSelected.id]) {
      return []
    }

    return carts[firmSelected.id]
  }
})

export const CART_CATEGORIES_ID_SELECTOR = createSelector<string[]>({
  key: 'cartCategoriesIdSelector',
  get: ({ get }) => {
    const cart: CartProduct[] = get(CART_OF_FIRM_SELECTOR)
    const products: Record<Id, Product> = get(PRODUCTS_STATE)

    return cart.map((cp: CartProduct) => products[cp.id]?.categoryId || '')
  }
})

export const CART_PRODUCTS_ID_SELECTOR = createSelector({
  key: 'cartProductsIdSelector',
  get: ({ get }) => {
    const products: CartProduct[] = get(CART_OF_FIRM_SELECTOR)

    return products.map((product: CartProduct) => product.id)
  }
})

export const CART_COUPON_PRODUCTS_LIST_SELECTOR = createSelector<string[]>({
  key: 'cartCouponProductsListSelector',
  get: ({ get }) => {
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const categories = get(CATEGORIES_STATE)

    if (
      !checkoutForm.coupon?.category
      || checkoutForm.coupon.type !== 'category'
      || checkoutForm.coupon.chosen_product_id !== null
    ) {
      return []
    }

    return categories[Number(checkoutForm.coupon.category.id)].products
  }
})

export const CART_SUB_TOTAL_SELECTOR = createSelector({
  key: 'cartSubTotalSelector',
  get: ({ get }) => {
    const cart = get(CART_OF_FIRM_SELECTOR)
    const products = get(PRODUCTS_STATE)
    const modifierGroups = get(MODIFIER_GROUPS_STATE)
    const modifiers = get(MODIFIERS_STATE)

    let total = 0

    cart.forEach((cp: CartProduct) => {
      const product: Product = products[cp.id]

      if (!product) {
        return
      }

      const service = new CartService(modifierGroups, modifiers)
      const price: number = getProductPrice(product)

      total += service
        .calculateCartProductPrice(price, cp.quantity, cp.modifierGroups || [])
    })

    return total
  }
})

export const CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR = createSelector({
  key: 'cartSubtotalWithDiscounts',
  get: ({ get }) => {
    const subtotal = get(CART_SUB_TOTAL_SELECTOR)
    const couponDiscountAmount = get(CHECKOUT_PRODUCT_COUPON_AMOUNT)
    const loyaltyDiscountAmount = get(CHECKOUT_PRODUCT_LOYALTY_AMOUNT)

    return subtotal - couponDiscountAmount - loyaltyDiscountAmount
  }
})

export const CART_TOTAL_ITEMS_SELECTOR = createSelector({
  key: 'cartTotalItems',
  get: ({ get }) => {
    const products = get(PRODUCTS_STATE)
    const cartProducts = get(CART_OF_FIRM_SELECTOR)
    let total = 0

    cartProducts.forEach((cartProduct: CartProduct) => {
      if (products[cartProduct.id] && products[cartProduct.id].unit?.id !== 1) {
        total += 1
        return
      }

      total += cartProduct.quantity
    })

    return total
  }
})

export const CART_TOTAL_SELECTOR = createSelectorFamily<number, Readonly<WithCartTotal>>({
  key: 'cartTotalSelector',
  get: ({
    withServiceFee = true,
    withCheckoutOptions = true,
    withDeliveryCosts = true,
    withLoyaltyDiscount = true,
    withGlobalVoucherDiscount = true
  }: Readonly<WithCartTotal>) => ({ get }) => {
    const totalAmountOfCart = get(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)
    const totalAmountOfServiceFees = get(GET_TOTAL_AMOUNT_OF_SERVICE_FEES_SELECTOR)
    const totalAmountOfCheckoutOptions = get(GET_TOTAL_AMOUNT_OF_CHECKOUT_OPTIONS_SELECTOR)
    const deliveryCost = get(GET_DELIVERY_COST)
    const loyaltyDiscountCanBeApplied = get(LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR)
    const loyaltyTypeIsDiscount = get(LOYALTY_TYPE_IS_DISCOUNT_SELECTOR)
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const firm = get(FIRM_SELECTOR)

    let price = totalAmountOfCart

    if (withServiceFee) {
      price += totalAmountOfServiceFees
    }

    if (withDeliveryCosts) {
      price += deliveryCost
    }

    if (withLoyaltyDiscount && loyaltyDiscountCanBeApplied) {
      if (loyaltyTypeIsDiscount) {
        if (firm!.settings.loyalty!.discount_type === 'percentage') {
          price -= NumberUtils.getPercentageValue(price, firm!.settings.loyalty!.discount_value)
        } else {
          price -= firm!.settings.loyalty!.discount_value
        }
      }
    }

    if (checkoutForm.coupon && checkoutForm.coupon.value) {
      if (withGlobalVoucherDiscount && checkoutForm.coupon.type !== 'product' && checkoutForm.coupon.type !== 'category') {
        if (checkoutForm.coupon.type === 'percentage') {
          price -= NumberUtils.getPercentageValue(price , checkoutForm.coupon.value)
        } else {
          price -= checkoutForm.coupon.value
        }
      }
    }

    if (withCheckoutOptions) {
      price += totalAmountOfCheckoutOptions
    }

    if (price < 0) {
      price = 0
    }

    return price
  }
})

export const CART_ITEM_WITH_PRODUCT_SELECTOR = createSelectorFamily<CartProduct | undefined, any>({
  key: 'cartItemWithProductSelector',
  get: (productId: string) => ({ get }): CartProduct | undefined => {
    const cart: CartProduct[] = get(CART_OF_FIRM_SELECTOR)
    const modifierGroups: CartModifierGroupStateInterface[] = get(CART_MODIFIER_GROUP_STATE)
    const productComment = get(PRODUCT_COMMENT_STATE)

    return cart.find((cartProduct: CartProduct) => {
      return (cartProduct.id === productId) &&
        deepEqual(cartProduct.modifierGroups, modifierGroups) &&
        cartProduct.comment === productComment
    })
  }
})
