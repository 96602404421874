import { BottomNavigationActionProps } from '@mui/material'
import MobileNavigationCartItem from '~/src/components/layout/mobile-navigation/MobileNavigationCartItem'
import MobileNavigationMenuItem from '~/src/components/layout/mobile-navigation/MobileNavigationMenuItem'
import LinkBottomNavigationAction from '~/src/components/mui-wrappers/styled/link-bottom-navigation-action'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'

const MobileNavigationItem = (props: CustomNavItemProps | BottomNavigationActionProps) => {
  if (props.value === 'menu') {
    return <MobileNavigationMenuItem {...props as BottomNavigationActionProps} />
  }

  if (props.value === 'cart') {
    return <MobileNavigationCartItem {...props as BottomNavigationActionProps} />
  }

  return <LinkBottomNavigationAction {...props as CustomNavItemProps} />
}

export default MobileNavigationItem
