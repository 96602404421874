import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Box, Typography } from '@mui/material'
import Card from '~/src/components/mui-wrappers/Card'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const CustomerLoyaltyCurrentPoints = () => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)
  const customer = useEoValue(CUSTOMER_STATE)

  if (!isLoyaltyEnabled || !customer) {
    return null
  }

  return (
    <Box>
      <Typography
        variant='body2'
        sx={{
          textTransform: 'uppercase'
        }}
      >
        {t('profile.loyalty.points_balance')}
      </Typography>
      <Card
        sx={{
          textAlign: 'center',
          fontSize: '1rem',
          mt: 1,
          p: 1,
          '&:hover': {
            boxShadow: `0 0 5px ${theme.palette.primary.main}`
          }
        }}
      >
        <b>{customer.loyalty?.current_points}</b> {t('profile.loyalty.points')}
      </Card>
    </Box>
  )
}

export default CustomerLoyaltyCurrentPoints
