import { DateUtils, Day } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import FirmOpeningHourDay from '~/src/components/firms/FirmOpeningHourDay'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'


const FirmOpeningHours = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const { t } = useTranslations()

  if (!firm?.settings.periods) {
    return null
  }

  return (
    <Box>
      <Typography variant='h5'>
        {t('firm.opening_hours')}
      </Typography>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(4, 1fr)',
        gridColumnGap: 2,
        gridRowGap: 2
      }}>
        {DateUtils.allDay().map((day: Day, index: number) => {
          return <FirmOpeningHourDay key={index} day={day} />
        })}
      </Box>
    </Box>
  )
}

export default FirmOpeningHours
