import LayoutExplorerServiceStyleOne from '~/src/pages/explorer/layout/LayoutExplorerServiceStyleOne'
import LayoutExplorerServiceStyleThree from '~/src/pages/explorer/layout/LayoutExplorerServiceStyleThree'
import LayoutExplorerServiceStyleTwo from '~/src/pages/explorer/layout/LayoutExplorerServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getExplorerLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutExplorerServiceStyleTwo().getLayout()
    case TemplateEnum.STYLE_THREE:
      return new LayoutExplorerServiceStyleThree().getLayout()
    default:
      return new LayoutExplorerServiceStyleOne().getLayout()
  }
}

export default getExplorerLayout
