import { SxProps } from '@mui/material'
import StylesFooterServiceInterface from '~/src/components/layout/footer/styles/StylesFooterServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesFooterServiceStyleOne extends TemplateServiceStyleBase implements StylesFooterServiceInterface {
  public getContainerSx(): SxProps {
    return {
      px: 2,
      py: 1,
      display: 'grid',
      gridTemplateAreas: '"left right"',
      gridTemplateColumns: 'auto minmax(auto,50%)',
      gap: 10,
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateAreas: '"right"',
        gridTemplateColumns: '1fr',
        gap: 'unset'
      }
    }
  }
}
