import { Avatar, AvatarProps, Box, BoxProps, Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { TypographyProps } from '@mui/material/Typography/Typography'

export interface ProfileLargeDropdownLabelProps {
  ComponentProps?: {
    Root?: BoxProps,
    Avatar?: AvatarProps,
    CustomerTextContainer?: BoxProps,
    CustomerName?: TypographyProps,
    CustomerEmail?: TypographyProps,
  },
}

const ProfileLargeDropdownLabel = ({ ComponentProps }: ProfileLargeDropdownLabelProps) => {
  const customer = useEoValue(CUSTOMER_STATE)

  if (!customer) {
    return null
  }

  return (
    <Box
      id='profile_large_dropdown_label__container'
      component='span'
      sx={{
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        textTransform: 'none'
      }}
      {...ComponentProps?.Root}>
      <Avatar
        id='profile_large_dropdown_label__avatar'
        {...ComponentProps?.Avatar}>
        {(customer.first_name || customer.last_name || '').substring(0, 2)}
      </Avatar>

      <Box
        id='profile_large_dropdown_label__text_container'
        sx={{
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center'
        }}
        {...ComponentProps?.CustomerTextContainer}>
        <Typography
          id='profile_large_dropdown_label__customer_name'
          variant='h6'
          sx={{
            textAlign: 'start',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%'
          }}
          {...ComponentProps?.CustomerName}>
          {customer.first_name} {customer.last_name}
        </Typography>
        {customer.email && (
          <Typography
            id='profile_large_dropdown_label__customer_email'
            variant='subtitle1'
            sx={{
              textAlign: 'start',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100%'
            }}
            {...ComponentProps?.CustomerEmail}>
            {customer.email}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default ProfileLargeDropdownLabel
