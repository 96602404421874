import { Category, Id } from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { checkCategoryAvailability } from '~/src/services/CategoryService'

export const CATEGORY_ID_STATE = createState<string | null>({
  key: 'categoryIdState',
  default: null
})

export const CATEGORIES_STATE = createState<Record<Id, Category>>({
  key: 'categoriesState',
  default: {}
})

export const CATEGORY_BY_ID_SELECTOR = createSelector<Category | null>({
  key: 'categoryByIdSelector',
  get: ({ get }) => {
    const categories = get(CATEGORIES_STATE)
    const categoryId = get(CATEGORY_ID_STATE)

    if (!categoryId) {
      return null
    }

    return categories[categoryId]
  }
})

export const CATEGORY_BY_ID_SELECTOR_FAMILY = createSelectorFamily<Category | undefined, string | null>({
  key: 'categoryByIdSelectorFamily',
  get: (id: string | null) => ({ get }): Category | undefined => {
    if (!id) {
      return
    }

    return get(CATEGORIES_STATE)[id]
  }
})

export const AVAILABLE_CATEGORIES_SELECTOR = createSelector<Category[]>({
  key: 'availableCategoriesSelector',
  get: ({ get }) => {
    const categories = get(CATEGORIES_STATE)
    const checkoutForm = get(CHECKOUT_FORM_STATE)
    const firm = get(FIRM_SELECTOR)

    return Object.values(categories)
      .filter((category:Category) => {
        const time: Date = checkoutForm.delivery_method.time ? new Date(checkoutForm.delivery_method.time) : new Date()

        return !firm?.settings.theme.only_show_available_parent_categories || checkCategoryAvailability(category.id, categories, time)
      })
  }
})

export const TOP_CATEGORIES_SELECTOR = createSelector<Category[]>({
  key: 'topCategoriesSelector',
  get: ({ get }) => {
    const categories = get(AVAILABLE_CATEGORIES_SELECTOR)

    return Object.values(categories)
      .filter((category: Category) => !category.parent_id)
      .sort((a: Category, b: Category) => a.sort - b.sort)
  }
})

export const TOP_CATEGORY_SELECTED_SELECTOR = createSelector<Category | undefined>({
  key: 'topCategorySelectedSelector',
  get: ({ get }) => {
    const topCategories = get(TOP_CATEGORIES_SELECTOR)
    const categoryId = get(CATEGORY_ID_STATE)

    return topCategories.find((cat: Category) => cat.id === categoryId)
  }
})

export const ORDERED_FLATTEN_CATEGORIES_WITH_PRODUCTS_SELECTOR = createSelector<Category[]>({
  key: 'orderedFlattenCategoriesWithProductsSelector',
  get: ({ get }) => {
    const topCategories = get(TOP_CATEGORIES_SELECTOR)
    const allCategories = get(CATEGORIES_STATE)

    const flattenCategories = (categories: Category[]): Category[] => {
      let flattened: Category[] = []

      for (const category of categories) {
        flattened = [
          ...flattened,
          {
            ...category,
            subcategories: []
          },
          ...flattenCategories(
            category.subcategories
              .map((catId: string) => allCategories[catId])
              .sort((a: Category, b: Category) => a.sort - b.sort)
          )
        ]
      }

      return flattened
    }

    return flattenCategories(topCategories)
      .filter((category: Category) => category.products.length)
  }
})

export const CATEGORY_SHOULD_BE_DISPLAY_SELECTOR = createSelectorFamily<boolean, Id | undefined | null>({
  key: 'categoryShouldBeDisplaySelector',
  get: (id: Id | undefined | null) => ({ get }): boolean => {
    const categories = get(AVAILABLE_CATEGORIES_SELECTOR)

    if (!id) {
      return true
    }

    return !!categories.find((category: Category) => category.id.toString() === id.toString())
  }
})
