import { Box, Typography } from '@mui/material'
import useGetShoppingCartTotalTemplate
  from '~/src/components/cart/shopping-cart-total/styles/useGetShoppingCartTotalTemplate'
import Price from '~/src/components/Price'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CART_TOTAL_SELECTOR } from '~/src/stores/cart'

const ShoppingCartTotalPrice = () => {
  const { t } = useTranslations()
  const styles = useGetShoppingCartTotalTemplate()
  const cartTotal = useEoValue(CART_TOTAL_SELECTOR({}))

  return (
    <Box sx={styles.getTotalPriceContainerSxProps()}>
      <Typography
        component='span'
        sx={styles.getTotalPriceLabelSxProps()}
      >
        {t('shopping_cart.total')}
      </Typography>
      <Typography
        component='span'
        variant='h5'
        sx={styles.getTotalPriceValueSxProps()}
      >
        <Price price={cartTotal} />
      </Typography>
    </Box>
  )
}

export default ShoppingCartTotalPrice
