import { SwipeableDrawerProps } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import SwipeableDrawerStyled from '~/src/components/mui-wrappers/styled/drawer'
import SwipeAbleDrawerContext from '~/src/components/mui-wrappers/swipeable-drawer/SwipeAbleDrawerContext'

interface Props extends Partial<SwipeableDrawerProps> {
  persistent?: boolean,
  activator?: ReactNode,
}

const SwipeAbleDrawer = ({ activator, open, persistent = false, children, ...otherProps }: Props) => {
  const [ opened, setOpened ] = useState(open || false)

  const toggle = () => {
    if (!persistent) {
      setOpened(!opened)
    }
  }

  useEffect(() => {
    setOpened(Boolean(open))
  }, [ open ])

  return (
    <SwipeAbleDrawerContext.Provider value={{ toggle: toggle }}>
      {activator}
      <SwipeableDrawerStyled
        onClose={toggle}
        onOpen={toggle}
        open={opened}
        {...otherProps}
      >
        {children}
      </SwipeableDrawerStyled>
    </SwipeAbleDrawerContext.Provider>
  )
}

export default SwipeAbleDrawer
