import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryContentServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/StylesDeliveryMethodsSummaryContentServiceInterface'
import getDeliveryMethodsSummaryContentTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/GetDeliveryMethodsSummaryContentTemplateService'

const useGetDeliveryMethodsSummaryContentTemplate = (): StylesDeliveryMethodsSummaryContentServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryContentTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryContentTemplate
