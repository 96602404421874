import RoutesEnum from '~/src/router/enums/routes.enum'
import { redirect, RouteObject } from 'react-router-dom'
import { lazy } from 'react'

const MenuPage = lazy(async () => import('~/src/pages/menu'))
const LoginPage = lazy(async () => import('~/src/pages/auth/login'))
const RegisterPage = lazy(async () => import('~/src/pages/auth/register'))
const ActivationPage = lazy(async () => import('~/src/pages/auth/activation'))
const CartPage = lazy(async () => import('~/src/pages/cart'))
const ProductsByCategoriesPage = lazy(async () => import('~/src/pages/category/[slug]/products'))
const LocationsPage = lazy(async () => import('~/src/pages/category/[slug]/locations'))
const ExtraInfoGuestPage = lazy(async () => import('~/src/pages/extra-info/guest'))
const FirmInfoPage = lazy(async () => import('~/src/pages/firm/infos'))
const PrivacyPolicyPage = lazy(async () => import('~/src/pages/legal/privacy-policy'))
const TermsAndConditionsPage = lazy(async () => import('~/src/pages/legal/terms-and-conditions'))
const NewsPage = lazy(async () => import('~/src/pages/news'))
const NewsDetailsPage = lazy(async () => import('~/src/pages/news/[slug]/details'))
const CheckoutPage = lazy(async () => import('~/src/pages/checkout'))
const UpcomingPage = lazy(async () => import('~/src/pages/order/upcoming'))
const OrdersPage = lazy(async () => import('~/src/pages/orders'))
const AccountPage = lazy(async () => import('~/src/pages/profile/account'))
const AddressPage = lazy(async () => import('~/src/pages/profile/address'))
const AddressDetail = lazy(async () => import('~/src/pages/profile/address/[slug]'))
const AddressesPage = lazy(async () => import('~/src/pages/profile/addresses'))
const LoyaltyPage = lazy(async () => import('~/src/pages/profile/loyalty'))
const MarketingPage = lazy(async () => import('~/src/pages/profile/marketing'))
const SearchResultPage = lazy(async () => import('~/src/pages/search'))
const ProductPage = lazy(async () => import('~/src/pages/dispatchers/Product'))
const CategoriesPage = lazy(async () => import('~/src/pages/categories'))
const ProfileLayout = lazy(async () => import('~/src/components/profile/profile-layout'))
const OrderLayout = lazy(async () => import('~/src/components/orders/order-layout'))
const ExtraInfoCustomerPage = lazy(async () => import('~/src/pages/extra-info/customer'))
const OrderTrackerPage = lazy(async () => import('~/src/pages/order/[slug]/tracker'))

const commonPaths: RouteObject[] = [
  {
    path: RoutesEnum.MENU,
    element: <MenuPage />
  },
  {
    path: RoutesEnum.LOGIN,
    element: <LoginPage />
  },
  {
    path: RoutesEnum.REGISTER,
    element: <RegisterPage />
  },
  {
    path: RoutesEnum.ACCOUNT_ACTIVATION,
    element: <ActivationPage />
  },
  {
    path: RoutesEnum.CART,
    element: <CartPage />
  },
  {
    path: RoutesEnum.CATEGORIES,
    element: <CategoriesPage />
  },
  {
    path: `${RoutesEnum.CATEGORY}/:catId${RoutesEnum.PRODUCTS}`,
    element: <ProductsByCategoriesPage />
  },
  {
    path: `${RoutesEnum.CATEGORY}/:catId${RoutesEnum.LOCATIONS}`,
    element: <LocationsPage />

  },
  {
    path: `${RoutesEnum.CATEGORY}/:catId${RoutesEnum.PRODUCT}/:productId`,
    element: <ProductPage />
  },
  {
    path: RoutesEnum.EXTRA_INFO_GUEST,
    element: <ExtraInfoGuestPage />
  },
  {
    path: RoutesEnum.EXTRA_INFO_CUSTOMER,
    element: <ExtraInfoCustomerPage />
  },
  {
    path: RoutesEnum.FIRM_INFO,
    element: <FirmInfoPage />
  },
  {
    path: RoutesEnum.PRIVACY_POLICY,
    element: <PrivacyPolicyPage />
  },
  {
    path: RoutesEnum.TERMS_AND_CONDITIONS,
    element: <TermsAndConditionsPage />
  },
  {
    path: RoutesEnum.NEWS,
    element: <NewsPage />
  },
  {
    path: `${RoutesEnum.NEWS}/:newsId/details`,
    element: <NewsDetailsPage />
  },
  {
    path: RoutesEnum.CHECKOUT,
    element: <CheckoutPage />
  },
  /**
   * use for the marketplace locations search
   */
  {
    path: `${RoutesEnum.SEARCH}`,
    element: <SearchResultPage />
  },
  {
    path: `${RoutesEnum.SEARCH}/:search`,
    element: <SearchResultPage />
  },
  {
    path: `/${RoutesEnum.EAT_IN}/:tableMethod/:tableNumber`,
    element: <MenuPage />
  },
  {
    path: RoutesEnum.PROFILE,
    element: <ProfileLayout />,
    children: [
      {
        index: true,
        loader() {
          return redirect(RoutesEnum.ACCOUNT)
        }
      },
      {
        path: RoutesEnum.ACCOUNT,
        element: <AccountPage />
      },
      {
        path: RoutesEnum.ADDRESS,
        element: <AddressPage />
      },
      {
        path: `${RoutesEnum.ADDRESS}/:addressId`,
        element: <AddressDetail />
      },
      {
        path: RoutesEnum.ADDRESSES,
        element: <AddressesPage />
      },
      {
        path: RoutesEnum.LOYALTY,
        element: <LoyaltyPage />
      },
      {
        path: RoutesEnum.MARKETING,
        element: <MarketingPage />
      }
    ]
  },
  {
    path: RoutesEnum.ORDERS,
    element: <OrderLayout />,
    children: [
      {
        index: true,
        loader() {
          return redirect(RoutesEnum.UPCOMING)
        }
      },
      {
        path: RoutesEnum.HISTORY,
        element: <OrdersPage />
      },
      {
        path: RoutesEnum.UPCOMING,
        element: <UpcomingPage />
      },

      {
        path: `:orderId/${RoutesEnum.TRACKER}`,
        element: <OrderTrackerPage />
      }
    ]
  }
]

const generate = (): RouteObject[] => commonPaths.reduce(
  (acc: RouteObject[], current: RouteObject) => {
    return [
      ...acc,
      {
        path: `:lang${current.path}`,
        element: current.element,
        children: current.children
      },
      {
        path: `:shopName/:lang${current.path}`,
        element: current.element,
        children: current.children
      }
    ]
  },
  []
)

export default generate
