import { SxProps } from '@mui/material'
import StylesShoppingCartItemsServiceInterface
  from '~/src/components/cart/shopping-cart-items/styles/StylesShoppingCartItemsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartItemsServiceStyleOne extends TemplateServiceStyleBase implements StylesShoppingCartItemsServiceInterface {
  public getContainerSxProps(showQuantityAsText: boolean, maxContentItems: number): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: this._getContainerTemplateColumns(showQuantityAsText, maxContentItems),
      alignItems: 'center',
      gap: this.muiTheme.spacing(0.5),
      overflow: 'hidden',
      overflowY: 'auto',
      [this.muiTheme.breakpoints.down('md')]: {
        maxHeight: 'initial'
      }
    }
  }

  private _getContainerTemplateColumns(showQuantityAsText: boolean, maxContentItems: number): string {
    let  gridTemplateColumns: string = showQuantityAsText ? 'max-content auto' : '1fr'

    if (maxContentItems) {
      gridTemplateColumns += ` repeat(${maxContentItems}, max-content)`
    }

    return gridTemplateColumns
  }
}
