import { SxProps } from '@mui/material'
import { HeaderUserActionsProps } from '~/src/components/layout/header/header-user-actions'
import StylesHeaderServiceInterface from '~/src/components/layout/header/styles/StylesHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import { HeaderLanguageSelectorProps } from '~/src/components/layout/header/header-language-selector'

export default class StylesHeaderServiceStyleOne extends TemplateServiceStyleBase implements StylesHeaderServiceInterface {
  public getBaseHeaderSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr repeat(3,auto)',
      gridTemplateAreas: '' +
        '"logo current-firm language-selector user-actions . cart"' +
        '"sub-header sub-header sub-header sub-header sub-header sub-header"',
      alignItems: 'center',
      gap: 2,
      pt: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr) repeat(2,auto)',
        gridTemplateAreas: '' +
          '"logo logo language-selector user-actions cart"' +
          '"current-firm current-firm current-firm current-firm current-firm"' +
          '"delivery-methods delivery-methods delivery-methods delivery-methods delivery-methods"' +
          '"sub-header sub-header sub-header sub-header sub-header"',
        gap: 1,
        'div#header__current_firm_logo': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          'a > img:first-of-type': {
            maxHeight: '50px',
            height: 'auto',
            maxWidth: '100%'
          }
        },
        pt: 1,
        rowGap: 0,
        pb: 1
      }
    }
  }

  public getHeaderSx(): SxProps {
    return {
      ...this.getBaseHeaderSx(),
      gridArea: 'header',
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      boxShadow: this.muiTheme.elevation,
      width: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 3
    }
  }

  public getSimpleHeaderSx(isLocatorPage?: boolean): SxProps {
    return {
      width: '100%',
      zIndex: 3,
      px: isLocatorPage ? 2 : 12,
      py: 3,
      [this.muiTheme.breakpoints.down('md')]: {
        pt: PlatformUtils().isCapacitorNative
          ? `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(1)})`
          : 1,
        pb: 1,
        px: 2
      }
    }
  }

  public getMobileHeaderContainerSx(): SxProps {
    return {
      ...this.getBaseHeaderSx(),
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      width: '100%',
      zIndex: 2,
      pt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(2)}) !important`
    }
  }

  public getHeaderNavSx(): SxProps {
    return {
      gridArea: 'nav',
      display: 'none'
    }
  }

  public getMenuBurgerSx(): SxProps {
    return {
      gridArea: 'menu-burger',
      justifySelf: 'start',
      pl: 1,
      svg: {
        width: '2rem',
        height: '2rem'
      }
    }
  }

  public getHeaderLogoSx(): SxProps {
    return {
      gridArea: 'logo',
      pl: 2
    }
  }

  public getHeaderCurrentFirmLogoSx(): SxProps {
    return {
      position: 'relative',
      height: '50px'
    }
  }

  public getHeaderCurrentFirmSx(): SxProps {
    return {
      gridArea: 'current-firm',
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2
      }
    }
  }

  public getHeaderDeliveryOptionsSx(): SxProps {
    return {
      gridArea: 'delivery-methods',
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      [this.muiTheme.breakpoints.up('md')]: {
        display: 'none'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        p: 0,
        px: 2
      }
    }
  }

  public getHeaderLanguageSelectorSx(): SxProps {
    return {
      gridArea: 'language-selector'
    }
  }

  public getHeaderLanguageSelectorProps(): HeaderLanguageSelectorProps {
    return {
      showAsCode: true,
      color: 'header',
      useFlag: false,
      variant: 'outlined',
      useAutomaticShading: true
    }
  }

  public getHeaderLocationSearchSx(): SxProps {
    return {}
  }

  public getHeaderUserActionsSx(): SxProps {
    return {
      gridArea: 'user-actions'
    }
  }

  public getHeaderUserActionsProps(): HeaderUserActionsProps {
    return {}
  }

  public getHeaderCartSx(): SxProps {
    return {
      gridArea: 'cart',
      pr: 2
    }
  }
}
