import { Product } from '@eo-storefronts/eo-core'
import { Chip } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { PRODUCT_IS_REWARD_FOR_LOYALTY_SELECTOR } from '~/src/stores/product'

interface Props {
  product: Product,
}

const ProductLoyaltyChip = ({ product }: Props) => {
  const { t } = useTranslations()
  const productIsRewardForLoyalty = useEoValue(PRODUCT_IS_REWARD_FOR_LOYALTY_SELECTOR(product.id))

  if (!productIsRewardForLoyalty) {
    return null
  }

  return (
    <Chip
      size='small'
      color='success'
      label={t('product.loyalty_free')}
      sx={{
        position: 'absolute',
        zIndex: 1,
        left: '-10px',
        top: '-10px'
      }}
    />
  )
}

export default ProductLoyaltyChip
