import LocationSubHeader from '~/src/components/layout/sub-header/location-sub-header'
import MarketplaceSubHeader from '~/src/components/layout/sub-header/marketplace-sub-header'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'

const SubHeader = () => {
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)

  if (isOnMarketplacePage) {
    return <MarketplaceSubHeader />
  }

  return <LocationSubHeader />
}

export default SubHeader
