import useDeliveryMethodService from '~/src/hooks/delivery-methods/useDeliveryMethodService'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'
import CartEmpty from '~/src/components/cart/CartEmpty'
import ShoppingCartMessages from '~/src/components/cart/shopping-cart-messages'
import ShoppingCartItems from '~/src/components/cart/shopping-cart-items'
import ShoppingCartItemsContainer from '~/src/components/cart/shopping-cart-items-container'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'

interface Props {
  enableCollapse: boolean,
  showPrice: boolean,
  showQuantityAsText: boolean,
  showQuantitySelector: boolean,
  showDeleteButton: boolean,
  showIngredientsAndOptions: boolean,
}

const ShoppingCartContent = ({
  enableCollapse,
  showPrice,
  showQuantityAsText,
  showQuantitySelector,
  showDeleteButton,
  showIngredientsAndOptions
}: Props) => {
  const { isMethodInactive } = useDeliveryMethodService()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const totalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)
  const styles = useGetShoppingCartTemplate()

  if (isMethodInactive(checkoutForm.delivery_method.method)) {
    return null
  }

  if (!totalItems) {
    return <CartEmpty />
  }

  return (
    <ShoppingCartItemsContainer
      enableCollapse={enableCollapse}
      sx={styles.getShoppingCartItemsContainerSxProps()}
    >
      <ShoppingCartMessages />
      <ShoppingCartItems
        showPrice={showPrice}
        showQuantityAsText={showQuantityAsText}
        showQuantitySelector={showQuantitySelector}
        showDeleteButton={showDeleteButton}
        showIngredientsAndOptions={showIngredientsAndOptions}
      />
    </ShoppingCartItemsContainer>
  )
}

export default ShoppingCartContent
