import { Box, SxProps } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface Props {
  sx?: SxProps,
}

const FirmOfflineBanner = ({ sx }: Props) => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)

  return (
    <Box
      sx={{
        backgroundColor: firm?.settings.offline_settings?.bg_color,
        color: firm?.settings.offline_settings?.text_color,
        p: 2,
        textAlign: 'center',
        ...sx
      }}
    >
      <Title variant='body2' component='span'>{firm?.settings.offline_settings?.message}</Title>
    </Box>
  )
}

export default FirmOfflineBanner
