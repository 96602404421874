import getSideDrawerTemplateService from '~/src/components/layout/side-drawer/styles/GetSideDrawerTemplateService'
import StylesSideDrawerServiceInterface
  from '~/src/components/layout/side-drawer/styles/StylesSideDrawerServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetSideDrawerTemplate = (): StylesSideDrawerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSideDrawerTemplateService(pageStyle, muiTheme)
}

export default useGetSideDrawerTemplate
