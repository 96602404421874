import { Product } from '@eo-storefronts/eo-core'

interface ReturnsType {
  hasOptions(product: Product): boolean,
}

const useProductHasOptions = (): ReturnsType => {
  const hasOptions = (product: Product): boolean => !!product.modifierGroups?.length

  return { hasOptions }
}

export default useProductHasOptions
