import { useEffect, useState } from 'react'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import RoutesEnum from '~/src/router/enums/routes.enum'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'
import { Firm } from '@eo-storefronts/eo-core'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useTranslations } from '~/src/hooks/useTranslations'

const useGetProfileNavigationItems = (): CustomNavItemProps[] => {
  const [ navItems, setNavItems ] = useState<CustomNavItemProps[]>([])
  const isLoyaltyEnabled = useEoValue<boolean>(LOYALTY_IS_ENABLED_SELECTOR)
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { t } = useTranslations()

  useEffect(() => {
    if (!firm) {
      return
    }

    const navItems: CustomNavItemProps[] = [
      {
        label: t('profile.personal_details'),
        value: 'account',
        icon: <PersonOutlineOutlinedIcon/>,
        iconPosition: 'start',
        resolve: true,
        href: `${RoutesEnum.PROFILE}/${RoutesEnum.ACCOUNT}`
      },
      {
        label: t('profile.address.your_addresses'),
        value: 'addresses',
        icon: <LocationOnOutlinedIcon/>,
        iconPosition: 'start',
        resolve: true,
        href: `${RoutesEnum.PROFILE}/${RoutesEnum.ADDRESSES}`
      },
      {
        label: t('profile.marketing.title'),
        value: 'marketing',
        icon: <CampaignOutlinedIcon/>,
        iconPosition: 'start',
        resolve: true,
        href: `${RoutesEnum.PROFILE}/${RoutesEnum.MARKETING}`
      }
    ]

    if (isLoyaltyEnabled && !isOnMarketplacePage) {
      navItems.push(
        {
          label: t('profile.loyalty.title'),
          value: 'loyalty',
          icon: <LoyaltyIcon/>,
          iconPosition: 'start',
          resolve: true,
          href: `${RoutesEnum.PROFILE}/${RoutesEnum.LOYALTY}`
        })
    }

    setNavItems(navItems)
  }, [ firm, isLoyaltyEnabled, isOnMarketplacePage ])

  return navItems
}

export default useGetProfileNavigationItems
