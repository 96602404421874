import getSideDrawerNavigationItemsTemplateService
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/styles/GetSideDrawerNavigationItemsTemplateService'
import StylesSideDrawerNavigationItemsServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/styles/StylesSideDrawerNavigationItemsServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetSideDrawerNavigationItemsTemplate = (): StylesSideDrawerNavigationItemsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSideDrawerNavigationItemsTemplateService(pageStyle, muiTheme)
}

export default useGetSideDrawerNavigationItemsTemplate
