import { DateUtils } from '@eo-storefronts/eo-core'
import { createState } from '~/src/hooks/useEoState'

export const LAST_MODIFICATION_STATE = createState({
  key: 'lastModificationState',
  default: {
    last_firm_update: DateUtils.momentFormat(new Date()),
    last_order_update: DateUtils.momentFormat(new Date()),
    last_product_update: DateUtils.momentFormat(new Date())
  }
})
