import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import EatInLocationPicker from '~/src/components/delivery-methods/EatInLocationPicker'
import EatInMethodSelector from '~/src/components/delivery-methods/EatInMethodSelector'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const EatInMethod = () => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)

  if (firm?.settings.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.custom_methods?.length) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Title>{`${t('global.where')}:`}</Title>
        <EatInMethodSelector />
        <EatInLocationPicker />
      </Box>
    )
  }

  return <EatInLocationPicker />
}

export default EatInMethod
