import { BottomNavigationActionProps, TabProps } from '@mui/material'
import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'
import LinkTab from '~/src/components/mui-wrappers/styled/link-tab'
import Tabs from '~/src/components/mui-wrappers/styled/tabs'
import useGetActualRoute from '~/src/hooks/router/useGetActualRoute'
import { useEoValue } from '~/src/hooks/useEoState'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'
import CustomNavItemValues from '~/src/types/CustomNavItemValues'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import useGetNavigationItems from '~/src/hooks/router/navigation/useGetNavigationItems'

const HeaderNav = () => {
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const actualRoute = useGetActualRoute()
  const navItems: Array<CustomNavItemProps | BottomNavigationActionProps> = useGetNavigationItems('headerNav')
  const [ selected, setSelected ] = useState<CustomNavItemValues | boolean>(false)
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)

  const _getSelectedValue = (): CustomNavItemValues | boolean => {
    if (navItems.some((value: CustomNavItemProps | BottomNavigationActionProps) => value.value === selected)) {
      return selected
    }

    return false
  }

  const handleChange = (_event: SyntheticEvent, value: CustomNavItemValues) => {
    setSelected(value)
  }

  const handleOnClick = () => {
    sideDrawerContext.onToggle()
    setSelected(actualRoute || false)
  }

  useEffect(() => {
    if (!actualRoute && isOnMarketplacePage) {
      return setSelected('explore')
    }

    setSelected(actualRoute || false)
  }, [ actualRoute ])

  return (
    <Tabs
      id='header__nav'
      value={_getSelectedValue()}
      onChange={handleChange}
    >
      {navItems.map((navItemProps: CustomNavItemProps | BottomNavigationActionProps) => (
        <LinkTab
          key={navItemProps.value}
          onClick={handleOnClick}
          {...navItemProps as TabProps}
        />
      ))}
    </Tabs>
  )
}

export default HeaderNav
