import { createContext } from 'react'

interface DropdownProps {
  anchorEl: null | HTMLElement,
  open: boolean,
  handleClose: (() => void) | null,
}

const DropdownContext = createContext<DropdownProps>({
  anchorEl: null,
  open: false,
  handleClose: null
})

export default DropdownContext
