import Price from '~/src/components/Price'
import Title from '~/src/components/mui-wrappers/Title'
import { Product, TemplateEnum } from '@eo-storefronts/eo-core'
import useProductUnitPriceTemplateService
  from '~/src/components/products/product-unit-price/styles/useProductUnitPriceTemplateService'
import { DisplayPromotionType } from '~/src/types/DisplayPromotionType'
import { productHasPromotion } from '~/src/services/ProductService'

interface Props {
  product: Product,
  displayPromotion: DisplayPromotionType,
  containerTemplate?: TemplateEnum,
}

const ProductPromotionalPrice = ({ product, containerTemplate, displayPromotion }: Props) => {
  const styles = useProductUnitPriceTemplateService(containerTemplate)

  if (!productHasPromotion(product)) {
    return null
  }

  return (
    <Title
      component='span'
      sx={{
        position: displayPromotion === 'top' ? 'absolute' : 'unset',
        top: '-12px',
        left: 0,
        marginLeft: displayPromotion === 'inline' ? '2px' : 0,
        ...styles.getPromotionalPriceSx()
      }}
    >
      <Price
        price={product.promotion!.price}
        displayZeroAmount={true}
      />
    </Title>
  )
}

export default ProductPromotionalPrice
