import RoutesEnum from '~/src/router/enums/routes.enum'
import Params from '~/src/router/enums/params.enum'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'
import DeliveryAddress from '~/src/components/delivery-methods/DeliveryAddress'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import { Constants } from '~/src/helpers/constants'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_AS_SELECTOR_FAMILY } from '~/src/stores/authentication'
import useGuestFormFields from '~/src/hooks/forms/guest-form/useGuestFormFields'
import useDialog from '~/src/hooks/useDialog'
import LoginFormDialog from '~/src/components/auth/login/LoginFormDialog'
import useCustomerExtraInfo from '~/src/hooks/customer/useCustomerExtraInfo'
import { Customer } from '@eo-storefronts/eo-core'

interface ReturnsType {
  finalize(): void,
  continueAsCustomer(): void,
  continueAsGuest(): void,
}

const useShoppingCartActions = (): ReturnsType => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { getErrors } = useValidateOrder()
  const isLoggedInAsGuest: boolean = useEoValue(IS_LOGGED_IN_AS_SELECTOR_FAMILY('guest'))
  const isLoggedInAsCustomer: boolean = useEoValue(IS_LOGGED_IN_AS_SELECTOR_FAMILY('customer'))
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const { present } = useDialog()
  const { areAllHidden: guestFormFieldsAreAllHidden } = useGuestFormFields()
  const { needsExtraInfo } = useCustomerExtraInfo()

  const _goToCheckout = (customer?: Customer) => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()

    if (isLoggedInAsGuest && !customer && !guestFormFieldsAreAllHidden()) {
      return push(`${resolve(RoutesEnum.EXTRA_INFO_GUEST)}?${Params.REDIRECT_TO}=CHECKOUT`)
    }

    if ((isLoggedInAsCustomer || customer) && needsExtraInfo(customer)) {
      return push(`${resolve(RoutesEnum.EXTRA_INFO_CUSTOMER)}?${Params.REDIRECT_TO}=CHECKOUT`)
    }

    push(resolve(RoutesEnum.CHECKOUT))
  }

  const _presentDeliveryAddressDialog = () => {
    present<ResponsiveDialogProps>(DeliveryAddress, 'delivery-address-dialog', {
      openByDefault: true,
      activator: undefined
    })
  }

  const _check = (customer?: Customer) => {
    if (getErrors().find((e: OrderErrorType) => e.type === 'address')) {
      _presentDeliveryAddressDialog()
    } else {
      _goToCheckout(customer)
    }
  }

  const finalize = () => {
    setTimeout(() => {
      _check()
    }, Constants.DEBOUNCE_TIME)
  }

  const continueAsCustomer = () => {
    return present<ResponsiveDialogProps>(LoginFormDialog, 'login-form-dialog', {
      openByDefault: true,
      onClose: ({ successfullyLoggedIn, customer }) => {
        if (!successfullyLoggedIn || !customer) {
          return
        }

        _check(customer)
      }
    })
  }

  const continueAsGuest = () => {
    _check()
  }

  return {
    finalize,
    continueAsCustomer,
    continueAsGuest
  }
}

export default useShoppingCartActions
