import useLogRemoveFromCart from '~/src/hooks/analytics/ecommerce/useLogRemoveFromCart'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CART_STATE, CartState } from '~/src/stores/cart'
import { getCartProductIndex } from '~/src/helpers/getCartProductIndex'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CartProduct } from '~/src/types/CartProduct'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

interface ReturnsType {
  remove(cartProduct: CartProduct): void,
}

const useRemoveProductOfCart = (): ReturnsType => {
  const { log: logRemoveFromCart } = useLogRemoveFromCart()
  const firm = useEoValue(FIRM_SELECTOR)
  const setCarts = useSetEoState(CART_STATE)
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)

  const remove = (cartProduct: CartProduct): void => {
    if (!firm) {
      return
    }

    if (cartProduct.is_loyalty) {
      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        loyaltyDiscount: 0,
        loyaltyProductId: null
      }))
    }

    if (cartProduct.is_coupon) {
      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        coupon: null
      }))
    }

    setCarts((state: CartState) => {
      const cartProducts: CartProduct[] = [ ...state[firm.id] || [] ]
      const index = getCartProductIndex(cartProducts, cartProduct)

      if (index !== -1) {
        cartProducts.splice(index, 1)
      }

      return {
        ...state,
        [firm.id]: cartProducts
      }
    })

    logRemoveFromCart(cartProduct)
  }

  return { remove }
}

export {
  useRemoveProductOfCart
}
