import { LanguageType } from '@eo-storefronts/eo-core'
import { Box, SxProps } from '@mui/material'

interface Props {
  code: LanguageType,
  sx?: SxProps,
}

const FirmLanguagesFlag = ({ code, sx }: Props) => {
  return (
    <Box
      component='img'
      src={`/icons/flags/${code}.png`}
      alt={`${code}-flag-icon`}
      sx={{
        width: '2rem',
        height: '2rem',
        maxWidth: '28px',
        maxHeight: '28px',
        ...sx
      }}
    />
  )
}

export default FirmLanguagesFlag
