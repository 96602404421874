import { Product } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { convertProductUnitKgInGr } from '~/src/services/ProductService'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  getQuantity(product: Product): number,
}

const useGetProductQuantityToAdd = (): ReturnsType => {
  const cart = useEoValue<CartProduct[]>(CART_OF_FIRM_SELECTOR)

  const getQuantity = (product: Product): number => {
    const { min, max, unitInterval } = convertProductUnitKgInGr(product)
    const cartProduct = cart.find((cp: CartProduct) => cp.id.toString() === product.id.toString())

    let quantity: number = (!cartProduct && min) ? min : unitInterval

    if (cartProduct && max && (cartProduct.quantity + quantity) > max) {
      quantity = max - cartProduct.quantity
    }

    return quantity
  }

  return { getQuantity }
}

export default useGetProductQuantityToAdd
