import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import FirmInfoContainer from '~/src/components/firms/FirmInfoContainer'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import CanOnlyOrderDuringOperationalHoursButton from '~/src/components/error/CanOnlyOrderDuringOperationalHoursButton'
import { useEoValue } from '~/src/hooks/useEoState'
import { isOffline } from '~/src/services/OpeningHourService'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

const CanOnlyOrderDuringOperationalHoursResponsiveModal = () => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)

  if (firm && isOffline(firm)) {
    return null
  }

  return (
    <ResponsiveDialog
      Title={<GoBackToTheShopButton dialog={true}/>}
      activator={<CanOnlyOrderDuringOperationalHoursButton/>}
      maxWidth='lg'
    >
      <FirmInfoContainer/>
    </ResponsiveDialog>
  )
}

export default CanOnlyOrderDuringOperationalHoursResponsiveModal
