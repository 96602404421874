import { useEffect, useState } from 'react'

interface ReturnsType {
  params: Record<string, string>,
}

const useRouterQuery = (): ReturnsType => {
  const [ params, setParams ] = useState<Record<string, string>>({})

  useEffect(() => {
    const url: URL = new URL(window.location.href)

    if (!url.search) {
      setParams({})
      return
    }

    const queryParams: Record<string, string> = {}

    url.search
      .split('&')
      .forEach((queryParam: string) => {
        const [ key, value ] = queryParam.split('=')
        const cleanedKey: string = key.replace('?', '')

        queryParams[cleanedKey] = decodeURI(value) || ''
      })

    setParams(queryParams)
  }, [ window.location.href ])

  return { params }
}

export {
  useRouterQuery
}
