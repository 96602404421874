import { useContext, useEffect } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'
import { useLocation } from 'react-router-dom'

const useAutomaticCloseSideDrawer = () => {
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!sideDrawerContext.open || location.href.includes('drawer')) {
      return
    }

    sideDrawerContext.onClose()
  }, [ pathname ])
}

export default useAutomaticCloseSideDrawer
