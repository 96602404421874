import { Box, Typography } from '@mui/material'
import useGetShoppingCartTotalTemplate
  from '~/src/components/cart/shopping-cart-total/styles/useGetShoppingCartTotalTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'

const ShoppingCartTotalItems = () => {
  const styles = useGetShoppingCartTotalTemplate()
  const amountOfItemsInCart = useEoValue(CART_TOTAL_ITEMS_SELECTOR)

  return (
    <Box sx={styles.getTotalItemsContainerSxProps()}>
      <Typography
        component='span'
        sx={styles.getTotalItemsLabelSxProps()}
      >
        Items
      </Typography>
      <Typography
        component='span'
        variant='h5'
        sx={styles.getTotalItemsValueSxProps()}
      >
        {amountOfItemsInCart}
      </Typography>
    </Box>
  )
}

export default ShoppingCartTotalItems
