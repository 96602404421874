import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { AppleSignInVerifyResponse, Firm } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { verifyApplePayload } from '~/src/services/AuthenticationService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  verify({ response }: SignInWithAppleResponse): Promise<AppleSignInVerifyResponse | undefined>,
}

const useSignInWithAppleVerify = (): ReturnsType => {
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const isUsingNewBrandAuthSystem = useEoValue<boolean>(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)

  const verify = async ({ response }: SignInWithAppleResponse) => {
    if (!brand?.id || !firm?.id) {
      return
    }

    return verifyApplePayload(
      brand,
      firm,
      isUsingNewBrandAuthSystem,
      {
        code: response.authorizationCode,
        id_token: response.identityToken,
        email: response.email,
        first_name: response.givenName,
        last_name: response.familyName
      }
    )
  }

  return { verify }
}

export default useSignInWithAppleVerify
