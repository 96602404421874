import { ChangeEvent, FormEvent, useContext, useRef, useState } from 'react'
import ForgotPasswordActions from '~/src/components/auth/forgot-password/ForgotPasswordActions'
import ForgotPasswordFields from '~/src/components/auth/forgot-password/ForgotPasswordFields'
import useResetPassword from '~/src/hooks/auth/useResetPassword'
import { PasswordForgotFormInterface } from '~/src/types/forms/PasswordForgotFormInterface'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { HttpError } from '@eo-storefronts/eo-core'

const ForgotPasswordForm = () => {
  const form = useRef<HTMLFormElement>(null)
  const [ forgotPasswordForm, setForgotPasswordForm ] = useState<PasswordForgotFormInterface>({ email: '' })
  const { reset, loading, error } = useResetPassword()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, formField: keyof PasswordForgotFormInterface) => {
    setForgotPasswordForm({
      ...forgotPasswordForm,
      [formField]: event.target.value
    })
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (!form?.current?.checkValidity()) {
      return
    }

    reset(forgotPasswordForm.email)
      .then(() => {
        responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
      })
      .catch(() => {
        // Ignore
      })
  }

  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
    >
      <ForgotPasswordFields
        error={error as HttpError}
        form={forgotPasswordForm}
        onChange={handleOnChange}
      />

      <ForgotPasswordActions loading={loading} />
    </form>
  )
}

export default ForgotPasswordForm
