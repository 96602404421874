import StylesHeaderCurrentFirmTitleServiceInterface
  from '~/src/components/layout/header/header-current-firm-title/styles/StylesHeaderCurrentFirmTitleServiceInterface'
import StylesHeaderCurrentFirmTitleServiceStyleOne
  from '~/src/components/layout/header/header-current-firm-title/styles/StylesHeaderCurrentFirmTitleServiceStyleOne'
import StylesHeaderCurrentFirmTitleServiceStyleTwo
  from '~/src/components/layout/header/header-current-firm-title/styles/StylesHeaderCurrentFirmTitleServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getHeaderCurrentFirmTitleTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesHeaderCurrentFirmTitleServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesHeaderCurrentFirmTitleServiceStyleTwo(muiTheme)
    default:
      return new StylesHeaderCurrentFirmTitleServiceStyleOne(muiTheme)
  }
}

export default getHeaderCurrentFirmTitleTemplateService
