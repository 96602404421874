import { SxProps } from '@mui/material'
import StylesHeaderLanguageSelectorServiceInterface
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderLanguageSelectorServiceStyleOne extends TemplateServiceStyleBase implements StylesHeaderLanguageSelectorServiceInterface {
  public getDropdownSx(): SxProps {
    return {}
  }
}
