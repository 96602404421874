import { ClientAuthResponse } from '@eo-storefronts/eo-core'
import { loginAsClient } from '~/src/services/AuthenticationService'
import useSetApiTokenState from '~/src/hooks/auth/useSetApiTokenState'

interface ReturnsType {
  doLoginAsClient(): void,
}

const useLoginAsClient = (): ReturnsType => {
  const { setAppApiToken } = useSetApiTokenState()

  const doLoginAsClient = () => {
    loginAsClient()
      .then((auth: ClientAuthResponse) => {
        setAppApiToken({
          expiresIn: auth.expires_in,
          token: auth.access_token,
          tokenType: auth.token_type
        })
      })
  }

  return { doLoginAsClient }
}

export default useLoginAsClient
