import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesDeliveryMethodsSummaryServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/StylesDeliveryMethodsSummaryServiceInterface'
import getDeliveryMethodsSummaryTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/GetDeliveryMethodsSummaryTemplateService'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useGetDeliveryMethodsSummaryTemplate = (): StylesDeliveryMethodsSummaryServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryTemplate
