import { Helmet } from 'react-helmet'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { DOCUMENT_TITLE_SELECTOR } from '~/src/stores/layout'

interface Props {
  pageName?: keyof typeof RoutesEnum,
}

const DocumentTitleInjector = ({ pageName }: Props) => {
  const { t } = useTranslations()
  const documentTitle = useEoValue(DOCUMENT_TITLE_SELECTOR)

  return (
    <Helmet>
      <title>
        {documentTitle}{pageName ? ` - ${t(`global.pages.${pageName.toLowerCase()}.title`)}` : ''}
      </title>
    </Helmet>
  )
}

export default DocumentTitleInjector
