import { Firm, Id } from '@eo-storefronts/eo-core'
import { createSelector, createState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

const key = 'locatorState'

export default interface LocatorState {
  firmSelected?: Firm,
  isLoading: boolean,
  firmAvailableForDelivery?: Id,
}

export const locatorStateInitialState = (): LocatorState => ({
  isLoading: false
})

export const LOCATOR_STATE = createState<LocatorState>({
  key,
  default: locatorStateInitialState()
})

export const LOCATOR_NEXT_STEP_BUTTON_DISABLED_SELECTOR = createSelector<boolean>({
  key: 'locatorNextStepButtonDisabledSelector',
  get: ({ get }): boolean => {
    const checkoutForm: CheckoutFormState = get<CheckoutFormState>(CHECKOUT_FORM_STATE)
    const { firmSelected }: LocatorState = get<LocatorState>(LOCATOR_STATE)

    if (checkoutForm.delivery_method.method === 'delivery') {
      const { address } = checkoutForm.delivery_method

      return !(address && firmSelected)
    } else if (checkoutForm.delivery_method.method === 'pickup') {
      const { date, time } = checkoutForm.delivery_method

      return !(date && time && firmSelected)
    }

    return false
  }
})
