import getHeaderMultiFirmSwitchTemplateService
  from '~/src/components/layout/header/header-multi-firm-switch/styles/GetHeaderMultiFirmSwitchTemplateService'
import StylesHeaderMultiFirmSwitchServiceInterface
  from '~/src/components/layout/header/header-multi-firm-switch/styles/StylesHeaderMultiFirmSwitchServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useGetHeaderMultiFirmSwitchTemplate = (): StylesHeaderMultiFirmSwitchServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('header')

  return getHeaderMultiFirmSwitchTemplateService(componentStyle, muiTheme)
}

export default useGetHeaderMultiFirmSwitchTemplate
