import { Backdrop, BackdropProps, Theme, useMediaQuery } from '@mui/material'

interface Props extends Omit<BackdropProps, 'open' | 'onClick'> {
  setShowPanel(state: boolean): void,
  showPanel: boolean,
}

const LocationSearchAutocompleteBackdrop = ({ showPanel, setShowPanel, ...BackdropProps }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (isMobile) {
    return null
  }

  return (
    <Backdrop
      {...BackdropProps}
      open={showPanel}
      onClick={() => setShowPanel(false)}
      sx={{
        zIndex: 2,
        ...(BackdropProps?.sx ?? {})
      }}
    />
  )
}

export default LocationSearchAutocompleteBackdrop
