import { ReactNode } from 'react'
import EatInModal from '~/src/components/eat-in/eat-in-modal'

interface Props {
  children: ReactNode,
}

const OrderOnTheSpotProvider = ({ children }: Props) => (
  <>
    {children}
    <EatInModal />
  </>
)

export default OrderOnTheSpotProvider
