export default abstract class CookiesUtils {
  public static readonly LANG: string = 'eo-lang'

  public static setCookie(name: string, value: string | number, expirationDays: number): void {
    const date = new Date()
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000))
    const expires = `expires=${date.toUTCString()}`
    document.cookie = `${name}=${value};${expires};path=/`
  }

  public static getCookie(name: string): string {
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      const c = ca[i]
      let [ n, v ] = c.split('=')
      n = n.replaceAll(' ', '')

      if (n === name) {
        return v
      }
    }

    return ''
  }
}
