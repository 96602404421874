import { LayoutState } from '~/src/stores/layout'
import LayoutServiceInterface from '~/src/utils/layout/LayoutServiceInterface'

export default class LayoutLocationsServiceStyleOne implements LayoutServiceInterface {
  public getLayout(): Partial<LayoutState> {
    return {
      header: {
        display: true,
        displayMenuBurger: false,
        displayCart: true,
        displayCurrentFirm: true,
        displayDeliveryOptions: false,
        displayLanguagesSelector: true,
        displayUserActions: true,
        displayProductSearch: true,
        displayLocationSearch: false,
        logo: {
          disableLink: false
        },
        title: '',
        type: 'default'
      },
      subHeader: {
        display: false,
        variant: 'default'
      },
      content: {
        displayNotification: true
      },
      mainSideBar: {
        display: true,
        displayCart: false,
        displayDeliveryOptions: true,
        displayOrderSummary: false
      },
      footer: {
        display: false,
        displayGoToCheckoutBtn: false,
        displayGoToCartBtn: false,
        displayInformations: false,
        displayLinks: false
      },
      mobileNavigation: {
        display: false
      }
    }
  }
}
