import { useContext } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeliveryMethodsDialogActions = () => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <Button
      fullWidth
      variant='contained'
      onClick={responsiveDialogContext.toggleClose}
    >
      {t('actions.confirm')}
    </Button>
  )
}

export default DeliveryMethodsDialogActions
