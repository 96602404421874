import { GridProps, SxProps } from '@mui/material'
import StylesProductListServiceInterface
  from '~/src/components/products/product-list/styles/StylesProductListServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductListServiceStyleOne extends TemplateServiceStyleBase implements StylesProductListServiceInterface {
  public getGridProps(): Pick<GridProps, 'columns'> {
    return {}
  }

  public getGridListItemProps(): Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> {
    return {
      xl: 2,
      lg: 3,
      md: 4,
      sm: 4,
      xs: 6
    }
  }

  public getGridStyleSxProps(): SxProps {
    return {
      p: 0,
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2
      }
    }
  }
}
