import { MouseEvent } from 'react'
import useGetShoppingCartActionsTemplate
  from '~/src/components/cart/shopping-cart-actions/styles/useGetShoppingCartActionsTemplate'
import useLoyaltyProductsModal from '~/src/hooks/customer/useLoyaltyProductsModal'
import { useTranslations } from '~/src/hooks/useTranslations'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import useShoppingCartActions from '~/src/hooks/cart/useShoppingCartActions'

const ShoppingCartActionsLoggedIn = () => {
  const styles = useGetShoppingCartActionsTemplate()
  const { t } = useTranslations()
  const { errors } = useValidateOrder()
  const { checkLoyaltyProductIsSelected } = useLoyaltyProductsModal()
  const { finalize } = useShoppingCartActions()
  const disabled = !!errors.filter((error: OrderErrorType) => error.variant === 'error').length

  const _handleOnClick = (event: MouseEvent) => {
    if (checkLoyaltyProductIsSelected()) {
      event.preventDefault()
      event.stopPropagation()

      return
    }

    finalize()
  }

  return (
    <Button
      disabled={disabled}
      variant='contained'
      disableElevation
      sx={styles.getGoToCheckoutSxProps()}
      onClick={_handleOnClick}
    >
      {t('global.go_to_checkout')}
    </Button>
  )
}

export default ShoppingCartActionsLoggedIn
