import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import ButtonWithIcon, { ButtonWithIconProps } from '~/src/components/mui-wrappers/buttons/ButtonWithIcon'
import useLogout from '~/src/hooks/auth/useLogout'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'
import { useContext } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'


const LogoutButton = (props: Omit<ButtonWithIconProps, 'startIcon' | 'onClick'>) => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { logout } = useLogout()
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)

  const handleLogout = () => {
    logout()
    sideDrawerContext?.open && sideDrawerContext.onClose()
    push(resolve(fallbackPage))
  }

  return (
    <ButtonWithIcon
      onClick={handleLogout}
      startIcon={<LogoutOutlinedIcon/>}
      {...props}
    >
      <Typography
        variant='body1'
        component='span'
      >
        {t('global.logout')}
      </Typography>
    </ButtonWithIcon>
  )
}

export default LogoutButton
