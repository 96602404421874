import {
  HeaderThemeComponent,
  NavigationThemeFlowEnum,
  Product,
  ProductCardThemeComponent,
  ProductNavigationThemeFlowEnum
} from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'

export const defaultThemeState = () => (
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        '2k': 2048,
        '4k': 3840
      }
    },
    typography: {
      fontFamily: 'GeneralFont, Roboto, Arial',
      fontSize: 12
    },
    elevation: 0,
    palette: {
      mode: 'light',
      primary: {
        main: '#ffffff',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ffffff',
        light: '#ffffff',
        dark: '#ffffff',
        contrastText: '#ffffff'
      },
      background: {
        main: '#ffffff',
        from: '#ffffff',
        to: '#ffffff',
        contrastText: '#ffffff'
      },
      container: {
        main: '#ffffff',
        light: '#ffffff',
        dark: '#ffffff',
        contrastText: '#ffffff'
      },
      tab: {
        main: '#ffffff',
        light: '#ffffff',
        dark: '#ffffff',
        contrastText: '#ffffff'
      },
      success: {
        main: '#ffffff',
        from: '#ffffff',
        to: '#ffffff',
        contrastText: '#ffffff'
      },
      wells: {
        main: '#ffffff',
        light: '#ffffff',
        dark: '#ffffff',
        contrastText: '#ffffff'
      },
      text: {
        white: '#ffffff',
        black: '#000000',
        light: '#ffffff',
        dark: '#000000'
      },
      content: {
        main: '#ffffff',
        contrastText: '#ffffff'
      }
    }
  }
)

export const THEME_STATE = createState({
  key: 'themeState',
  default: defaultThemeState()
})

export const NAVIGATE_BY_CATEGORIES_SELECTOR = createSelector<boolean>({
  key: 'navigateByCategoriesSelector',
  get: ({ get }): boolean => {
    return get(FIRM_THEME_SELECTOR)?.flow?.product_list_navigation === NavigationThemeFlowEnum.CATEGORIES
  }
})

export const NAVIGATE_BY_PRODUCT_MODAL_SELECTOR= createSelector<boolean>({
  key: 'navigateByProductModal',
  get: ({ get }): boolean => {
    return get(FIRM_THEME_SELECTOR)?.flow?.product_navigation === ProductNavigationThemeFlowEnum.MODAL
  }
})

export const HEADER_THEME_COMPONENT_SELECTOR = createSelector<HeaderThemeComponent | undefined>({
  key: 'headerThemeComponentSelector',
  get: ({ get }): HeaderThemeComponent | undefined => {
    return get(FIRM_THEME_SELECTOR)?.components?.header
  }
})

export const PRODUCT_CARD_THEME_COMPONENT_SELECTOR = createSelector<ProductCardThemeComponent | undefined>({
  key: 'productCardThemeComponentSelector',
  get: ({ get }): ProductCardThemeComponent | undefined => {
    return get(FIRM_THEME_SELECTOR)?.components?.product_card
  }
})

export const SHOW_PRODUCT_DEFAULT_IMG_SELECTOR = createSelector<boolean>({
  key: 'showProductDefaultImgSelector',
  get: ({ get }): boolean => {
    return get(FIRM_THEME_SELECTOR)?.components?.product_card.show_empty_product_image_placeholder ?? false
  }
})

// @ts-ignore
export const SHOW_PRODUCT_IMG_SELECTOR_FAMILY = createSelectorFamily<boolean, Product | null>({
  key: 'showPoductImageSelectorFamily',
  get: (product: Product | null) => ({ get }): boolean => {
    const isDefault = product?.images[0]?.is_default ?? false
    const productCardThemeComponent: ProductCardThemeComponent | undefined = get(PRODUCT_CARD_THEME_COMPONENT_SELECTOR)

    return !!(productCardThemeComponent?.show_img
      && (
        !isDefault ||
        productCardThemeComponent?.show_empty_product_image_placeholder && isDefault
      ))
  }
})
