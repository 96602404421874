import { InView } from 'react-intersection-observer'
import { Box } from '@mui/material'
import ProductListItem from '~/src/components/products/product-list-item'
import { PlatformUtils, Product, TemplateEnum } from '@eo-storefronts/eo-core'
import ProductItemSkeleton from '~/src/components/products/product-item/ProductItemSkeleton'
import { Constants } from '~/src/helpers/constants'

interface Props {
  product: Product,
  containerTemplate?: TemplateEnum,
}

const ProductItemCardVirtualized = ({ product, containerTemplate }: Props) => (
  <InView
    as='div'
    triggerOnce
    rootMargin={'25% 0% 25% 0%'}
    delay={PlatformUtils().isCapacitorNative ? Constants.VIRTUALIZATION_EVENT_DELAY.mobile : Constants.VIRTUALIZATION_EVENT_DELAY.desktop}
  >
    {({ inView, ref }) => (
      <Box
        ref={ref}
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%'
        }}
      >
        {inView &&
          <ProductListItem
            product={product}
            containerTemplate={containerTemplate}/>
        }
        {!inView &&
          <ProductItemSkeleton
            product={product}
            containerTemplate={containerTemplate}/>
        }
      </Box>
    )}
  </InView>
)

export default ProductItemCardVirtualized
