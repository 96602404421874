import { SignInWithApple, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { App } from '@capacitor/app'

interface ReturnsType {
  login(): Promise<SignInWithAppleResponse>,
}

const useSignInWithApple = (): ReturnsType => {
  const login = async (): Promise<SignInWithAppleResponse> => {
    const { id } = await App.getInfo()

    return SignInWithApple.authorize({
      clientId: id,
      redirectURI: window.location.href,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce'
    })
  }

  return { login }
}

export default useSignInWithApple
