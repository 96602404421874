import { Location, MarketplaceCategory } from '@eo-storefronts/eo-core'
import { Skeleton } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  location?: Location,
  isLoading: boolean,
}

const LocationCategories = ({ location, isLoading }: Props) => {
  if (isLoading || !location) {
    return (
      <Skeleton
        variant='text'
        sx={{ fontSize: '1rem' }}
      />
    )
  }

  return (
    <Title
      component='p'
      variant='subtitle2'
      sx={{
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        pr: 0.5
      }}
    >
      {location.categories
        .map((category: MarketplaceCategory) => category.name)
        .join(' - ')}
    </Title>
  )
}

export default LocationCategories
