import { MouseEvent } from 'react'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import ProductsListModal from '~/src/components/products/modals/products-list'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCTS_LOYALTY_REWARDS_SELECTOR } from '~/src/stores/product'
import useLoyaltyModal from '~/src/hooks/cart/useLoyaltyModal'

const LoyaltyProductsResponsiveDialog = () => {
  const productsId = useEoValue(PRODUCTS_LOYALTY_REWARDS_SELECTOR)
  const { chooseProduct } = useLoyaltyModal()
  const { t } = useTranslations()

  const _handleOnProductChosen = (e: MouseEvent<HTMLElement>, productId: string): void => {
    if (!productId) {
      return
    }

    e.stopPropagation()
    e.preventDefault()
    
    chooseProduct(productId)
  }

  return (
    <ProductsListModal
      openByDefault={true}
      name={'loyalty-products-modal'}
      Title={<Title>{t('products.choose_from_list')}</Title>}
      productIds={productsId}
      onProductChoose={_handleOnProductChosen}
      listSx={{
        GridSx: {
          pt: 2
        },
        ItemsSx: {
          cursor: 'pointer',
          '*': {
            pointerEvents: 'none'
          }
        }
      }}
    />
  )
}

export default LoyaltyProductsResponsiveDialog
