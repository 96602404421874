import { ReactNode, useEffect } from 'react'
import { useSetEoState } from '~/src/hooks/useEoState'
import { GOOGLE_MAPS_API_STATE, GoogleMapsApiState } from '~/src/stores/google-maps-api'
import { useJsApiLoader } from '@react-google-maps/api'
import EnvUtils from '~/src/utils/EnvUtils'
import { Constants } from '~/src/helpers/constants'

interface Props {
  children: ReactNode,
}

const GoogleMapsApiProvider = ({ children }: Props) => {
  const setGoogleMapsApi = useSetEoState(GOOGLE_MAPS_API_STATE)
  const apiLoader: GoogleMapsApiState = useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: EnvUtils.googleMapKey,
    libraries: Constants.GOOGLE_MAP_LIBRARIES
  })

  useEffect(() => {
    setGoogleMapsApi(apiLoader)
  }, [ apiLoader, setGoogleMapsApi ])

  return (
    <>{children}</>
  )
}

export default GoogleMapsApiProvider
