import StylesTextFieldServiceInterface
  from '~/src/components/mui-wrappers/styled/text-field/styles/StylesTextFieldServiceInterface'
import StylesTextFieldServiceStyleOne
  from '~/src/components/mui-wrappers/styled/text-field/styles/StylesTextFieldServiceStyleOne'
import StylesTextFieldServiceStyleThree
  from '~/src/components/mui-wrappers/styled/text-field/styles/StylesTextFieldServiceStyleThree'
import StylesTextFieldServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/text-field/styles/StylesTextFieldServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTextFieldTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTextFieldServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTextFieldServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesTextFieldServiceStyleThree(muiTheme)
    default:
      return new StylesTextFieldServiceStyleOne(muiTheme)
  }
}

export default getTextFieldTemplateService
