import { darken, paperClasses, SxProps } from '@mui/material'
import StylesSideDrawerServiceInterface
  from '~/src/components/layout/side-drawer/styles/StylesSideDrawerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSideDrawerServiceStyles extends TemplateServiceStyleBase implements StylesSideDrawerServiceInterface {
  public getSwipeableDrawerSx(): SxProps {
    return {
      [`> .${paperClasses.root}`]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto max-content',
        gridTemplateAreas: '"top" "bottom"',
        minWidth: '450px',
        width: '20%',
        px: 0,
        pt: 'var(--eo-safe-area-top)',
        pb: 'var(--eo-safe-area-bottom)',
        background: `linear-gradient(${(this.muiTheme.palette.background as any).from}, ${(this.muiTheme.palette.background as any).to})`,
        color: 'background.contrastText',
        [this.muiTheme.breakpoints.down('md')]: {
          width: '100vw',
          maxWidth: '100vw',
          minWidth: 'auto'
        }
      }
    }
  }

  public getBottomSx(): SxProps {
    return {
      gridArea: 'bottom',
      pt: 1,
      px: 4,
      pb: 4,
      borderTop: `1px solid ${darken((this.muiTheme.palette.background as any).to, .1)}`
    }
  }

  public getTopSx(): SxProps {
    return {
      gridArea: 'top',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      gap: 2,
      pb: 1,
      px: 4,
      pt: 4
    }
  }

  public getCloseButtonSx(): SxProps {
    return {
      position: 'absolute',
      zIndex: 1,
      right: 'calc(var(--eo-safe-area-right) + 10px)',
      top: 'calc(var(--eo-safe-area-top) + 10px)'
    }
  }
}
