import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryEditButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/StylesDeliveryMethodsSummaryEditButtonServiceInterface'
import getDeliveryMethodsSummaryEditButtonTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/GetDeliveryMethodsSummaryEditButtonTemplateService'

const useGetDeliveryMethodsSummaryEditButtonTemplate = (): StylesDeliveryMethodsSummaryEditButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryEditButtonTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryEditButtonTemplate
