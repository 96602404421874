export default abstract class RegexUtils {
  public static readonly urlRegex: string = 'https?:\\/\\/'
  public static readonly phoneRegex: string = '^(\\+\\d{1,3}[- ]?)?\\d{10}$'
  public static readonly emailRegex: string = '/[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g'
  public static readonly identifierRegex: string = '^([0-9]+)$|^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$'

  public static test(regex: string, strToTest: string): boolean {
    return new RegExp(regex).test(strToTest)
  }
}
