import { Box, BoxProps, FormHelperText, InputLabel, TextFieldProps } from '@mui/material'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import FormikFieldPropsInterface from '~/src/types/FormikFieldProps'

interface Props extends BoxProps<'div'> {
  TextFieldProps: FormikFieldPropsInterface & Omit<TextFieldProps, 'name' | 'size'>,
}

const FormikTextField = ({
  TextFieldProps: {
    helperText,
    form,
    name,
    label,
    type = 'text',
    size,
    inputMode = 'text',
    required,
    ...otherTextFieldProps
  },
  ...boxProps
}: Props) => {
  return (
    <Box {...boxProps}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <InputLabel
          required={required}
          sx={{ color: 'inherit' }}>
          {label}
        </InputLabel>
        <FormHelperText
          id={`${name}-helper-text`}
          sx={{
            color: 'inherit'
          }}
        >
          {helperText}
        </FormHelperText>
      </Box>
      <TextField
        id={name}
        name={name}
        inputProps={{
          type: type,
          inputMode: inputMode,
          required: required
        }}
        error={form.touched[name] && !!form.errors[name]}
        helperText={form.touched[name] && !!form.errors[name] ? form.errors[name] as string : undefined}
        value={form.values[name]}
        variant='outlined'
        fullWidth
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        aria-describedby={`${name}-helper-text`}
        size={size}
        required={required}
        {...otherTextFieldProps}
      />
    </Box>
  )
}

export default FormikTextField
