import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useGetDeliveryMethodsSummaryAddressButtonTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/useGetDeliveryMethodsSummaryAddressButtonTemplate'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import DeliveryMethodSummaryAddressButtonLabel
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/DeliveryMethodSummaryAddressButtonLabel'

const DeliveryMethodsSummaryAddressButton = () => {
  const styles = useGetDeliveryMethodsSummaryAddressButtonTemplate()
  const { delivery_method: { address } } = useEoValue(CHECKOUT_FORM_STATE)

  return (
    <Button {...styles.getButtonProps(address)}>
      <Box sx={styles.getButtonIconContainerSx()}>
        {!address && <AddIcon {...styles.getAddIconProps()}/>}
        {address && <LocationOnOutlinedIcon {...styles.getLocationOnOutlinedIconProps()}/>}
      </Box>
      <DeliveryMethodSummaryAddressButtonLabel {...styles.getLabelContainerProps()} />
    </Button>
  )
}

export default DeliveryMethodsSummaryAddressButton
