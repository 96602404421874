import { MarketplaceCategory } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import Image from '~/src/components/mui-wrappers/Image'

interface Props {
  category: MarketplaceCategory,
}

const MarketplaceSubHeaderLabel = ({ category }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'background.contrastText',
        gap: 2
      }}
    >
      <Image
        alt={`category-${category.name}`}
        src={category.icon}
        sx={{
          width: '6rem',
          height: '6rem'
        }}
      />

      <span>{category.name}</span>
    </Box>
  )
}

export default MarketplaceSubHeaderLabel
