import SideDrawerLanguageSwitcher from '~/src/components/layout/side-drawer/side-drawer-language-switcher'
import SideDrawerPushNotifications from '~/src/components/layout/side-drawer/side-drawer-push-notifications'
import LogoutButton from '~/src/components/user-actions/LogoutButton'
import { Box, buttonClasses, Typography } from '@mui/material'
import Environment from '~/src/config/environment'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'

const SideDrawerBottomLinks = () => {
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)

  return (
    <Box>
      <SideDrawerLanguageSwitcher />
      {isLoggedIn && (
        <LogoutButton
          enableResponsive={false}
          color='inherit'
          sx={{
            mt: 1,
            p: 0,
            '&:hover': {
              color: 'primary.main',
              backgroundColor: 'transparent'
            },
            [`.${buttonClasses.startIcon}`]: {
              '>*:nth-of-type(1)': {
                fontSize: '1.2857142857142856rem'
              },
              ml: 0,
              mr: 2
            },
            textTransform: 'capitalize'
          }}
        />
      )}
      <SideDrawerPushNotifications />
      <Typography
        sx={{
          p: 1,
          m: 0,
          position: 'absolute',
          bottom: 'calc(var(--eo-safe-area-bottom) + 8px)',
          right: 'calc(var(--eo-safe-area-right) + 8px)',
          opacity: .5,
          fontStyle: 'italic',
          fontSize: '0.8em'
        }}
      >
        {Environment.getAppVersion()}
      </Typography>
    </Box>
  )
}

export default SideDrawerBottomLinks
