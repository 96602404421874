import { CheckoutOption } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { GET_FIRM_CHECKOUT_OPTIONS_SELECTOR } from '~/src/stores/firm'

const useFillInDefaultCheckoutOptionsEffect = () => {
  const checkoutOptions = useEoValue(GET_FIRM_CHECKOUT_OPTIONS_SELECTOR)
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)

  useEffect(() => {
    const defaultSelected: CheckoutOption[] = checkoutOptions.filter((option: CheckoutOption) => option.selected)

    setCheckoutForm((state: CheckoutFormState) => {
      if (state.checkoutOptions.length > 0) {
        return state
      }

      return {
        ...state,
        checkoutOptions: defaultSelected
      }
    })
  }, [])
}

export default useFillInDefaultCheckoutOptionsEffect
