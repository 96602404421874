import { Modifier } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartModifierGroupStateInterface, CartModifierStateInterface } from '~/src/stores/cart'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import CartModifierInlineName from '~/src/components/cart/cart-modifier-in-line/CartModifierInlineName'
import CartModifierInlinePrice from '~/src/components/cart/cart-modifier-in-line/CartModifierInlinePrice'

interface Props {
  cartModifier: CartModifierStateInterface,
  groupId: string,
  paddingLeft?: number,
}

const CartModifierInLine = ({ groupId, cartModifier, paddingLeft = 0 }: Props) => {
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)

  if (!modifiers[cartModifier.id]) {
    return null
  }

  if (
    cartModifier.action === 'pre'
    && cartModifier.type === 'ingredient'
    && cartModifier.quantity === 1
  ) {
    return null
  }

  return (
    <>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pl: paddingLeft
      }}>
        <CartModifierInlineName cartModifier={cartModifier} groupId={groupId} />
        <CartModifierInlinePrice cartModifier={cartModifier} />
      </Box>
      {cartModifier.modifierGroups.map((modifierGroup: CartModifierGroupStateInterface) => (
        modifierGroup.modifiers.map((modifier: CartModifierStateInterface) => (
          <CartModifierInLine
            key={modifier.id}
            cartModifier={modifier}
            groupId={modifierGroup.id}
            paddingLeft={paddingLeft+2}
          />
        ))
      ))}
    </>
  )
}

export default CartModifierInLine
