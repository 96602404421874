import useDeliveryMethodService from '~/src/hooks/delivery-methods/useDeliveryMethodService'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import Divider from '~/src/components/mui-wrappers/Divider'
import OrderSubTotal from '~/src/components/orders/OrderSubTotal'
import Fees from '~/src/components/fees/Fees'
import ShoppingCartDiscounts from '~/src/components/cart/shopping-cart-discounts'
import ShoppingCartTotal from '~/src/components/cart/shopping-cart-total'
import ShoppingCartCoupon from '~/src/components/cart/shopping-cart-coupon'
import ShoppingCartActions from '~/src/components/cart/shopping-cart-actions'
import { Box } from '@mui/material'
import useGetShoppingCartTemplate from '~/src/components/cart/shopping-cart/styles/useGetShoppingCartTemplate'
import ShoppingCartOptions from '~/src/components/cart/shopping-cart/shopping-cart-infos/ShoppingCartOptions'

interface Props {
  showSubTotal: boolean,
  showFees: boolean,
  showCheckoutOptions: boolean,
  showTotal: boolean,
  showCoupon: boolean,
  showActions: boolean,
  enableEditCheckoutOptions: boolean,
}

const ShoppingCartInfos = ({
  showSubTotal,
  showFees,
  showCheckoutOptions,
  showTotal,
  showCoupon,
  showActions,
  enableEditCheckoutOptions
}: Props) => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { isMethodInactive } = useDeliveryMethodService()
  const totalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)
  const styles = useGetShoppingCartTemplate()

  if (isMethodInactive(checkoutForm.delivery_method.method) || !totalItems) {
    return null
  }

  return (
    <Box sx={styles.getShoppingCartInfoSxProps()}>
      {showSubTotal && <Divider sx={styles.getDividerSxProps()} />}
      {showSubTotal && <OrderSubTotal />}
      {showFees && <Fees />}
      <ShoppingCartDiscounts />
      {showCheckoutOptions && <ShoppingCartOptions enableEditCheckoutOptions={enableEditCheckoutOptions} />}
      {(showTotal || showCoupon || showActions) && <Divider sx={styles.getDividerSxProps()} />}
      {showTotal && <ShoppingCartTotal />}
      {showCoupon && <ShoppingCartCoupon displayLabel={false}/>}
      {showActions && <ShoppingCartActions />}
    </Box>
  )
}

export default ShoppingCartInfos
