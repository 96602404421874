import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'

const getUpdateLayout = (): DeepPartial<LayoutState> => {
  return {
    header: {
      display: false,
      displayMenuBurger: false,
      displayCart: false,
      displayCurrentFirm: false,
      displayDeliveryOptions: false,
      displayLanguagesSelector: false,
      displayUserActions: false,
      displayProductSearch: false,
      displayLocationSearch: false,
      logo: {
        disableLink: false
      },
      title: '',
      type: 'navigation'
    },
    subHeader: {
      display: false,
      variant: 'default'
    },
    content: {
      displayNotification: false
    },
    mainSideBar: {
      display: false,
      displayCart: false,
      displayDeliveryOptions: false,
      displayOrderSummary: false
    },
    footer: {
      display: false,
      displayGoToCartBtn: false,
      displayGoToCheckoutBtn: false,
      displayInformations: false,
      displayLinks: false
    },
    mobileNavigation: {
      display: false
    }
  }
}

export default getUpdateLayout
