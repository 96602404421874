import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CUSTOMER_LOYALTY_HISTORY, LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { getLoyaltyHistory } from '~/src/services/CustomerService'
import { Firm, LoyaltyHistory, OrderSummary } from '@eo-storefronts/eo-core'
import { ALL_ORDERS_SELECTOR } from '~/src/stores/orders'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const useFetchLoyaltyHistoryEffect = () => {
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)
  const allOrders = useEoValue<OrderSummary[]>(ALL_ORDERS_SELECTOR)
  const isLoyaltyEnabledAndCustomerLoggedIn = useEoValue<boolean>(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const setLoyaltyHistory = useSetEoState(CUSTOMER_LOYALTY_HISTORY)

  useAsyncEffect(async () => {
    if (!isLoyaltyEnabledAndCustomerLoggedIn || !firm) {
      return
    }

    const loyaltyHistory: LoyaltyHistory[] = await getLoyaltyHistory(firm.id)
    setLoyaltyHistory(loyaltyHistory)
  }, [ isLoyaltyEnabledAndCustomerLoggedIn, firm, allOrders ])
}

export default useFetchLoyaltyHistoryEffect
