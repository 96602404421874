import getShoppingCartItemsTemplateService
  from '~/src/components/cart/shopping-cart-items/styles/GetShoppingCartItemsTemplateService'
import StylesShoppingCartItemsServiceInterface
  from '~/src/components/cart/shopping-cart-items/styles/StylesShoppingCartItemsServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartItemsTemplate = (): StylesShoppingCartItemsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartItemsTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartItemsTemplate
