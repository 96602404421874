import { HeaderThemeComponent, PlatformUtils } from '@eo-storefronts/eo-core'
import { AvatarProps, BadgeProps, Breakpoint, SxProps } from '@mui/material'
import { HeaderUserActionsProps } from '~/src/components/layout/header/header-user-actions'
import StylesHeaderServiceInterface from '~/src/components/layout/header/styles/StylesHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { AllSystemCSSProperties } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { HeaderLanguageSelectorProps } from '~/src/components/layout/header/header-language-selector'

type PickedCssProperties =
  'background'
  | 'backgroundColor'
  | 'color'
  | 'backgroundImage'
  | 'backgroundSize'
  | 'backgroundRepeat'
  | 'backgroundAttachment'
  | 'boxShadow'
  | 'backgroundPositionX'
  | 'backgroundPositionY'

type HeaderBackgroundSxPropsType = Partial<Pick<AllSystemCSSProperties, PickedCssProperties>>

export default class StylesHeaderServiceStyleTwo extends TemplateServiceStyleBase implements StylesHeaderServiceInterface {
  private readonly _gridElements = {
    spacer: {
      name: '.',
      size: '1fr'
    },
    nav: {
      name: 'nav',
      size: 'max-content'
    },
    logo: {
      name: 'logo',
      size: 'auto'
    },
    currentFirm: {
      name: 'current-firm',
      size: 'auto'
    },
    languageSelector: {
      name: 'language-selector',
      size: 'max-content'
    },
    userActions: {
      name: 'user-actions',
      size: 'max-content'
    },
    deliveryMethods: {
      name: 'delivery-methods',
      size: 'max-content'
    },
    menuBurger: {
      name: 'menu-burger',
      size: 'max-content'
    }
  }

  public getBaseHeaderSx(isLocatorPage?: boolean): SxProps {
    return {
      width: '100%',
      zIndex: 3,
      gridArea: 'header',
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      px: isLocatorPage ? 2 : 12,
      py: 2,
      ...this._headerBackgroundSxProps,
      'div#header__current_firm': {
        '*': {
          color: 'inherit'
        }
      },
      ...this._headerGridSxProps,
      [this.muiTheme.breakpoints.down('md')]: {
        backgroundSize: this.firmTheme?.components?.header.image?.pattern ? 'initial' : 'auto',
        pt: PlatformUtils().isCapacitorNative
          ? `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(1)})`
          : 1,
        pb: 1,
        px: 2,
        '>:first-of-type': {
          pr: 2
        },
        ...this._headerGridSxProps![this.muiTheme.breakpoints.down('md') as keyof SxProps] as SxProps
      }
    }
  }

  public getHeaderSx(isLocatorPage?: boolean): SxProps {
    return {
      ...this.getBaseHeaderSx(isLocatorPage),
      position: 'sticky',
      top: 0
    }
  }

  public getSimpleHeaderSx(isLocatorPage?: boolean): SxProps {
    return {
      width: '100%',
      zIndex: 3,
      px: isLocatorPage ? 2 : 12,
      py: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        pt: PlatformUtils().isCapacitorNative
          ? `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(1)})`
          : 1,
        pb: 1,
        px: 2
      }
    }
  }

  public getMobileHeaderContainerSx(): SxProps {
    return {
      ...this.getBaseHeaderSx(),
      backgroundAttachment: 'initial'
    }
  }

  public getHeaderNavSx(): SxProps {
    return {
      gridArea: 'nav',
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: '100vw'
    }
  }

  public getMenuBurgerSx(): SxProps {
    return {
      gridArea: 'menu-burger',
      justifySelf: 'start',
      pl: 1,
      svg: {
        width: '2rem',
        height: '2rem'
      }
    }
  }

  public getHeaderLogoSx(): SxProps {
    return {
      gridArea: 'logo',
      [this.muiTheme.breakpoints.up('md')]: {
        mr: 4
      },
      [this.muiTheme.breakpoints.down('md')]: {
        width: 'inherit',
        overflow: 'hidden',
        height: '50px',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  public getHeaderCurrentFirmLogoSx(): SxProps {
    return {
      position: 'relative',
      [this.muiTheme.breakpoints.down('md')]: {
        width: 'inherit',
        height: 'inherit',
        a: {
          display: 'flex',
          alignItems: 'center',
          width: 'inherit',
          height: 'inherit',
          img: {
            height: 'auto',
            maxHeight: '50px',
            maxWidth: '100%'
          }
        }
      }
    }
  }

  public getHeaderCurrentFirmSx(): SxProps {
    return {
      gridArea: 'current-firm'
    }
  }

  public getHeaderDeliveryOptionsSx(): SxProps {
    return {
      gridArea: 'delivery-methods',
      [this.muiTheme.breakpoints.down('md')]: {
        width: '100%',
        '> div': {
          pb: 1
        }
      }
    }
  }

  public getHeaderLanguageSelectorSx(): SxProps {
    return {
      gridArea: 'language-selector'
    }
  }

  public getHeaderLanguageSelectorProps(): HeaderLanguageSelectorProps {
    return {
      showAsCode: true,
      color: 'header',
      useFlag: false,
      variant: 'outlined',
      useAutomaticShading: true
    }
  }

  public getHeaderLocationSearchSx(): SxProps {
    return {}
  }

  public getHeaderUserActionsSx(): SxProps {
    return {
      gridArea: 'user-actions',
      color: `${this.firmTheme?.components?.header.color ?? this.muiTheme.palette.primary.main} !important`,
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'inherit'
      }
    }
  }

  public getHeaderUserActionsProps(isLoggedIn: boolean): HeaderUserActionsProps {
    const AvatarProps: AvatarProps = {
      color: 'header',
      useAutomaticShading: true,
      sx: {
        width: 44,
        height: 44
      }
    }
    const BadgeProps: BadgeProps = {
      color: 'header',
      useAutomaticShading: true
    }

    if (!this.muiTheme.breakpoints.down('md')) {
      return {
        AvatarProps,
        BadgeProps,
        ButtonProps: {
          enableResponsive: false,
          color: 'header',
          useAutomaticShading: true,
          sx: {
            border: 'none',
            p: 1,
            fontSize: '1em',
            '&:hover': {
              border: 'none'
            }
          }
        }
      }
    }

    return {
      showLogoutBtn: false,
      AvatarProps,
      BadgeProps,
      LinkProps: {
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          color: 'inherit',
          ...this._getLinkSxProps(isLoggedIn)
        }
      },
      ButtonProps: {
        color: 'header',
        useAutomaticShading: true,
        enableResponsive: true,
        sx: {
          border: 'none',
          p: `calc(${this.muiTheme.spacing(1)} + 1px)`,
          fontSize: '1rem',
          'span#text': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          },
          '&:hover': {
            border: 'none'
          },
          [this.muiTheme.breakpoints.down('md')]: {
            p: `calc(${this.muiTheme.spacing(1)} + 1px)`
          }
        }
      }
    }
  }

  public getHeaderCartSx(): SxProps {
    return {
      gridArea: 'cart'
    }
  }

  private _getLinkSxProps(isLoggedIn: boolean): SxProps {
    if (isLoggedIn) {
      return {}
    }

    return {
      width: 'unset'
    }
  }

  private get _headerBackgroundSxProps(): HeaderBackgroundSxPropsType {
    const headerThemeComponent: HeaderThemeComponent | undefined = this.firmTheme?.components?.header

    if (!headerThemeComponent) {
      return {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText'
      }
    }

    return {
      backgroundColor: headerThemeComponent.background_color ?? 'background.main',
      color: headerThemeComponent.color ?? 'background.contrastText',
      backgroundImage: headerThemeComponent.image?.url ? `url(${headerThemeComponent.image.url.toString()})` : 'unset',
      backgroundRepeat: headerThemeComponent.image?.pattern ? 'repeat' : 'no-repeat',
      backgroundSize: headerThemeComponent.image?.pattern ? 'initial' : 'cover',
      backgroundAttachment: headerThemeComponent.image?.pattern ? 'fixed' : 'unset',
      backgroundPositionX: headerThemeComponent.image?.pattern ? 'unset' : 'center',
      backgroundPositionY: headerThemeComponent.image?.pattern ? 'unset' : 'top',
      boxShadow: headerThemeComponent?.box_shadow ?? 'none'
    }
  }

  private get _headerGridSxProps(): SxProps {
    return {
      gridTemplateColumns: this._getTemplateGridColumnsFromBreakpoint(),
      gridTemplateAreas: this._getTemplateAreasFromBreakpoint(),
      columnGap: 1,
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: this._getTemplateGridColumnsFromBreakpoint('md'),
        gridTemplateAreas: this._getTemplateAreasFromBreakpoint('md')
      }
    }
  }

  private _getTemplateAreasFromBreakpoint(breakpoint?: Breakpoint): string {
    const {
      displayCurrentFirm,
      displayUserActions,
      displayDeliveryOptions,
      displayLanguagesSelector,
      displayMenuBurger,
      type
    } = this.layout!.header
    const {
      spacer,
      menuBurger,
      nav,
      logo,
      currentFirm,
      languageSelector,
      userActions,
      deliveryMethods
    } = this._gridElements
    let matrix: Array<Array<string | boolean>>

    switch (breakpoint) {
      case 'md':
        matrix = [
          [ logo.name, displayCurrentFirm && currentFirm.name, spacer.name, displayLanguagesSelector && languageSelector.name, displayUserActions && userActions.name, type === 'navigation' && nav.name, displayMenuBurger && menuBurger.name ]
        ]

        if (matrix[0].filter(element => typeof element === 'string').length === 1) {
          matrix[0].push(spacer.name)
        }

        if (displayDeliveryOptions) {
          matrix.push(
            matrix[0].filter(element => typeof element === 'string').map(() => deliveryMethods.name)
          )
        }

        break
      default:
        matrix = [
          [ logo.name, displayCurrentFirm && currentFirm.name, spacer.name, displayLanguagesSelector && languageSelector.name, displayUserActions && userActions.name, type === 'navigation' && nav.name, displayMenuBurger && menuBurger.name ]
        ]
    }

    return matrix
      .filter(row => row.some(element => element !== false)) // Exclude rows that are entirely false
      .map(row => `"${row.filter(element => typeof element === 'string').join(' ')}"`)
      .join(' ')
  }

  private _getTemplateGridColumnsFromBreakpoint(breakpoint?: Breakpoint): string {
    const {
      displayUserActions,
      displayMenuBurger,
      displayCurrentFirm,
      displayLanguagesSelector,
      type
    } = this.layout!.header
    const {
      spacer,
      nav,
      logo,
      currentFirm,
      languageSelector,
      userActions,
      menuBurger
    } = this._gridElements
    let matrix: Array<Array<string | boolean>>

    switch (breakpoint) {
      case 'md':
        matrix = [
          [ logo.size, displayCurrentFirm && currentFirm.size, spacer.size, displayLanguagesSelector && languageSelector.size, displayUserActions && userActions.size, displayMenuBurger && menuBurger.size, type === 'navigation' && nav.size ]
        ]

        if (matrix[0].filter(element => typeof element === 'string').length === 1) {
          matrix[0].push(spacer.size)
        }

        if (matrix[0].filter(element => typeof element === 'string').length === 2) {
          matrix = [
            [ '50%', 'minmax(50%, 50%)' ]
          ]
        }

        break
      default:
        matrix = [
          [ logo.size, displayCurrentFirm && currentFirm.size, spacer.size, displayLanguagesSelector && languageSelector.size, displayUserActions && userActions.size, displayMenuBurger && menuBurger.size, type === 'navigation' && nav.size ]
        ]
    }

    return matrix
      .filter(row => row.some(element => element !== false)) // Exclude rows that are entirely false
      .map(row => row.filter(element => typeof element === 'string').join(' '))
      .join(' ')
  }
}
