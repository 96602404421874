import { buttonClasses } from '@mui/material'
import Button, { ButtonProps } from '~/src/components/mui-wrappers/buttons/Button'

const PrimaryButton = ({ sx, children, ...otherProps }: ButtonProps) => (
  <Button
    variant='contained'
    sx={{
      p: 1/2,
      minWidth: 0,
      [`&.${buttonClasses.outlined}`]: {
        px: 2,
        color: 'primary.main',
        borderColor: 'primary.main',
        backgroundColor: 'background.main',
        borderWidth: '2px',
        textTransform: 'none',
        ':hover': {
          backgroundColor: 'background.main',
          borderWidth: '2px'
        }
      },
      ...sx
    }}
    {...otherProps}
  >
    {children}
  </Button>
)

export default PrimaryButton
