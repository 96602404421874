import { useEoValue } from '~/src/hooks/useEoState'
import { Box, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import FirmLoyaltyRewardCard from '~/src/components/profile/account/loyalty/reward-card'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'

const FirmLoyaltyRewards = () => {
  const { t } = useTranslations()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_SELECTOR)

  if (!isLoyaltyEnabled) {
    return null
  }

  return (
    <Box>
      <Typography
        variant='body2'
        sx={{
          textTransform: 'uppercase'
        }}
      >
        {t('profile.loyalty.rewards')}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <FirmLoyaltyRewardCard />
      </Box>
    </Box>
  )
}

export default FirmLoyaltyRewards
