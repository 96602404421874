import { ReactNode, useState } from 'react'
import SideDrawerContext from '~/src/components/layout/side-drawer/SideDrawerContext'
import SideDrawer from '~/src/components/layout/side-drawer'

interface Props {
  children: ReactNode,
}

const LayoutDrawerProvider = ({ children }: Props) => {
  const [ drawerState, setDrawerState ] = useState<boolean>(false)

  const _handleOnDrawerToggle = () => {
    setDrawerState(!drawerState)
  }

  const _handleOnDrawerOpen = () => {
    setDrawerState(true)
  }

  const _handleOnDrawerClose = () => {
    setDrawerState(false)
  }

  return (
    <SideDrawerContext.Provider
      value={{
        open: drawerState,
        onToggle: _handleOnDrawerToggle,
        onOpen: _handleOnDrawerOpen,
        onClose: _handleOnDrawerClose
      }}
    >
      {children}
      <SideDrawer/>
    </SideDrawerContext.Provider>
  )
}

export default LayoutDrawerProvider
