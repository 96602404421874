import { SxProps } from '@mui/material'
import StylesHeaderLanguageSelectorServiceInterface
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderLanguageSelectorServiceStyleThree extends TemplateServiceStyleBase implements StylesHeaderLanguageSelectorServiceInterface {
  public getDropdownSx(): SxProps {
    return {
      border: `solid 1px ${this.muiTheme.palette.primary.main}`,
      fontSize: '1rem',
      minWidth: '64px',
      px: 0,
      py: '0 !important',
      svg: {
        display: 'none'
      },
      '&:hover': {
        border: `solid 1px ${this.muiTheme.palette.primary.main}`
      }
    }
  }
}
