import { CheckoutOption } from '@eo-storefronts/eo-core'
import CheckoutOptionItem from '~/src/components/checkout/CheckoutOptionItem'
import { useEoValue } from '~/src/hooks/useEoState'
import { GET_FIRM_CHECKOUT_OPTIONS_SELECTOR } from '~/src/stores/firm'

interface Props {
  enableEdit?: boolean,
}

const CheckoutOptions = ({ enableEdit = true }: Props) => {
  const checkoutOptions = useEoValue(GET_FIRM_CHECKOUT_OPTIONS_SELECTOR)

  if (!checkoutOptions.length) {
    return null
  }

  return (
    <>
      {checkoutOptions.map((checkoutOption: CheckoutOption, index: number) => (
        <CheckoutOptionItem
          key={index}
          enableEdit={enableEdit}
          checkoutOption={checkoutOption}
        />
      ))}
    </>
  )
}

export default CheckoutOptions
