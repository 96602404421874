import { Badge, BottomNavigationActionProps } from '@mui/material'
import BottomNavigationAction from '~/src/components/mui-wrappers/styled/bottom-navigation-action'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'
import useDialog from '~/src/hooks/useDialog'
import ShoppingCartResponsiveDialog from '~/src/components/cart/ShoppingCartResponsiveDialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

const MobileNavigationCartItem = (props: BottomNavigationActionProps) => {
  const amountOfItemsInCart = useEoValue(CART_TOTAL_ITEMS_SELECTOR)
  const { present } = useDialog()

  const _handleOnClick = () => {
    present(ShoppingCartResponsiveDialog, DialogNameEnum.SHOPPING_CART)
  }

  return (
    <>
      <Badge
        color='primary'
        overlap='circular'
        variant={amountOfItemsInCart < 10 ? 'standard' : 'dot'}
        badgeContent={amountOfItemsInCart}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.BaseBadge-badge': {
            top: '25%',
            right: '25%',
            lineHeight: 'normal'
          }
        }}
      >
        <BottomNavigationAction
          onClick={_handleOnClick}
          {...props}
        />
      </Badge>
    </>
  )
}

export default MobileNavigationCartItem
