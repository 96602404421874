import getShoppingCartTotalTemplateService
  from '~/src/components/cart/shopping-cart-total/styles/GetShoppingCartTotalTemplateService'
import StylesShoppingCartTotalServiceInterface
  from '~/src/components/cart/shopping-cart-total/styles/StylesShoppingCartTotalServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartTotalTemplate = (): StylesShoppingCartTotalServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartTotalTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartTotalTemplate
