import { Button } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'
import { useContext } from 'react'
import Link from '~/src/components/mui-wrappers/Link'
import SwipeAbleDrawerContext, {
  SwipeAbleDrawerContextInterface
} from '~/src/components/mui-wrappers/swipeable-drawer/SwipeAbleDrawerContext'

interface Props extends ButtonProps {
  link: string,
  text: string,
}

const AuthSelectionOption = ({ link, text, ...btnProps }: Props) => {
  const context = useContext<SwipeAbleDrawerContextInterface>(SwipeAbleDrawerContext)

  const handleOnClick = () => {
    if (!context.toggle) {
      return
    }

    context.toggle()
  }

  return (
    <Link
      onClick={handleOnClick}
      href={link}
      resolve
      removeBaseLinkStyle
    >
      <Button fullWidth {...btnProps}>
        {text}
      </Button>
    </Link>
  )
}

export default AuthSelectionOption
