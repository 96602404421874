import DeliveryDiningOutlined from '@mui/icons-material/DeliveryDiningOutlined'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { ReactNode } from 'react'

const DeliveryMethodsSummaryMethodSentenceMethod = () => {
  const { t } = useTranslations()
  const { delivery_method } = useEoValue(CHECKOUT_FORM_STATE)
  const deliveryMethodIcon: Record<DeliveryMethodNames, ReactNode> = {
    [DeliveryMethodNames.DELIVERY]: <DeliveryDiningOutlined/>,
    [DeliveryMethodNames.PICKUP]: <ShoppingBagOutlinedIcon/>,
    [DeliveryMethodNames.ON_THE_SPOT]: <StorefrontOutlinedIcon/>
  }

  if (!delivery_method.method) {
    return null
  }

  return (
    <>
      {deliveryMethodIcon[delivery_method.method]}
      <span>{t(`delivery_methods.${delivery_method.method}`)}</span>
    </>
  )
}

export default DeliveryMethodsSummaryMethodSentenceMethod
