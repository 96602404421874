import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import LayoutOrderServiceStyleTwo from '~/src/components/orders/order-layout/layout/LayoutOrderServiceStyleTwo'
import LayoutOrderServiceStyleOne from '~/src/components/orders/order-layout/layout/LayoutOrderServiceStyleOne'
import LayoutOrderServiceStyleThree from '~/src/components/orders/order-layout/layout/LayoutOrderServiceStyleThree'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getOrderLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutOrderServiceStyleTwo().getLayout(getLayoutArgs)
    case TemplateEnum.STYLE_THREE:
      return new LayoutOrderServiceStyleThree().getLayout()
    default:
      return new LayoutOrderServiceStyleOne().getLayout(getLayoutArgs)
  }
}

export default getOrderLayout
