import DeliveryMethodsSummaryMethod
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method'
import DeliveryMethodsSummaryMethodInformations
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-informations'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import { useContext, useEffect, useRef, useState } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Constants } from '~/src/helpers/constants'

const DeliveryMethodsSummaryContainerStyleTwo = () => {
  const { delivery_method: { method } } = useEoValue(CHECKOUT_FORM_STATE)
  const isDeliveryMethodFrozen = useEoValue(FROZEN_DELIVERY_METHOD_STATE)
  const methodRef = useRef<DeliveryMethodNames | null>(method)
  const [ showAnimation, setShowAnimation ] = useState<boolean>(method === DeliveryMethodNames.DELIVERY || method === DeliveryMethodNames.PICKUP)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const _handleOnClick = () => {
    if (isDeliveryMethodFrozen) {
      return
    }

    responsiveDialogContext.toggleOpen && responsiveDialogContext.toggleOpen()
  }

  useEffect(() => {
    if (methodRef.current === method) {
      return
    }

    methodRef.current = method
    setShowAnimation(false)

    setTimeout(() => {
      setShowAnimation(true)
    }, Constants.DEBOUNCE_TIME * 2)
  }, [ method ])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: .5,
        '>.delivery-methods-summary__button': {
          zIndex: 3
        },
        '>div': {
          zIndex: 1
        }
      }}
      onClick={_handleOnClick}>
      <DeliveryMethodsSummaryMethod showAnimation={showAnimation}/>
      <DeliveryMethodsSummaryMethodInformations showAnimation={showAnimation}/>
    </Box>
  )
}

export default DeliveryMethodsSummaryContainerStyleTwo
