import DeliveryMethodsSummaryEditButton
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button'
import { Box } from '@mui/material'
import DeliveryMethodsSummaryMainButtons
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons'
import { useEoValue } from '~/src/hooks/useEoState'
import { FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import FirmClosed from '~/src/components/firms/FirmClosed'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import CanOnlyOrderDuringOperationalHoursResponsiveModal
  from '~/src/components/error/CanOnlyOrderDuringOperationalHoursResponsiveModal'
import useGetDeliveryMethodsSummaryTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/useGetDeliveryMethodsSummaryTemplate'

const DeliveryMethodsSummary = () => {
  const styles = useGetDeliveryMethodsSummaryTemplate()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const frozenDeliveryMethod = useEoValue(FROZEN_DELIVERY_METHOD_STATE)
  const { checkAll } = useFirmAcceptsOrder()

  const handleOnClick = () => {
    if (frozenDeliveryMethod) {
      return
    }

    styles.getContainerOnClick(frozenDeliveryMethod, responsiveDialogContext)
  }

  if (!checkAll()) {
    return (
      <>
        <FirmClosed sx={styles.getFirmClosedSx()}/>
        <CanOnlyOrderDuringOperationalHoursResponsiveModal/>
      </>
    )
  }

  return (
    <Box
      sx={styles.getContainerSx(frozenDeliveryMethod)}
      onClick={handleOnClick}
    >
      <DeliveryMethodsSummaryMainButtons/>
      {!frozenDeliveryMethod && <DeliveryMethodsSummaryEditButton/>}
    </Box>
  )
}

export default DeliveryMethodsSummary
