import { useEffect, useLayoutEffect } from 'react'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplatePages } from '~/src/types/Templates'
import getLayoutForPage from '~/src/utils/layout/LayoutDispatcherService'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { Constants } from '~/src/helpers/constants'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { BRAND_STATE } from '~/src/stores/brand'

const useLayout = (page: TemplatePages): void => {
  const style: TemplateEnum | null = useGetPageStyle(page)
  const [ layout, setLayoutState ] = useEoState(LAYOUT_STATE)
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  useEffect(() => {
    if (!style || !brand || !firm) {
      return
    }

    const partialState: DeepPartial<LayoutState> = getLayoutForPage({
      style,
      page,
      getLayoutArgs: {
        isMobile: layout.isMobile,
        brand,
        firm
      }
    })

    setLayoutState((state: LayoutState): LayoutState => (
      {
        ...state,
        ...partialState,
        header: {
          ...state.header,
          ...partialState.header,
          logo: {
            ...state.header.logo,
            ...partialState.header?.logo
          }
        },
        subHeader: {
          ...state.subHeader,
          ...partialState.subHeader
        },
        content: {
          ...state.content,
          ...partialState.content
        },
        mainSideBar: {
          ...state.mainSideBar,
          ...partialState.mainSideBar
        },
        footer: {
          ...state.footer,
          ...partialState.footer
        },
        mobileNavigation: {
          ...state.mobileNavigation,
          ...partialState.mobileNavigation
        }
      }
    ))
  }, [ style, layout.isMobile, brand, firm ])

  useLayoutEffect(() => {
    const headerHTMLElement: HTMLElement | null = document.querySelector('header')
    const navHTMLElement: HTMLElement | null = document.querySelector('nav')
    let scrollPaddingTop = 0

    if (headerHTMLElement || navHTMLElement) {
      const headerHeight: number = Math.floor(headerHTMLElement?.getBoundingClientRect()?.height ?? 0)
      const navHeight: number = Math.floor(navHTMLElement?.getBoundingClientRect()?.height ?? 0)


      if (layout.isMobile) {
        scrollPaddingTop = navHeight - 5
      } else {
        scrollPaddingTop = headerHeight + navHeight
      }
    }

    /**
     * minus 5 is to resolve any UI glitches that could appear
     * @type {number}
     */
    document
      .documentElement
      .style
      .setProperty(
        'scroll-padding-top',
        `${scrollPaddingTop - Constants.SCROLL_PADDING_TOP_OFFSET}px`
      )
  }, [ layout.isMobile, layout.header, layout.subHeader ])

  useLayoutEffect(() => {
    const mobileNavigation: HTMLElement | null = document.getElementById('layout__mobile__navigation_bottom_navigation')
    let value = '0.5rem'

    if (mobileNavigation) {
      value = `${mobileNavigation.offsetHeight}px`
    }

    document.documentElement.style.setProperty('--mobile-navigation-height', value)
  }, [
    layout.mobileNavigation.display,
    document.getElementById('layout__mobile__navigation_bottom_navigation')
  ])
}

export {
  useLayout
}
