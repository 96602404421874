import { SnackbarProvider as NotistackProvider } from 'notistack'
import { ReactNode, useRef } from 'react'
import Snackbar from '~/src/components/mui-wrappers/snackbar/Snackbar'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface Props {
  children: ReactNode,
}

const SnackbarProvider = ({ children }: Props) => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const snackbarRef = useRef<NotistackProvider>(null)

  return (
    <NotistackProvider
      ref={snackbarRef}
      maxSnack={isMobile ? 1 : 3}
      preventDuplicate
      dense
      autoHideDuration={3000}
      Components={{
        success: Snackbar,
        info: Snackbar,
        warning: Snackbar,
        error: Snackbar
      }}
    >
      {children}
    </NotistackProvider>
  )
}

export default SnackbarProvider
