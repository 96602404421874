import { Box, Typography } from '@mui/material'
import DeliveryPickupPoints from '~/src/components/delivery-methods/DeliveryPickupPoints'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import useGetDeliveryMethodsSummaryPickupPointsTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/useGetDeliveryMethodsSummaryPickupPointsTemplate'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'

const DeliveryMethodsSummaryPickupPoints = () => {
  const styles = useGetDeliveryMethodsSummaryPickupPointsTemplate()
  const firm = useEoValue(FIRM_SELECTOR)
  const { t } = useTranslations()

  if (!firm || !firm?.settings?.delivery_methods[DeliveryMethodNames.PICKUP]?.pickup_points?.length) {
    return null
  }

  return (
    <Box {...styles.getContainerProps()}>
      <Typography {...styles.getTypographyProps()}>
        {t('delivery_methods.select_pickup_point')}
      </Typography>
      <DeliveryPickupPoints/>
    </Box>
  )
}

export default DeliveryMethodsSummaryPickupPoints
