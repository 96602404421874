import { Box } from '@mui/material'
import BrandAddress from '~/src/components/brand/BrandAddress'
import BrandEmail from '~/src/components/brand/BrandEmail'
import BrandPhone from '~/src/components/brand/BrandPhone'
import FooterSocials from '~/src/components/layout/footer/FooterSocials'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

const FooterInformations = () => {
  const brand = useEoValue(BRAND_STATE)

  if (!brand) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Title variant='h5'>{brand.name}</Title>

      {/* SHOP informations */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <BrandAddress />
        <BrandEmail />
        <BrandPhone />
      </Box>

      {/* Socials links */}
      <FooterSocials />
    </Box>
  )
}

export default FooterInformations
