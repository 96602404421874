import { DateUtils } from '@eo-storefronts/eo-core'
import { ClockPickerView } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { useEffect, useState } from 'react'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import { useConfirmTime } from '~/src/hooks/delivery-methods/useConfirmTime'
import useDateFnsLocale from '~/src/hooks/delivery-methods/useDateFnsLocale'
import { useTimePickerAvailability } from '~/src/hooks/delivery-methods/useTimePickerAvailability'
import { useEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import DeliveryMethodTimePickerToolbar from '~/src/components/delivery-methods/delivery-method-time-picker-toolbar'

const DeliveryMethodsTimePicker = () => {
  const { confirmCheckoutTime } = useConfirmTime()
  const { isTimeDisabled } = useTimePickerAvailability()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const [ time, setTime ] = useState(checkoutForm.delivery_method.time as string)
  const { adapterLocale, localeText } = useDateFnsLocale()

  const handleOnChange = (value: Date|null) => {
    if (!value) {
      return
    }

    setTime(DateUtils.momentFormat(value))
  }

  const handleOnAccept = async (value: Date|null) => {
    if (!value) {
      return
    }

    const valid = await confirmCheckoutTime(DateUtils.momentFormat(value))

    if (!valid) {
      setTime(checkoutForm.delivery_method.time as string)
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        time: DateUtils.momentFormat(value)
      }
    }))
  }

  const shouldDisableTime = (timeValue: number, clockType: ClockPickerView) => {
    return isTimeDisabled(time, timeValue, clockType)
  }

  useEffect(() => {
    setTime(checkoutForm.delivery_method.time as string)
  }, [ checkoutForm.delivery_method.time ])

  if (!checkoutForm.delivery_method.time) {
    return null
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
      localeText={localeText}
    >
      <MobileTimePicker
        value={new Date(time)}
        ampm={false}
        onChange={handleOnChange}
        onAccept={handleOnAccept}
        disableIgnoringDatePartForTimeValidation={true}
        minTime={new Date(checkoutForm.delivery_method.minDate as string)}
        ToolbarComponent={() => <DeliveryMethodTimePickerToolbar time={time} />}
        renderInput={(params) => <TextField {...params} color='container' size='small' />}
        shouldDisableTime={shouldDisableTime}
      />
    </LocalizationProvider>
  )
}

export default DeliveryMethodsTimePicker
