import { Location, OrderUrl } from '@eo-storefronts/eo-core'
import { ReactNode, useState } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import Link from '~/src/components/mui-wrappers/Link'
import useCheckLocation from '~/src/hooks/customer/useCheckLocation'
import RoutesEnum from '~/src/router/enums/routes.enum'

interface Props {
  orderUrl: OrderUrl,
  location: Location,
  children: ReactNode,
}

const EasyOrderButton = ({ children, location, orderUrl }: Props) => {
  const { check: doLinkCustomer } = useCheckLocation()
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const _handleOnButtonClick = () => {
    void doLinkCustomer(location.id)
    setIsLoading(true)
  }

  return (
    <Link
      href={`/${orderUrl.url}/${location.default_language}${RoutesEnum.MENU}`}
      resolve={false}
      removeBaseLinkStyle
    >
      <Button
        variant='contained'
        color='primary'
        fullWidth
        preventMultipleClick
        onClick={_handleOnButtonClick}
        loading={isLoading}
        sx={{
          py: 1.25,
          textTransform: 'none'
        }}
      >
        {children}
      </Button>
    </Link>
  )
}

export default EasyOrderButton
