import { DeliveryMethodNames, Firm } from '@eo-storefronts/eo-core'
import { Box, TextField, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { useEatInLocationPickerDetails } from '~/src/hooks/delivery-methods/useEatInLocationPickerDetails'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const EatInLocationPicker = () => {
  const { t } = useTranslations()
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)
  const muiTheme = useCustomMuiTheme()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { tableMethodLocationName, tableNumbers } = useEatInLocationPickerDetails()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        table_number: event.target.value
      }
    }))
  }

  if (
    !firm?.settings.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.number_required
    && !checkoutForm.delivery_method.table_method
  ) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{tableMethodLocationName}</Typography>
      <TextField
        select
        size='small'
        color='container'
        SelectProps={{
          native: true
        }}
        variant='outlined'
        onChange={handleOnChange}
        value={checkoutForm.delivery_method.table_number || ''}
      >
        <option value=''>{t('delivery_methods.choose_your_spot')}</option>
        {
          tableNumbers.map((tableNumber: string|number, index: number) => (
            <option
              key={index}
              value={tableNumber}
            >
              {tableNumber}
            </option>
          ))
        }
      </TextField>
    </Box>
  )
}

export default EatInLocationPicker
