import { Country } from '@eo-storefronts/eo-core'
import { Box, FormControlLabel, FormGroup } from '@mui/material'
import FormikSelectField from '~/src/components/mui-wrappers/fields/FormikSelectField'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import { SUPPORTED_COUNTRIES_SELECTOR } from '~/src/stores/lang'
import { AddressFormInterface } from '~/src/types/forms/AddressFormInterface'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import { useEoValue } from '~/src/hooks/useEoState'
import Toggle from '~/src/components/mui-wrappers/styled/toggle'

interface Props {
  form: FormikProps<AddressFormInterface>,
  showSetAsDefault: boolean,
  showDescription: boolean,
}

const AddressFormFields = ({ form, showSetAsDefault, showDescription }: Props) => {
  const supportedCountries = useEoValue(SUPPORTED_COUNTRIES_SELECTOR)
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column'
      }}
    >
      {showDescription && (
        <FormikTextField
          TextFieldProps={{
            form,
            name: 'name',
            color: 'container',
            label: t('profile.address.description'),
            helperText: t('global.required')
          }}
        />
      )}
      <FormikTextField
        TextFieldProps={{
          form,
          name: 'street',
          color: 'container',
          label: t('profile.address.street'),
          helperText: t('global.required')
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: 2
        }}
      >
        <FormikTextField
          TextFieldProps={{
            form,
            name: 'houseNumber',
            color: 'container',
            label: t('profile.address.house_number'),
            helperText: t('global.required')
          }}
        />
        <FormikTextField
          TextFieldProps={{
            form,
            name: 'bus',
            color: 'container',
            label: t('profile.address.bus')
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: 2
        }}
      >
        <FormikTextField
          TextFieldProps={{
            form,
            name: 'zipCode',
            color: 'container',
            label: t('profile.address.zip_code'),
            helperText: t('global.required')
          }}
        />
        <FormikTextField
          TextFieldProps={{
            form,
            name: 'locality',
            color: 'container',
            label: t('profile.address.locality'),
            helperText: t('global.required')
          }}
        />
      </Box>

      {supportedCountries && (
        <FormikSelectField
          SelectFieldProps={{
            form: form,
            name: 'country',
            color: 'container',
            label: t('profile.address.country'),
            helperText: t('global.required'),
            defaultValue: undefined
          }}
        >
          <option value={undefined}></option>
          {supportedCountries.map((country: Country) => (
            <option
              key={country.id}
              value={btoa(encodeURIComponent(JSON.stringify(country)))}
            >
              {country.name}
            </option>
          ))}
        </FormikSelectField>
      )}

      {showSetAsDefault && (
        <FormGroup>
          <FormControlLabel
            control={(
              <Toggle
                name='isDefault'
                checked={form.values.isDefault}
                onChange={form.handleChange}
              />
            )}
            label={t('profile.address.make_default')}
          />
        </FormGroup>
      )}
    </Box>
  )
}

export default AddressFormFields
