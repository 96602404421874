import { TemplateEnum } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { STYLES_STATE } from '~/src/stores/styles'
import { TemplateComponents, Templates } from '~/src/types/Templates'

const useGetComponentStyle = (component: TemplateComponents): TemplateEnum | null => {
  const styles = useEoValue<Templates | null>(STYLES_STATE)

  if (!styles) {
    return null
  }

  return styles.components[component]
}

export default useGetComponentStyle
