import { AvatarProps, BadgeProps, LinkProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { ButtonWithIconProps } from '~/src/components/mui-wrappers/buttons/ButtonWithIcon'
import LoginButton from '~/src/components/user-actions/LoginButton'
import LogoutButton from '~/src/components/user-actions/LogoutButton'
import { useEoValue } from '~/src/hooks/useEoState'
import { AUTHENTICATION_STATE, AuthenticationState } from '~/src/stores/authentication'
import { useLocation } from 'react-router-dom'
import { BRAND_STATE } from '~/src/stores/brand'
import ShowQrCodeButton from '~/src/components/user-actions/ShowQrCodeButton'
import ProfileDropdownButton from '~/src/components/user-actions/profile-dropdown-button'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import { SELECTED_SHOP_STATE } from '~/src/stores/firm'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { buildUrl } from '~/src/helpers/router.helper'
import Params from '~/src/router/enums/params.enum'

export interface HeaderUserActionsProps {
  showLogoutBtn?: boolean,
  ButtonProps?: Omit<ButtonWithIconProps, 'startIcon' | 'onClick'>,
  LinkProps?: Omit<LinkProps, 'removeBaseLinkStyle' | 'href'>,
  BadgeProps?: BadgeProps,
  AvatarProps?: AvatarProps,
}

const HeaderUserActions = (
  {
    showLogoutBtn = true,
    ButtonProps,
    LinkProps,
    BadgeProps,
    AvatarProps
  }: HeaderUserActionsProps
) => {
  const { pathname } = useLocation()
  const brand = useEoValue(BRAND_STATE)
  const selectedShop = useEoValue(SELECTED_SHOP_STATE)
  const [ displayLogout, setDisplayLogout ] = useState<boolean>(false)
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)

  const _generateLoginHref = (): string => {
    return buildUrl(RoutesEnum.LOGIN, new Map<Params, keyof typeof RoutesEnum>([
      [ Params.REDIRECT_TO, !brand?.is_multifirm || selectedShop ? 'ACCOUNT' : 'LOCATOR' ]
    ]))
  }

  useEffect(() => {
    setDisplayLogout(pathname.includes('profile'))
  }, [ pathname ])

  if (!!authState.loggedInAs && authState.loggedInAs === 'customer') {
    if (displayLogout && showLogoutBtn) {
      return <LogoutButton {...ButtonProps} />
    }

    if (brand?.settings?.use_quick_auth && PlatformUtils().isMobileWeb) {
      return (
        <ShowQrCodeButton
          AvatarProps={AvatarProps}
          BadgeProps={BadgeProps}/>
      )
    }

    return (
      <ProfileDropdownButton
        color={ButtonProps?.color}
        useAutomaticShading={ButtonProps?.useAutomaticShading}
        variant={ButtonProps?.variant}
        sx={ButtonProps?.sx}/>
    )
  }

  return (
    <LoginButton
      href={_generateLoginHref()}
      ButtonProps={ButtonProps}
      LinkProps={LinkProps}
    />
  )
}

export default HeaderUserActions
