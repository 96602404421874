import { Box } from '@mui/material'
import Link from '~/src/components/mui-wrappers/Link'
import { useTranslations } from '~/src/hooks/useTranslations'
import routesEnum from '~/src/router/enums/routes.enum'
import { useEoValue } from '~/src/hooks/useEoState'
import { TERMS_AND_CONDITION_SELECTOR } from '~/src/stores/marketplace/terms'

const FooterLinks = () => {
  const { t } = useTranslations()
  const termsLink = useEoValue<string>(TERMS_AND_CONDITION_SELECTOR)

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 1
      }}
    >
      <Link
        href={routesEnum.PRIVACY_POLICY}
        color='inherit'
        underline='hover'
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }}
      >
        {t('auth.privacy_policy')}
      </Link>
      <Link
        href={termsLink}
        color='inherit'
        underline='hover'
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }}
      >
        {t('auth.terms_and_conditions')}
      </Link>
    </Box>
  )
}

export default FooterLinks
