import { Box } from '@mui/material'
import useGetShoppingCartActionsTemplate
  from '~/src/components/cart/shopping-cart-actions/styles/useGetShoppingCartActionsTemplate'
import useLoyaltyProductsModal from '~/src/hooks/customer/useLoyaltyProductsModal'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { MouseEvent } from 'react'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import useShoppingCartActions from '~/src/hooks/cart/useShoppingCartActions'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const ShoppingCartActionsLoggedOut = () => {
  const { t } = useTranslations()
  const styles = useGetShoppingCartActionsTemplate()
  const { errors } = useValidateOrder()
  const firm = useEoValue(FIRM_SELECTOR)
  const { checkLoyaltyProductIsSelected } = useLoyaltyProductsModal()
  const { continueAsGuest, continueAsCustomer } = useShoppingCartActions()
  const disabled = !!errors.filter((error: OrderErrorType) => error.variant === 'error').length

  const _handleOnClick = (event: MouseEvent, loginType: 'customer' | 'guest') => {
    if (checkLoyaltyProductIsSelected()) {
      event?.preventDefault && event.preventDefault()
      event?.stopPropagation && event.stopPropagation()

      return
    }

    loginType === 'customer' ? continueAsCustomer() : continueAsGuest()
  }

  if (!firm) {
    return null
  }

  return (
    <Box sx={styles.getContainerSxProps()}>
      <Button
        disabled={disabled}
        variant='contained'
        disableElevation
        sx={styles.getLoginAndGoToCheckoutSxProps()}
        onClick={(event: MouseEvent) => _handleOnClick(event, 'customer')}
      >
        {t('shopping_cart.actions.login_and_go_to_checkout')}
      </Button>
      {firm.settings.theme.flow?.ordering.allow_guest_checkout && (
        <Button
          disabled={disabled}
          variant='contained'
          color='inherit'
          disableElevation
          sx={styles.getContinueAsGuestSxProps()}
          onClick={(event: MouseEvent) => _handleOnClick(event, 'guest')}
        >
          {t('global.continue_as_guest')}
        </Button>
      )}
    </Box>
  )
}

export default ShoppingCartActionsLoggedOut
