import { useEoValue } from '~/src/hooks/useEoState'
import { Modifier } from '@eo-storefronts/eo-core'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { CartModifierStateInterface } from '~/src/stores/cart'
import CartModifierInlineQuantity from '~/src/components/cart/cart-modifier-in-line/CartModifierInlineQuantity'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { Box, Typography } from '@mui/material'

interface Props {
  cartModifier: CartModifierStateInterface,
  groupId: string,
}

const CartModifierInlineName = ({ groupId, cartModifier }: Props) => {
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <Box sx={{
      display: 'flex',
      gap: 2
    }}>
      {cartModifier.action === 'rem' && <DoDisturbIcon fontSize='small' />}
      {cartModifier.action !== 'rem' && <CartModifierInlineQuantity cartModifier={cartModifier} groupId={groupId}/>}
      <Typography variant="body2">
        {modifiers[cartModifier.id].name[lang] || ''}
      </Typography>
    </Box>
  )
}

export default CartModifierInlineName
