import { Language } from '@eo-storefronts/eo-core'
import { useCallback, useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  selectedLanguage: Language | null,
  languages: Language[],
}

const useFirmLanguages = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const [ selectedLanguage, setSelectedLanguage ] = useState<Language | null>(null)
  const [ languages, setLanguages ] = useState<Language[]>([])

  const resolve = useCallback((code: string, languages: Language[]) => {
    for (const lang of languages) {
      if (lang.code === code) {
        return lang
      }
    }

    return languages[0]
  }, [])

  useEffect(() => {
    if (firm) {
      setLanguages(firm.languages)
    }
  }, [ firm ])

  useEffect(() => {
    if (firm) {
      setSelectedLanguage(resolve(lang, firm.languages))
    }
  }, [ lang ])

  return { selectedLanguage, languages }
}

export {
  useFirmLanguages
}
