import { Firm } from '@eo-storefronts/eo-core'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, paperClasses, SxProps, Typography } from '@mui/material'
import { MouseEvent, ReactNode, SyntheticEvent, useState } from 'react'
import FirmTimeslotsGrouped from '~/src/components/firms/FirmTimeslotsGrouped'
import FirmTimeslotsSentence from '~/src/components/firms/FirmTimeslotsSentence'
import FirmTimeslotsUnGrouped from '~/src/components/firms/FirmTimeslotsUnGrouped'
import Popover from '~/src/components/mui-wrappers/styled/popover'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface Props {
  firm: Firm,
  grouped: boolean,
  sx?: SxProps,
  icon?: ReactNode,
}

const FirmTimeslotSentenceContainer = ({ firm, grouped, sx, icon }: Props) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const id: string | undefined = open ? 'timeslot-popover' : undefined
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const muiTheme = useCustomMuiTheme()

  const handleClick = ($event: MouseEvent<HTMLElement>) => {
    $event.stopPropagation()
    $event.preventDefault()
    setAnchorEl($event.target as HTMLElement)
  }

  const handleClose = ($event: SyntheticEvent) => {
    $event.stopPropagation()
    $event.preventDefault()
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          my: 1,
          ...sx
        }}
        onClick={handleClick}
      >
        <FirmTimeslotsSentence firm={firm} />
        <Typography
          variant='subtitle2'
          sx={{
            fontWeight: 'normal',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {!icon && <InfoOutlinedIcon />}
          {icon}
        </Typography>
      </Box>
      <Popover
        open={open}
        id={id}
        onBackdropClick={handleClose}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          [`.${paperClasses.root}`]: {
            p: 2
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            [muiTheme.breakpoints.down('md')]: {
              flexDirection: 'column',
              my: 1
            }
          }}
        >
          {(grouped && !isMobile) && <FirmTimeslotsGrouped firm={firm} />}
          {(!grouped || isMobile) && <FirmTimeslotsUnGrouped firm={firm} />}
        </Box>
      </Popover>
    </>
  )
}

export default FirmTimeslotSentenceContainer
