import { styled, Tabs as MUITabs, TabsProps } from '@mui/material'
import Tab from '~/src/components/mui-wrappers/styled/tab'
import useGetTabsTemplate from '~/src/components/mui-wrappers/styled/tabs/styles/useGetTabsTemplate'

interface Props extends TabsProps {
  displayNullTab?: boolean,
  nullTabLabel?: string,
}

const Tabs = styled(({ displayNullTab = true, nullTabLabel, children, ...otherProps }: Props) => (
  <MUITabs
    {...otherProps}
    component='nav'>
    {displayNullTab && (
      <Tab
        value={null}
        label={nullTabLabel}
        sx={{
          display: !nullTabLabel ? 'none' : undefined
        }}
      />
    )}
    {children}
  </MUITabs>
))(() => useGetTabsTemplate().getComponentSx())

export default Tabs
