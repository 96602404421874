import { LanguageType, ModifierGroup } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import CartModifierInLine from '~/src/components/cart/cart-modifier-in-line'
import CartItemMinMax from '~/src/components/cart/CartItemMinMax'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartModifierGroupStateInterface, CartModifierStateInterface } from '~/src/stores/cart'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'

interface Props {
  element: CartModifierGroupStateInterface,
  displayTitle: boolean,
  displayMinMax: boolean,
}

const CartItemModifierGroup = ({ element, displayTitle, displayMinMax }: Props) => {
  const modifierGroups = useEoValue<Record<string, ModifierGroup>>(MODIFIER_GROUPS_STATE)
  const lang = useEoValue<LanguageType|string>(LANGUAGE_STATE)

  return (
    <>
      {displayTitle && (
        <Title variant='body2' sx={{ fontStyle: 'italic', fontSize: '1em' }}>
          {modifierGroups[element.id]?.name[lang] || ''}
        </Title>
      )}

      {displayMinMax && (
        <CartItemMinMax
          min={modifierGroups[element.id]?.multipleChoiceMin}
          max={modifierGroups[element.id]?.multipleChoiceMax}
        />
      )}

      <Box sx={{ ml: 1 }}>
        {element.modifiers.map((modifier: CartModifierStateInterface) => (
          <CartModifierInLine
            key={modifier.id}
            groupId={element.id}
            cartModifier={modifier}
          />
        ))}
      </Box>
    </>
  )
}

export default CartItemModifierGroup
