import { useEoValue } from '~/src/hooks/useEoState'
import SideBarListItem from '~/src/types/SideBarListItem'
import { useEffect, useState } from 'react'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useTranslations } from '~/src/hooks/useTranslations'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Firm } from '@eo-storefronts/eo-core'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { LOYALTY_IS_ENABLED_SELECTOR } from '~/src/stores/loyalty'

const useGetSideDrawerNavigationItems = (): SideBarListItem[] => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const [ items, setItems ] = useState<SideBarListItem[]>([])
  const isLoyaltyEnabled = useEoValue<boolean>(LOYALTY_IS_ENABLED_SELECTOR)
  const { t } = useTranslations()

  const getNavItems = (): SideBarListItem[] => ([
    {
      href: RoutesEnum.FIRM_HOME,
      resolve: true,
      label: t('header.nav.home'),
      startAdornment: <HomeOutlinedIcon />
    },
    {
      href: RoutesEnum.ORDERS,
      resolve: true,
      label: t('orders.my_orders'),
      startAdornment: <ReceiptLongOutlinedIcon />
    },
    {
      label: t('header.nav.account'),
      startAdornment: <PermIdentityOutlinedIcon />,
      href: `${RoutesEnum.PROFILE}/${RoutesEnum.ACCOUNT}`,
      resolve: true
    },
    {
      label: t('profile.address.your_addresses'),
      startAdornment: <LocationOnOutlinedIcon />,
      href: `${RoutesEnum.PROFILE}/${RoutesEnum.ADDRESSES}`,
      resolve: true
    },
    {
      href: `${RoutesEnum.PROFILE}/${RoutesEnum.LOYALTY}`,
      resolve: true,
      label: t('profile.loyalty.title'),
      startAdornment: <GradeOutlinedIcon />
    },
    {
      href: RoutesEnum.NEWS,
      resolve: true,
      label: t('global.news'),
      startAdornment: <NewspaperIcon />
    }
  ])

  useEffect(() => {
    setItems(
      getNavItems()
        .filter((item: SideBarListItem) => {
          if (
            item.href === RoutesEnum.LOYALTY
            && !isLoyaltyEnabled
          ) {
            return
          }

          if (item.href === RoutesEnum.NEWS) {
            if (firm && !firm?.settings.theme?.flow?.show_newsfeed) {
              return
            }
          }

          return item
        }))
  }, [ isLoyaltyEnabled, firm ])

  return items
}

export default useGetSideDrawerNavigationItems
