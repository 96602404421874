import { Address, FirmStoreLocator } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { findFirmByAddress } from '~/src/services/FirmService'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  find(address: Address): Promise<FirmStoreLocator | null>,
}

const useFindFirmByAddress = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const currentFirm = useEoValue(FIRM_SELECTOR)

  const find = async (address: Address): Promise<FirmStoreLocator | null> => {
    if (!firm && !currentFirm) {
      return null
    }

    return findFirmByAddress(
      firm && firm.settings.theme?.show_delivery_method_first ? firm.id : currentFirm!.id,
      address
    )
  }

  return { find }
}

export {
  useFindFirmByAddress
}
