import { useTranslations } from '~/src/hooks/useTranslations'
import Alert from '~/src/components/mui-wrappers/styled/alert'

const DeliveryMethodNotAvailableMessage = () => {
  const { t } = useTranslations()

  return (
    <Alert
      sx={{ alignItems: 'center' }}
      severity='info'
    >
      {t('global.temporarily_unavailable')}
    </Alert>
  )
}

export default DeliveryMethodNotAvailableMessage
