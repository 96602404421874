import ApiConfigUtils from '~/src/utils/ApiConfigUtils'
import { loginAsClient } from '~/src/services/AuthenticationService'
import { ApiConfiguration, EoInjectable } from '@eo-storefronts/eo-core'
import { BaseAuthenticationState } from '~/src/stores/authentication'

@EoInjectable()
export default class AppUtils {
  private _authResponse: BaseAuthenticationState | null = null

  public async init(): Promise<void> {
    if (this._authResponse) {
      return
    }
    
    await ApiConfigUtils.init()
    const response = await loginAsClient()

    ApiConfiguration.addOrReplaceHeader('Authorization', `${response.token_type} ${response.access_token}`)
    this._authResponse = {
      token: response.access_token,
      tokenType: response.token_type,
      expiresIn: response.expires_in
    }
  }

  public getAuthResponse(): BaseAuthenticationState | null {
    return this._authResponse
  }
}
