import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesExpandableSearchButtonServiceInterface
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/StylesExpandableSearchButtonServiceInterface'
import StylesExpandableSearchButtonServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/StylesExpandableSearchButtonServiceStyleTwo'
import StylesExpandableSearchButtonServiceStyleThree
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/StylesExpandableSearchButtonServiceStyleThree'
import StylesExpandableSearchButtonServiceStyleOne
  from '~/src/components/mui-wrappers/styled/expandable-search-button/styles/StylesExpandableSearchButtonServiceStyleOne'

const getExpandableSearchButtonTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesExpandableSearchButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesExpandableSearchButtonServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesExpandableSearchButtonServiceStyleThree(muiTheme)
    default:
      return new StylesExpandableSearchButtonServiceStyleOne(muiTheme)
  }
}

export default getExpandableSearchButtonTemplateService
