import DefaultPageResolver from '~/src/router/utils/default-page-resolver/index'
import PageMap from '~/src/router/utils/default-page-resolver/types/PageMap'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { TemplatePages } from '~/src/types/Templates'
import { EoInjectable } from '@eo-storefronts/eo-core'

@EoInjectable()
export default class MarketplaceDefaultPage extends DefaultPageResolver {
  protected pagesMapping: PageMap[] = [
    {
      includes: RoutesEnum.EXPLORER,
      page: 'explorer'
    },
    {
      includes: RoutesEnum.LOCATIONS,
      page: 'locations'
    },
    {
      includes: RoutesEnum.LOCATION,
      page: 'location'
    }
  ]

  protected defaultPage: TemplatePages = 'explorer'
}
