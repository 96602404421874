import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import useDialog from '~/src/hooks/useDialog'

interface Props {
  buttonText?: string,
  redirectTo?: string,
}

const PushNotificationDialogAction = ({ buttonText, redirectTo = '' }: Props) => {
  const { push } = useRouterPush()
  const { dismiss } = useDialog()

  const _handleOnClick = () => {
    push(redirectTo)
    dismiss('push-notification')
  }

  if (!buttonText || !redirectTo) {
    return null
  }

  return (
    <Button
      onClick={_handleOnClick}
      variant='contained'
    >
      {buttonText}
    </Button>
  )
}

export default PushNotificationDialogAction
