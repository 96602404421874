import AuthRoutesEnum from '~/src/router/enums/auth.enum'
import FirmRoutesEnum from '~/src/router/enums/firm.enum'
import MarketplaceRoutesEnum from '~/src/router/enums/marketplace.enum'
import ProfileRoutesEnum from '~/src/router/enums/profile.enum'
import OrdersRoutesEnum from '~/src/router/enums/orders.enum'

enum BaseRoutesEnum {
  'MENU' = '/menu',
  'NOT_FOUND' = '/not-found',
  'SEARCH' = '/search',
  'CATEGORIES' = '/categories',
  'CATEGORY' = '/category',
  'PRIVACY_POLICY' = '/legal/privacy-policy',
  'TERMS_AND_CONDITIONS' = '/legal/terms-and-conditions',
  'FORCE_UPDATE' = '/update',
  'IN_APP_UPDATE' = '/in-app-update',
  'NEWS' = '/news',
  'NEWS_DETAIL' = '/details'
}

const RoutesEnum = {
  ...BaseRoutesEnum,
  ...AuthRoutesEnum,
  ...ProfileRoutesEnum,
  ...OrdersRoutesEnum,
  ...MarketplaceRoutesEnum,
  ...FirmRoutesEnum
}

export type RoutesEnumType = typeof RoutesEnum

export default RoutesEnum
