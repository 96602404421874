import { DateUtils, Modifier } from '@eo-storefronts/eo-core'
import { CartModifierStateInterface } from '~/src/stores/cart'

export default class ModifierService {
  private readonly _modifier: Modifier

  constructor(modifier: Modifier) {
    this._modifier = modifier
  }

  public isModifierInStock(): boolean {
    return this._modifier.inStock
  }

  public isModifierSnoozed(time: Date): boolean {
    if (!this._modifier.snooze?.start || !this._modifier.snooze?.end) {
      return false
    }

    return DateUtils.isAfter(time, new Date(this._modifier.snooze.start))
      && DateUtils.isBefore(time, new Date(this._modifier.snooze.end))
  }

  public calculatePrice(cartModifier: CartModifierStateInterface): number {
    if (cartModifier.quantity) {
      return this._modifier.price * cartModifier.quantity
    }

    return this._modifier.price
  }
}
