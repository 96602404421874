import { Box, Skeleton } from '@mui/material'

const LocationResponsiveDialogActionsSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: '100%',
        gap: 2
      }}
    >
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={'100%'}
        sx={{
          p: 1,
          py: 2,
          borderRadius: 'var(--btn-border-radius)'
        }}
      />
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={'100%'}
        sx={{
          p: 1,
          py: 2,
          borderRadius: 'var(--btn-border-radius)'
        }}
      />
    </Box>
  )
}

export default LocationResponsiveDialogActionsSkeleton
