import LoadingButton from '@mui/lab/LoadingButton'
import { ButtonProps as MuiButtonProps } from '@mui/material'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import { Constants } from '~/src/helpers/constants'

export interface ButtonProps extends Omit<MuiButtonProps, 'onClick'> {
  children: ReactNode,
  preventMultipleClick?: boolean,
  loading?: boolean,
  onClick?(event: MouseEvent): void,
  small?: boolean,
}

const Button = ({
  preventMultipleClick = false,
  loading = false,
  children,
  onClick,
  small = false,
  sx = {},
  startIcon,
  ...btnProps
}: ButtonProps) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(loading)

  const handleOnClick = (event: MouseEvent) => {
    if (preventMultipleClick) {
      setIsLoading(true)

      const timer = setTimeout(() => {
        setIsLoading(false)
        clearTimeout(timer)
        performClickAction(event)
      }, Constants.DEBOUNCE_TIME)

      return
    }

    performClickAction(event)
  }

  const performClickAction = (event: MouseEvent) => {
    if (!onClick) {
      return
    }

    onClick(event)
  }

  useEffect(() => {
    setIsLoading(loading)
  }, [ loading ])

  return (
    <LoadingButton
      onClick={handleOnClick}
      loading={isLoading}
      sx={{
        minWidth: small ? '39px' : 'unset',
        borderRadius: 'var(--btn-border-radius)',
        borderWidth: '1px',
        '&:disabled': {
          borderColor: 'action.disabled'
        },
        ...sx
      }}
      disableElevation
      startIcon={!small && startIcon}
      {...btnProps}
    >
      {!small && children}
      {small && startIcon}
    </LoadingButton>
  )
}

export default Button
