import getLinkTabTemplateService from '~/src/components/mui-wrappers/styled/link-tab/styles/GetLinkTabTemplateService'
import StylesLinkTabServiceInterface
  from '~/src/components/mui-wrappers/styled/link-tab/styles/StylesLinkTabServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetLinkTabTemplate = (): StylesLinkTabServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getLinkTabTemplateService(pageStyle, muiTheme)
}

export default useGetLinkTabTemplate
