import CustomerLabel from '~/src/components/customers/CustomerLabel'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { Box } from '@mui/material'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { useEoValue } from '~/src/hooks/useEoState'

const ProfileDropdownLabel = () => {
  const { isMobile } = useEoValue(LAYOUT_STATE)

  return (
    <Box
      component='span'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1
      }}>
      <AccountCircleRoundedIcon/>
      {!isMobile && <CustomerLabel displayLastname={false}/>}
    </Box>
  )
}

export default ProfileDropdownLabel
