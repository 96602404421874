import { Firm } from '@eo-storefronts/eo-core'
import Chip from '~/src/components/mui-wrappers/styled/chip'
import { useFirmOpeningTimeStateAndSentence } from '~/src/hooks/firm/useFirmOpeningTimeStateAndSentence'
import { useTranslations } from '~/src/hooks/useTranslations'
import MuiColors from '~/src/types/MuiColors'

interface Props {
  firm: Firm,
}

const FirmTimeslotStateChip = ({ firm }: Props) => {
  const { t } = useTranslations()
  const { state } = useFirmOpeningTimeStateAndSentence(firm)

  const getColor = (): MuiColors => {
    if (state === 'offline') {
      return MuiColors.WARNING
    }

    if (state === 'on_holiday') {
      return MuiColors.INFO
    }

    if (state === 'closed') {
      return MuiColors.ERROR
    }

    return MuiColors.SUCCESS
  }

  if (!state) {
    return null
  }


  return (
    <Chip
      size='small'
      variant='filled'
      color={getColor()}
      label={t(`firm.${state}`)}
      sx={{
        textTransform: 'uppercase',
        fontWeight: '800'
      }}
    />
  )
}

export default FirmTimeslotStateChip
