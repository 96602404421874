import { Box } from '@mui/material'
import DeliveryMethodsSummaryContainer
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-container'
import DeliveryMethodsResponsiveDialog from '~/src/components/delivery-methods/DeliveryMethodsResponsiveDialog'

const DeliveryMethodsSummary = () => (
  <DeliveryMethodsResponsiveDialog
    activator={
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          py: 2
        }}
      >
        <DeliveryMethodsSummaryContainer />
      </Box>
    }
  />
)

export default DeliveryMethodsSummary
