import { listClasses, SxProps } from '@mui/material'
import StylesSideDrawerNavigationItemsServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-navigation-items/styles/StylesSideDrawerNavigationItemsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSideDrawerNavigationItemsServiceStyles extends TemplateServiceStyleBase implements StylesSideDrawerNavigationItemsServiceInterface {
  public getListSx(): SxProps {
    return {
      [`&.${listClasses.root}`]: {
        display: 'grid',
        gridTemplateAreas: '"nav-items"',
        gridTemplateRows: 'auto',
        height: '100%',
        p: 0
      }
    }
  }

  public getNavigationItemsSx(): SxProps {
    return {
      gridArea: 'nav-items'
    }
  }
}
