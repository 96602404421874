import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryPickupPointsServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/StylesDeliveryMethodsSummaryPickupPointsServiceInterface'
import { BoxProps, SxProps } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'

export default class StylesDeliveryMethodsSummaryPickupPointsServiceStyleTwo extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryPickupPointsServiceInterface {
  getContainerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    }
  }

  getContainerProps(): BoxProps {
    return {
      sx: this.getContainerSx()
    }
  }

  getTypographyProps(): TypographyProps {
    return {}
  }
}
