import { GetIntervalsResponse } from '@eo-storefronts/eo-core'
import { fetchTimeIntervals } from '~/src/services/FirmService'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { useProductsAndCategoriesFromCart } from '~/src/hooks/cart/useProductsAndCategoriesFromCart'
import { CHECKOUT_FORM_STATE, CHECKOUT_PICKUP_POINT_SELECTOR } from '~/src/stores/checkout'

interface ReturnsType {
  fetchTimeslotsIntervals(): Promise<GetIntervalsResponse>,
}

const useFetchTimeslotIntervals = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const { getCategoriesIds, getProductsIds } = useProductsAndCategoriesFromCart()
  const pickupPoint = useEoValue(CHECKOUT_PICKUP_POINT_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  const fetchTimeslotsIntervals = async (): Promise<GetIntervalsResponse> => {
    if (
      !firm
      || !checkoutForm.delivery_method.method
      || !checkoutForm.delivery_method.timeslotId
      || !checkoutForm.delivery_method.time
    ) {
      return {
        intervals: [],
        timeslots: []
      }
    }

    return fetchTimeIntervals(
      firm.id,
      checkoutForm.delivery_method.timeslotId,
      new Date(checkoutForm.delivery_method.time),
      checkoutForm.delivery_method.method,
      getProductsIds(),
      getCategoriesIds(),
      pickupPoint && !pickupPoint.main_branch ? pickupPoint.id : undefined
    )
  }

  return {
    fetchTimeslotsIntervals
  }
}

export default useFetchTimeslotIntervals
