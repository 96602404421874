import { DateUtils, DeliveryMethod, DeliveryMethodNames, Timeslot } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { canOrderOnDayForTimeslots, canOrderOnDayIdForPeriod, isOnHoliday } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  canOrderOnDay(date: Date): boolean,
}

const useCanOrderOnDay = (): ReturnsType => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  const canOrderOnDay = (date: Date): boolean => {
    if (
      !firm
      || !checkoutForm.delivery_method.method
      || checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
      || !checkoutForm.delivery_method.time
    ) {
      return false
    }

    // Firm is on holiday
    if (isOnHoliday(date, firm.settings.periods.holiday_period)) {
      return false
    }

    const dayId = DateUtils.getDayIdLikeMomentJs(date)
    const deliveryMethod = firm.settings.delivery_methods[checkoutForm.delivery_method.method] as DeliveryMethod
    
    // Rest of the date, we first check if the date is before the min date, if yes we disable it
    if (
      checkoutForm.delivery_method.minDate
      && DateUtils.isBefore(date, new Date(checkoutForm.delivery_method.minDate) )
      && !DateUtils.isSameDate(date, new Date(checkoutForm.delivery_method.minDate))
    ) {
      return false
    }

    if (
      (!checkoutForm.delivery_method.pickupPoint || checkoutForm.delivery_method.pickupPoint.main_branch)
      && deliveryMethod.order_timeslots.active
    ) {
      const timeslots = deliveryMethod.order_timeslots.timeslots.map((t: Timeslot) => t.day_id as number)

      return canOrderOnDayForTimeslots(timeslots, dayId)
    }

    if (checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY) {
      return canOrderOnDayIdForPeriod(firm.settings.periods.delivery_hours, dayId)
    }

    if (checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP) {
      if (
        checkoutForm.delivery_method.pickupPoint
        && !checkoutForm.delivery_method.pickupPoint.main_branch
        && !checkoutForm.delivery_method.pickupPoint.use_pickup_hours
      ) {
        if (checkoutForm.delivery_method.pickupPoint.use_timeslots) {
          return canOrderOnDayForTimeslots(
            Object.values(checkoutForm.delivery_method.pickupPoint.periods.timeslot_days),
            dayId
          )
        }

        return canOrderOnDayIdForPeriod(checkoutForm.delivery_method.pickupPoint.periods.pickup_hours, dayId)
      }

      return canOrderOnDayIdForPeriod(firm.settings.periods.pickup_hours, dayId)
    }

    return false
  }

  return { canOrderOnDay }
}

export {
  useCanOrderOnDay
}
