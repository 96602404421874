import { Box, BoxProps } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'

const LoginForgotPasswordLink = ({ children, ...boxProps }: BoxProps) => {
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <Box
      component='span'
      sx={{
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'inherit'
      }}
      onClick={responsiveDialogContext.toggleOpen}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default LoginForgotPasswordLink
