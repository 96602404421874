import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/StylesDeliveryMethodsSummaryServiceInterface'
import StylesDeliveryMethodsSummaryServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/StylesDeliveryMethodsSummaryServiceStyleTwo'
import StylesDeliveryMethodsSummaryServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/StylesDeliveryMethodsSummaryServiceStyleOne'

const getDeliveryMethodsSummaryTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryTemplateService
