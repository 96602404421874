import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryMainButtonsServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/StylesDeliveryMethodsSummaryMainButtonsServiceInterface'
import getDeliveryMethodsSummaryMainButtonsTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/GetDeliveryMethodsSummaryMainButtonsTemplateService'

const useGetDeliveryMethodsSummaryMainButtonsTemplate = (): StylesDeliveryMethodsSummaryMainButtonsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryMainButtonsTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryMainButtonsTemplate
