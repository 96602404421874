import StylesHeaderCurrentFirmServiceInterface
  from '~/src/components/layout/header/header-current-firm/styles/StylesHeaderCurrentFirmServiceInterface'
import StylesHeaderCurrentFirmServiceStyleOne
  from '~/src/components/layout/header/header-current-firm/styles/StylesHeaderCurrentFirmServiceStyleOne'
import StylesHeaderCurrentFirmServiceStyleTwo
  from '~/src/components/layout/header/header-current-firm/styles/StylesHeaderCurrentFirmServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getHeaderCurrentFirmTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesHeaderCurrentFirmServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesHeaderCurrentFirmServiceStyleTwo(muiTheme)
    default:
      return new StylesHeaderCurrentFirmServiceStyleOne(muiTheme)
  }
}

export default getHeaderCurrentFirmTemplateService
