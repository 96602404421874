import { Box } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import { AddressFormInterface } from '~/src/types/forms/AddressFormInterface'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  form: FormikProps<AddressFormInterface>,
}

const AddressFormAction = ({ form }: Props) => {
  const { t } = useTranslations()

  return (
    <Box sx={{ mt: 2, textAlign: 'right' }}>
      <Button
        type='submit'
        disabled={!form.dirty || !form.isValid}
        color='container'
        loading={form.isSubmitting}
        disableElevation
        variant='contained'
      >
        {t('actions.save')}
      </Button>
    </Box>
  )
}

export default AddressFormAction
