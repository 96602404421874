import { FC, useContext } from 'react'
import DialogsContext from '~/src/components/providers/DialogsContext'

interface ReturnsType {
  present<T>(component: FC<T>, name: string, options?: T): void,
  dismiss(name: string): void,
  dismissAll(): void,
}

const useDialog = (): ReturnsType => {
  return useContext(DialogsContext)
}

export default useDialog
