import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import { useContext } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'
import useGetSideDrawerTemplate from '~/src/components/layout/side-drawer/styles/useGetSideDrawerTemplate'
import SwipeableDrawerStyled from '~/src/components/mui-wrappers/styled/drawer'
import useAutomaticCloseSideDrawer from '~/src/hooks/layout/useAutomaticCloseSideDrawer'
import SideDrawerGreetings from '~/src/components/layout/side-drawer/side-drawer-greetings'
import SideDrawerNavigationItems from '~/src/components/layout/side-drawer/side-drawer-navigation-items'
import SideDrawerLoyalty from '~/src/components/layout/side-drawer/side-drawer-loyalty'
import SideDrawerFirmBox from '~/src/components/layout/side-drawer/side-drawer-firm-box'
import SideDrawerBottomLinks from '~/src/components/layout/side-drawer/side-drawer-bottom-links'

const SideDrawer = () => {
  const styles = useGetSideDrawerTemplate()
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)

  useAutomaticCloseSideDrawer()

  return (
    <SwipeableDrawerStyled
      anchor='left'
      disableSwipeToOpen
      open={sideDrawerContext.open}
      onClose={sideDrawerContext.onClose}
      onOpen={sideDrawerContext.onOpen}
      sx={styles.getSwipeableDrawerSx()}
    >
      <IconButton
        color='primary'
        onClick={sideDrawerContext.onClose}
        sx={styles.getCloseButtonSx()}
      >
        <CloseIcon />
      </IconButton>

      <Box sx={styles.getTopSx()}>
        <SideDrawerGreetings />
        <SideDrawerLoyalty />
        <SideDrawerFirmBox />
        <SideDrawerNavigationItems />
      </Box>

      <Box sx={styles.getBottomSx()}>
        <SideDrawerBottomLinks />
      </Box>
    </SwipeableDrawerStyled>
  )
}

export default SideDrawer
