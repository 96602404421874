import { Box } from '@mui/material'
import ShoppingCartTotalItems from '~/src/components/cart/shopping-cart-total/ShoppingCartTotalItems'
import ShoppingCartTotalPrice from '~/src/components/cart/shopping-cart-total/ShoppingCartTotalPrice'
import useGetShoppingCartTotalTemplate
  from '~/src/components/cart/shopping-cart-total/styles/useGetShoppingCartTotalTemplate'

interface Props {
  showTotalItems?: boolean,
}

const ShoppingCartTotal = ({ showTotalItems = true }: Props) => {
  const styles = useGetShoppingCartTotalTemplate()

  return (
    <Box sx={styles.getContainerSxProps()}>
      {showTotalItems && <ShoppingCartTotalItems />}
      <ShoppingCartTotalPrice />
    </Box>
  )
}

export default ShoppingCartTotal
