import getPopoverTemplateService from '~/src/components/mui-wrappers/styled/popover/styles/GetPopoverTemplateService'
import StylesPopoverServiceInterface
  from '~/src/components/mui-wrappers/styled/popover/styles/StylesPopoverServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetPopoverTemplate = (): StylesPopoverServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getPopoverTemplateService(pageStyle, muiTheme)
}

export default useGetPopoverTemplate
