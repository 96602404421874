import { Box, Typography } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  day: string,
  openingHours: string,
}

const FirmTimeslot = ({ day, openingHours }: Props) => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()
  const closed = openingHours === 'closed'

  return (
    <Box
      sx={{
        [muiTheme.breakpoints.down('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1
        }
      }}
    >
      <Typography component='b' variant='body2'><b>{day}</b></Typography>
      <Typography
        component='div'
        variant='body2'
        color={closed ? 'error' : undefined}
      >
        {closed ? t('global.closed') : openingHours}
      </Typography>
    </Box>
  )
}

export default FirmTimeslot
