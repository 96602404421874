import LayoutExtraInfoGuestServiceStyleOne
  from '~/src/pages/extra-info/guest/layout/LayoutExtraInfoGuestServiceStyleOne'
import LayoutExtraInfoGuestServiceStyleTwo
  from '~/src/pages/extra-info/guest/layout/LayoutExtraInfoGuestServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getExtraInfoGuestLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutExtraInfoGuestServiceStyleTwo().getLayout(getLayoutArgs)
    default:
      return new LayoutExtraInfoGuestServiceStyleOne().getLayout(getLayoutArgs)
  }
}

export default getExtraInfoGuestLayout
