import { ApiConfiguration, Firm, Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkLocation } from '~/src/services/CustomerService'
import {
  API_TOKEN_STATE,
  AUTHENTICATION_STATE,
  AuthenticationState,
  BaseAuthenticationState
} from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  check(id?: Id, auth?: Partial<AuthenticationState>): Promise<unknown | undefined>,
}

const useCheckLocation = (): ReturnsType => {
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const apiToken = useEoValue<BaseAuthenticationState | null>(API_TOKEN_STATE)

  const check = async (id?: Id, auth: Partial<AuthenticationState> = authState): Promise<unknown | undefined> => {
    const locationId: Id | undefined = id ?? firm?.id

    if (
      !brand
      || !locationId
      || !auth.customer?.uuid
      || !auth.customer?.token
      || !apiToken?.token
      || !ApiConfiguration.getHeader('Authorization')
    ) {
      return
    }

    return checkLocation(auth.customer.uuid, locationId, brand.id)
  }

  return { check }
}

export default useCheckLocation
