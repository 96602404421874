export default abstract class EnvironmentAbstract {
  public static isSentryEnabled(): boolean {
    return false
  }

  public static getApiEndpoint(): string {
    return ''
  }

  public static getApiVersion(): string {
    return '5.7.1'
  }

  public static getAppVersion(): string {
    return '9.0.0'
  }

  public static getClientId(): string {
    return 'app'
  }

  public static getClientSecret(): string {
    return 'VhtDuF5GQjXyMu3Md8XTKuys3t4vy8astxOAGCeE'
  }

  public static getDevice(): string {
    return 'web'
  }

  public static getGoogleMapKey(): string {
    return 'AIzaSyC5AOgpS53ZLxllERs2kl83sk6aA0kqW_E'
  }

  public static getGoogleSignInClientIdWeb(): string | undefined {
    return '1025274739483-bvi17k5j304bht14o5nvlc29vkl95t7k.apps.googleusercontent.com'
  }

  public static getLocalhost(): string {
    return ''
  }
}
