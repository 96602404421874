import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import DeliveryAddressContainer from '~/src/components/delivery-methods/DeliveryAddress'
import DeliveryMethodsDateTimeContainer from '~/src/components/delivery-methods/DeliveryMethodsDateTimeContainer'
import DeliveryMethodsSwitch from '~/src/components/delivery-methods/DeliveryMethodsSwitch'
import DeliveryPickupPoints from '~/src/components/delivery-methods/DeliveryPickupPoints'
import EatInMethod from '~/src/components/delivery-methods/EatInMethod'
import Divider from '~/src/components/mui-wrappers/Divider'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { LAYOUT_STATE } from '~/src/stores/layout'

const DeliveryMethods = () => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { isMobile } = useEoValue(LAYOUT_STATE)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
          flexShrink: 0,
          flexWrap: 'wrap'
        }}
      >
        <DeliveryMethodsSwitch />
        {checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY && !isMobile &&
            <DeliveryAddressContainer />}
        {checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP && <DeliveryPickupPoints />}
      </Box>
      <Divider />
      {checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT && <EatInMethod />}
      {checkoutForm.delivery_method.method !== DeliveryMethodNames.ON_THE_SPOT && <DeliveryMethodsDateTimeContainer />}
      {checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY && isMobile && (
        <>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Title>{`${t('global.where')}:`}</Title>
            <DeliveryAddressContainer />
          </Box>
        </>
      )}
    </>
  )
}

export default DeliveryMethods
