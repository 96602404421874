import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_DELIVERY_METHOD_SELECTOR } from '~/src/stores/checkout'
import { IS_ALL_DELIVERY_METHODS_INACTIVE_FOR_FIRM } from '~/src/stores/firm'
import DeliveryMethodNotAvailableMessage from '~/src/components/delivery-methods/DeliveryMethodNotAvailableMessage'
import useDeliveryMethodService from '~/src/hooks/delivery-methods/useDeliveryMethodService'
import DeliveryMethodsSummaryPickupPoints
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points'
import DeliveryMethodsSummary from '~/src/components/delivery-methods/delivery-methods-summary'
import useGetDeliveryMethodsSummaryContentTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-content/styles/useGetDeliveryMethodsSummaryContentTemplate'

const DeliveryMethodSummaryContent = () => {
  const styles = useGetDeliveryMethodsSummaryContentTemplate()
  const deliveryMethodSelected = useEoValue(CHECKOUT_DELIVERY_METHOD_SELECTOR)
  const isAllFirmMethodsInactive = useEoValue(IS_ALL_DELIVERY_METHODS_INACTIVE_FOR_FIRM)
  const { isMethodInactive } = useDeliveryMethodService()

  if (isAllFirmMethodsInactive || deliveryMethodSelected && isMethodInactive(deliveryMethodSelected)) {
    return <DeliveryMethodNotAvailableMessage />
  }

  return (
    <>
      {deliveryMethodSelected === DeliveryMethodNames.PICKUP && (
        <DeliveryMethodsSummaryPickupPoints />
      )}

      <Box {...styles.getDeliveryMethodsSummaryContainerProps()}>
        <DeliveryMethodsSummary />
      </Box>
    </>
  )
}

export default DeliveryMethodSummaryContent
