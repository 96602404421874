import { Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { getFirstAvailableProduct } from '~/src/services/ProductService'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  getCategoriesIds(): string[],
  getProductsIds(): Id[],
}

const useProductsAndCategoriesFromCart = (): ReturnsType => {
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const products = useEoValue(PRODUCTS_STATE)

  const getCategoriesIds = (): string[] => {
    const cartCategories: string[] = cart
      .filter((cartProduct: CartProduct) => products[cartProduct.id])
      .map((cartProduct: CartProduct) => products[cartProduct.id].categoryId!)

    if (cartCategories.length > 0) {
      return cartCategories
    }

    const product = getFirstAvailableProduct(products)

    if (product?.categoryId) {
      return [ product.categoryId ]
    }

    return []
  }

  const getProductsIds = (): Id[] => {
    const cartProducts: Id[] = cart
      .filter((cartProduct: CartProduct) => products[cartProduct.id])
      .map((cartProduct: CartProduct) => cartProduct.id)

    if (cartProducts.length > 0) {
      return cartProducts
    }

    const product = getFirstAvailableProduct(products)

    if (product) {
      return [ product.id ]
    }

    return []
  }

  return {
    getCategoriesIds,
    getProductsIds
  }
}

export {
  useProductsAndCategoriesFromCart
}
