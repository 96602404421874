import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton/IconButton'
import { OverridableStringUnion } from '@mui/types'
import { useMemo } from 'react'
import SearchInputProperties from '~/src/types/SearchInputProperties'

const useGlobalSearchInputProperties = (size: OverridableStringUnion<'small' | 'medium' | 'large', IconButtonPropsSizeOverrides>): SearchInputProperties => {
  return useMemo<SearchInputProperties>(() => {
    switch (size) {
      case 'large':
        return {
          py: '0.5em',
          px: '1em',
          radius: '25px'
        }
      case 'medium':
        return {
          py: '0.3em',
          px: '0.8em',
          radius: '20px'
        }
      case 'small':
        return {
          py: '0',
          px: '0',
          radius: '15px'
        }
    }
  }, [ size ])
}

export default useGlobalSearchInputProperties
