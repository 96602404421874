import { Language } from '@eo-storefronts/eo-core'
import { useContext } from 'react'
import FirmLanguagesMenuItem from '~/src/components/firms/FirmLanguagesMenuItem'
import DropdownContext from '~/src/components/mui-wrappers/dropdown/DropdownContext'
import DropdownMenu from '~/src/components/mui-wrappers/dropdown/DropdownMenu'
import { useFirmLanguages } from '~/src/hooks/firm/useFirmLanguages'
import { MenuProps } from '@mui/material'

interface Props extends Pick<MenuProps, 'useAutomaticShading' | 'variant' | 'color'> {
  useFlag?: boolean,
}

const FirmLanguagesMenu = ({ useAutomaticShading, variant, color, useFlag }: Props) => {
  const dropdownContext = useContext(DropdownContext)
  const { languages } = useFirmLanguages()

  const handleOnClose = () => {
    dropdownContext.handleClose && dropdownContext.handleClose()
  }

  return (
    <DropdownMenu
      useAutomaticShading={useAutomaticShading}
      variant={variant}
      color={color}>
      {languages.map((language: Language, index: number) => (
        <FirmLanguagesMenuItem
          key={index}
          useFlag={useFlag}
          language={language}
          onClose={handleOnClose}
        />
      ))}
    </DropdownMenu>
  )
}

export default FirmLanguagesMenu
