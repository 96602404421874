import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'
import { useEffect } from 'react'
// @ts-ignore
import reactManifest from 'react-manifest'
import { HeaderThemeComponent } from '@eo-storefronts/eo-core'

const useManifest = () => {
  const brand = useEoValue(BRAND_STATE)

  useEffect(() => {
    if (!brand) {
      return
    }
    const headerThemeComponent: HeaderThemeComponent | undefined = brand?.settings.theme?.components?.header

    reactManifest.update({
      name: brand.name,
      short_name: brand.name,
      display: 'standalone',
      start_url: location.origin,
      theme_color: headerThemeComponent?.background_color ?? brand.settings.theme.container_color,
      background_color: headerThemeComponent?.color ?? brand.settings.theme.container_contrast_color,
      orientation: 'portrait',
      icons: [
        {
          src: brand.assets.logo_square,
          sizes: '600x600'
        }
      ]
    }, '#manifest-placeholder')
  }, [ brand?.settings.theme ])
}

export default useManifest
