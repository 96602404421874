import { atom, AtomOptions, selector, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

const useEoValue = useRecoilValue
const useSetEoState = useSetRecoilState
const useEoState = useRecoilState

const createState = <T>(options: AtomOptions<T>) => {
  if (process.env.NODE_ENV !== 'development') {
    return atom<T>(options)
  }

  return atom<T>({
    ...options,
    effects: [
      ...options.effects || [],
      ({ onSet }) => {
        onSet((newValue) => {
          // eslint-disable-next-line no-console
          console.log(options.key, newValue)
        })
      }
    ]
  })
}

const createSelector = selector
const createSelectorFamily = selectorFamily

export {
  createState,
  createSelector,
  createSelectorFamily,
  useEoValue,
  useSetEoState,
  useEoState
}
