import { MouseEvent, useContext, useEffect, useState } from 'react'
import FooterGoToButton from '~/src/components/layout/footer/FooterGoToButton'
import SwipeAbleDrawerContext, {
  SwipeAbleDrawerContextInterface
} from '~/src/components/mui-wrappers/swipeable-drawer/SwipeAbleDrawerContext'
import useLoyaltyProductsModal from '~/src/hooks/customer/useLoyaltyProductsModal'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CART_TOTAL_ITEMS_SELECTOR } from '~/src/stores/cart'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const FooterGoToCheckoutButton = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const authState = useEoValue(AUTHENTICATION_STATE)
  const { t } = useTranslations()
  const [ buttonEnabled, setButtonEnabled ] = useState<boolean>(false)
  const totalItems = useEoValue(CART_TOTAL_ITEMS_SELECTOR)
  const { checkAll }  = useFirmAcceptsOrder()
  const context = useContext<SwipeAbleDrawerContextInterface>(SwipeAbleDrawerContext)
  const { checkLoyaltyProductIsSelected } = useLoyaltyProductsModal()

  const handleOnClick = (event: MouseEvent) => {
    if (checkLoyaltyProductIsSelected()) {
      event.preventDefault()
      event.stopPropagation()

      return
    }

    if (
      (authState.loggedInAs && authState.loggedInAs !== 'guest')
      || (authState.guest?.email && authState.guest.email !== '')
      || !context.toggle
    ) {
      return
    }

    event.preventDefault()
    event.stopPropagation()
    context.toggle()
  }

  useEffect(() => {
    if (totalItems === 0) {
      return setButtonEnabled(false)
    }

    if (!checkAll()) {
      return setButtonEnabled(false)
    }

    return setButtonEnabled(true)
  }, [ firm, totalItems ])

  return (
    <FooterGoToButton
      disabled={!buttonEnabled}
      link={routes.CHECKOUT}
      text={`${t('footer.go_to')} ${t('footer.checkout')}`}
      onClick={handleOnClick}
    />
  )
}

export default FooterGoToCheckoutButton
