import { DateUtils } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  sentence: string,
}

const useDeliveryMethodsInlineTime = (): ReturnsType => {
  const { t } = useTranslations()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const [ sentence, setSentence ] = useState<string>('')
  const lang = useEoValue(LANGUAGE_STATE)

  useEffect(() => {
    if (checkoutForm.delivery_method.asap) {
      setSentence(t('delivery_methods.asap'))
      return
    }

    if (!checkoutForm.delivery_method.time) {
      setSentence(t('delivery_methods.pick_a_time'))
      return
    }

    if (DateUtils.isSameDate(new Date(), new Date(checkoutForm.delivery_method.time))) {
      setSentence(`${t('global.today')}, ${DateUtils.formatTime(new Date(checkoutForm.delivery_method.time))}`)
      return
    }

    if (DateUtils.isSameDate(DateUtils.addDays(1), new Date(checkoutForm.delivery_method.time))) {
      setSentence(`${t('global.tomorrow')}, ${DateUtils.formatTime(new Date(checkoutForm.delivery_method.time))}`)
      return
    }

    setSentence(`${DateUtils.format(new Date(checkoutForm.delivery_method.time), lang)}, ${DateUtils.formatTime(new Date(checkoutForm.delivery_method.time))}`)
  }, [ checkoutForm.delivery_method.asap, checkoutForm.delivery_method.time, lang ])

  return { sentence }
}

export {
  useDeliveryMethodsInlineTime
}
