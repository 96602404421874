import { LinearProgress, linearProgressClasses, styled } from '@mui/material'

const RoundedLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 3,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3
  }
}))

export default RoundedLinearProgress
