import StylesSnackbarServiceInterface
  from '~/src/components/mui-wrappers/styled/snackbar/styles/StylesSnackbarServiceInterface'
import StylesSnackbarServiceStyleOne
  from '~/src/components/mui-wrappers/styled/snackbar/styles/StylesSnackbarServiceStyleOne'
import StylesSnackbarServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/snackbar/styles/StylesSnackbarServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getSnackbarTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesSnackbarServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesSnackbarServiceStyleTwo(muiTheme)
    default:
      return new StylesSnackbarServiceStyleOne(muiTheme)
  }
}

export default getSnackbarTemplateService
