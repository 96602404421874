import { Button } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  onConfirm: (() => void) | null,
  onCancel: (() => void) | null,
}

const ConfirmResponsiveDialogActions = ({ onConfirm, onCancel }: Props) => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnCancel = () => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
    onCancel && onCancel()
  }

  const handleOnConfirm = () => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
    onConfirm && onConfirm()
  }

  return (
    <>
      <Button onClick={handleOnConfirm}>{t('actions.confirm')}</Button>
      <Button onClick={handleOnCancel}>{t('actions.cancel')}</Button>
    </>
  )
}

export default ConfirmResponsiveDialogActions
