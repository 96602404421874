import { GoogleMapLibraries } from '~/src/types/GoogleMapLibraries'
import { TemplateEnum } from '@eo-storefronts/eo-core'

export abstract class Constants {
  static TRANSPARENT_IMG_SRC = '/images/transparent.png'
  static DEFAULT_MOMENT_CALENDAR_FORMAT = 'DD/MM/YYYY - HH:mm'
  static FETCH_ORDER_DETAIL_INTERVAL = 10000
  static DEBOUNCE_TIME = 250
  static VIRTUALIZATION_EVENT_DELAY = {
    desktop: 0,
    mobile: 500
  }
  static SCROLLABLE_LIST_DRAG_DEBOUNCE_TIME = 350
  static MOTION_ANIMATION_DURATION = 0.350
  static MOTION_ANIMATION_DELAY = 0.250
  static JS_LOADER_PROCESS_TIME = 625
  static EARTH_RADIUS = 6371
  static SCROLL_PADDING_TOP_OFFSET = 5
  static DYNAMIC_SLUG_URL: string[] = []
  static GOOGLE_MAP_LIBRARIES: GoogleMapLibraries = [ 'places' ]
  static MARKETPLACE_TEMPLATE_PRESET: TemplateEnum = TemplateEnum.STYLE_THREE
}
