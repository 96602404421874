import { useMediaQuery } from '@mui/material'
import { Theme as MuiTheme } from '@mui/material/styles/createTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'
import DesktopHeader from '~/src/components/layout/header/DesktopHeader'
import MobileHeader from '~/src/components/layout/header/MobileHeader'
import SimpleHeader from '~/src/components/layout/header/SimpleHeader'

const Header = () => {
  const layout = useEoValue<LayoutState>(LAYOUT_STATE)
  const isSmallDevice = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('md'))

  if (layout.header.type === 'simple') {
    return <SimpleHeader />
  }

  if (isSmallDevice) {
    return <MobileHeader />
  }

  return <DesktopHeader />
}

export default Header
