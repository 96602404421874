import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryMainButtonsServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/StylesDeliveryMethodsSummaryMainButtonsServiceInterface'
import StylesDeliveryMethodsSummaryMainButtonsServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/StylesDeliveryMethodsSummaryMainButtonsServiceStyleTwo'
import StylesDeliveryMethodsSummaryMainButtonsServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/StylesDeliveryMethodsSummaryMainButtonsServiceStyleOne'

const getDeliveryMethodsSummaryMainButtonsTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryMainButtonsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryMainButtonsServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryMainButtonsServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryMainButtonsTemplateService
