import { useLocation } from 'react-router-dom'
import RoutesEnum from '~/src/router/enums/routes.enum'

const useIsLocatorPage = () => {
  const { pathname } = useLocation()

  return {
    isLocatorPage: pathname.includes(RoutesEnum.LOCATOR)
  }
}

export default useIsLocatorPage
