import { Box } from '@mui/material'
import AppLoader from '~/src/components/loader/AppLoader'
import LanguageLoader from '~/src/components/loader/LanguageLoader'
import { useEoValue } from '~/src/hooks/useEoState'
import AppLoaderState, { APP_LOADER_STATE } from '~/src/stores/app-loader'
import NoConnectionLoader from '~/src/components/loader/NoConnectionLoader'

const LoaderRoot = () => {
  const { state, type } = useEoValue<AppLoaderState>(APP_LOADER_STATE)

  return (
    <Box
      id='REACT__LOADER_PORTAL'
      sx={{
        visibility: state ? 'initial' : 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2000,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
        backdropFilter: 'blur(5rem)',
        textAlign: 'center',
        px: 1
      }}
    >
      {(state && type === 'standard') && <AppLoader />}
      {(state && type === 'connection') && <NoConnectionLoader />}
      {(state && type === 'language') && <LanguageLoader />}
    </Box>
  )
}


export default LoaderRoot
