import { Box, Typography } from '@mui/material'
import FirmLoyaltyRewardProgress from '~/src/components/profile/account/loyalty/reward-card/progress'
import FirmLoyaltyRewardPointBalance from '~/src/components/profile/account/loyalty/reward-card/point-balance'
import Image from '~/src/components/mui-wrappers/Image'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useState } from 'react'
import { Firm } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'

const FirmLoyaltyRewardCategory = () => {
  const { t } = useTranslations()
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)
  const [ hasImage, setHasImage ] = useState<boolean>(true)

  const _handleOnImageError = () => {
    setHasImage(false)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'max-content auto',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: `"${hasImage ? 'image' : 'title'} title"` +
          `"${hasImage ? 'image' : 'progress'} progress"` +
          `"${hasImage ? 'image' : 'points'} points"`
      }}
    >
      {hasImage && (
        <Box
          sx={{
            gridArea: 'image',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Image
            src={firm?.settings.loyalty?.free_product_category?.images[0].thumb}
            alt=''
            sx={{ maxHeight: '80px' }}
            onError={_handleOnImageError}
          />
        </Box>
      )}
      <Typography
        sx={{
          gridArea: 'title',
          fontWeight: 'bold'
        }}
      >
        {t('profile.loyalty.free_product_category')}: {firm?.settings.loyalty?.free_product_category?.name}
      </Typography>
      <Box sx={{ gridArea: 'progress' }}>
        <FirmLoyaltyRewardProgress />
      </Box>
      <FirmLoyaltyRewardPointBalance sx={{ gridArea: 'points' }} />
    </Box>
  )
}

export default FirmLoyaltyRewardCategory
