import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { useEffect } from 'react'
import { CART_TOTAL_SELECTOR } from '~/src/stores/cart'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'

const useCheckDeliveryCostEffect = () => {
  const [
    {
      delivery_method: {
        method,
        initialDeliveryCost,
        deliveryFreeFromPrice
      }
    }, setCheckoutState ] = useEoState(CHECKOUT_FORM_STATE)
  const cartTotal = useEoValue(CART_TOTAL_SELECTOR({
    withDeliveryCosts: false,
    withCheckoutOptions: false,
    withLoyaltyDiscount: false,
    withGlobalVoucherDiscount: false,
    withCategoryVoucherDiscount: false,
    withProductVoucherDiscount: false,
    withServiceFee: false
  }))

  const _updateDeliveryCost = (value: number) => {
    return setCheckoutState((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        deliveryCost: value
      }
    }))
  }

  useEffect(() => {
    if (
      method !== DeliveryMethodNames.DELIVERY
      || deliveryFreeFromPrice === null
    ) {
      return
    }

    if (cartTotal < deliveryFreeFromPrice) {
      return _updateDeliveryCost(initialDeliveryCost)
    }

    _updateDeliveryCost(0)
  }, [ deliveryFreeFromPrice, cartTotal ])
}

export default useCheckDeliveryCostEffect
