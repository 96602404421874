import useFetchGuestForm from '~/src/hooks/forms/guest-form/useFetchGuestForm'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { useEffect } from 'react'
import { GUEST_FORM_FIELDS_STATE } from '~/src/stores/guest-form'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const useFetchGuestFormEffect = () => {
  const { fields, fetch, isLoading } = useFetchGuestForm()
  const { delivery_method } = useEoValue(CHECKOUT_FORM_STATE)
  const setGuestFormFields = useSetEoState(GUEST_FORM_FIELDS_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  useAsyncEffect(() => {
    if (!delivery_method.method || !firm) {
      return
    }

    void fetch(delivery_method.method, delivery_method.pickupPoint)
  }, [ delivery_method.method, delivery_method.pickupPoint, firm ])

  useEffect(() => {
    if (!fields) {
      return
    }

    setGuestFormFields(fields)
  }, [ fields ])

  return { isLoading }
}

export default useFetchGuestFormEffect
