import { Firm, Tracking, TrackingField } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const useInitFacebookPixel = (): void => {
  const firm = useEoValue(FIRM_SELECTOR)
  const currentFirm = useEoValue(FIRM_SELECTOR)

  const _getFacebookPixelId = (firm: Firm | null): string | undefined => firm
    ?.settings
    ?.tracking
    ?.find((tracking: Tracking) => tracking.type === 'FACEBOOK_PIXEL')
    ?.fields.find((field: TrackingField) => field.id === 'facebook_pixel_id')
    ?.value

  useEffect(() => {
    if (!firm && !currentFirm) {
      return
    }

    const facebookPixelId: string | undefined = _getFacebookPixelId(firm || currentFirm)

    if (!facebookPixelId) {
      return
    }

    import('react-facebook-pixel')
      .then(module => module.default)
      .then(ReactPixel => {
        ReactPixel.init(facebookPixelId, undefined, { debug: process.env.NODE_ENV !== 'production', autoConfig: true })
        ReactPixel.pageView()
      })
  }, [ firm, currentFirm ])
}

export default useInitFacebookPixel
