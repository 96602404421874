import { deDE, enUS, frFR, nbNO, nlNL, ptBR } from '@mui/x-date-pickers'
import { Locale } from 'date-fns'
import enLocale from 'date-fns/locale/en-US'
import ptLocale from 'date-fns/locale/pt'
import frLocale from 'date-fns/locale/fr'
import nlLocale from 'date-fns/locale/nl'
import deLocale from 'date-fns/locale/de'
import noLocale from 'date-fns/locale/nb'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

type LocaleTextType = typeof frFR.components.MuiLocalizationProvider.defaultProps.localeText
  | typeof deDE.components.MuiLocalizationProvider.defaultProps.localeText
  | typeof nlNL.components.MuiLocalizationProvider.defaultProps.localeText
  | typeof ptBR.components.MuiLocalizationProvider.defaultProps.localeText
  | typeof enUS.components.MuiLocalizationProvider.defaultProps.localeText
  | typeof nbNO.components.MuiLocalizationProvider.defaultProps.localeText

interface ReturnsType {
  adapterLocale: Locale,
  localeText: LocaleTextType,
}

const useDateFnsLocale = (): ReturnsType => {
  const lang = useEoValue(LANGUAGE_STATE)

  if (lang === 'de') {
    return {
      adapterLocale: deLocale,
      localeText: deDE.components.MuiLocalizationProvider.defaultProps.localeText
    }
  }

  if (lang === 'fr') {
    return {
      adapterLocale: frLocale,
      localeText: frFR.components.MuiLocalizationProvider.defaultProps.localeText
    }
  }

  if (lang === 'nl') {
    return {
      adapterLocale: nlLocale,
      localeText: nlNL.components.MuiLocalizationProvider.defaultProps.localeText
    }
  }

  if (lang === 'pt') {
    return {
      adapterLocale: ptLocale,
      localeText: ptBR.components.MuiLocalizationProvider.defaultProps.localeText
    }
  }

  if (lang === 'no') {
    return {
      adapterLocale: noLocale,
      localeText: {
        ...nbNO.components.MuiLocalizationProvider.defaultProps.localeText,
        datePickerDefaultToolbarTitle: 'Velg dato',
        dateRangePickerDefaultToolbarTitle: 'Velg dato',
        timePickerDefaultToolbarTitle: 'Velg tid'
      }
    }
  }

  return {
    adapterLocale: enLocale,
    localeText: enUS.components.MuiLocalizationProvider.defaultProps.localeText
  }
}

export default useDateFnsLocale
