import { ToggleButtonProps } from '@mui/material'
import ToggleButton from '~/src/components/mui-wrappers/styled/ToggleButton'
import { useTranslations } from '~/src/hooks/useTranslations'
import useDeliveryMethodService from '~/src/hooks/delivery-methods/useDeliveryMethodService'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'

const DeliveryMethodsSwitchButton = (props: ToggleButtonProps) => {
  const { t } = useTranslations()
  const { isMethodEnabled, isMethodInactive } = useDeliveryMethodService()
  const deliveryMethod = props.value as DeliveryMethodNames

  if (!isMethodEnabled(deliveryMethod)) {
    return null
  }

  return (
    <ToggleButton
      {...props}
      disabled={isMethodInactive(deliveryMethod)}
    >
      {t(`delivery_methods.${props.value}`)}
    </ToggleButton>
  )
}

export default DeliveryMethodsSwitchButton
