import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryMethodButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/StylesDeliveryMethodsSummaryMethodButtonServiceInterface'
import { BoxProps } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'

export default class StylesDeliveryMethodsSummaryMethodButtonServiceStyleTwo extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryMethodButtonServiceInterface {
  getButtonColor(): Pick<ButtonProps, 'color'> {
    return {
      color: 'inherit'
    }
  }

  getButtonVariant(frozenDeliveryMethod: boolean): Pick<ButtonProps, 'variant'> {
    return {
      variant: frozenDeliveryMethod ? 'text' : 'outlined'
    }
  }

  getButtonProps(frozenDeliveryMethod: boolean): ButtonProps {
    return {
      ...this.getButtonColor(),
      ...this.getButtonVariant(frozenDeliveryMethod),
      disabled: frozenDeliveryMethod
    }
  }

  getLabelContainerProps(): BoxProps {
    return {
      component: 'span',
      sx: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left',
        width: '100%'
      }
    }
  }
}
