import { createSelector, createState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { CUSTOMER_LOYALTY_REACHED_SELECTOR, CUSTOMER_STATE } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Firm, LoyaltyHistory } from '@eo-storefronts/eo-core'

export const CUSTOMER_LOYALTY_HISTORY = createState<LoyaltyHistory[]>({
  key: 'customerLoyaltyHistory',
  default: []
})

export const LOYALTY_IS_ENABLED_SELECTOR = createSelector({
  key: 'loyaltyIsEnabledSelector',
  get: ({ get }) => {
    const firm = get<Firm | null>(FIRM_SELECTOR)

    if (!firm) {
      return false
    }

    return firm.settings.modules.use_loyalty && !!firm.settings.loyalty?.active
  }
})

export const LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR = createSelector<boolean>({
  key: 'loyaltyIsEnabledAndCustomerIsLoggedInAndCustomerIsLoggedInSelector',
  get: ({ get }) => {
    const customer = get(CUSTOMER_STATE)
    const loyaltyIsEnabled = get(LOYALTY_IS_ENABLED_SELECTOR)

    return !!customer && loyaltyIsEnabled
  }
})

export const ADD_LOYALTY_TO_ORDER_SELECTOR = createSelector<boolean>({
  key: 'addLoyaltyToOrderSelector',
  get: ({ get }) => {
    const loyaltyIsEnabledAndCustomerIsLoggedIn = get(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
    const customer = get(CUSTOMER_STATE)
    const loyaltyTypeIsDiscount = get(LOYALTY_TYPE_IS_DISCOUNT_SELECTOR)
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    if (loyaltyTypeIsDiscount) {
      return loyaltyIsEnabledAndCustomerIsLoggedIn && !!customer?.loyalty?.add_loyalty_to_order
    }

    return loyaltyIsEnabledAndCustomerIsLoggedIn && !!customer?.loyalty?.add_loyalty_to_order && !!checkoutForm.loyaltyProductId
  }
})

export const LOYALTY_TYPE_IS_DISCOUNT_SELECTOR = createSelector<boolean>({
  key: 'loyaltyTypeIsDiscountSelector',
  get: ({ get }) => {
    const loyaltyIsEnabled = get(LOYALTY_IS_ENABLED_SELECTOR)
    const firm = get(FIRM_SELECTOR)

    if (!loyaltyIsEnabled) {
      return false
    }

    return firm?.settings.loyalty?.loyalty_type === 'discount'
  }
})

export const LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR = createSelector<boolean>({
  key: 'loyaltyTypeIsFreeProductSelector',
  get: ({ get }) => {
    const loyaltyIsEnabled = get(LOYALTY_IS_ENABLED_SELECTOR)
    const firm = get(FIRM_SELECTOR)

    if (!loyaltyIsEnabled) {
      return false
    }

    return firm?.settings.loyalty?.loyalty_type !== 'discount'
  }
})

export const LOYALTY_DISCOUNT_CAN_BE_APPLIED_SELECTOR = createSelector<boolean>({
  key: 'loyaltyDiscountCanBeAppliedSelector',
  get: ({ get }) => {
    const loyaltyIsEnabledAndCustomerIsLoggedIn = get(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
    const customerLoyaltyReached = get(CUSTOMER_LOYALTY_REACHED_SELECTOR)

    return loyaltyIsEnabledAndCustomerIsLoggedIn && customerLoyaltyReached
  }
})

export const LOYALTY_DISCOUNT_SELECTOR = createSelector<string>({
  key: 'loyaltyDiscountSelector',
  get: ({ get }) => {
    const loyaltyTypeIsDiscount = get(LOYALTY_TYPE_IS_DISCOUNT_SELECTOR)
    const firm = get(FIRM_SELECTOR)

    if (!loyaltyTypeIsDiscount) {
      return ''
    }

    if (firm?.settings.loyalty?.discount_type === 'amount') {
      return `${firm.settings.currency.symbol} ${firm.settings.loyalty.discount_value.toFixed(2)}`
    }

    return `${firm?.settings.loyalty?.discount_value} %`
  }
})
