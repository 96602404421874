import GridList from '~/src/components/mui-wrappers/grid-list/GridList'
import GridListItem from '~/src/components/mui-wrappers/grid-list/GridListItem'
import useGetProductListTemplate from '~/src/components/products/product-list/styles/useGetProductListTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { Theme } from '@eo-storefronts/eo-core'
import { GridListItemProps, GridListProps } from '~/src/types/GridList'
import { MouseEvent } from 'react'
import ProductItemCardVirtualized from '~/src/components/products/product-item/ProductCardItemVirtualized'

interface Props {
  productsId: string[],
  GridProps?: GridListProps,
  ItemProps?: GridListItemProps,
  onItemClick?(e: MouseEvent<HTMLElement>, productId: string): void,
}

const ProductList = ({ productsId, GridProps, ItemProps, onItemClick }: Props) => {
  const style = useGetProductListTemplate()
  const products = useEoValue(PRODUCTS_STATE)
  const firmTheme = useEoValue<Theme | undefined>(FIRM_THEME_SELECTOR)

  return (
    <GridList
      sx={style.getGridStyleSxProps()}
      {...style.getGridProps()}
      {...GridProps}
    >
      {productsId.map((productId: string) => (
        <GridListItem
          key={productId}
          onClick={(e: MouseEvent<HTMLElement>) => onItemClick && onItemClick(e, productId)}
          {...style.getGridListItemProps()}
          {...ItemProps}
        >
          <ProductItemCardVirtualized
            product={products[productId]}
            containerTemplate={firmTheme?.components?.products_list}
          />
        </GridListItem>
      ))}
    </GridList>
  )
}

export default ProductList
