import { SxProps } from '@mui/material'
import StylesShoppingCartCouponServiceInterface
  from '~/src/components/cart/shopping-cart-coupon/styles/StylesShoppingCartCouponServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartCouponServiceStyleOne extends TemplateServiceStyleBase implements StylesShoppingCartCouponServiceInterface {
  public getLabelSxProps(): SxProps {
    return {
      mb: 1
    }
  }

  public getContainerSxProps(): SxProps {
    return { display: 'flex', justifyContent: 'space-between', gap: 2 }
  }

  public getTextFieldSxProps(): SxProps {
    return {
      width: '100%'
    }
  }

  public getButtonSxProps(): SxProps {
    return {
      px: 4
    }
  }
}
