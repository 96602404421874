import { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import { memo, useState } from 'react'
import { Collapse, IconButton } from '@mui/material'
import Alert from '~/src/components/mui-wrappers/styled/alert'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  error: OrderErrorType,
}

const ShoppingCartMessage = ({ error }: Props) => {
  const [ open, setOpen ] = useState(true)

  const _handleOnClose = () => {
    if (!error.closable) {
      return
    }

    setOpen(false)
  }

  return (
    <Collapse in={open}>
      <Alert
        severity={error.variant}
        action={error.closable && (
          <IconButton
            color="inherit"
            size="small"
            onClick={_handleOnClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        {error.text}
      </Alert>
    </Collapse>
  )
}

export default memo(ShoppingCartMessage)
