import { Firm, Product } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR, CART_STATE, CartState } from '~/src/stores/cart'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'

let RunOnceForCartUpdate = false

const useCartCleanUpEffect = (): void => {
  const products = useEoValue<Record<string, Product>>(PRODUCTS_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const setCart = useSetEoState(CART_STATE)
  const cartOfFirm = useEoValue(CART_OF_FIRM_SELECTOR)

  useEffect(() => {
    cleanUpCart()
  }, [ products, firm ])

  useEffect(() => {
    if (cartOfFirm.length === 0 || RunOnceForCartUpdate) {
      return
    }

    RunOnceForCartUpdate = true
    cleanUpCart()
  }, [ cartOfFirm ])

  const cleanUpCart = () => {
    if (!firm || !Object.keys(products).length) {
      return
    }

    setCart((state: CartState) => {
      const currentFirmCart = [ ...state[firm.id] || [] ]

      if (!currentFirmCart.length) {
        return state
      }

      const cleanedUpCurrentFirmCartProducts: CartProduct[] = []

      currentFirmCart.forEach((cp: CartProduct) => {
        if (!products[cp.id] || cp.is_coupon || cp.is_loyalty) {
          return
        }

        cleanedUpCurrentFirmCartProducts.push(cp)
      })

      return {
        ...state,
        [firm.id]: cleanedUpCurrentFirmCartProducts
      }
    })
  }
}

export default useCartCleanUpEffect
