import { Typography } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'

interface Props extends TypographyProps {
  component?: string,
}

const Title = ({ sx, children, variant, ...otherProps }: Props) => {
  const _getFontWeight = (): string => {
    if (!variant || [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ].includes(variant)) {
      return 'bold'
    }

    return 'initial'
  }

  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: 'TitleFont, GeneralFont, Roboto, Arial',
        fontWeight: _getFontWeight(),
        ...sx
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export default Title
