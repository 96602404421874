import Params from '~/src/router/enums/params.enum'
import RoutesEnum from '~/src/router/enums/routes.enum'

const buildParams = (params: Map<Params, string | number | boolean>, alreadyHasParam?: boolean): string => {
  let paramsAsString = ''

  Array.from(params.entries()).forEach(([ key, value ]: [ Params, string | number | boolean ], index: number) => {
    paramsAsString += `${(index === 0 && !alreadyHasParam) ? '?' : '&'}${key}=${encodeURIComponent(value)}`
  })

  return paramsAsString
}

const buildUrl = (url: string, urlParams?: Map<Params, keyof typeof RoutesEnum | string>): string => {
  const [ urlWithAnchors, params ]: Array<string | undefined> = url.split('?')
  let [ cleanedUrl, anchors ]: Array<string | undefined> = urlWithAnchors.split('#')

  if (urlParams) {
    if (params) {
      cleanedUrl += '?'
    }

    cleanedUrl += `${params || ''}${buildParams(urlParams, !!params)}`
  }

  if (anchors) {
    cleanedUrl += `#${anchors}`
  }

  return cleanedUrl
}

const removeParameterFromUrl = (param: Params, url: string = window.location.href): string => {
  let [ cleanedUrl, paramsAndAnchors ]: Array<string | undefined> = url.split('?')
  const [ params, anchors ]: Array<string | undefined> = paramsAndAnchors ? paramsAndAnchors.split('#') : [ '', '' ]

  if (params) {
    params
      .split('&')
      .forEach((value: string, index: number) => {
        if (value.includes(`${param}=`)) {
          return
        }

        cleanedUrl += `${index === 0 ? '?' : '&'}${value}`
      })
  }

  if (anchors) {
    cleanedUrl += `#${anchors}`
  }

  return cleanedUrl
}

export { buildUrl, removeParameterFromUrl }
