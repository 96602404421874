import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryPickupPointsServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/StylesDeliveryMethodsSummaryPickupPointsServiceInterface'
import StylesDeliveryMethodsSummaryPickupPointsServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/StylesDeliveryMethodsSummaryPickupPointsServiceStyleTwo'
import StylesDeliveryMethodsSummaryPickupPointsServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/StylesDeliveryMethodsSummaryPickupPointsServiceStyleOne'

const getDeliveryMethodsSummaryPickupPointsTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryPickupPointsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryPickupPointsServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryPickupPointsServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryPickupPointsTemplateService
