import { useEffect, useState } from 'react'
import RoutesEnum from '~/src/router/enums/routes.enum'
import CustomNavItemValues from '~/src/types/CustomNavItemValues'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_MARKETPLACE_SELECTOR } from '~/src/stores/brand'
import { useLocation } from 'react-router-dom'

const useGetActualRoute = (): CustomNavItemValues | null => {
  const { pathname } = useLocation()
  const isMarketplace = useEoValue<boolean>(IS_MARKETPLACE_SELECTOR)
  const [ selected, setSelected ] = useState<CustomNavItemValues | null>(null)

  useEffect(() => {
    if (pathname.includes(RoutesEnum.MENU)
      || pathname.includes(RoutesEnum.LOCATOR)) {
      return setSelected('home')
    }

    if (
      pathname.includes(RoutesEnum.CATEGORIES) ||
      pathname.includes(RoutesEnum.PRODUCTS) ||
      (pathname.includes(RoutesEnum.CATEGORY) && pathname.includes(RoutesEnum.PRODUCTS)) ||
      (pathname.includes(RoutesEnum.CATEGORY) && pathname.includes(RoutesEnum.LOCATIONS)) ||
      (pathname.includes(RoutesEnum.CATEGORY) && pathname.includes(RoutesEnum.LOCATION)) ||
      (pathname.includes(RoutesEnum.SEARCH))
    ) {
      if (isMarketplace) {
        return setSelected('categories')
      }

      return setSelected('home')
    }

    if (
      pathname.includes(RoutesEnum.ORDERS)
      || pathname.includes(RoutesEnum.UPCOMING)
      || pathname.includes(RoutesEnum.TRACKER)
    ) {
      return setSelected('orders')
    }

    if (
      pathname.includes(RoutesEnum.PROFILE)
      || pathname.includes(RoutesEnum.LOGIN)
      || pathname.includes(RoutesEnum.REGISTER)
    ) {
      return setSelected('profile')
    }

    return setSelected(null)
  }, [ pathname ])

  return selected
}

export default useGetActualRoute

