import { useEffect } from 'react'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR, CART_SUB_TOTAL_SELECTOR } from '~/src/stores/cart'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const useLogViewCart = (): void => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()
  const firm = useEoValue(FIRM_SELECTOR)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const total = useEoValue(CART_SUB_TOTAL_SELECTOR)

  useEffect(() => {
    logGoogleAnalyticEvent('view_cart', {
      currency: firm?.settings.currency.code,
      value: total,
      items: cart
    })
    logFacebookAnalyticEvent('ViewCart', {
      currency: firm?.settings.currency.code,
      value: total,
      content_type: 'product_group',
      content_name: 'Cart',
      content_category: 'Cart',
      contents: cart
    })
  }, [])
}

export default useLogViewCart
