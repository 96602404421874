import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications'
import { useEffect } from 'react'
import usePushNotificationsModal from '~/src/hooks/push-notifications/usePushNotificationsModal'
import EnvUtils from '~/src/utils/EnvUtils'

const usePushNotificationsActionEffect = () => {
  const { presentModal } = usePushNotificationsModal()

  useEffect(() => {
    if (EnvUtils.isDeviceWeb) {
      return
    }

    PushNotifications
      .register()
      .then(() => {
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
          void presentModal(notification)
        })

        PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
          void presentModal(action.notification)
        })
      })
  }, [])
}

export default usePushNotificationsActionEffect
