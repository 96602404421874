import { BottomNavigationActionProps } from '@mui/material'
import { useContext } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'
import BottomNavigationAction from '~/src/components/mui-wrappers/styled/bottom-navigation-action'

interface Props extends BottomNavigationActionProps {
  iconPosition?: string,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MobileNavigationMenuItem = ({ iconPosition, ...props }: Props) => {
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)

  const handleOnClick = () => {
    sideDrawerContext.onToggle()
  }

  return (
    <BottomNavigationAction
      onClick={handleOnClick}
      {...props}
    />
  )
}

export default MobileNavigationMenuItem
