import {
  styled,
  Dialog as MUIDialog,
  DialogProps,
  dialogContentClasses,
  useMediaQuery, Theme
} from '@mui/material'

const DialogStyled = styled(({ fullScreen, ...dialogProps }: DialogProps) => {
  const dialogFullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <MUIDialog
      fullScreen={fullScreen ?? dialogFullScreen}
      {...dialogProps} />
  )
})(() => ({
  [`.${dialogContentClasses.root}`]: {
    backgroundColor: 'container.main',
    color: 'container.contrastText'
  }
}))

export default DialogStyled
