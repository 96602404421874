import { Id } from '@eo-storefronts/eo-core'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { convertProductUnitKgInGr } from '~/src/services/ProductService'
import AlreadyInCart, { AlreadyInCartProps } from '~/src/components/products/modals/coupon/already-in-cart'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { PRODUCTS_STATE } from '~/src/stores/product'
import useDialog from '~/src/hooks/useDialog'
import { useAddProductToCart } from '~/src/hooks/cart/useAddProductToCart'
import useProductHasOptions from '~/src/hooks/products/useProductHasOptions'
import useGetProductQuantityToAdd from '~/src/hooks/products/useGetProductQuantityToAdd'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { CartProduct } from '~/src/types/CartProduct'
import { useUpdateCartProducts } from '~/src/hooks/cart/useUpdateCartProducts'
import ProductConfigurationModal, { ProductConfigurationModalProps } from '~/src/components/products/modals/configuration'

const useLoyaltyModal = () => {
  const products = useEoValue(PRODUCTS_STATE)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const { present } = useDialog()
  const { addToCart } = useAddProductToCart()
  const { hasOptions } = useProductHasOptions()
  const { getQuantity } = useGetProductQuantityToAdd()
  const { updateQuantity, updateIsLoyalty } = useUpdateCartProducts()

  const _addProductToBasket = (productId: Id) => {
    addToCart(products[productId], getQuantity(products[productId]))
  }

  const chooseProduct = (productId: Id) => {
    if (!products[productId]) {
      return
    }

    const cartProduct = cart.find((cp: CartProduct) => cp.id.toString() === productId.toString())

    if (
      cartProduct
      && (
        checkoutForm.coupon?.chosen_product_id?.toString() !== productId.toString()
        || cartProduct.quantity > convertProductUnitKgInGr(products[productId]).unitInterval
      )
      && !hasOptions(products[productId])
    ) {
      return present<AlreadyInCartProps>(
        AlreadyInCart,
        'already-in-cart',
        {
          onClose: (addNewProduct: boolean) => {
            if (!addNewProduct) {
              if (cartProduct.quantity > 1) {
                updateQuantity(cartProduct, cartProduct.quantity - 1)
                _addProductToBasket(productId)
              } else {
                updateIsLoyalty(cartProduct, true)
              }

              setCheckoutForm((state: CheckoutFormState) => ({
                ...state,
                loyaltyDiscount: products[productId].price,
                loyaltyProductId: products[productId].id
              }))

              return
            }

            _addProductToBasket(productId)
          }
        }
      )
    }

    if (hasOptions(products[productId])) {
      return present<ProductConfigurationModalProps>(
        ProductConfigurationModal,
        `product-${productId}`,
        {
          product: products[productId]
        }
      )
    }

    _addProductToBasket(productId)
  }

  return {
    chooseProduct
  }
}

export default useLoyaltyModal
