import Price from '~/src/components/Price'
import Title from '~/src/components/mui-wrappers/Title'
import { Product } from '@eo-storefronts/eo-core'
import { productHasPromotion } from '~/src/services/ProductService'

interface Props {
  product: Product,
}

const ProductPrice = ({ product }: Props) => (
  <Title
    component='span'
    className={productHasPromotion(product) ? 'eo-strike' : ''}
  >
    <Price
      price={product.price}
      displayZeroAmount={true}
    />
  </Title>
)

export default ProductPrice
