import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryEditButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/StylesDeliveryMethodsSummaryEditButtonServiceInterface'
import StylesDeliveryMethodsSummaryEditButtonServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/StylesDeliveryMethodsSummaryEditButtonServiceStyleTwo'
import StylesDeliveryMethodsSummaryEditButtonServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/StylesDeliveryMethodsSummaryEditButtonServiceStyleOne'

const getDeliveryMethodsSummaryEditButtonTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryEditButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryEditButtonServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryEditButtonServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryEditButtonTemplateService
