import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded'
import useDialog from '~/src/hooks/useDialog'
import ProfileQuickAuthDialog from '~/src/components/profile/quick-auth/dialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import { Avatar, AvatarProps, Badge, badgeClasses, BadgeProps, styled } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'

const StyledBadge = styled(Badge)(() => ({
  '&:hover': {
    cursor: 'pointer'
  },
  [`.${badgeClasses.badge}`]: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    borderWidth: 2,
    borderStyle: 'solid',
    svg: {
      width: 14,
      height: 14
    }
  }
}))

interface Props {
  BadgeProps?: BadgeProps,
  AvatarProps?: AvatarProps,
}

const ShowQrCodeButton = ({ BadgeProps, AvatarProps }: Props) => {
  const customer = useEoValue(CUSTOMER_STATE)
  const { present } = useDialog()

  const _handleClick = () => {
    present(ProfileQuickAuthDialog, DialogNameEnum.PROFILE_QUICK_AUTH)
  }

  if (!customer?.first_name) {
    return null
  }

  return (
    <StyledBadge
      onClick={_handleClick}
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={<QrCodeScannerRoundedIcon/>}
      {...BadgeProps}
    >
      <Avatar {...AvatarProps}>
        {customer?.first_name?.substring(0, 2)}
      </Avatar>
    </StyledBadge>
  )
}

export default ShowQrCodeButton
