import { DeliveryMethodNames, OrderOnSpotCustomMethod } from '@eo-storefronts/eo-core'
import { Box, inputBaseClasses, outlinedInputClasses, TextField, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CheckoutFormState, CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const EatInMethodSelector = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const muiTheme = useCustomMuiTheme()
  const { t } = useTranslations()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        table_method: event.target.value !== '' ? Number(event.target.value) : null,
        table_number: null
      }
    }))
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{t('delivery_methods.eat_in_select')}</Typography>
      <TextField
        select
        size='small'
        SelectProps={{
          native: true
        }}
        variant='outlined'
        onChange={handleOnChange}
        value={checkoutForm.delivery_method.table_method || ''}
        sx={{
          [`.${inputBaseClasses.root},.${outlinedInputClasses.root}`]: {
            '--eo-input-base-color': (muiTheme.palette as any).container.contrastText,

            '&:hover': {
              fieldset: {
                borderColor: 'var(--eo-input-base-color)'
              }
            },
            '> *': {
              color: 'var(--eo-input-base-color)'
            },
            fieldset: {
              borderColor: 'var(--eo-input-base-color)'
            }
          }
        }}
      >
        <option value=''>{firm?.labels?.at_table}</option>
        {
          firm?.settings.delivery_methods[DeliveryMethodNames.ON_THE_SPOT]?.custom_methods?.map((method: OrderOnSpotCustomMethod) => (
            <option
              key={method.id}
              value={method.id}
            >
              {method.name}
            </option>
          ))
        }
      </TextField>
    </Box>
  )
}

export default EatInMethodSelector
