import Decimal from 'decimal.js-light'

export default class NumberUtils {
  public static round(value: number, toDecimal: number): number {
    return new Decimal(value).toDecimalPlaces(toDecimal).toNumber()
  }

  public static toFixed(value: number, toDecimal: number): string {
    return new Decimal(value).toFixed(toDecimal)
  }

  public static getPercentageValue(value: number, percent: number): number {
    return parseFloat(new Decimal(value).mul(percent).dividedBy(100).toFixed(2))
  }
}
