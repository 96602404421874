import { DeliveryMethodNames, DeliveryMethods, Firm } from '@eo-storefronts/eo-core'

export default class DeliveryMethodService {
  private readonly _firm: Firm | null

  public constructor(firm: Firm | null) {
    this._firm = firm
  }

  public isMethodEnabled(method: DeliveryMethodNames): boolean {
    return Boolean(this._firm?.settings.delivery_methods && Object.keys(this._firm.settings.delivery_methods).indexOf(method) !== -1)
  }

  public isMethodInactive(method: DeliveryMethodNames): boolean {
    if (!this.isMethodEnabled(method)) {
      return true
    }

    return Boolean(this._firm?.settings.delivery_methods[method as keyof DeliveryMethods]?.temporary_inactive)
  }
}
