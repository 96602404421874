import { Category, LanguageType } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import Image from '~/src/components/mui-wrappers/Image'

interface Props {
  category: Category,
  showCategoryImg: boolean,
}

const LocationSubHeaderItemLabel = ({ category, showCategoryImg }: Props) => {
  const lang = useEoValue<LanguageType | string>(LANGUAGE_STATE)

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      alignItems: 'center'
    }}>
      {showCategoryImg && (
        <Image
          alt={category.name[lang]!}
          src={category?.images[0]?.detail}
          sx={{
            height: '1.5em'
          }}
        />
      )}
      <Box component='span'>{category.name[lang]}</Box>
    </Box>
  )
}

export default LocationSubHeaderItemLabel
