import getNotFoundTemplateService from '~/src/pages/not-found/styles/GetNotFoundTemplateService'
import StylesNotFoundServiceInterface from '~/src/pages/not-found/styles/StylesNotFoundServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetNotFoundTemplate = (): StylesNotFoundServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  useLayout('notFound')

  return getNotFoundTemplateService(pageStyle, muiTheme)
}

export default useGetNotFoundTemplate
