import { listItemButtonClasses } from '@mui/material'
import StylesListItemButtonServiceInterface
  from '~/src/components/mui-wrappers/styled/list-item-button/styles/StylesListItemButtonServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesListItemButtonServiceStyles extends TemplateServiceStyleBase implements StylesListItemButtonServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      borderRadius: 'var(--btn-border-radius)',
      color: this.muiTheme.palette.secondary.contrastText,
      svg: {
        fill: this.muiTheme.palette.secondary.contrastText
      },
      [`&.${listItemButtonClasses.root}:hover`]: {
        backgroundColor: 'transparent',
        color: this.muiTheme.palette.primary.main,
        svg: {
          fill: this.muiTheme.palette.primary.main
        }
      },
      [`&.${listItemButtonClasses.selected}`]: {
        backgroundColor: 'transparent',
        color: this.muiTheme.palette.primary.main,
        svg: {
          fill: this.muiTheme.palette.primary.main
        }
      }
    }
  }
}
