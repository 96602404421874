import { Service } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import Image from '~/src/components/mui-wrappers/Image'

interface Props {
  service: Service,
}

const LocationTag = ({ service }: Props) => {
  return (
    <Box
      component='span'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 0.5,
        py: 0.2,
        img: {
          height: '1.25rem'
        }
      }}
    >
      <Image
        src={service.icon}
        alt={`image-${service.name}`}
        title={service.name}
      />
    </Box>
  )
}

export default LocationTag
