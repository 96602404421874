import { useEffect, useState } from 'react'
import { useRouterQuery } from '~/src/hooks/router/useRouterQuery'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum from '~/src/router/enums/routes.enum'

interface ReturnsType {
  params: keyof typeof RoutesEnum | string | null,
}

const useGetQueryParam = (queryParam: Params): ReturnsType => {
  const { params: queryParams } = useRouterQuery()
  const [ params, setParams ] = useState<keyof typeof RoutesEnum | string | null>(null)

  useEffect(() => {
    if (!queryParams[queryParam]) {
      setParams(null)
    }

    setParams(queryParams[queryParam] as keyof typeof RoutesEnum | string)
  }, [ queryParams ])


  return { params }
}

export default useGetQueryParam
