import { ReactNode } from 'react'
import useCheckTokenExpiration from '~/src/hooks/auth/useCheckTokenExpiration'

interface Props {
  children: ReactNode,
}

const AuthProvider = ({ children }: Props) => {
  useCheckTokenExpiration()

  return (
    <>{children}</>
  )
}

export default AuthProvider
