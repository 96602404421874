import { useSetEoState } from '~/src/hooks/useEoState'
import { checkoutFormInitialState, CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'

interface ReturnsType {
  resetStates(resetCheckout?: boolean): void,
}

const useResetStates = (): ReturnsType => {
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)

  const resetStates = (resetCheckout = true): void => {
    localStorage.removeItem('checkoutForm')
    resetCheckout && setCheckoutForm((state: CheckoutFormState) => {
      const { delivery_method: { method } } = state

      return {
        ...checkoutFormInitialState(),
        delivery_method: {
          ...checkoutFormInitialState().delivery_method,
          method
        }
      }
    })
    setTimeslots({})
    setIntervals({})
  }

  return { resetStates }
}

export default useResetStates
