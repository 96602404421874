import { Paper, PaperProps } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const Card = ({ sx, children, ...otherProps }: PaperProps) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Paper
      sx={{
        p: 2,
        backgroundImage: 'unset',
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        ...sx
      }}
      elevation={muiTheme.elevation}
      {...otherProps}
    >
      {children}
    </Paper>
  )
}

export default Card
