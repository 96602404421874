import { FormikProps } from 'formik'
import { ThemeColorsFormInterface } from '~/src/components/layout/dev-mode/dialog'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import { Stack } from '@mui/material'

interface Props {
  form: FormikProps<ThemeColorsFormInterface>,
}

const ThemeColors = ({ form }: Props) => {
  return (
    <Stack
      direction='row'
      gap={2}
      flexWrap='wrap'
      sx={{
        '& > *': {
          flex: '1 1 100px'
        }
      }}>
      <FormikTextField
        TextFieldProps={{
          fullWidth: true,
          form,
          color: 'content',
          name: 'theme_color',
          label: 'Theme main color:',
          type: 'color',
          size: 'small',
          helperText: form.values.theme_color
        }}
      />
      <FormikTextField
        TextFieldProps={{
          form,
          color: 'content',
          name: 'theme_color_contrast_color',
          label: 'Theme main color contrast:',
          type: 'color',
          size: 'small',
          helperText: form.values.theme_color_contrast_color
        }}
      />
    </Stack>
  )
}

export default ThemeColors
