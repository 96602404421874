import ShoppingCartCouponDiscount from '~/src/components/cart/shopping-cart-coupon-discount'
import ShoppingCartLoyaltyDiscount from '~/src/components/cart/shopping-cart-loyalty-discount'

const ShoppingCartDiscounts = () => (
  <>
    <ShoppingCartLoyaltyDiscount />
    <ShoppingCartCouponDiscount />
  </>
)

export default ShoppingCartDiscounts
