import {
  ChildFirm,
  DateUtils,
  DeliveryMethodNames,
  Firm,
  HolidayPeriod,
  OpeningHour,
  Period,
  PickupHour,
  Timeslot
} from '@eo-storefronts/eo-core'

const isOpenAM = (period: OpeningHour): boolean => {
  if (!period?.am?.type) {
    return false
  }

  return period.am.type === 'open'
}

const isOpenPM = (period: OpeningHour): boolean => {
  if (!period?.pm?.type) {
    return false
  }

  return period.pm.type === 'open'
}

const isClosed = (period: OpeningHour): boolean => {
  return !isOpenAM(period) && !isOpenPM(period)
}

const buildOpeningHoursString = (period: OpeningHour): string => {
  if (isClosed(period)) {
    return 'closed'
  }

  if (isOpenAM(period) && !isOpenPM(period)) { // only open AM
    return `${period?.am?.from_time} - ${period?.am?.to_time}`
  }

  if (!isOpenAM(period) && isOpenPM(period)) { // only open PM
    return `${period?.pm?.from_time} - ${period?.pm?.to_time}`
  }

  return `${period?.am?.from_time} - ${period?.am?.to_time} & ${period?.pm?.from_time} - ${period?.pm?.to_time}`
}

const isBeforeAmOpeningHours = (date: Date, period: OpeningHour): boolean => {
  if (!isOpenAM(period)) {
    return false
  }

  const [ fromHour, fromMinute ] = period.am!.from_time.split(':')
  const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))

  return date.getTime() < fromTime
}

const isBetweenAmOpeningHours = (date: Date, period: OpeningHour): boolean => {
  if (!isOpenAM(period)) {
    return false
  }

  const [ fromHour, fromMinute ] = period.am!.from_time.split(':')
  const [ toHour, toMinute ] = period.am!.to_time.split(':')

  const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))
  const toTime = (new Date()).setHours(Number(toHour), Number(toMinute))

  return date.getTime() >= fromTime && date.getTime() < toTime
}

const isBeforePmOpeningHours = (date: Date, period: OpeningHour): boolean => {
  if (!isOpenPM(period)) {
    return false
  }

  const [ fromHour, fromMinute ] = period.pm!.from_time.split(':')
  const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))

  return date.getTime() < fromTime
}

const isBetweenPmOpeningHours = (date: Date, period: OpeningHour): boolean => {
  if (!isOpenPM(period)) {
    return false
  }

  const [ fromHour, fromMinute ] = period.pm!.from_time.split(':')
  const [ toHour, toMinute ] = period.pm!.to_time.split(':')

  const fromTime = (new Date()).setHours(Number(fromHour), Number(fromMinute))
  const toTime = (new Date()).setHours(Number(toHour), Number(toMinute))

  return date.getTime() >= fromTime && date.getTime() < toTime
}

const isOnHoliday = (date: Date, holidayPeriods: HolidayPeriod[]): boolean => {
  if (!holidayPeriods?.length) {
    return false
  }

  for (const period of holidayPeriods) {
    const start = (new Date(period.from_date)).getTime()
    const end = (new Date(period.to_date)).getTime()
    const time = date.getTime()

    if (start === end && DateUtils.isSameDate(new Date(period.from_date), date)) {
      return true
    }

    if (time >= start && time < end) {
      return true
    }
  }

  return false
}

const isOffline = (firm: Firm): boolean => {
  return firm.settings.is_offline
}


const isOpened = (openingHours: OpeningHour[], date: Date = new Date()): boolean => {
  const todayId = DateUtils.getDayIdLikeMomentJs(date)

  const todayPeriod = openingHours.find(
    (openingHour: OpeningHour) => openingHour.day_id === todayId
  )

  if (!todayPeriod) {
    return false
  }

  return isBetweenAmOpeningHours(date, todayPeriod) || isBetweenPmOpeningHours(date, todayPeriod)
}

const isOperationallyOpened = (operationalHours: OpeningHour[], date: Date = new Date()): boolean => {
  const todayId = DateUtils.getDayIdLikeMomentJs(date)

  const todayPeriod = operationalHours.find(
    (openingHour: OpeningHour) => openingHour.day_id === todayId
  )

  if (!todayPeriod) {
    return false
  }

  return isBetweenAmOpeningHours(date, todayPeriod) || isBetweenPmOpeningHours(date, todayPeriod)
}

const isTimeBetweenOperationalTimeslot = (timeslots: Timeslot[], date: Date = new Date()): boolean => {
  const todayId = DateUtils.getDayIdLikeMomentJs(date)

  const todayTimeslots = timeslots.filter(
    (timeslot: Timeslot) => timeslot.day_id === todayId
  )

  if (!todayTimeslots.length) {
    return false
  }

  for (const todayTimeslot of todayTimeslots) {
    if (
      isBetweenAmOpeningHours(
        date,
        {
          day: '',
          day_id: todayId,
          am: {
            type: 'open',
            from_time: todayTimeslot.from_time,
            to_time: todayTimeslot.to_time
          }
        }
      )
    ) {
      return true
    }
  }

  return false
}

const canOrderOnDayIdForPeriod = (periods: PickupHour[], dayId: number): boolean => {
  const period = periods.find((p: PickupHour) => p.day_id === dayId)

  if (!period) {
    return false
  }

  return !isClosed(period)
}

const canOrderOnDayForTimeslots = (timeslotsDays: number[], dayId: number): boolean => {
  const day: number | undefined = timeslotsDays.find((t: number) => t === dayId)

  return Boolean(day)
}

const getFirstAvailablePeriodHourForDayId = (firm: Firm, dayId: number, deliveryMethod?: DeliveryMethodNames): string => {
  let periodName = 'opening_hours'

  if (deliveryMethod) {
    periodName = `${deliveryMethod}_hours`
  }

  const period = ((firm.settings.periods[periodName as keyof Period] || []) as Array<OpeningHour|PickupHour>).find(
    (p: OpeningHour|PickupHour) => p.day_id === dayId
  )

  if (!period) {
    return ''
  }

  if (isOpenAM(period)) {
    return period.am!.from_time
  }

  return period.pm!.from_time
}

const getFirstAvailableTimeslotHourForDayId = (firm: Firm, dayId: number, deliveryMethod: DeliveryMethodNames, todayTimeslots: Timeslot[]): string => {
  if (deliveryMethod === DeliveryMethodNames.ON_THE_SPOT) {
    return ''
  }

  const todayDayId = DateUtils.getDayIdLikeMomentJs(new Date())
  const now = new Date()

  let timeslots = firm.settings.delivery_methods[deliveryMethod]?.order_timeslots.timeslots.filter(
    (t: Timeslot) => t.day_id === dayId
  ) || []

  if (dayId === todayDayId) {
    timeslots = todayTimeslots
  }

  for (const timeslot of timeslots) {
    if (
      timeslot.day_id === todayDayId
      && parseInt(timeslot.to_time.replace(':', '')) < parseInt(`${now.getHours()}${now.getMinutes()}`)
    ) {
      continue
    }

    return timeslot.from_time
  }

  return ''
}

export {
  isOpenAM,
  isOpenPM,
  isClosed,
  buildOpeningHoursString,
  isBeforeAmOpeningHours,
  isBetweenAmOpeningHours,
  isBeforePmOpeningHours,
  isBetweenPmOpeningHours,
  isOnHoliday,
  isOffline,
  isOpened,
  isOperationallyOpened,
  isTimeBetweenOperationalTimeslot,
  canOrderOnDayIdForPeriod,
  canOrderOnDayForTimeslots,
  getFirstAvailablePeriodHourForDayId,
  getFirstAvailableTimeslotHourForDayId
}
