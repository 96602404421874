import { EoInject, EoInjectable, Firm, Id, IndexDBService, Language, LanguageType } from '@eo-storefronts/eo-core'
import { Device } from '@capacitor/device'
import locales from '~/src/i18n'

@EoInjectable()
export default class LanguageResolver {
  private _language: LanguageType | string = 'en'

  constructor(
    @EoInject(IndexDBService) private readonly indexDBService: IndexDBService
  ) { }

  get supportedLanguages(): LanguageType[] {
    return Object.keys(locales) as LanguageType[]
  }

  public async resolve(firm: Firm | null | undefined, lang?: LanguageType): Promise<void> {
    if (!firm) {
      return
    }

    if (lang && this.isValidLang(firm, lang)) {
      await this.setLang(firm.id, lang)
      return
    }

    const languages = await this.indexDBService.get<Record<string, LanguageType>>('languages')
    const deviceLanguage = await Device.getLanguageCode()

    if (languages && languages[firm.id]) {
      this._language = languages[firm.id]
      return
    }

    if (firm.languages.find((lang: Language) => lang.code === deviceLanguage as unknown as LanguageType)) {
      await this.setLang(firm.id, deviceLanguage as unknown as LanguageType)
      return
    }

    await this.setLang(firm.id, firm.default_language)
  }

  private isValidLang(firm: Firm | null, lang?: string): boolean {
    return !!firm?.languages.find((l: Language) => l.code === lang)
  }

  public getLang(): LanguageType | string {
    return this._language
  }

  private async setLang(firmId: Id, lang: LanguageType): Promise<void> {
    let languages = await this.indexDBService.get<Record<string, LanguageType>>('languages')
    languages = languages || {}

    languages[firmId] = lang

    await this.indexDBService.set('languages', languages)

    this._language = lang
  }
}
