import LayoutProductServiceStyleOne
  from '~/src/pages/category/[slug]/product/[slug]/layout/LayoutProductServiceStyleOne'
import LayoutProductServiceStyleTwo
  from '~/src/pages/category/[slug]/product/[slug]/layout/LayoutProductServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getProductLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutProductServiceStyleTwo().getLayout()
    default:
      return new LayoutProductServiceStyleOne().getLayout()
  }
}

export default getProductLayout
