import { BottomNavigation, BottomNavigationActionProps } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import MobileNavigationItem from '~/src/components/layout/mobile-navigation/MobileNavigationItem'
import useGetMobileNavigationTemplate from '~/src/components/layout/mobile-navigation/styles/useGetMobileNavigationTemplate'
import useGetActualRoute from '~/src/hooks/router/useGetActualRoute'
import { useEoValue } from '~/src/hooks/useEoState'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'
import CustomNavItemValues from '~/src/types/CustomNavItemValues'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import useGetNavigationItems from '~/src/hooks/router/navigation/useGetNavigationItems'

const MobileNavigation = () => {
  const styles = useGetMobileNavigationTemplate()
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const actualRoute = useGetActualRoute()
  const navItems: Array<BottomNavigationActionProps | CustomNavItemProps> = useGetNavigationItems('bottomNav')
  const [ selected, setSelected ] = useState<CustomNavItemValues | null>(null)

  const handleChange = (_event: SyntheticEvent, value: CustomNavItemValues) => {
    if (value === 'cart' || value === 'menu') {
      return
    }

    setSelected(value)
  }

  useEffect(() => {
    if (!actualRoute && isOnMarketplacePage) {
      return setSelected('explore')
    }

    setSelected(actualRoute)
  }, [ actualRoute ])

  return (
    <BottomNavigation
      value={selected}
      onChange={handleChange}
      sx={styles.getBottomNavigationSx(navItems.length)}
    >
      {navItems.map((itemProps: BottomNavigationActionProps | CustomNavItemProps, index: number) => (
        <MobileNavigationItem
          key={index}
          sx={styles.getBottomNavigationActionSx()}
          {...itemProps} />
      ))}
    </BottomNavigation>
  )
}

export default MobileNavigation
