import { createTheme, CssBaseline, Theme as MuiTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import useTheme from '~/src/hooks/layout/useTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { THEME_STATE } from '~/src/stores/theme'
import { BRAND_STATE } from '~/src/stores/brand'

interface Props {
  children: ReactNode,
}

const ThemeProvider = ({ children }: Props) => {
  const { set: registerTheme } = useTheme()
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const theme = useEoValue<any>(THEME_STATE)
  const [ muiTheme, setMuiTheme ] = useState<MuiTheme>(createTheme(theme))

  useEffect(() => {
    if (!firm || !brand) {
      return
    }

    void registerTheme(firm, brand)
  }, [ firm, brand ])

  useEffect(() => {
    setMuiTheme(createTheme(theme))
  }, [ theme ])

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
