import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import DeliveryMethodsSummaryMethodSentenceSplitter from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceSplitter'
import { useEatInLocationPickerDetails } from '~/src/hooks/delivery-methods/useEatInLocationPickerDetails'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface Props {
  showSplitter?: boolean,
}

const DeliveryMethodsSummaryMethodSentenceOnTheSpot = ({ showSplitter = true }: Props) => {
  const { delivery_method: { method, table_number } } = useEoValue(CHECKOUT_FORM_STATE)
  const { tableMethodLocationName } = useEatInLocationPickerDetails()

  if (method !== DeliveryMethodNames.ON_THE_SPOT || (table_number === null && !tableMethodLocationName)) {
    return null
  }

  return (
    <span>
      {showSplitter && <DeliveryMethodsSummaryMethodSentenceSplitter/>}
      {tableMethodLocationName && <>{tableMethodLocationName}</>}
      {tableMethodLocationName && table_number && <>&nbsp;</>}
      {table_number && <>{table_number}</>}
    </span>
  )
}

export default DeliveryMethodsSummaryMethodSentenceOnTheSpot
