import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const SubProductTitle = () => {
  const { t } = useTranslations()
  
  return (
    <Title
      variant='h5'
      sx={{
        color: 'background.contrastText'
      }}
    >
      {t('sub_products.title')}
    </Title>
  )
}

export default SubProductTitle
