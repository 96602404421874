import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import LayoutNewsDetailsServiceStyleTwo from '~/src/pages/news/[slug]/details/layout/LayoutNewsDetailsServiceStyleTwo'
import LayoutNewsDetailsServiceStyleThree
  from '~/src/pages/news/[slug]/details/layout/LayoutNewsDetailsServiceStyleThree'
import LayoutNewsDetailsServiceStyleOne from '~/src/pages/news/[slug]/details/layout/LayoutNewsDetailsServiceStyleOne'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getNewsDetailsLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutNewsDetailsServiceStyleTwo().getLayout(getLayoutArgs)
    case TemplateEnum.STYLE_THREE:
      return new LayoutNewsDetailsServiceStyleThree().getLayout(getLayoutArgs)
    default:
      return new LayoutNewsDetailsServiceStyleOne().getLayout(getLayoutArgs)
  }
}

export default getNewsDetailsLayout
