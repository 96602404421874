import { collapseClasses, SxProps } from '@mui/material'
import StylesShoppingCartServiceInterface
  from '~/src/components/cart/shopping-cart/styles/StylesShoppingCartServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesShoppingCartServiceStyles extends TemplateServiceStyleBase implements StylesShoppingCartServiceInterface {
  public getContainerSxProps(): SxProps {
    return {
      display: 'grid',
      gridTemplateAreas: '"delivery-methods-resume" "shopping-cart-items" "shopping-cart-info"',
      gridTemplateRows: 'auto 1fr auto',
      gap: 1,
      overflowY: 'auto',
      position: 'relative'
    }
  }

  public getDividerSxProps(): SxProps {
    return {
      my: '0 !important'
    }
  }

  public getDeliveryMethodsContainerSxProps(): SxProps {
    return {
      gridArea: 'delivery-methods-resume',
      px: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        p: 0,
        '> div.MuiBox-root': {
          pt: 0
        }
      }
    }
  }

  public getShoppingCartItemsContainerSxProps(): SxProps {
    return {
      px: 2,
      gridArea: 'shopping-cart-items',
      overflowY: 'auto',
      [this.muiTheme.breakpoints.down('md')]: {
        px: 0,
        [`.${collapseClasses.root}`]: {
          ml: 0
        }
      }
    }
  }

  public getShoppingCartInfoSxProps(): SxProps {
    return {
      gridArea: 'shopping-cart-info',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      backgroundColor: 'inherit',
      color: 'inherit',
      p: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        px: 0,
        py: 1
      }
    }
  }
}
