import CartItemModifierGroup from '~/src/components/cart/CartItemModifierGroup'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'
import { CartProduct } from '~/src/types/CartProduct'

interface Props {
  cartProduct: CartProduct,
  displayTitles?: boolean,
  displayMinMax?: boolean,
}

const CartItemModifierGroups = ({ cartProduct, displayTitles = true, displayMinMax = false }: Props) => (
  <>
    { cartProduct.modifierGroups
      .map(
        (value: CartModifierGroupStateInterface, index: number) => (
          <CartItemModifierGroup
            key={index}
            element={value}
            displayTitle={displayTitles}
            displayMinMax={displayMinMax}
          />
        )
      ) }
  </>
)

export default CartItemModifierGroups
