import getShoppingCartItemTemplateService
  from '~/src/components/cart/shopping-cart-item/styles/GetShoppingCartItemTemplateService'
import StylesShoppingCartItemServiceInterface
  from '~/src/components/cart/shopping-cart-item/styles/StylesShoppingCartItemServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartItemTemplate = (): StylesShoppingCartItemServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartItemTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartItemTemplate
