import { Product } from '@eo-storefronts/eo-core'
import { useMemo } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_ITEM_WITH_PRODUCT_SELECTOR, CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { convertProductUnitKgInGr } from '~/src/services/ProductService'
import { CartProduct } from '~/src/types/CartProduct'

const useProductMaxReached = (product: Product): boolean => {
  const cart = useEoValue<CartProduct[]>(CART_OF_FIRM_SELECTOR)
  const cartProduct = useEoValue<CartProduct | undefined>(CART_ITEM_WITH_PRODUCT_SELECTOR(product.id))
  const { max } = convertProductUnitKgInGr(product)

  return useMemo(() => {
    return cartProduct && max && cartProduct.quantity >= max
  }, [ cart ])
}

export default useProductMaxReached
