import { createContext } from 'react'

interface SideDrawerProps {
  open: boolean,
  onToggle(): void,
  onOpen(): void,
  onClose(): void,
}

const SideDrawerContext = createContext<SideDrawerProps>({
  open: false,
  onToggle: () => {
    throw new Error('SideDrawerContext.onToggle not implemented')
  },
  onOpen: () => {
    throw new Error('SideDrawerContext.onOpen not implemented')
  },
  onClose: () => {
    throw new Error('SideDrawerContext.onClose not implemented')
  }
})

export default SideDrawerContext

export type { SideDrawerProps }
