import { Product } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkProductAvailability } from '~/src/services/ProductService'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

interface State {
  inStock: boolean,
  snoozed: boolean,
}

interface ReturnsType {
  state: State,
}

const useProductAvailableState = (product: Product): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const [ state, setState ] = useState<State>({
    inStock: true,
    snoozed: false
  })

  useEffect(() => {
    const time: Date = checkoutForm.delivery_method.time ? new Date(checkoutForm.delivery_method.time) : new Date()
    setState(checkProductAvailability(product, time))
  }, [ checkoutForm.delivery_method.time ])

  return { state }
}

export {
  useProductAvailableState
}
