import { Alert, Divider, Stack, styled } from '@mui/material'
import { FormikProps } from 'formik'
import { ThemeColorsFormInterface } from '~/src/components/layout/dev-mode/dialog/index'
import MainColors from '~/src/components/layout/dev-mode/dialog/fields/MainColors'
import ThemeColors from '~/src/components/layout/dev-mode/dialog/fields/ThemeColors'
import MainGradientColors from '~/src/components/layout/dev-mode/dialog/fields/MainGradientColors'
import ContainerColors from '~/src/components/layout/dev-mode/dialog/fields/ContainerColors'
import ContentColors from '~/src/components/layout/dev-mode/dialog/fields/ContentColors'
import TabColors from '~/src/components/layout/dev-mode/dialog/fields/TabColors'
import SuccessColors from '~/src/components/layout/dev-mode/dialog/fields/SuccessColors'
import WellColors from '~/src/components/layout/dev-mode/dialog/fields/WellColors'

const SpacedDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}))

interface Props {
  form: FormikProps<ThemeColorsFormInterface>,
}

const DevModeDialogContent = ({ form }: Props) => {
  return (
    <Stack gap={1}>
      <Alert color='warning'>The changes made there won't be save into the dashboard, this is only used to preview your future changes</Alert>

      <SpacedDivider />

      <MainColors form={form} />
      <ThemeColors form={form} />

      <SpacedDivider>Advanced colors</SpacedDivider>

      <MainGradientColors form={form} />
      <ContainerColors form={form} />
      <ContentColors form={form} />
      <TabColors form={form} />
      <SuccessColors form={form} />
      <WellColors form={form} />
    </Stack>
  )
}

export default DevModeDialogContent
