import LayoutActivationServiceStyleThree from '~/src/pages/auth/activation/layout/LayoutActivationServiceStyleThree'
import LayoutActivationServiceStyleTwo from '~/src/pages/auth/activation/layout/LayoutActivationServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getActivationLayout = (style: TemplateEnum, args: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutActivationServiceStyleTwo().getLayout(args)
    case TemplateEnum.STYLE_THREE:
      return new LayoutActivationServiceStyleThree().getLayout()
    default:
      return new LayoutActivationServiceStyleTwo().getLayout(args)
  }
}

export default getActivationLayout
