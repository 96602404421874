import { tabClasses } from '@mui/material'
import StylesTabServiceInterface from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabServiceStyleThree extends TemplateServiceStyleBase implements StylesTabServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      maxWidth: '100%',
      transition: 'var(--ease-in-out)',
      borderRadius: '12px',
      fontWeight: 500,
      fontSize: '1em',
      textTransform: 'none',
      marginLeft: this.muiTheme.spacing(0.5),
      marginRight: this.muiTheme.spacing(0.5),
      minHeight: 0,
      backgroundColor: this.muiTheme.palette.getContrastText(this.muiTheme.palette.text.primary),
      color: this.muiTheme.palette.text.primary,
      [`&.${tabClasses.selected}`]: {
        color: this.muiTheme.palette.getContrastText(this.muiTheme.palette.text.primary),
        backgroundColor: 'transparent'
      },
      [`&:not(.${tabClasses.selected}):hover`]: {
        opacity: 0.65,
        backgroundColor: this.muiTheme.palette.primary.main,
        color: this.muiTheme.palette.getContrastText(this.muiTheme.palette.text.primary)
      }    }
  }
}
