import { bottomNavigationActionClasses, SxProps } from '@mui/material'
import StylesMobileNavigationServiceInterface
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMobileNavigationServiceStyleTwo extends TemplateServiceStyleBase implements StylesMobileNavigationServiceInterface {
  public getBottomNavigationSx(itemsNumber?: number): SxProps {
    return {
      zIndex: 3,
      backgroundColor: (this.muiTheme.palette.background as any).main,
      color: (this.muiTheme.palette.background as any).contrastText,
      ['@supports ((-webkit-backdrop-filter: blur(15px) grayscale(100%)) or (backdrop-filter: blur(15px) grayscale(100%)))']: {
        backgroundColor: `${(this.muiTheme.palette.background as any).main}a8`,
        backdropFilter: 'blur(15px) grayscale(100%)',
        webkitBackdropFilter: 'blur(15px) grayscale(100%)'
      },
      [this.muiTheme.breakpoints.up('sm')]: {
        '--item-size': '80px',
        bottom: this.muiTheme.spacing(2),
        boxShadow: this.muiTheme.shadows[2],
        borderRadius: '20px',
        margin: 'auto',
        width: `calc(var(--item-size) * ${itemsNumber})`,
        '& > *': {
          width: 'var(--item-size)'
        },
        'button:first-of-type': {
          borderTopLeftRadius: 'inherit',
          borderBottomLeftRadius: 'inherit'
        },
        'button:last-of-type': {
          borderTopRightRadius: 'inherit',
          borderBottomRightRadius: 'inherit'
        }
      },
      [this.muiTheme.breakpoints.down('sm')]: {
        boxShadow: 0,
        borderRadius: 0,
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
        height: 'calc(56px + var(--eo-safe-area-bottom))',
        paddingBottom: 'var(--eo-safe-area-bottom)',
        '> *': {
          flex: '1 1 0',
          minWidth: 'unset',
          maxWidth: 'unset',
          button: {
            minWidth: 'inherit',
            maxWidth: 'inherit'
          }
        },
        '> button': {
          padding: '0'
        }
      }
    }
  }

  public getBottomNavigationActionSx(): SxProps {
    return {
      paddingTop: '0 !important',
      [`.${bottomNavigationActionClasses.label}`]: {
        display: 'none'
      }
    }
  }
}
