import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import EnvUtils from '~/src/utils/EnvUtils'

interface ReturnType {
  loginWithGoogle(): Promise<User>,
}

const useSignInWithGoogle = (): ReturnType => {
  const _initialize = () => {
    GoogleAuth.initialize({
      clientId: EnvUtils.googleSignInClientIdWeb,
      scopes: [ 'profile', 'email' ],
      grantOfflineAccess: true
    })
  }

  useEffect(() => {
    if (PlatformUtils().isCapacitorNative) {
      return
    }

    _initialize()
  }, [])

  const loginWithGoogle = async (): Promise<User> => {
    return GoogleAuth.signIn()
  }

  return {
    loginWithGoogle
  }
}

export default useSignInWithGoogle
