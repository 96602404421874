import { Id } from '@eo-storefronts/eo-core'
import useUpdateCustomerPushNotifications from '~/src/hooks/customer/useUpdateCustomerPushNotifications'
import { useEoValue } from '~/src/hooks/useEoState'
import { saveDeviceToken } from '~/src/services/CustomerService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  saveDeviceToken(token: string, customerId?: Id): Promise<void | undefined>,
}

const useSaveDeviceToken = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)
  const currentFirm = useEoValue(FIRM_SELECTOR)
  const authState = useEoValue(AUTHENTICATION_STATE)
  const { updatePushNotification } = useUpdateCustomerPushNotifications()

  const save = async (token: string, customerId?: Id): Promise<void | undefined> => {
    const id: Id | undefined = customerId ?? authState?.customer?.id

    if (!id || !firm || !currentFirm) {
      return
    }

    saveDeviceToken(id, firm, currentFirm.id, token)
      .then(() => {
        updatePushNotification(true)
      })
  }

  return { saveDeviceToken: save }
}

export default useSaveDeviceToken
