import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryMethodButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/StylesDeliveryMethodsSummaryMethodButtonServiceInterface'
import StylesDeliveryMethodsSummaryMethodButtonServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/StylesDeliveryMethodsSummaryMethodButtonServiceStyleTwo'
import StylesDeliveryMethodsSummaryMethodButtonServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button/styles/StylesDeliveryMethodsSummaryMethodButtonServiceStyleOne'

const getDeliveryMethodsSummaryMethodButtonTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryMethodButtonServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryMethodButtonServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryMethodButtonServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryMethodButtonTemplateService
