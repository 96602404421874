import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box, IconButton, Typography } from '@mui/material'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_COUPON_DISCOUNT_PRICE_SELECTOR, CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { CartProduct } from '~/src/types/CartProduct'
import { useUpdateCartProducts } from '~/src/hooks/cart/useUpdateCartProducts'
import { useRemoveProductOfCart } from '~/src/hooks/cart/useRemoveProductOfCart'

const ShoppingCartCouponDiscount = () => {
  const { t } = useTranslations()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const { updateIsCoupon } = useUpdateCartProducts()
  const checkoutCouponDiscountPrice = useEoValue(CHECKOUT_COUPON_DISCOUNT_PRICE_SELECTOR)
  const { remove } = useRemoveProductOfCart()

  const handleOnClick = () => {
    const cartProduct = cart.find((cartProduct: CartProduct) => cartProduct.is_coupon)

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      coupon: null
    }))

    if (!cartProduct) {
      return
    }

    const sameCartProducts = cart.filter((cp: CartProduct) => cp.id === cartProduct?.id && cp.is_coupon !== cartProduct.is_coupon)

    if (!sameCartProducts.length) {
      updateIsCoupon(cartProduct, false)
    } else {
      remove(cartProduct)
    }
  }

  if (!checkoutCouponDiscountPrice) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <Typography>
        <IconButton
          size='small'
          onClick={handleOnClick}
        >
          <DeleteOutlinedIcon />
        </IconButton>
        {`${t('shopping_cart.coupon_discount')} (${checkoutForm.coupon?.code})`}
      </Typography>
      {checkoutForm.coupon?.type !== 'product' && (
        <Typography>
          {checkoutCouponDiscountPrice}
        </Typography>
      )}
    </Box>
  )
}

export default ShoppingCartCouponDiscount
