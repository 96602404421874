import {
  DialogActions,
  dialogClasses,
  DialogContent,
  dialogTitleClasses,
  drawerClasses,
  paperClasses,
  typographyClasses,
  useTheme
} from '@mui/material'
import { Suspense, SyntheticEvent, useEffect, useState } from 'react'
import DialogTitle from '~/src/components/mui-wrappers/DialogTitle'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import ResponsiveDialogWrapper from '~/src/components/mui-wrappers/responsive-dialog/wrappers/ResponsiveDialogWrapper'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { ResponsiveDialogEventType } from '~/src/types/ResponsiveDialogEventType'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'
import useDialog from '~/src/hooks/useDialog'
import { Constants } from '~/src/helpers/constants'
import { useLocation } from 'react-router-dom'

const ResponsiveDialog = ({
  Title,
  Actions,
  activator,
  modalType,
  children,
  onOpen,
  onClose,
  responsive = true,
  persistent = false,
  showCloseButton = true,
  openByDefault = false,
  contentSx = {},
  sx = {},
  name,
  ...otherProps
}: ResponsiveDialogProps) => {
  const theme = useTheme()
  const { dismiss } = useDialog()
  const { push } = useRouterPush()
  const { pathname } = useLocation()
  const [ state, setState ] = useState<boolean>(false)

  const _toggleOpen = ($event: ResponsiveDialogEventType = {} as ResponsiveDialogEventType) => {
    setState(true)

    onOpen && onOpen($event)
  }

  const _cleanUrl = () => {
    modalType && push(pathname, { modal: true })
  }

  const _removeListeners = () => {
    window.removeEventListener('popstate', handleOnPopState)
  }

  const _prepareClosing = (cleanUrl = true) => {
    setState(false)
    cleanUrl && _cleanUrl()
    _removeListeners()
  }

  const _toggleClose = ($event: SyntheticEvent<{}, Event> = {} as ResponsiveDialogEventType, replaceHistory = true) => {
    if (persistent) {
      return
    }

    _prepareClosing(replaceHistory)

    /**
     * Delaying DOM destruction to handle the close animation
     */
    setTimeout(() => {
      name && dismiss(name)
      onClose && onClose($event)
    }, Constants.DEBOUNCE_TIME)
  }

  const _toggle = () => {
    if (state) {
      onClose && onClose({} as ResponsiveDialogEventType)
    } else {
      onOpen && onOpen({} as ResponsiveDialogEventType)
    }

    setState(!state)
  }

  const handleOnPopState = ($event: PopStateEvent) => {
    $event.preventDefault()
    $event.stopPropagation()
    _toggleClose({} as SyntheticEvent, false)
  }

  useEffect(() => {
    setState(openByDefault)
  }, [ openByDefault ])

  useEffect(() => {
    if (!state) {
      return
    }

    window.addEventListener('popstate', handleOnPopState)

    if (modalType) {
      push(`${pathname}?modal=${modalType.toString()}`, { modal: true })
    }
  }, [ state ])

  return (
    <ResponsiveDialogContext.Provider
      value={{
        state,
        toggle: _toggle,
        toggleOpen: _toggleOpen,
        toggleClose: _toggleClose
      }}
    >
      {activator}

      <ResponsiveDialogWrapper
        open={state}
        onClose={_toggleClose}
        onOpen={_toggleOpen}
        responsive={responsive}
        {...otherProps}
        sx={{
          [`&.${drawerClasses.root}`]: {
            [`.${dialogTitleClasses.root}`]: {
              width: '100%',
              [`>h1, >h2, >h3, >h4, >h5, >h6, >span, >p, >.${typographyClasses.root}`]: {
                ml: showCloseButton ? `calc(1.2857142857142856rem + ${theme.spacing(2)})` : 0,
                width: '100%',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }
            }
          },
          [`&.${dialogClasses.root}`]: {
            [`.${dialogTitleClasses.root}`]: {
              px: 3,
              pr: 2
            }
          },
          [`.${dialogClasses.container} > .${paperClasses.root}`]: {
            pt: 'var(--eo-safe-area-top)',
            pb: 'var(--eo-safe-area-bottom)'
          },
          [`& > .${paperClasses.root}`]: {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText'
          },
          ...sx
        }}
      >
        {(Title || showCloseButton) && (
          <DialogTitle
            CloseButtonProps={(!persistent || !showCloseButton) ? {
              onClick: _toggleClose
            } : undefined}
            showCloseButton={showCloseButton}
            sx={{
              p: 1,
              py: 1.5
            }}
          >
            {Title}
          </DialogTitle>
        )}

        <DialogContent
          sx={{
            px: 2,
            ...contentSx
          }}
        >
          <Suspense>
            {children}
          </Suspense>
        </DialogContent>

        {Actions && (
          <DialogActions
            sx={{
              pt: 2,
              px: 3,
              pb: 3
            }}
          >
            {Actions}
          </DialogActions>
        )}
      </ResponsiveDialogWrapper>
    </ResponsiveDialogContext.Provider>
  )
}

export default ResponsiveDialog
