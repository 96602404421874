import { Id, OrderSummary } from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_AS_SELECTOR_FAMILY } from '~/src/stores/authentication'
import { indexDbPersist } from '~/src/helpers/recoilPersist'

const keyLastGuestOrderIdState = 'lastGuestOrderIdState'
const keyOrderDetailsState = 'orderDetailsState'
const keyPastOrdersState = 'pastOrdersState'
const keyPendingOrdersState = 'pendingOrdersState'

const sortByDate = (key: keyof Pick<OrderSummary, 'created' | 'process_timestamp'>) => (a: OrderSummary, b: OrderSummary) => {
  return new Date(b[key]).getTime() - new Date(a[key]).getTime()
}

export const LAST_GUEST_ORDER_ID_STATE = createState<Id | null>({
  key: keyLastGuestOrderIdState,
  default: null,
  effects: [ indexDbPersist(keyLastGuestOrderIdState) ]
})

export const ORDER_DETAILS_STATE = createState<OrderSummary | null>({
  key: keyOrderDetailsState,
  default: null
})

export const PAST_ORDERS_STATE = createState<OrderSummary[]>({
  key: keyPastOrdersState,
  default: []
})

export const PENDING_ORDERS_STATE = createState<OrderSummary[]>({
  key: keyPendingOrdersState,
  default: []
})

export const ALL_ORDERS_SELECTOR = createSelector<OrderSummary[]>({
  key: 'allOrdersSelector',
  get: ({ get }): OrderSummary[] => {
    return [
      ...get(PAST_ORDERS_STATE),
      ...get(PENDING_ORDERS_STATE)
    ]
  }
})

export const ORDERS_BY_DATE_SELECTOR = createSelectorFamily<OrderSummary[], boolean>({
  key: 'ordersByDateSelector',
  get: (includeNotPayedOrders: boolean) => ({ get }): OrderSummary[] => {
    return get(ALL_ORDERS_SELECTOR)
      .filter((order) => includeNotPayedOrders || order.paid)
      .sort(sortByDate('created'))
  }
})

export const MOST_RECENT_ORDER_SELECTOR = createSelector<OrderSummary | null | undefined>({
  key: 'mostRecentOrderSelector',
  get: ({ get }): OrderSummary | null | undefined => {
    const orderDetail: OrderSummary | null = get(ORDER_DETAILS_STATE)
    const isLoggedInAsGuest = get(IS_LOGGED_IN_AS_SELECTOR_FAMILY('guest'))

    if (isLoggedInAsGuest) {
      return orderDetail
    }

    const mostRecentOrder: OrderSummary | undefined = get(ORDERS_BY_DATE_SELECTOR(true))[0]

    return mostRecentOrder?.id === orderDetail?.id ? orderDetail : mostRecentOrder
  }
})
