import { Box } from '@mui/material'
import LocationCategories
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/location-categories'
import LocationTags
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/location-tags'
import LocationAddress
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/LocationAddress'
import LocationContact
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/LocationContact'
import LocationDescription
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/LocationDescription'
import LocationImage
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/LocationImage'
import LocationName
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/LocationName'
import LocationTimeslots
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/LocationTimeslots'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_LOCATION_SELECTED_STATE } from '~/src/stores/marketplace/locations'

interface Props {
  isLoading?: boolean,
}

const LocationResponsiveDialogContent = ({ isLoading = false }: Props) => {
  const location = useEoValue(MARKETPLACE_LOCATION_SELECTED_STATE)

  return (
    <Box className='location__card_content' sx={{ width: '100%' }}>
      <Box
        className='location__image_container'
        sx={{
          position: 'sticky',
          top: 0,
          px: 1
        }}
      >
        <LocationImage
          location={location}
          isLoading={isLoading}
        />
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          p: 1,
          px: 2
        }}
      >
        <LocationName
          location={location}
          isLoading={isLoading}
        />
        <LocationCategories
          location={location}
          isLoading={isLoading}
        />
        <LocationAddress
          location={location}
          isLoading={isLoading}
        />
        <LocationTags
          location={location}
          isLoading={isLoading}
        />
        <LocationContact
          location={location}
          isLoading={isLoading}
        />
        <LocationTimeslots
          location={location}
          isLoading={isLoading}
        />
        <LocationDescription
          location={location}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}

export default LocationResponsiveDialogContent
