import { Box } from '@mui/material'
import ShoppingCartItem from '~/src/components/cart/shopping-cart-item'
import useGetShoppingCartItemsTemplate
  from '~/src/components/cart/shopping-cart-items/styles/useGetShoppingCartItemsTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { CartProduct } from '~/src/types/CartProduct'

interface Props {
  showIngredientsAndOptions?: boolean,
  showQuantityAsText?: boolean,
  showQuantitySelector?: boolean,
  showPrice?: boolean,
  showDeleteButton?: boolean,
}

const ShoppingCartItems = ({
  showIngredientsAndOptions = false,
  showQuantityAsText = false,
  showDeleteButton = true,
  showPrice = true,
  showQuantitySelector = true
}: Props) => {
  const styles = useGetShoppingCartItemsTemplate()
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)

  const getNumberOfMaxContent = (): number => {
    return [ showPrice, showQuantitySelector, showDeleteButton ].filter((show: boolean) => show).length
  }

  return (
    <Box sx={styles.getContainerSxProps(showQuantityAsText, getNumberOfMaxContent())}>
      {cart.map((cartProduct: CartProduct, index: number) => (
        <ShoppingCartItem
          key={index}
          cartProduct={cartProduct}
          showDeleteButton={showDeleteButton}
          showPrice={showPrice}
          showQuantityAsText={showQuantityAsText}
          showQuantitySelector={showQuantitySelector}
          showIngredientsAndOptions={showIngredientsAndOptions}
        />
      ))}
    </Box>
  )
}

export default ShoppingCartItems
