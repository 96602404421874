import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

const AddCustomerAddressDialogButton = () => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <Button
      onClick={responsiveDialogContext.toggleOpen}
      startIcon={<AddIcon color='primary' />}
      sx={{
        textTransform: 'none',
        textAlign: 'left',
        color: 'background.contrastText'
      }}
    >
      {t('profile.address.add')}
    </Button>
  )
}

export default AddCustomerAddressDialogButton
