import { useTranslations } from '~/src/hooks/useTranslations'
import { Box, Typography } from '@mui/material'
import DiscountIcon from '@mui/icons-material/Discount'
import FirmLoyaltyRewardProgress from '~/src/components/profile/account/loyalty/reward-card/progress'
import FirmLoyaltyRewardPointBalance from '~/src/components/profile/account/loyalty/reward-card/point-balance'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_DISCOUNT_SELECTOR } from '~/src/stores/loyalty'

const FirmLoyaltyRewardDiscount = () => {
  const { t } = useTranslations()
  const loyaltyDiscount = useEoValue(LOYALTY_DISCOUNT_SELECTOR)

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'max-content auto',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '"icon title"' +
          '"icon progress"' +
          '"icon points"'
      }}
    >
      <Box
        sx={{
          gridArea: 'icon',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <DiscountIcon color='primary' sx={{ fontSize: '3rem' }} />
      </Box>
      <Typography
        sx={{
          gridArea: 'title',
          fontWeight: 'bold'
        }}
      >
        {t('profile.loyalty.discount_message', { discount: loyaltyDiscount })}
      </Typography>
      <Box sx={{ gridArea: 'progress' }}>
        <FirmLoyaltyRewardProgress />
      </Box>
      <FirmLoyaltyRewardPointBalance sx={{ gridArea: 'points' }} />
    </Box>
  )
}

export default FirmLoyaltyRewardDiscount
