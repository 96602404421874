import { createState } from '~/src/hooks/useEoState'
import { FormField } from '@eo-storefronts/eo-core'

const GUEST_FORM_FIELDS_STATE = createState<FormField[]>({
  key: 'GUEST_FORM_FIELDS_STATE',
  default: []
})

export {
  GUEST_FORM_FIELDS_STATE
}
