import StylesChipServiceInterface from '~/src/components/mui-wrappers/styled/chip/styles/StylesChipServiceInterface'
import StylesChipServiceStyleOne from '~/src/components/mui-wrappers/styled/chip/styles/StylesChipServiceStyleOne'
import StylesChipServiceStyleTwo from '~/src/components/mui-wrappers/styled/chip/styles/StylesChipServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getChipTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesChipServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesChipServiceStyleTwo(muiTheme)
    default:
      return new StylesChipServiceStyleOne(muiTheme)
  }
}

export default getChipTemplateService
