import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryPickupPointsServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/StylesDeliveryMethodsSummaryPickupPointsServiceInterface'
import getDeliveryMethodsSummaryPickupPointsTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-pickup-points/styles/GetDeliveryMethodsSummaryPickupPointsTemplateService'

const useGetDeliveryMethodsSummaryPickupPointsTemplate = (): StylesDeliveryMethodsSummaryPickupPointsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryPickupPointsTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryPickupPointsTemplate
