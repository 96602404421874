import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { BACK_BUTTON_STATE, BackButtonState } from '~/src/stores/back-button'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'

const FooterBackBtn = () => {
  const { t } = useTranslations()
  const backBtn = useEoValue<BackButtonState | null>(BACK_BUTTON_STATE)

  if (!backBtn) {
    return null
  }

  return (
    <ButtonLink
      linkProps={{
        href: backBtn.href,
        resolve: backBtn.resolve
      }}
      variant='outlined'
      displayIcon={false}
      sx={{
        fontSize: '1rem',
        fontWeight: 700
      }}
    >
      {t(backBtn.label ?? 'footer.cancel')}
    </ButtonLink>
  )
}

export default FooterBackBtn
