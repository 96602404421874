import { ServiceFee as ServiceFeeInterface } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { GET_ACTIVE_SERVICE_FEES_SELECTOR } from '~/src/stores/firm'
import ServiceFee from '~/src/components/fees/ServiceFee'

const ServiceFees = () => {
  const activeServiceFee = useEoValue(GET_ACTIVE_SERVICE_FEES_SELECTOR)

  if (!activeServiceFee) {
    return null
  }

  return (
    <>
      {activeServiceFee.map((serviceFee: ServiceFeeInterface) => (
        <ServiceFee
          key={serviceFee.name}
          fee={serviceFee}
        />
      ))}
    </>
  )
}

export default ServiceFees
