import {
  Alert,
  alertClasses,
  AlertColor,
  IconButton,
  Snackbar as MUISnackbar,
  SnackbarProps,
  styled
} from '@mui/material'
import { SharedProps, SnackbarMessage, useSnackbar } from 'notistack'
import useGetSnackbarTemplate from '~/src/components/mui-wrappers/styled/snackbar/styles/useGetSnackbarTemplate'
import CloseIcon from '@mui/icons-material/Close'

interface Props extends Omit<SnackbarProps, 'onClose' | 'severity'>, Pick<SharedProps, 'variant'> {
  message: SnackbarMessage,
}


const Snackbar = styled(({ message, variant, ...otherProps }: Props) => {
  const { closeSnackbar } = useSnackbar()

  const _handleOnClose = () => {
    closeSnackbar(otherProps.key)
  }

  if (variant === 'default') {
    return (
      <MUISnackbar
        open={true}
        autoHideDuration={6000}
        onClose={_handleOnClose}
        message={message}
        {...otherProps} />
    )
  }

  return (
    <Alert
      onClick={_handleOnClose}
      severity={variant as AlertColor}
      sx={{
        alignItems: 'center',
        alignContent: 'center',
        cursor: 'pointer',
        [`.${alertClasses.message}`]: {
          display: 'flex',
          gap: 0.5,
          justifyContent: 'center',
          alignItems: 'center'
        }
      }}
    >
      <span>{message}</span>
      <IconButton
        onClick={_handleOnClose}
        size='small'>
        <CloseIcon />
      </IconButton>
    </Alert>
  )
})(() => useGetSnackbarTemplate().getComponentSx())

export default Snackbar
