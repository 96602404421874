import MailIcon from '@mui/icons-material/Mail'
import { Box, Link } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

interface Props {
  showIcon?: boolean,
}

const BrandEmail = ({ showIcon = true }: Props) => {
  const brand = useEoValue(BRAND_STATE)

  if (!brand?.email) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1
      }}
    >
      {showIcon && <MailIcon />}

      <Link
        underline='hover'
        href={`mailto:${brand.email}`}
        color='inherit'
      >
        {brand.email}
      </Link>
    </Box>
  )
}

export default BrandEmail
