import { ReactNode } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  href: string,
  children: ReactNode,
}

const CustomOrderButton = ({ children, href }: Props) => {
  return (
    <a href={href} target="_blank">
      <Button
        fullWidth
        variant='contained'
        color='primary'
        sx={{
          textTransform: 'none'
        }}
      >
        {children}
      </Button>
    </a>
  )
}

export default CustomOrderButton
