import getListItemButtonTemplateService
  from '~/src/components/mui-wrappers/styled/list-item-button/styles/GetListItemButtonTemplateService'
import StylesListItemButtonServiceInterface
  from '~/src/components/mui-wrappers/styled/list-item-button/styles/StylesListItemButtonServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetListItemButtonTemplate = (): StylesListItemButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getListItemButtonTemplateService(pageStyle, muiTheme)
}

export default useGetListItemButtonTemplate
