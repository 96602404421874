import LayoutProfileServiceStyleThree
  from '~/src/components/profile/profile-layout/layout/LayoutProfileServiceStyleThree'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import LayoutProfileServiceStyleTwo from '~/src/components/profile/profile-layout/layout/LayoutProfileServiceStyleTwo'
import LayoutProfileServiceStyleOne from '~/src/components/profile/profile-layout/layout/LayoutProfileServiceStyleOne'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getProfileLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutProfileServiceStyleTwo().getLayout(getLayoutArgs)
    case TemplateEnum.STYLE_THREE:
      return new LayoutProfileServiceStyleThree().getLayout()
    default:
      return new LayoutProfileServiceStyleOne().getLayout(getLayoutArgs)
  }
}

export default getProfileLayout
