import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { Box, Link } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

interface Props {
  showIcon?: boolean,
}

const BrandPhone = ({ showIcon = true }: Props) => {
  const brand = useEoValue(BRAND_STATE)

  if (!brand?.phone) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1
      }}
    >
      {showIcon && <LocalPhoneIcon />}

      <Link
        underline='hover'
        href={`tel:${brand.phone}`}
        color='inherit'
      >
        {brand.phone}
      </Link>
    </Box>
  )
}

export default BrandPhone
