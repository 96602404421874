import getSnackbarTemplateService from '~/src/components/mui-wrappers/styled/snackbar/styles/GetSnackbarTemplateService'
import StylesSnackbarServiceInterface
  from '~/src/components/mui-wrappers/styled/snackbar/styles/StylesSnackbarServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetSnackbarTemplate = (): StylesSnackbarServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSnackbarTemplateService(pageStyle, muiTheme)
}

export default useGetSnackbarTemplate
