import { Location } from '@eo-storefronts/eo-core'
import CardMedia from '~/src/components/mui-wrappers/CardMedia'

interface Props {
  direction: 'horizontal' | 'vertical',
  location: Location,
}

const LocationCardMedia = ({ location, direction }: Props) => {
  return (
    <CardMedia
      src={location.image}
      alt={location.name}
      sx={{
        width: direction === 'vertical' ? '100%' : '120px',
        aspectRatio: direction === 'vertical' ? '16/12' : '1/1'
      }}
    />
  )
}

export default LocationCardMedia
