import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryAddressButtonServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/StylesDeliveryMethodsSummaryAddressButtonServiceInterface'
import getDeliveryMethodsSummaryAddressButtonTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/styles/GetDeliveryMethodsSummaryAddressButtonTemplateService'

const useGetDeliveryMethodsSummaryAddressButtonTemplate = (): StylesDeliveryMethodsSummaryAddressButtonServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryAddressButtonTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryAddressButtonTemplate
