import { SxProps } from '@mui/material'
import StylesMobileNavigationServiceInterface
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMobileNavigationServiceStyleOne extends TemplateServiceStyleBase implements StylesMobileNavigationServiceInterface {
  public getBottomNavigationSx(): SxProps {
    return {
      zIndex: 3,
      width: '100%'
    }
  }

  public getBottomNavigationActionSx(): SxProps {
    return {}
  }
}
