import LocationSubHeaderItemLabel
  from '~/src/components/layout/sub-header/location-sub-header/location-sub-header-item/LocationSubHeaderItemLabel'
import LinkTab from '~/src/components/mui-wrappers/styled/link-tab'
import { Category } from '@eo-storefronts/eo-core'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useEoValue } from '~/src/hooks/useEoState'
import { NAVIGATE_BY_CATEGORIES_SELECTOR } from '~/src/stores/theme'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { TabProps } from '@mui/material'

interface Props extends TabProps {
  category: Category,
}

const LocationSubHeaderTab = ({ category, sx, ...otherProps }: Props) => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const isCategoriesNavigation = useEoValue<boolean>(NAVIGATE_BY_CATEGORIES_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  const handleOnClick = () => {
    if (!isCategoriesNavigation) {
      push(resolve(`${RoutesEnum.MENU}#${encodeURIComponent(category.name[lang] || '')}`))
      document.getElementById(encodeURIComponent(category.name[lang] || ''))?.scrollIntoView()
    } else {
      push(resolve(`${RoutesEnum.CATEGORY}/${category.id}${RoutesEnum.PRODUCTS}`))
    }
  }

  return (
    <LinkTab
      label={
        <LocationSubHeaderItemLabel
          category={category}
          showCategoryImg={!!firmTheme?.flow?.show_category_navigation_img}
        />
      }
      onClick={handleOnClick}
      sx={{
        py: firmTheme?.flow?.show_category_navigation_img ? 1 : 1.25,
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default LocationSubHeaderTab
