import { ApiConfiguration } from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'
import { Device, DeviceInfo } from '@capacitor/device'

export default class ApiConfigUtils {
  public static async init(): Promise<void> {
    ApiConfiguration.setBaseUrl(EnvUtils.apiEndpoint)
    ApiConfiguration.setHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apiVersion: EnvUtils.apiVersion,
      appVersion: EnvUtils.appVersion,
      deviceModel: 'Web',
      deviceOS: 'Web',
      deviceOSVersion: 'Web'
    })


    try {
      const info: DeviceInfo = await Device.getInfo()

      info.model && ApiConfiguration.addOrReplaceHeader('deviceModel', info.model)
      info.platform && ApiConfiguration.addOrReplaceHeader('deviceOs', info.platform)
      info.osVersion && ApiConfiguration.addOrReplaceHeader('deviceOSVersion', info.osVersion)
    } catch {
      // Do not do anything
    }
  }

  public static setLanguage(value: string) {
    ApiConfiguration.addOrReplaceHeader('lang', value)
    ApiConfiguration.addOrReplaceHeader('language', value)
    ApiConfiguration.addOrReplaceHeader('Accept-language', value)
  }
}
