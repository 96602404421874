import StylesTabsServiceInterface from '~/src/components/mui-wrappers/styled/tabs/styles/StylesTabsServiceInterface'
import StylesTabsServiceStyleOne from '~/src/components/mui-wrappers/styled/tabs/styles/StylesTabsServiceStyleOne'
import StylesTabsServiceStyleThree from '~/src/components/mui-wrappers/styled/tabs/styles/StylesTabsServiceStyleThree'
import StylesTabsServiceStyleTwo from '~/src/components/mui-wrappers/styled/tabs/styles/StylesTabsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTabsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTabsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTabsServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesTabsServiceStyleThree(muiTheme)
    default:
      return new StylesTabsServiceStyleOne(muiTheme)
  }
}

export default getTabsTemplateService
