import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import ProductUnitPriceTemplateServiceInterface
  from '~/src/components/products/product-unit-price/styles/ProductUnitPriceTemplateServiceInterface'

export default class ProductUnitPriceTemplateServiceStyleFour extends TemplateServiceStyleBase implements ProductUnitPriceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      gridArea: 'unit-product-price',
      textAlign: 'center'
    }
  }

  public getPromotionalPriceSx(): SxProps {
    return { }
  }
}
