import { Customer } from '@eo-storefronts/eo-core'
import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import { ChangeEvent, ReactNode } from 'react'
import Toggle from '~/src/components/mui-wrappers/styled/toggle'
import useUpdateCustomerPushNotifications from '~/src/hooks/customer/useUpdateCustomerPushNotifications'
import useIsNotificationPermissionsAllowed from '~/src/hooks/push-notifications/useIsNotificationPermissionsAllowed'
import usePushNotifications from '~/src/hooks/push-notifications/usePushNotifications'
import useStopPushNotifications from '~/src/hooks/push-notifications/useStopPushNotifications'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { AUTHENTICATION_STATE, AuthenticationState } from '~/src/stores/authentication'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import EnvUtils from '~/src/utils/EnvUtils'

interface Props extends Omit<FormControlLabelProps, 'control' | 'label'> {
  label?: ReactNode,
}

const PushNotificationSwitch = ({ label, ...formControlLabelProps }: Props) => {
  const { t } = useTranslations()
  const { check: checkPermission, isAllowed } = useIsNotificationPermissionsAllowed()
  const { initPushNotifications } = usePushNotifications()
  const { stop: stopUsingPushNotifications } = useStopPushNotifications()
  const { updatePushNotification } = useUpdateCustomerPushNotifications()
  const authState = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked) {
      await stopUsingPushNotifications()
    } else {
      await initPushNotifications()
    }

    await checkPermission()
    updatePushNotification(checked)
  }

  if (EnvUtils.isDeviceWeb || authState.loggedInAs !== 'customer') {
    return null
  }

  return (
    <FormControlLabel
      control={<Toggle
        checked={isAllowed && customer?.allow_push_notifications}
        onChange={handleOnChange}
      />}
      label={label ?? t('global.use_push_notifications')}
      {...formControlLabelProps}
    />
  )
}

export default PushNotificationSwitch
