import AppLoader from '~/src/components/loader/AppLoader'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const LanguageLoader = () => {
  const { t } = useTranslations()

  return (
    <>
      <AppLoader />
      <Title component='p'>{t('global.language.loading')}</Title>
    </>
  )
}

export default LanguageLoader
