import { Address } from '@eo-storefronts/eo-core'
import { useCustomerAddress } from '~/src/hooks/customer/useCustomerAddress'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'

interface RedirectOptions {
  redirectAfterSubmit: boolean,
  redirectUrl?: string,
}

type OnSubmitCallbackType = ((address: Address) => void) | undefined

interface ReturnsType {
  submit(address: Address, redirectionOptions?: RedirectOptions, closeAfterSubmit?: boolean, onSubmit?: OnSubmitCallbackType): Promise<void>,
}

const useAddressForm = (): ReturnsType => {
  const { updateAddress, insertAddress } = useCustomerAddress()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const submit = async (
    address: Address,
    redirectOptions: RedirectOptions = {
      redirectAfterSubmit: false
    },
    closeAfterSubmit = false,
    onSubmit?: OnSubmitCallbackType
  ) => {
    const { redirectAfterSubmit, redirectUrl } = redirectOptions

    if (address.id) {
      await updateAddress(address, redirectAfterSubmit, redirectUrl)
    } else {
      await insertAddress(address, redirectAfterSubmit, redirectUrl)
    }

    onSubmit && onSubmit(address)
    closeAfterSubmit && responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
  }

  return { submit }
}

export default useAddressForm
