import { Location, Service } from '@eo-storefronts/eo-core'
import { Box, Skeleton } from '@mui/material'
import LocationTag
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content/location-tags/LocationTag'

interface Props {
  location?: Location,
  isLoading?: boolean,
}

const LocationTags = ({ isLoading = false, location }: Props) => {
  if (isLoading || !location) {
    return (
      <Skeleton
        variant='text'
        sx={{ fontSize: '1rem' }}
      />
    )
  }

  const { services } = location

  if (!services?.length) {
    return null
  }

  // @ts-ignore
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      {services.map((service: Service, index: number) => (
        <LocationTag
          key={index}
          service={service}
        />
      ))}
    </Box>
  )
}

export default LocationTags
