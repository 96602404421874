import { App, AppState } from '@capacitor/app'
import { ConnectionStatus, Network } from '@capacitor/network'
import { useEffect } from 'react'
import useInitAnalyticsProviders from '~/src/hooks/analytics/useInitAnalyticsProviders'
import useCustomerFetchEffect from '~/src/hooks/auth/useCustomerFetchEffect'
import { useLoginAsGuestEffect } from '~/src/hooks/auth/useLoginAsGuestEffect'
import useCartCleanUpEffect from '~/src/hooks/cart/useCartCleanUp'
import { usePreselectDeliveryMethod } from '~/src/hooks/checkout/usePreselectDeliveryMethod'
import useFetchLoyaltyHistoryEffect from '~/src/hooks/customer/useFetchLoyaltyHistoryEffect'
import useCalculateTimeAndCostsEffect from '~/src/hooks/delivery-methods/useCalculateTimeAndCostsEffect'
import useCustomLinksFetchEffect from '~/src/hooks/firm/useCustomLinksFetchEffect'
import useFetchAnnouncementsEffect from '~/src/hooks/firm/useFetchAnnouncementsEffect'
import useFetchLastModificationEffect from '~/src/hooks/firm/useFetchLastModificationEffect'
import useFetchGuestFormEffect from '~/src/hooks/forms/guest-form/useFetchGuestFormEffect'
import usePushNotificationsActionEffect from '~/src/hooks/push-notifications/usePushNotificationsActionEffect'
import useRouterSlugEffect from '~/src/hooks/router/useRouterSlugEffect'
import useInitDownloadAppBanner from '~/src/hooks/useDownloadAppBanner'
import { useSetEoState } from '~/src/hooks/useEoState'
import { APP_STATE } from '~/src/stores/app'
import { APP_LOADER_STATE } from '~/src/stores/app-loader'
import useFillInDefaultCheckoutOptionsEffect from '~/src/hooks/checkout/useFillInDefaultCheckoutOptionsEffect'

const useGlobalAppEffect = (): void => {
  const setAppState = useSetEoState(APP_STATE)
  const setAppLoader = useSetEoState(APP_LOADER_STATE)

  const _updateAppLoaderWithConnection = (status: ConnectionStatus) => {
    setAppLoader({
      state: !status.connected,
      type: 'connection'
    })
  }

  useEffect(() => {
    App.addListener('appStateChange', (state: AppState) => {
      setAppState(state)
      Network.getStatus().then(_updateAppLoaderWithConnection)
    })

    Network.addListener('networkStatusChange', _updateAppLoaderWithConnection)
  }, [])

  usePushNotificationsActionEffect()
  useInitDownloadAppBanner()
  useRouterSlugEffect()
  useInitAnalyticsProviders()
  useCustomerFetchEffect()
  useLoginAsGuestEffect()
  useFetchLoyaltyHistoryEffect()
  useCalculateTimeAndCostsEffect()
  useFetchLastModificationEffect()
  useCartCleanUpEffect()
  usePreselectDeliveryMethod()
  useCustomLinksFetchEffect()
  useFetchAnnouncementsEffect()
  useFetchGuestFormEffect()
  useFillInDefaultCheckoutOptionsEffect()
}

export default useGlobalAppEffect
