import StylesHeaderServiceInterface from '~/src/components/layout/header/styles/StylesHeaderServiceInterface'
import StylesHeaderServiceStyleOne from '~/src/components/layout/header/styles/StylesHeaderServiceStyleOne'
import StylesHeaderServiceStyleThree from '~/src/components/layout/header/styles/StylesHeaderServiceStyleThree'
import StylesHeaderServiceStyleTwo from '~/src/components/layout/header/styles/StylesHeaderServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getHeaderTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme, layout?: LayoutState): StylesHeaderServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesHeaderServiceStyleTwo(muiTheme, firmTheme, layout)
    case TemplateEnum.STYLE_THREE:
      return new StylesHeaderServiceStyleThree(muiTheme, firmTheme, layout)
    default:
      return new StylesHeaderServiceStyleOne(muiTheme, firmTheme, layout)
  }
}

export default getHeaderTemplateService
