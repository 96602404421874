import { SxProps, tabClasses, tabsClasses } from '@mui/material'
import StylesMarketplaceSubHeaderServiceInterface
  from '~/src/components/layout/sub-header/marketplace-sub-header/styles/StylesMarketplaceSubHeaderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMarketplaceSubHeaderServiceStyleThree extends TemplateServiceStyleBase implements StylesMarketplaceSubHeaderServiceInterface {
  public getHeaderMarketplaceSubHeaderSx(): SxProps {
    return {
      backgroundColor: 'transparent',
      color: 'background.contrastText',
      padding: '0 15rem',
      [`.${tabsClasses.flexContainer}`]: {
        py: '2rem',
        pb: '1rem',
        px: '1rem',
        gap: 6
      },
      '.small': {
        [`.${tabsClasses.flexContainer}`]: {
          justifyContent: 'flex-start',
          gap: 2,
          p: 0,
          [`.${tabClasses.root}`]: {
            px: 0,
            '&:last-of-type': {
              pr: 1
            },
            div: {
              flexDirection: 'row',
              gap: 1,
              img: {
                width: '2rem',
                height: '2rem'
              }
            }
          }
        }
      },
      [`.${tabsClasses.indicator}`]: {
        height: '4px !important'
      },
      [this.muiTheme.breakpoints.down('lg')]: {
        p: '0 5rem'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 0,
        transition: 'all 0.25s ease-in-out',
        width: '100%',
        [`.${tabsClasses.flexContainer}`]: {
          pt: 1,
          pl: 1.5,
          gap: 1
        }
      }
    }
  }

  public getHeaderMarketplaceSubHeaderItemSx(): SxProps {
    return {
      margin: 0,
      border: 'none',
      p: `${this.muiTheme.spacing(1)} !important`,
      pt: `${this.muiTheme.spacing(0.5)} !important`,
      minWidth: 'unset',
      opacity: 1,
      background: 'transparent !important',
      color: `${(this.muiTheme.palette.background as any).contrastText} !important`,
      [`&.${tabClasses.selected}`]: {
        '>div>span': {
          color: `${this.muiTheme.palette.primary.main} !important`
        }
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 1.5
      }
    }
  }

  public getLinkSx(): SxProps {
    return {}
  }
}
