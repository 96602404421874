import StylesSideDrawerServiceInterface
  from '~/src/components/layout/side-drawer/styles/StylesSideDrawerServiceInterface'
import StylesSideDrawerServiceStyles from '~/src/components/layout/side-drawer/styles/StylesSideDrawerServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getSideDrawerTemplateService = (style: string | null, muiTheme: CustomMuiTheme): StylesSideDrawerServiceInterface => {
  return new StylesSideDrawerServiceStyles(muiTheme)
}

export default getSideDrawerTemplateService
