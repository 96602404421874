import StylesShoppingCartItemsServiceInterface
  from '~/src/components/cart/shopping-cart-items/styles/StylesShoppingCartItemsServiceInterface'
import StylesShoppingCartItemsServiceStyleOne
  from '~/src/components/cart/shopping-cart-items/styles/StylesShoppingCartItemsServiceStyleOne'
import StylesShoppingCartItemsServiceStyleTwo
  from '~/src/components/cart/shopping-cart-items/styles/StylesShoppingCartItemsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getShoppingCartItemsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesShoppingCartItemsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesShoppingCartItemsServiceStyleTwo(muiTheme)
    default:
      return new StylesShoppingCartItemsServiceStyleOne(muiTheme)
  }
}

export default getShoppingCartItemsTemplateService
