import { Customer, Firm } from '@eo-storefronts/eo-core'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { updatePushNotificationsState, updatePushNotificationsStateV2 } from '~/src/services/CustomerService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  updatePushNotification(isAllowed: boolean): void,
}

const useUpdateCustomerPushNotifications = (): ReturnsType => {
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const isUsingNewBrandAuthSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const [ customer, setCustomer ] = useEoState<Customer | null>(CUSTOMER_STATE)

  const updatePushNotification = (isAllowed: boolean): void => {
    let promise: Promise<unknown> | undefined

    if (isUsingNewBrandAuthSystem && brand?.id && customer?.uuid) {
      promise = updatePushNotificationsStateV2(brand.id, customer.uuid, isAllowed)
    }

    if (firm?.id && customer?.id) {
      promise = updatePushNotificationsState(firm.id, customer.id, isAllowed)
    }

    promise && promise.then(() => {
      setCustomer((state: Customer | null) => {
        if (!state) {
          return null
        }

        return {
          ...state,
          allow_push_notifications: isAllowed
        }
      })
    })
  }


  return { updatePushNotification }
}

export default useUpdateCustomerPushNotifications
