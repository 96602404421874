import { Location } from '@eo-storefronts/eo-core'
import { Skeleton } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  location?: Location,
  isLoading?: boolean,
}

const LocationDescription = ({ isLoading = false, location }: Props) => {
  const { t } = useTranslations()

  if (isLoading || !location) {
    return (
      <Skeleton
        variant='rounded'
        width={'100%'}
        height={60}
      />
    )
  }

  const { company_description } = location

  if (!company_description) {
    return null
  }

  return (
    <>
      <Title variant='h6'>{t('global.about')}</Title>
      <Title component='p'>
        {company_description}
      </Title>
    </>
  )
}

export default LocationDescription
