import { PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import { DateUtils } from '@eo-storefronts/eo-core'
import { useCanOrderOnDay } from '~/src/hooks/delivery-methods/useCanOrderOnDay'

interface Props {
  day: Date,
  PickerDayProps: PickersDayProps<Date>,
}

const DeliveryMethodsDatePickerDay = ({ day, PickerDayProps }: Props) => {
  const { canOrderOnDay } = useCanOrderOnDay()

  let disabled = PickerDayProps.disabled

  if (!disabled) {
    disabled = !canOrderOnDay(new Date(`${DateUtils.momentFormat(day, 'YYYY-MM-DD')}T00:00:00.000+00:00`))
  }

  return <PickersDay {...PickerDayProps} disabled={disabled} />
}

export default DeliveryMethodsDatePickerDay
