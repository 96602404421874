import { Language, LanguageType } from '@eo-storefronts/eo-core'
import { MouseEvent } from 'react'
import FirmLanguagesFlag from '~/src/components/firms/FirmLanguagesFlag'
import { useFirmLanguages } from '~/src/hooks/firm/useFirmLanguages'
import useBuildLinkWithLanguage from '~/src/hooks/router/useBuildLinkWithLanguage'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useSetEoState } from '~/src/hooks/useEoState'
import DropdownLinkItem from '~/src/components/mui-wrappers/dropdown/DropdownLinkItem'
import AppLoaderState, { APP_LOADER_STATE } from '~/src/stores/app-loader'
import useSetLanguageState from '~/src/hooks/language/useSetLanguageState'

interface Props {
  useFlag?: boolean,
  language: Language,
  onClose(): void,
}

const FirmLanguagesMenuItem = ({ useFlag, language, onClose }: Props) => {
  const { t } = useTranslations()
  const { build: buildLink } = useBuildLinkWithLanguage()
  const { selectedLanguage } = useFirmLanguages()
  const setAppLoader = useSetEoState<AppLoaderState>(APP_LOADER_STATE)
  const { setAppLanguage } = useSetLanguageState()

  const handleOnClick = async (event: MouseEvent) => {
    event.preventDefault()
    const languageType: LanguageType = language.code

    setAppLoader({
      state: true,
      type: 'language'
    })

    setAppLanguage(languageType)

    onClose()
  }

  return (
    <DropdownLinkItem
      disabled={language.code === selectedLanguage?.code}
      href={buildLink(language.code)}
      resolve={false}
      onClick={handleOnClick}
    >
      {!useFlag && t(`global.language.${language.code}`)}
      {useFlag && <FirmLanguagesFlag code={language.code} />}
    </DropdownLinkItem>
  )
}

export default FirmLanguagesMenuItem
