import { Box, BoxProps, FormHelperText, InputLabel, TextFieldProps } from '@mui/material'
import { ReactNode } from 'react'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import FormikFieldPropsInterface from '~/src/types/FormikFieldProps'

interface Props extends BoxProps<'div'> {
  SelectFieldProps: FormikFieldPropsInterface & Omit<TextFieldProps, 'name' | 'size' | 'inputMode' | 'inputProps' | 'inputRef' | 'type'>,
  children: ReactNode,
}

const FormikSelectField = ({
  SelectFieldProps: { helperText, form, name, label, size, ...otherSelectProps },
  children,
  ...boxProps
}: Props) => {
  return (
    <Box {...boxProps}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <InputLabel
          sx={{
            color: 'inherit'
          }}
        >
          {label}
        </InputLabel>
        <FormHelperText
          id={`${name}-helper-text`}
          sx={{
            color: 'inherit'
          }}
        >
          {helperText}
        </FormHelperText>
      </Box>
      <TextField
        id={name}
        name={name}
        error={form.touched[name] && !!form.errors[name]}
        helperText={form.touched[name] && !!form.errors[name] ? form.errors[name] as string : undefined}
        value={form.values[name]}
        variant='outlined'
        select
        SelectProps={{
          native: true
        }}
        fullWidth
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        aria-describedby={`${name}-helper-text`}
        size={size}
        {...otherSelectProps}
      >
        {children}
      </TextField>
    </Box>
  )
}

export default FormikSelectField
