import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/styles/StylesDeliveryMethodsSummaryServiceInterface'
import { SxProps } from '@mui/material'
import {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'

export default class StylesDeliveryMethodsSummaryServiceStyleOne extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryServiceInterface {
  public getContainerSx(frozenDeliveryMethod: boolean): SxProps {
    return {
      display: 'flex',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      gap: 1,
      cursor: !frozenDeliveryMethod ? 'pointer' : undefined,
      maxWidth: '100%'
    }
  }

  public getContainerOnClick(frozenDeliveryMethod: boolean, responsiveDialogContext: ResponsiveDialogContextInterface): void {
    if (frozenDeliveryMethod) {
      return
    }

    responsiveDialogContext.toggle && responsiveDialogContext.toggle()
  }

  public getFirmClosedSx(): SxProps {
    return {
      mb: 2
    }
  }
}
