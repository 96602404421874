import Dropdown from '~/src/components/mui-wrappers/dropdown/Dropdown'
import ProfileDropdownMenu from '~/src/components/user-actions/profile-dropdown-button/ProfileDropdownMenu'
import ProfileLargeDropdownLabel, {
  ProfileLargeDropdownLabelProps
} from '~/src/components/user-actions/profile-dropdown-button/ProfileLargeDropdownLabel'
import { ButtonProps, MenuProps } from '@mui/material'

interface Props extends Partial<Pick<ButtonProps, 'sx' | 'variant' | 'color'>> {
  onClick?(): void,
  ComponentProps?: {
    Menu?: Partial<MenuProps>,
    Label?: Partial<ProfileLargeDropdownLabelProps>,
  },
}

const ProfileLargeDropdownButton = ({ onClick, sx = {}, variant, color, ComponentProps }: Props) => {
  return (
    <Dropdown
      variant={variant}
      color={color}
      toggleContent={<ProfileLargeDropdownLabel {...ComponentProps?.Label}/>}
      sx={sx}
    >
      <ProfileDropdownMenu
        onClick={onClick}
        MenuProps={ComponentProps?.Menu}/>
    </Dropdown>
  )
}

export default ProfileLargeDropdownButton
