import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeliveryMethodsTitle = () => {
  const { t } = useTranslations()

  return <Title
    variant='h6'
    sx={{
      color: 'secondary.contrastText'
    }}
  >
    {t('delivery_methods.title')}
  </Title>
}

export default DeliveryMethodsTitle
