import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'
import { useContext } from 'react'
import SideDrawerContext, { SideDrawerProps } from '~/src/components/layout/side-drawer/SideDrawerContext'

const HeaderMenuBurger = () => {
  const sideDrawerContext = useContext<SideDrawerProps>(SideDrawerContext)

  const handleOnClick = () => {
    sideDrawerContext.onToggle()
  }

  return (
    <IconButton onClick={handleOnClick}>
      <MenuIcon />
    </IconButton>
  )
}

export default HeaderMenuBurger
