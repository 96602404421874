import { Category } from '@eo-storefronts/eo-core'
import LinkTab from '~/src/components/mui-wrappers/styled/link-tab'
import Tabs from '~/src/components/mui-wrappers/styled/tabs'
import { LAYOUT_STATE, LayoutState } from '~/src/stores/layout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { TOP_CATEGORIES_SELECTOR, TOP_CATEGORY_SELECTED_SELECTOR } from '~/src/stores/categories'
import { useTranslations } from '~/src/hooks/useTranslations'
import { NAVIGATE_BY_CATEGORIES_SELECTOR } from '~/src/stores/theme'
import LocationSubHeaderTab
  from '~/src/components/layout/sub-header/location-sub-header/location-sub-header-item/LocationSubHeaderTab'

const LocationSubHeaderItem = () => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()
  const isCategoriesNavigation = useEoValue<boolean>(NAVIGATE_BY_CATEGORIES_SELECTOR)
  const { subHeader } = useEoValue<LayoutState>(LAYOUT_STATE)
  const topCategories = useEoValue<Category[]>(TOP_CATEGORIES_SELECTOR)
  const topCategorySelected = useEoValue<Category | undefined>(TOP_CATEGORY_SELECTED_SELECTOR)

  const computeClassName = (): string => {
    const classNames: string[] = subHeader.className?.split(' ') ?? []

    if (subHeader.variant === 'small') {
      classNames.push('small')
    }

    return classNames.join(' ')
  }

  return (
    <Tabs
      value={topCategorySelected?.id || false}
      variant='scrollable'
      scrollButtons='auto'
      className={computeClassName()}
      sx={{
        display: 'flex',
        overflowX: 'auto',
        listStyleType: 'none',
        margin: 0
      }}
    >
      {isCategoriesNavigation && (
        <LinkTab
          href={RoutesEnum.MENU}
          resolve
          value={false}
          label={t('header.sub_header.view_all_categories')}
        />
      )}

      {topCategories.map((category: Category, index: number) => (
        <LocationSubHeaderTab
          key={`${category.id}-${index}`}
          value={category.id}
          category={category}
          sx={{
            [muiTheme.breakpoints.down('md')]: {
              mr: (index === topCategories.length - 1) ? muiTheme.spacing(2) : undefined
            }
          }}
        />
      ))}
    </Tabs>
  )
}

export default LocationSubHeaderItem
