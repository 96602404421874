import { Location, OrderUrl } from '@eo-storefronts/eo-core'
import DeliverooOrderButton from '~/src/components/marketplace/order-buttons/DeliverooOrderButton'
import EasyOrderButton from '~/src/components/marketplace/order-buttons/EasyOrderButton'
import TakeawayOrderButton from '~/src/components/marketplace/order-buttons/TakeawayOrderButton'
import UberEatsOrderButton from '~/src/components/marketplace/order-buttons/UberEatsOrderButton'
import { useTranslations } from '~/src/hooks/useTranslations'
import CustomOrderButton from '~/src/components/marketplace/order-buttons/CustomOrderButton'
import TooGoodToGoOrderButton from '~/src/components/marketplace/order-buttons/TooGoodToGoOrderButton'

interface Props {
  orderUrl: OrderUrl,
  location: Location,
}


const OrderButton = ({ orderUrl, location }: Props) => {
  const { t } = useTranslations()
  /**
   * as the backend returns some name formatted with uppercase or spacing,
   * we format and flatten it like that it's easier to compare
   * @type {string}
   */
  const formattedOrderType: string = orderUrl.name.trim().replaceAll(' ', '').toLowerCase()
  const label = t(`order.button.${formattedOrderType}`)

  switch (formattedOrderType) {
    case 'easyorder':
      return (
        <EasyOrderButton
          location={location}
          orderUrl={orderUrl}
        >
          {label}
        </EasyOrderButton>
      )
    case 'toogoodtogo':
      return (
        <TooGoodToGoOrderButton href={orderUrl.url}>
          {label}
        </TooGoodToGoOrderButton>
      )
    case 'deliveroo':
      return (
        <DeliverooOrderButton href={orderUrl.url}>
          {label}
        </DeliverooOrderButton>
      )
    case 'takeaway':
      return (
        <TakeawayOrderButton href={orderUrl.url}>
          {label}
        </TakeawayOrderButton>
      )
    case 'ubereats':
      return (
        <UberEatsOrderButton href={orderUrl.url}>
          {label}
        </UberEatsOrderButton>
      )
    default:
      return (
        <CustomOrderButton href={orderUrl.url}>
          {label}
        </CustomOrderButton>
      )
  }
}

export default OrderButton
