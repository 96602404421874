import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import App from '~/src/components/App'
import NotFound from '~/src/pages/not-found'
import RoutesEnum from '~/src/router/enums/routes.enum'
import appLoader from '~/src/router/loaders/app'
import generate from '~/src/router/utils/RouteBuilder'

const ExplorerPage = lazy(async () => import('~/src/pages/explorer'))
const LocatorPage = lazy(async () => import('~/src/pages/locator'))
const ForceUpdatePage = lazy(async () => import('~/src/pages/update'))
const InAppUpdatePage = lazy(async () => import('~/src/pages/in-app-update'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    id: 'root',
    errorElement: <NotFound />,
    loader: appLoader,
    shouldRevalidate: ({ currentParams, nextParams }): boolean => {
      return currentParams.shopName !== nextParams.shopName || currentParams.lang !== nextParams.lang
    },
    children: [
      {
        // Declared to handle the redirection in the loader
        path: ':lang',
        element: <></>
      },
      {
        // Declared to handle the redirection in the loader
        path: ':shopName/:lang',
        element: <></>
      },
      {
        // Declared to handle the redirection in the loader
        path: `${RoutesEnum.EAT_IN}/:tableMethod/:tableNumber`,
        element: <></>
      },
      {
        path: `:lang${RoutesEnum.EXPLORER}`,
        element: <ExplorerPage />
      },
      {
        path: `:lang${RoutesEnum.LOCATOR}`,
        element: <LocatorPage />
      },
      {
        path: `:lang${RoutesEnum.FORCE_UPDATE}`,
        element: <ForceUpdatePage />
      },
      {
        path: `:lang${RoutesEnum.IN_APP_UPDATE}`,
        element: <InAppUpdatePage />
      },
      ...generate()
    ]
  }
])

export default router
