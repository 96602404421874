import { Customer } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import SideDrawerGreetingsOffLine from '~/src/components/layout/side-drawer/side-drawer-greetings/offline'
import SideDrawerGreetingsOnline from '~/src/components/layout/side-drawer/side-drawer-greetings/online'

const SideDrawerGreetings = () => {
  const isLoggedIn = useEoValue<boolean>(IS_LOGGED_IN_SELECTOR)
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)

  if (!isLoggedIn || !customer) {
    return <SideDrawerGreetingsOffLine />
  }

  return <SideDrawerGreetingsOnline />
}

export default SideDrawerGreetings
