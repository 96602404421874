import StylesMobileNavigationServiceInterface
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceInterface'
import StylesMobileNavigationServiceStyleOne
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceStyleOne'
import StylesMobileNavigationServiceStyleThree
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceStyleThree'
import StylesMobileNavigationServiceStyleTwo
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getMobileNavigationTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesMobileNavigationServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesMobileNavigationServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesMobileNavigationServiceStyleThree(muiTheme)
    default:
      return new StylesMobileNavigationServiceStyleOne(muiTheme)
  }
}

export default getMobileNavigationTemplateService
