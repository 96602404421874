import { cardContentClasses, SxProps } from '@mui/material'
import { ProductItemCardActionsProps } from '~/src/components/products/product-item/ProductItemCardActions'
import { ProductItemCardContentProps } from '~/src/components/products/product-item/ProductItemCardContent'
import StylesProductItemServiceInterface
  from '~/src/components/products/product-item/styles/StylesProductItemServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { ProductUnitPriceProps } from '~/src/components/products/product-unit-price'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'

export default class StylesProductItemServiceStyleTwo extends TemplateServiceStyleBase implements StylesProductItemServiceInterface {
  public getCardLayout(): SxProps {
    return {
      display: 'grid',
      width: '100%',
      height: '100%',
      gridTemplateAreas:
        '"product-content"' +
        '"product-image"' +
        '"product-actions"',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr auto max-content',
      gap: this.muiTheme.spacing(2),
      p: 2,
      borderRadius: '1em',
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText'
    }
  }

  public getCardMediaStyle(): SxProps {
    return {
      gridArea: 'product-image',
      width: '100%',
      backgroundColor: 'secondary.main',
      color: 'inherit',
      borderRadius: '1em',
      maxWidth: '100%'
    }
  }

  public getCardContentStyle(): SxProps {
    return {
      color: 'inherit',
      gridArea: 'product-content',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      p: 1,
      [`&.${cardContentClasses.root}`]: {
        p: 0
      },
      [`&.${cardContentClasses.root}:last-child`]: {
        paddingBottom: 1
      },
      [this.muiTheme.breakpoints.only('xs')]: {
        py: 1
      }
    }
  }

  public getProductItemCardContentProps(): Pick<ProductItemCardContentProps, 'showDescription'> {
    return {
      showDescription: false
    }
  }

  public getCardContentTitleSx(): SxProps {
    return {
      color: 'inherit',
      textAlign: 'center',
      [this.muiTheme.breakpoints.only('xs')]: {
        fontWeight: 'bold'
      },
      wordBreak: 'break-word'
    }
  }

  public getCardContentTitleVariant(): OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> {
    return this.layout?.isMobile ? 'body1' : 'h6'
  }

  public getCardActionsStyle(): SxProps {
    return {
      color: 'inherit',
      gridArea: 'product-actions',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      mt: 'auto',
      p: 0,
      [this.muiTheme.breakpoints.only('xs')]: {
        pt: 0
      }
    }
  }

  public getProductActionsProps(): Pick<ProductItemCardActionsProps, 'showAddToCartButton'> {
    return  {
      showAddToCartButton: true
    }
  }

  public getProductPriceStyle(free: boolean): SxProps {
    return {
      color: free ? 'success.main' : 'inherit',
      '&>span:first-of-type': {
        fontSize: '1rem'
      }
    }
  }

  public getAddProductButtonStyle(): SxProps {
    return {
      minWidth: 'auto',
      p: '0.5rem',
      borderRadius: 'var(--btn-border-radius)',
      width: 'calc(1.2857142857142856rem + 0.5rem * 2)', // font-size of icon + button padding
      height: 'calc(1.2857142857142856rem + 0.5rem * 2)' // font-size of icon + button padding
    }
  }

  public getProductUnitPriceProps(): Pick<ProductUnitPriceProps, 'displayPromotion'> {
    return {
      displayPromotion: 'top'
    }
  }


}


