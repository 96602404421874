import { DeliveryMethodNames, Interval, Timeslot } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useFetchDeliveryAddressCost from '~/src/hooks/delivery-methods/useFetchDeliveryAddressCost'
import useSetFirstAvailableTime from '~/src/hooks/delivery-methods/useSetFirstAvailableTime'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import useFetchTimeslots from '~/src/hooks/delivery-methods/useFetchTimeslots'
import useFetchTimeslotIntervals from '~/src/hooks/delivery-methods/useFetchTimeslotIntervals'
import { CURRENT_TIMESLOT_KEY_SELECTOR, INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'

const useCalculateTimeAndCostsEffect = (): void => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const currentTimeslotKey = useEoValue(CURRENT_TIMESLOT_KEY_SELECTOR)
  const { fetchDeliveryCost } = useFetchDeliveryAddressCost()
  const { setFirstAvailableTime } = useSetFirstAvailableTime()
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const { fetchTimeslots } = useFetchTimeslots()
  const { fetchTimeslotsIntervals } = useFetchTimeslotIntervals()

  const _fetchTimeslots = async () => {
    const timeslots = await fetchTimeslots()

    setTimeslots((state: Record<string, Timeslot[]>) => ({
      ...state,
      [currentTimeslotKey]: timeslots?.data || []
    }))
  }

  const _fetchIntervals = async () => {
    const { intervals } = await fetchTimeslotsIntervals()
    const availableIntervals = intervals
      .filter((interval: Interval) => interval.available && !interval.blocked)

    setIntervals((state: Record<number, Interval[]>) => ({
      ...state,
      [Number(checkoutForm.delivery_method.timeslotId)]: availableIntervals || []
    }))
  }

  /**
   * Locator page show delivery method first
   * get the first available time when a firm is selected
   */
  useEffect(() => {
    void setFirstAvailableTime(false)

    if (
      checkoutForm.delivery_method.method
      && checkoutForm.delivery_method.method !== DeliveryMethodNames.ON_THE_SPOT
      && firm?.settings.delivery_methods[checkoutForm.delivery_method.method]
      && firm?.settings.delivery_methods[checkoutForm.delivery_method.method]?.order_timeslots.active
    ) {
      void Promise.all([
        _fetchTimeslots(),
        _fetchIntervals()
      ])
    }

  }, [ setFirstAvailableTime ])

  /**
   * Calculate delivery cost when delivery method or an address is selected
   */
  useEffect(() => {
    if (
      checkoutForm.delivery_method.method !== DeliveryMethodNames.DELIVERY
      || !checkoutForm.delivery_method.address
    ) {
      return
    }

    void fetchDeliveryCost(checkoutForm.delivery_method.address)
  }, [
    checkoutForm.delivery_method.method,
    checkoutForm.delivery_method.address
  ])
}

export default useCalculateTimeAndCostsEffect
