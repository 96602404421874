import { ButtonProps, IconButton } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import Button from '~/src/components/mui-wrappers/buttons/Button'

export interface ButtonWithIconProps extends ButtonProps {
  enableResponsive?: boolean,
}

const ButtonWithIcon = ({ children, sx, startIcon, endIcon, color = 'primary', enableResponsive = true, ...otherProps }: ButtonWithIconProps) => {
  const { isMobile } = useEoValue(LAYOUT_STATE)

  if (isMobile && enableResponsive) {
    return (
      <IconButton
        color={color as any}
        sx={{
          borderRadius: 'var(--btn-border-radius)',
          ...sx
        }}
        {...otherProps} >
        {startIcon}
        {endIcon}
      </IconButton>
    )
  }

  return (
    <Button
      color={color}
      sx={{
        borderRadius: 'var(--btn-border-radius)',
        ...sx
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

export default ButtonWithIcon
