import StylesShoppingCartActionsServiceInterface
  from '~/src/components/cart/shopping-cart-actions/styles/StylesShoppingCartActionsServiceInterface'
import StylesShoppingCartActionsServiceStyleOne
  from '~/src/components/cart/shopping-cart-actions/styles/StylesShoppingCartActionsServiceStyleOne'
import StylesShoppingCartActionsServiceStyleTwo
  from '~/src/components/cart/shopping-cart-actions/styles/StylesShoppingCartActionsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getShoppingCartActionsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesShoppingCartActionsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesShoppingCartActionsServiceStyleTwo(muiTheme)
    default:
      return new StylesShoppingCartActionsServiceStyleOne(muiTheme)
  }
}

export default getShoppingCartActionsTemplateService
