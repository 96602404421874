import { MouseEvent } from 'react'
import ToggleButtonGroup from '~/src/components/mui-wrappers/styled/ToggleButtonGroup'
import ToggleButton from '~/src/components/mui-wrappers/styled/ToggleButton'
import { useDeliveryMethodsTime } from '~/src/hooks/delivery-methods/useDeliveryMethodsTime'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'

interface Props {
  onChange(event: MouseEvent, value: string): void,
  value: string,
}

const DeliveryMethodsTimeButtonGroup = ({ value, onChange }: Props) => {
  const { t } = useTranslations()
  const { canOrderAsap } = useDeliveryMethodsTime()
  const { checkOrderIsLimitedToSameDay } = useFirmAcceptsOrder()

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={onChange}
      value={value}
    >
      <ToggleButton value='asap' disabled={!canOrderAsap}>{t('delivery_methods.asap')}</ToggleButton>
      { !checkOrderIsLimitedToSameDay() && <ToggleButton value='pick'>{t('delivery_methods.pick_a_time')}</ToggleButton> }
    </ToggleButtonGroup>
  )
}

export default DeliveryMethodsTimeButtonGroup
