import { Buffer } from 'buffer'

type EventType = 'add_to_cart' | 'anchor_navigation'

interface EventProviderInterface {
  listen(name: EventType, func: () => void): void,
  remove(name: EventType, func: () => void): void,
  trigger(name: EventType): void,
}

type EventState = Record<string, Record<string, () => void>>

class EventUtils implements EventProviderInterface {
  private _events: EventState = {}

  public listen(name: EventType, func: () => void) {
    this._events[name] = {
      ...this._events[name] || {},
      [this._buildFuncBase64(func)]: func
    }
  }

  public remove(name: EventType, func: () => void) {
    delete this._events[name][this._buildFuncBase64(func)]
  }

  public trigger(name: EventType) {
    if (!this._events[name]) {
      return
    }

    for (const func in this._events[name]) {
      this._events[name][func]()
    }
  }

  private _buildFuncBase64(func: () => void) {
    return Buffer.from(func.toString(), 'utf8').toString('base64')
  }
}

const eventProvider: EventUtils = new EventUtils()

export default eventProvider
