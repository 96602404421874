import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryMainButtonsServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/StylesDeliveryMethodsSummaryMainButtonsServiceInterface'
import { BoxProps, SxProps } from '@mui/material'

export default class StylesDeliveryMethodsSummaryMainButtonsServiceStyleTwo extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryMainButtonsServiceInterface {
  getContainerProps(): BoxProps {
    return {
      sx: this.getContainerSx()
    }
  }

  getContainerSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      maxWidth: '100%',
      overflow: 'hidden',
      columnGap: 1
    }
  }
}
