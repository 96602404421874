import {
  filledInputClasses,
  nativeSelectClasses,
  outlinedInputClasses,
  selectClasses,
  styled,
  TextField as MUITextField,
  TextFieldProps
} from '@mui/material'
import useGetTextFieldTemplate from '~/src/components/mui-wrappers/styled/text-field/styles/useGetTextFieldTemplate'
import TextFieldSize from '~/src/types/TextiFieldSize'

interface SizeProps {
  size?: TextFieldSize,
}

type Props = SizeProps & Omit<TextFieldProps, 'size'>

const TextField = styled(({ sx, size = 'normal', ...otherProps }: Props) => {
  const _getPadding = (): number => {
    if (size === 'extra-small') {
      return .5
    }
    if (size === 'small') {
      return 1
    }
    if (size === 'giant') {
      return 2
    }

    return 1.5
  }

  return (
    <MUITextField
      sx={{
        ...sx,
        [`.${filledInputClasses.root}`]: {
          '&::before': {
            display: 'none'
          },
          '&::after': {
            display: 'none'
          }
        },
        [`.${outlinedInputClasses.root} > input`]: {
          pt: _getPadding(),
          pb: _getPadding()
        },
        [`.${selectClasses.select}`]: {
          pt: _getPadding(),
          pb: _getPadding()
        },
        [`.${nativeSelectClasses.select}`]: {
          pt: _getPadding(),
          pb: _getPadding()
        }
      }}
      {...otherProps}
    />
  )
})(() => useGetTextFieldTemplate().getComponentSx())

export default TextField
