import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { MouseEvent } from 'react'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import Button, { ButtonProps } from '~/src/components/mui-wrappers/buttons/Button'
import { SxProps } from '@mui/material'

interface Props extends ButtonProps {
  open: boolean,

  onClick(event: MouseEvent<HTMLButtonElement>): void,
}

const DropdownToggle = ({ open, children, sx = {}, ...otherProps }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Button
      variant='contained'
      id='basic-button'
      aria-controls={open ? 'basic-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup='true'
      sx={{
        gap: 1,
        p: .5,
        py: 1,
        div: {
          span: {
            fontSize: '1rem'
          }
        },
        [muiTheme.breakpoints.down('md')]: {
          gap: 0.5,
          p: .5,
          py: .75,
          ...sx![muiTheme.breakpoints.down('md') as keyof SxProps] as SxProps
        },
        ...sx
      }}
      {...otherProps}
    >
      {children} <ArrowDropDownIcon/>
    </Button>
  )
}

export default DropdownToggle
