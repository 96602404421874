import { DateUtils, DeliveryMethodNames, Firm, PickupHour, Timeslot } from '@eo-storefronts/eo-core'
import {
  canOrderOnDayForTimeslots,
  canOrderOnDayIdForPeriod,
  getFirstAvailablePeriodHourForDayId,
  getFirstAvailableTimeslotHourForDayId,
  isOffline,
  isOnHoliday,
  isOpened,
  isOperationallyOpened,
  isTimeBetweenOperationalTimeslot
} from '~/src/services/OpeningHourService'
import { isLimitedToSameDayOrdering } from '~/src/services/DeliveryTimeService'

export default class CanPlaceOrderService {
  private readonly _firm: Firm | null = null
  private readonly _deliveryMethod: DeliveryMethodNames | null = null
  private readonly _timeslots: Timeslot[] = []

  public constructor(firm: Firm|null, deliveryMethod: DeliveryMethodNames|null, timeslots: Timeslot[]) {
    this._firm = firm
    this._deliveryMethod = deliveryMethod
    this._timeslots = timeslots

    return this
  }

  public checkAll(): boolean {
    if (this._firm && isOffline(this._firm)) {
      return false
    }

    if (!this.checkOrdersOnlyDuringOpeningHours()) {
      return false
    }

    return this.checkOrdersOnlyDuringOperationalHours()
  }

  public checkOrdersOnlyDuringOpeningHours(): boolean {
    if (
      this._deliveryMethod
      && this._deliveryMethod !== DeliveryMethodNames.ON_THE_SPOT
      && this._firm
      && this._firm?.settings.delivery_methods[this._deliveryMethod]?.orders_only_during_opening_hours
    ) {
      return this._isFirmOpened()
    }

    if (this._firm && this._deliveryMethod === DeliveryMethodNames.ON_THE_SPOT) {
      return this._isFirmOpened()
    }

    return true
  }

  public checkOrderIsLimitedToSameDay(): boolean {
    if (!this._firm || !this._deliveryMethod) {
      return false
    }

    return isLimitedToSameDayOrdering(this._firm, this._deliveryMethod)
  }

  public checkOrdersOnlyDuringOperationalHours(): boolean {
    if (
      !this._deliveryMethod
      || this._deliveryMethod === DeliveryMethodNames.ON_THE_SPOT
      || !this._firm?.settings.delivery_methods[this._deliveryMethod]?.orders_only_during_operational_hours
    ) {
      return true
    }

    if (!this._firm?.settings.delivery_methods[this._deliveryMethod]?.order_timeslots.active) {
      return isOperationallyOpened(this._firm.settings.periods[`${this._deliveryMethod}_hours`])
    }

    return isTimeBetweenOperationalTimeslot(
      this._timeslots
    )
  }

  public getNextOpeningHour(): string {
    if (!this._firm) {
      return ''
    }

    let addDay = 0
    let dayId = DateUtils.getDayIdLikeMomentJs(DateUtils.addDays(addDay))

    do {
      dayId = DateUtils.getDayIdLikeMomentJs(DateUtils.addDays(addDay))

      if (canOrderOnDayIdForPeriod(this._firm.settings.periods.opening_hours, dayId)) {
        const hour = getFirstAvailablePeriodHourForDayId(this._firm, dayId)
        return this._formatNextDate(addDay, hour)
      }

      addDay++
    } while (addDay < 7)

    return ''
  }

  public getNextOperationalHour(): string {
    if (
      !this._firm
      || !this._deliveryMethod
      || this._deliveryMethod === DeliveryMethodNames.ON_THE_SPOT
    ) {
      return ''
    }

    let addDay = 0
    let dayId: number

    do {
      dayId = DateUtils.getDayIdLikeMomentJs(DateUtils.addDays(addDay))

      if (this._firm?.settings.delivery_methods[this._deliveryMethod]?.order_timeslots.active) {
        const timeslotDays = this._firm?.settings.delivery_methods[this._deliveryMethod]?.order_timeslots
          .timeslots
          .map((t: Timeslot) => t.day_id as number) || []

        if (canOrderOnDayForTimeslots(timeslotDays, dayId)) {
          const hour = getFirstAvailableTimeslotHourForDayId(
            this._firm,
            dayId,
            this._deliveryMethod,
            this._timeslots
          )
          return this._formatNextDate(addDay, hour)
        }
      } else if (addDay > 0 && canOrderOnDayIdForPeriod(this._firm.settings.periods[`${this._deliveryMethod}_hours`], dayId)) {
        const hour = getFirstAvailablePeriodHourForDayId(this._firm, dayId, this._deliveryMethod)
        return this._formatNextDate(addDay, hour)
      } else {
        const period = this._firm.settings.periods[`${this._deliveryMethod}_hours`].find((p: PickupHour) => p.day_id === dayId)
        const now = new Date()

        if (
          period?.am
          && period.am.type === 'open'
          && parseInt(period.am.from_time.replace(':', '')) > parseInt(`${now.getHours()}${now.getMinutes()}`)
        ) {
          return this._formatNextDate(addDay, period.am.from_time)
        }

        if (
          period?.pm
          && period.pm.type === 'open'
          && parseInt(period.pm.from_time.replace(':', '')) > parseInt(`${now.getHours()}${now.getMinutes()}`)
        ) {
          return this._formatNextDate(addDay, period.pm.from_time)
        }
      }

      addDay++
    } while (addDay < 7)

    return ''
  }

  private _formatNextDate(days: number, hour: string): string {
    if (hour === '') {
      return ''
    }

    const [ h, m ] = hour.split(':')

    const nextDate = DateUtils.addDays(days)
    nextDate.setHours(parseInt(h), parseInt(m))

    return DateUtils.calendarSentence(new Date(), nextDate)
  }

  private _isFirmOpened(): boolean {
    if (!this._firm) {
      return false
    }

    return isOpened(this._firm.settings.periods.opening_hours) 
      && !isOnHoliday(new Date(), this._firm.settings.periods.holiday_period)
  }
}
