import StylesProductListServiceInterface
  from '~/src/components/products/product-list/styles/StylesProductListServiceInterface'
import StylesProductListServiceStyleFour
  from '~/src/components/products/product-list/styles/StylesProductListServiceStyleFour'
import StylesProductListServiceStyleOne
  from '~/src/components/products/product-list/styles/StylesProductListServiceStyleOne'
import StylesProductListServiceStyleTwo
  from '~/src/components/products/product-list/styles/StylesProductListServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getProductListTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesProductListServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProductListServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_FOUR:
      return new StylesProductListServiceStyleFour(muiTheme)
    default:
      return new StylesProductListServiceStyleOne(muiTheme)
  }
}

export default getProductListTemplateService
