import { HolidayPeriod } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import useDateFormatter from '~/src/hooks/useDateFormatter'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  period: HolidayPeriod,
}

const FirmHolidayPeriodSentence = ({ period }: Props) => {
  const { format } = useDateFormatter()
  const { t } = useTranslations()

  return (
    <Title variant='subtitle2'>
      {`${t('global.from_date')} ${format(new Date(period.from_date))} ${t('global.to_date').toLowerCase()} ${format(new Date(period.to_date))}`}
    </Title>
  )
}

export default FirmHolidayPeriodSentence
