import { Id, Product } from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { checkCategoryAvailability } from '~/src/services/CategoryService'
import { checkProductAvailability, searchProductsByName } from '~/src/services/ProductService'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { CATEGORIES_STATE, CATEGORY_BY_ID_SELECTOR } from '~/src/stores/categories'
import { CHECKOUT_FORM_STATE, CHECKOUT_PRODUCT_COUPON } from '~/src/stores/checkout'
import { CUSTOMER_LOYALTY_REACHED_SELECTOR } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR } from '~/src/stores/loyalty'
import { CartProduct } from '~/src/types/CartProduct'
import CartCoupon from '~/src/types/CartCoupon'

export const PRODUCT_ID_STATE = createState<Id | null>({
  key: 'productIdState',
  default: null
})

export const PRODUCTS_STATE = createState<Record<Id, Product>>({
  key: 'productsState',
  default: {}
})

export const PRODUCT_ID_SELECTOR = createSelector<Product | null>({
  key: 'productIdSelector',
  get: ({ get }) => {
    const productId = get(PRODUCT_ID_STATE)
    const products = get(PRODUCTS_STATE)

    if (!productId) {
      return null
    }

    return products[productId]
  }
})

export const PRODUCT_BY_ID_SELECTOR = createSelectorFamily<Product | null, Id>({
  key: 'productByIdSelector',
  get: (id: Id) => ({ get }) => {
    const products = get(PRODUCTS_STATE)

    return products[id] || null
  }
})

export const PRODUCTS_FROM_CATEGORY_SELECTOR = createSelector<Product[]>({
  key: 'productsFromCategorySelector',
  get: ({ get }) => {
    const category = get(CATEGORY_BY_ID_SELECTOR)
    const products = get(PRODUCTS_STATE)

    if (!category) {
      return []
    }

    return (category.products || []).map(
      (productId: string) => products[productId]
    )
  }
})

export const PRODUCT_SEARCH_SELECTOR = createSelectorFamily<string[], string>({
  key: 'productSearchSelector',
  get: (search: string) => ({ get }): string[] => {
    const products = get(PRODUCTS_STATE)
    const lang = get(LANGUAGE_STATE)

    return searchProductsByName(search, lang, products)
  }
})

export const PRODUCT_IS_REWARD_FOR_LOYALTY_SELECTOR = createSelectorFamily<boolean, any>({
  key: 'productIsRewardForLoyaltySelector',
  get: (productId: string) => ({ get }) => {
    const cart = get(CART_OF_FIRM_SELECTOR)
    const firm = get(FIRM_SELECTOR)
    const products = get(PRODUCTS_STATE)
    const loyaltyTypeIsFreeProduct = get(LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR)
    const customerLoyaltyReached = get(CUSTOMER_LOYALTY_REACHED_SELECTOR)

    if (
      !loyaltyTypeIsFreeProduct
      || !customerLoyaltyReached
    ) {
      return false
    }

    if (firm?.settings.loyalty?.loyalty_type === 'product') {
      return productId === firm?.settings.loyalty?.free_product?.id
        && !cart.find((cp: CartProduct) => cp.id === firm?.settings.loyalty?.free_product?.id && cp.is_loyalty)
    }

    return products[productId].categoryId === firm?.settings.loyalty?.free_product_category?.id
      && !cart.find((cp: CartProduct) => products[cp.id].categoryId === firm?.settings.loyalty?.free_product_category?.id && cp.is_loyalty)
  }
})

export const PRODUCTS_LOYALTY_REWARDS_SELECTOR = createSelector<string[]>({
  key: 'productsLoyaltyRewardsSelector',
  get: ({ get }) => {
    const firm = get(FIRM_SELECTOR)
    const categories = get(CATEGORIES_STATE)
    const products = get(PRODUCTS_STATE)
    const loyaltyTypeIsFreeProduct = get(LOYALTY_TYPE_IS_FREE_PRODUCT_SELECTOR)
    const customerLoyaltyReached = get(CUSTOMER_LOYALTY_REACHED_SELECTOR)
    const checkoutForm = get(CHECKOUT_FORM_STATE)

    if (
      !loyaltyTypeIsFreeProduct
      || !customerLoyaltyReached
    ) {
      return []
    }

    const time: Date = checkoutForm.delivery_method.time ? new Date(checkoutForm.delivery_method.time) : new Date()
    let loyaltyProducts: string[] = []

    if (
      firm?.settings.loyalty?.loyalty_type === 'product'
      && firm?.settings.loyalty?.free_product?.id.toString()
    ) {
      loyaltyProducts = [ firm?.settings.loyalty?.free_product?.id.toString() ]
    } else if (
      firm?.settings.loyalty?.free_product_category?.id?.toString()
      && categories[firm?.settings.loyalty?.free_product_category?.id?.toString()]?.products
    ) {
      loyaltyProducts = categories[firm?.settings.loyalty?.free_product_category?.id?.toString()]?.products
    }

    return loyaltyProducts.filter((productId: string) => {
      const p = products[productId]

      if (!p) {
        return false
      }

      const availability = checkProductAvailability(p, time)

      return !availability.snoozed
        && availability.inStock
        && checkCategoryAvailability(p.categoryId, categories, time)
    })
  }
})

export const PRODUCTS_SPOTLIGHTED_SELECTOR = createSelector<Product[]>({
  key: 'productSpotlightedSelector',
  get: ({ get }) => {
    const products: Record<Id, Product> = get(PRODUCTS_STATE)

    return Object.values(products).filter(product => product.featured)
  }
})

export const PRODUCT_COUPON = createSelectorFamily<CartCoupon | undefined, Id>({
  key: 'productCoupon',
  get: (productId: Id) => ({ get }) => {
    const coupon = get(CHECKOUT_PRODUCT_COUPON)

    if (coupon?.chosen_product_id === productId) {
      return coupon
    }

    return undefined
  }
})
