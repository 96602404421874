import DeliveryMethodSummaryAddressButtonLabel
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button/DeliveryMethodSummaryAddressButtonLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useDialog from '~/src/hooks/useDialog'
import { SyntheticEvent } from 'react'
import DeliveryAddress from '~/src/components/delivery-methods/DeliveryAddress'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { buttonClasses } from '@mui/material'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const DeliveryMethodsSummaryMethodInformationsDelivery = () => {
  const { present } = useDialog()
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const EndIcon = isMobile ? KeyboardArrowDownIcon : EditIcon

  const _handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    present(DeliveryAddress, 'delivery-methods-choose-address', {
      activator: undefined,
      openByDefault: true
    })
  }

  return (
    <Button
      variant={'contained'}
      fullWidth
      onClick={_handleOnClick}
      sx={{
        borderColor: checkoutForm.delivery_method.address ? 'primary.main' : 'primary.contrastText',
        borderWidth: '1px',
        borderStyle: 'solid',
        textTransform: 'none',
        justifyContent: 'unset',
        textAlign: 'left',
        borderRadius: 1,
        [`span.${buttonClasses.startIcon}`]: {
          pl: .5,
          mr: .5
        },
        [`span.${buttonClasses.endIcon}`]: {
          ml: 'auto'
        }
      }}
      startIcon={<LocationOnOutlinedIcon/>}
      endIcon={<EndIcon/>}>
      <DeliveryMethodSummaryAddressButtonLabel/>
    </Button>
  )
}

export default DeliveryMethodsSummaryMethodInformationsDelivery
