import { Address } from '@eo-storefronts/eo-core'
import ChooseCustomerAddress from '~/src/components/customers/addresses/ChooseCustomerAddress'
import FillDeliveryAddress from '~/src/components/delivery-methods/FillDeliveryAddress'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import AddressForm from '~/src/components/customers/addresses/AddressForm'
import { Dispatch, SetStateAction, useContext } from 'react'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useDeliveryAddressValidator } from '~/src/hooks/checkout/useDeliveryAddressValidator'

interface Props {
  loading?: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  selected: Address | null,
}

const ChooseAddress = ({ loading, setLoading, selected }: Props) => {
  const customer = useEoValue(CUSTOMER_STATE)
  const responsiveDialogContext = useContext(ResponsiveDialogContext)
  const { validateAddressForCurrentFirm } = useDeliveryAddressValidator()

  const _handleOnChoose = async (address: Address) => {
    setLoading(true)

    const isValid = await validateAddressForCurrentFirm(address)

    if (!isValid) {
      setLoading(false)
      return
    }

    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose({ isValid, address })
    setLoading(false)
  }

  if (!customer) {
    return (
      <FillDeliveryAddress
        address={selected}
        onFill={_handleOnChoose} />
    )
  }

  if (customer && !customer.addresses?.length) {
    return (
      <AddressForm
        redirectAfterSubmit={false}
        closeAfterSubmit={false}
        onSubmit={_handleOnChoose}
      />
    )
  }

  return (
    <ChooseCustomerAddress
      loading={loading}
      selected={selected}
      onChoose={_handleOnChoose}
    />
  )
}

export default ChooseAddress
