import { Id, Location } from '@eo-storefronts/eo-core'
import { dialogActionsClasses, dialogContentClasses, drawerClasses, paperClasses } from '@mui/material'
import { ReactNode } from 'react'
import LocationResponsiveDialogActions
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-actions'
import LocationResponsiveDialogContent
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import useFetchLocation from '~/src/hooks/marketplace/locations/useFetchLocation'
import { useSetEoState } from '~/src/hooks/useEoState'
import { MARKETPLACE_LOCATION_SELECTED_STATE } from '~/src/stores/marketplace/locations'
import ResponsiveDialogProps from '~/src/types/ResponsiveDialogProps'

interface Props extends Pick<ResponsiveDialogProps, 'openByDefault'> {
  activator: ReactNode,
  id?: Id,
}

const LocationResponsiveDialog = ({ activator, id, openByDefault = false }: Props) => {
  const { loading: isLoading, fetch } = useFetchLocation()
  const setLocationSelected = useSetEoState(MARKETPLACE_LOCATION_SELECTED_STATE)

  const _handleOnOpen = async () => {
    if (!id) {
      return
    }

    const location: Location | undefined = await fetch(id)
    location && setLocationSelected(location)
  }

  return (
    <ResponsiveDialog
      Actions={<LocationResponsiveDialogActions isLoading={isLoading} />}
      activator={activator}
      showCloseButton={false}
      showPuller
      onOpen={_handleOnOpen}
      openByDefault={openByDefault}
      maxWidth='sm'
      fullWidth
      sx={{
        [`& .${paperClasses.root}`]: {
          borderRadius: '10px',
          [`&:not(.${drawerClasses.paper}.${drawerClasses.paperAnchorBottom})`]: {
            pt: 1,
            maxWidth: '450px !important',
            maxHeight: '80vh !important'
          },
          [`&.${drawerClasses.paper}.${drawerClasses.paperAnchorBottom}`]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          },
          [`.${dialogContentClasses.root}`]: {
            p: 0,
            mt: 0
          },
          [`.${dialogActionsClasses.root}`]: {
            p: 2,
            py: 1.5,
            width: '100%'
          }
        }
      }}
    >
      <LocationResponsiveDialogContent isLoading={isLoading} />
    </ResponsiveDialog>
  )
}

export default LocationResponsiveDialog
