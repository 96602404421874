function deepEqual<T = unknown, I = unknown>(objectA: T, objectB: I): boolean {
  if (!objectA || !objectB) {
    return false
  }
  const stringifiedA: string = typeof objectA === 'string' ? objectA : JSON.stringify(objectA)
  const stringifiedB: string = typeof objectB === 'string' ? objectB : JSON.stringify(objectB)

  return stringifiedA === stringifiedB
}

export default deepEqual
