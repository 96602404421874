import { outlinedInputClasses } from '@mui/material'
import StylesTextFieldServiceInterface
  from '~/src/components/mui-wrappers/styled/text-field/styles/StylesTextFieldServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTextFieldServiceStyleTwo extends TemplateServiceStyleBase implements StylesTextFieldServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`.${outlinedInputClasses.root}`]: {
        borderRadius: '0.5em',
        backgroundColor: this.muiTheme.palette.getContrastText(this.muiTheme.palette.text.primary)
      },
      '& > p': {
        textAlign: 'right'
      }
    }
  }
}
