import { Firm } from '@eo-storefronts/eo-core'
import FirmTimeslot from '~/src/components/firms/FirmTimeslot'
import { GroupedOpeningHours, useGroupOpeningHours } from '~/src/hooks/firm/useGroupOpeningHours'

interface Props {
  firm: Firm,
}

const FirmTimeslotsGrouped = ({ firm }: Props) => {
  const groupedHours = useGroupOpeningHours(firm)

  return (
    <>
      {groupedHours.map((groupedHour: GroupedOpeningHours, index: number) => (
        <FirmTimeslot key={index} day={groupedHour.days} openingHours={groupedHour.openingHours} />
      ))}
    </>
  )
}

export default FirmTimeslotsGrouped
