import getShoppingCartCouponTemplateService
  from '~/src/components/cart/shopping-cart-coupon/styles/GetShoppingCartCouponTemplateService'
import StylesShoppingCartCouponServiceInterface
  from '~/src/components/cart/shopping-cart-coupon/styles/StylesShoppingCartCouponServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartCouponTemplate = (): StylesShoppingCartCouponServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartCouponTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartCouponTemplate
