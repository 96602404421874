import { ServiceFee } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import NumberUtils from '~/src/utils/NumberUtils'

const useGetFeePrice = (fee: ServiceFee): number => {
  const subtotal = useEoValue(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)

  if (fee.free_from && subtotal >= fee.free_from_amount) {
    return 0
  }

  if (fee.fee_choice === 'fee') {
    return fee.fee
  }

  return NumberUtils.getPercentageValue(subtotal, fee.percentage)
}

export default useGetFeePrice
