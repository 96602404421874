import getSideDrawerLanguageSwitcherTemplateService
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/styles/GetSideDrawerLanguageSwitcherTemplateService'
import StylesSideDrawerLanguageSwitcherServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/styles/StylesSideDrawerLanguageSwitcherServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetSideDrawerLanguageSwitcherTemplate = (): StylesSideDrawerLanguageSwitcherServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSideDrawerLanguageSwitcherTemplateService(pageStyle, muiTheme)
}

export default useGetSideDrawerLanguageSwitcherTemplate
