import { Firm } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import { useFirmOpeningTimeStateAndSentence } from '~/src/hooks/firm/useFirmOpeningTimeStateAndSentence'

interface Props {
  firm: Firm,
}

const FirmTimeslotsSentence = ({ firm }: Props) => {
  const { sentence } = useFirmOpeningTimeStateAndSentence(firm)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}
    >
      <Typography variant='subtitle2'>{sentence}</Typography>
    </Box>
  )
}

export default FirmTimeslotsSentence
