import { Firm, OpeningHour } from '@eo-storefronts/eo-core'
import FirmTimeslot from '~/src/components/firms/FirmTimeslot'
import { buildOpeningHoursString } from '~/src/services/OpeningHourService'

interface Props {
  firm: Firm,
}

const FirmTimeslotsUnGrouped = ({ firm }: Props) => {
  const { opening_hours: openingHours } = firm.settings.periods

  if (!openingHours?.length) {
    return null
  }

  return (
    <>
      {openingHours.map((slot: OpeningHour, index: number) => (
        <FirmTimeslot
          key={index}
          day={slot.day}
          openingHours={buildOpeningHoursString(slot)}
        />
      ))}
    </>
  )
}

export default FirmTimeslotsUnGrouped
