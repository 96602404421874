import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { Filter, FilterOption } from '@eo-storefronts/eo-core'

export const MARKETPLACE_AVAILABLE_FILTERS_STATE = createState<Filter[] | undefined>({
  key: 'availableFiltersState',
  default: undefined
})

export const MARKETPLACE_SELECTED_FILTERS_STATE = createState<FilterOption[]>({
  key: 'selectedFiltersState',
  default: []
})

export const MARKETPLACE_FILTER_ACTION_STATE = createState<{
  value: number | undefined,
  loading: boolean,
}>({
  key: 'selectedFiltersAmountState',
  default: {
    value: undefined,
    loading: false
  }
})

export const MARKETPLACE_SEARCH_VALUE_STATE = createState<string>({
  key: 'searchValueState',
  default: ''
})

export const MARKETPLACE_IS_FILTER_SELECTED_SELECTOR = createSelectorFamily<boolean, any>({
  key: 'isFilterSelected',
  get: (option: FilterOption) => ({ get }) => {
    const selectedFilters = get(MARKETPLACE_SELECTED_FILTERS_STATE)

    return !!selectedFilters.find((selectedOption: FilterOption) => selectedOption.uuid === option.uuid)
  }
})

export const MARKETPLACE_IS_FILTERING_SELECTOR = createSelector<boolean>({
  key: 'isThereFiltersSelected',
  get: ({ get }) => {
    const selectedFilters = get(MARKETPLACE_SELECTED_FILTERS_STATE)

    return selectedFilters.length > 0
  }
})
