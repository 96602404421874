import { ReactNode } from 'react'
import ForgotPasswordForm from '~/src/components/auth/forgot-password/ForgotPasswordForm'
import ForgotPasswordTitle from '~/src/components/auth/forgot-password/ForgotPasswordTitle'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'

interface Props {
  activator: ReactNode,
}

const ForgotPasswordResponsiveDialog = ({ activator }: Props) => {
  return (
    <ResponsiveDialog
      Title={<ForgotPasswordTitle />}
      activator={activator}>
      <ForgotPasswordForm />
    </ResponsiveDialog>
  )
}

export default ForgotPasswordResponsiveDialog
