import StylesSideDrawerLanguageSwitcherServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/styles/StylesSideDrawerLanguageSwitcherServiceInterface'
import StylesSideDrawerLanguageSwitcherServiceStyles
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/styles/StylesSideDrawerLanguageSwitcherServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getSideDrawerLanguageSwitcherTemplateService = (style: string | null, muiTheme: CustomMuiTheme): StylesSideDrawerLanguageSwitcherServiceInterface => {
  return new StylesSideDrawerLanguageSwitcherServiceStyles(muiTheme)
}

export default getSideDrawerLanguageSwitcherTemplateService
