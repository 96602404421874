import { useMediaQuery } from '@mui/material'
import { ReactNode } from 'react'
import DialogWrapper from '~/src/components/mui-wrappers/responsive-dialog/wrappers/DialogWrapper'
import SwipeableDrawerWrapper from '~/src/components/mui-wrappers/responsive-dialog/wrappers/SwipeableDrawerWrapper'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { ResponsiveDialogWrapperProps } from '~/src/types/ResponsiveDialogWrapperProps'

interface Props extends ResponsiveDialogWrapperProps {
  children: ReactNode,
}

const ResponsiveDialogWrapper = ({
  responsive = true,
  breakpoint = 'md',
  ...otherProps
}: Props) => {
  const isSmallDevice = useMediaQuery((theme: CustomMuiTheme) => theme.breakpoints.down(breakpoint))
  const Wrapper = (isSmallDevice && responsive) ? SwipeableDrawerWrapper : DialogWrapper

  return (
    <Wrapper {...otherProps} />
  )
}

export default ResponsiveDialogWrapper
