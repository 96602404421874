import { Id, Timeslot } from '@eo-storefronts/eo-core'
import { Box, CircularProgress, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import DeliveryMethodsTimeslotOption from '~/src/components/delivery-methods/DeliveryMethodsTimeslotOption'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { TIMESLOT_FOR_SELECTED_DATE_SELECTOR } from '~/src/stores/timeslots'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import TextField from '~/src/components/mui-wrappers/styled/text-field'

interface Props {
  loading: boolean,
}

const DeliveryMethodsTimeslots = ({ loading }: Props) => {
  const { t } = useTranslations()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)
  const timeslots = useEoValue(TIMESLOT_FOR_SELECTED_DATE_SELECTOR)

  const _updateCheckoutForm = (timeslotId: Id) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        timeslotId
      }
    }))
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    _updateCheckoutForm(event.target.value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography>{t('delivery_methods.select_your_slot')}</Typography>
      {loading && <CircularProgress size={20} />}
      {!loading && (
        <TextField
          select
          size='small'
          color='container'
          SelectProps={{
            native: true
          }}
          variant='outlined'
          onChange={handleOnChange}
          value={checkoutForm.delivery_method.timeslotId || 0}
        >
          {timeslots.map((timeslot: Timeslot) => (
            <DeliveryMethodsTimeslotOption
              key={timeslot.id}
              timeslot={timeslot}
            />
          ))}
        </TextField>
      )}
    </Box>
  )
}

export default DeliveryMethodsTimeslots
