import { TemplateEnum } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplatePages } from '~/src/types/Templates'
import { layoutDispatcherMap } from '~/src/utils/layout/LayoutDispatcherMap'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

interface LayoutQuery {
  style: TemplateEnum,
  page: TemplatePages,
  getLayoutArgs?: GetLayoutArgs,
}

const getLayoutForPage = ({
  style = TemplateEnum.STYLE_ONE,
  page = 'locator',
  getLayoutArgs = {}
}: Partial<LayoutQuery>): DeepPartial<LayoutState> => {
  return layoutDispatcherMap[page](style, getLayoutArgs)
}

export default getLayoutForPage
