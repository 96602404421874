import {
  ApiConfiguration,
  AppleSignInVerifyPayload,
  AppleSignInVerifyResponse,
  AuthService,
  CheckSessionTokenResponse,
  CustomerAuthResponse,
  Firm,
  GuestAuthResponse,
  Id,
  InitSocialLoginResponse,
  SocialLoginType
} from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'
import Credentials from '~/src/types/Credentials'

export const loginAsClient = async () => {
  return AuthService.loginAsClient({
    client_id: EnvUtils.clientId,
    client_secret: EnvUtils.clientSecret
  })
}

export const loginAsCustomer = async (credentials: Credentials, isUsingNewBrandAuthSystem: boolean, brandId?: Id): Promise<CustomerAuthResponse> => {
  brandId && ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())

  return AuthService.loginAsCustomer(
    {
      ...credentials,
      clientId: 'app'
    },
    isUsingNewBrandAuthSystem
  )
}

export const loginAsGuest = async (firmId: Id): Promise<GuestAuthResponse> => {
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return AuthService.loginAsGuest()
}

export const initSocialLogin = async (firmId: Id | undefined, brandId: Id | undefined, socialLoginType: SocialLoginType, returnUrl: string): Promise<InitSocialLoginResponse> => {
  if (brandId) {
    ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())
    ApiConfiguration.addOrReplaceHeader('firmId', brandId.toString())
  } else if (firmId) {
    ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  }

  return AuthService.initSocialLogin(socialLoginType, returnUrl)
}

export const checkSocialToken = async (firmId: Id | undefined, brandId: Id | undefined, socialToken: string): Promise<CheckSessionTokenResponse> => {
  if (brandId) {
    ApiConfiguration.addOrReplaceHeader('brandId', brandId.toString())
    ApiConfiguration.addOrReplaceHeader('firmId', brandId.toString())
  } else if (firmId) {
    ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  }

  return AuthService.checkSocialToken(socialToken)
}

export const verifyApplePayload = async (brand: Firm, firm: Firm, isUsingNewBrandAuthSystem: boolean, payload: AppleSignInVerifyPayload): Promise<AppleSignInVerifyResponse> => {
  ApiConfiguration.addOrReplaceHeader('firmId', firm.id.toString())

  if (isUsingNewBrandAuthSystem) {
    ApiConfiguration.addOrReplaceHeader('brandId', brand.id.toString())
  } else if (brand.id !== firm.id) {
    ApiConfiguration.addOrReplaceHeader('storeId', brand.uuid.toString())
  }

  return AuthService.verifyApplePayload(payload)
}
