import { Box, Button, TextField, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import useGetShoppingCartCouponTemplate
  from '~/src/components/cart/shopping-cart-coupon/styles/useGetShoppingCartCouponTemplate'
import useCoupon from '~/src/hooks/cart/useCoupon'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  displayLabel?: boolean,
}

const ShoppingCartCoupon = ({ displayLabel = true }: Props) => {
  const styles = useGetShoppingCartCouponTemplate()
  const { t } = useTranslations()
  const [ voucherCode, setVoucherCode ] = useState<string>('')
  const { checkCouponValidity } = useCoupon()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  const handleOnInput = (event: ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(event.currentTarget.value)
  }

  const handleOnAddVoucherCode = async () => {
    await checkCouponValidity(voucherCode)
  }

  if (!firm || !firm.settings.modules.uses_coupons || checkoutForm?.coupon) {
    return null
  }

  return (
    <>
      {displayLabel && (
        <Typography sx={styles.getLabelSxProps()}>
          {t('shopping_cart.coupon.question')}
        </Typography>
      )}

      <Box sx={styles.getContainerSxProps()}>
        <TextField
          type='text'
          name='reduction'
          color='container'
          defaultValue={voucherCode}
          placeholder={t('order.voucher_code')}
          size='small'
          onChange={handleOnInput}
          sx={styles.getTextFieldSxProps()}
        />
        <Button
          variant='contained'
          color='container'
          disableElevation
          disabled={!voucherCode}
          onClick={handleOnAddVoucherCode}
          sx={styles.getButtonSxProps()}
        >
          {t('global.apply')}
        </Button>
      </Box>
    </>
  )
}

export default ShoppingCartCoupon
