import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { differenceInMinutes, format, formatDistanceToNowStrict } from 'date-fns'
import { useMemo } from 'react'
import useDateFnsLocale from '~/src/hooks/delivery-methods/useDateFnsLocale'
import DeliveryMethodsSummaryMethodSentenceSplitter
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method/delivery-methods-summary-method-sentence/DeliveryMethodsSummaryMethodSentenceSplitter'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'

interface Props {
  showSplitter?: boolean,
}

const DeliveryMethodsSummaryMethodSentenceTime = ({ showSplitter = true }: Props) => {
  const { t } = useTranslations()
  const { delivery_method } = useEoValue(CHECKOUT_FORM_STATE)
  const { adapterLocale } = useDateFnsLocale()

  const asapTimeSentence = useMemo(() => {
    if (delivery_method.asap && delivery_method.minDate) {
      if (differenceInMinutes(new Date(delivery_method.minDate), new Date()) <= 10) {
        return ''
      }

      return `(${formatDistanceToNowStrict(new Date(delivery_method.minDate), { locale: adapterLocale })})`
    }

    if (delivery_method.time) {
      return format(new Date(delivery_method.time), 'HH:mm')
    }

    return ''
  }, [ delivery_method.method, delivery_method.asap, delivery_method.time, adapterLocale ])

  if (delivery_method.method === DeliveryMethodNames.ON_THE_SPOT) {
    return null
  }

  return (
    <span>
      {showSplitter && <DeliveryMethodsSummaryMethodSentenceSplitter/>}
      <AccessTimeIcon/>
      <>&nbsp;</>
      {delivery_method.asap && t('delivery_methods.asap')}
      <>&nbsp;</>
      {asapTimeSentence}
    </span>
  )
}

export default DeliveryMethodsSummaryMethodSentenceTime
