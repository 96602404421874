import getMenuTemplateService from '~/src/components/mui-wrappers/styled/menu/styles/GetMenuTemplateService'
import StylesMenuServiceInterface from '~/src/components/mui-wrappers/styled/menu/styles/StylesMenuServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetMenuTemplate = (): StylesMenuServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getMenuTemplateService(pageStyle, muiTheme)
}

export default useGetMenuTemplate
