import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import GoBackButton from '~/src/components/navigation/GoBackButton'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useTranslations } from '~/src/hooks/useTranslations'
import useIsLocatorPage from '~/src/hooks/router/useIsLocatorPage'
import useGetHeaderTemplate from '~/src/components/layout/header/styles/useGetHeaderTemplate'

const SimpleHeader = () => {
  const styles = useGetHeaderTemplate()
  const { isLocatorPage } = useIsLocatorPage()
  const { t } = useTranslations()
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const muiTheme = useCustomMuiTheme()
  const { resolve } = useFirmPathResolver()
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)

  return (
    <Box
      component='header'
      id='layout_header'
      gridArea='header'
      sx={styles.getSimpleHeaderSx(isLocatorPage)}
    >
      <GoBackButton
        small={isMobile}
        href={resolve(fallbackPage)}
        sx={{
          p: 1.5,
          color: 'inherit',
          boxShadow: muiTheme.elevation,
          [muiTheme.breakpoints.down('md')]: {
            borderRadius: '50%',
            minWidth: 'unset'
          }
        }}>
        {t('firm.back_to_the_shop')}
      </GoBackButton>
    </Box>
  )
}

export default SimpleHeader
