import LayoutPrivacyPolicyServiceStyleOne
  from '~/src/pages/legal/privacy-policy/layout/LayoutPrivacyPolicyServiceStyleOne'
import LayoutPrivacyPolicyServiceStyleThree
  from '~/src/pages/legal/privacy-policy/layout/LayoutPrivacyPolicyServiceStyleThree'
import LayoutPrivacyPolicyServiceStyleTwo
  from '~/src/pages/legal/privacy-policy/layout/LayoutPrivacyPolicyServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getPrivacyPolicyLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutPrivacyPolicyServiceStyleTwo().getLayout()
    case TemplateEnum.STYLE_THREE:
      return new LayoutPrivacyPolicyServiceStyleThree().getLayout()
    default:
      return new LayoutPrivacyPolicyServiceStyleOne().getLayout()
  }
}

export default getPrivacyPolicyLayout
