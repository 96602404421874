import AddCustomerAddressDialogButton from '~/src/components/customers/addresses/AddCustomerAddressDialogButton'
import AddressForm from '~/src/components/customers/addresses/AddressForm'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const AddCustomerAddressDialog = () => {
  const { t } = useTranslations()

  return (
    <ResponsiveDialog
      maxWidth='sm'
      activator={<AddCustomerAddressDialogButton />}
      Title={
        <Title
          variant='h5'
          component='p'
        >
          {t('profile.address.add')}
        </Title>
      }
    >
      <AddressForm redirectAfterSubmit={false} closeAfterSubmit />
    </ResponsiveDialog>
  )
}

export default AddCustomerAddressDialog
