import { Box } from '@mui/material'
import LoginFormActions from '~/src/components/auth/login/LoginFormActions'
import LoginFormFields from '~/src/components/auth/login/LoginFormFields'
import FormError from '~/src/components/error/FormError'
import { useLoginAsCustomer } from '~/src/hooks/auth/useLoginAsCustomer'
import { LoginFormInterface } from '~/src/types/forms/LoginFormInterface'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  redirectAfterLogin?: boolean,
}

const LoginForm = ({ redirectAfterLogin = true }: Props) => {
  const { t } = useTranslations()
  const { login, error, loading } = useLoginAsCustomer(redirectAfterLogin)
  const formik: FormikProps<LoginFormInterface> = useFormik<LoginFormInterface>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('errors.email_invalid'))
        .required(t('errors.email_required')),
      password: yup
        .string()
        .required(t('errors.password_required'))
    }),
    onSubmit: (values => login({
      username: values.email,
      password: values.password
    }))
  })

  return (
    <>
      {error && <FormError>{error}</FormError>}

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            mt: 6
          }}
        >
          <LoginFormFields form={formik} />

          <LoginFormActions
            form={formik}
            loading={loading}
          />
        </Box>
      </form>
    </>
  )
}

export default LoginForm
