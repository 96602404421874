import StylesFooterServiceInterface from '~/src/components/layout/footer/styles/StylesFooterServiceInterface'
import StylesFooterServiceStyleOne from '~/src/components/layout/footer/styles/StylesFooterServiceStyleOne'
import StylesFooterServiceStyleThree from '~/src/components/layout/footer/styles/StylesFooterServiceStyleThree'
import StylesFooterServiceStyleTwo from '~/src/components/layout/footer/styles/StylesFooterServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getFooterTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesFooterServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesFooterServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesFooterServiceStyleThree(muiTheme)
    default:
      return new StylesFooterServiceStyleOne(muiTheme)
  }
}

export default getFooterTemplateService
