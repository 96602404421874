import getMobileNavigationTemplateService
  from '~/src/components/layout/mobile-navigation/styles/GetMobileNavigationTemplateService'
import StylesMobileNavigationServiceInterface
  from '~/src/components/layout/mobile-navigation/styles/StylesMobileNavigationServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetMobileNavigationTemplate = (): StylesMobileNavigationServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getMobileNavigationTemplateService(pageStyle, muiTheme)
}

export default useGetMobileNavigationTemplate
