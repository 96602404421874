import {
  Address,
  ApiConfiguration,
  ContentType,
  CustomLink,
  DeliveryMethodNames,
  FindFirmArgType,
  Firm,
  FirmService,
  FirmStoreLocator,
  FormField,
  GetAnnouncementsResponse,
  GetIntervalsResponse,
  GetLastModificationResponse,
  GetTimeslotsResponse,
  Id,
  Info,
  NewsFeed
} from '@eo-storefronts/eo-core'

export const fetchFirm = async (arg: FindFirmArgType): Promise<Firm> => {
  clearHeaders()

  return FirmService.findFirm(arg)
}

export const findFirmByAddress = async (firmId: Id, address: Address): Promise<FirmStoreLocator> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return FirmService.findByAddress(address)
}

export const fetchLastModification = async (id: number): Promise<GetLastModificationResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', id.toString())

  return FirmService.getLastModification()
}

export const fetchFirmTimeslots = async (firmId: Id, date: Date, deliveryMethod: string, products: Id[], categories: string[], pickupPointId?: number | string): Promise<GetTimeslotsResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return FirmService.getTimeslots(date, deliveryMethod, products, categories, pickupPointId)
}

export const fetchTimeIntervals = async (firmId: Id, timeslotId: Id, date: Date, deliveryMethod: string, products: Id[], categories: string[], pickupPointId?: number | string): Promise<GetIntervalsResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return FirmService.getTimeIntervals(timeslotId, date, deliveryMethod, products, categories, pickupPointId)
}

export const fetchCustomLinks = async (firmId: Id): Promise<CustomLink[]> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return FirmService.getCustomLinks(firmId)
}

export const fetchGuestFormFields = async (firmId: Id, deliveryMethod: string, pickupPointId?: Id): Promise<FormField[]> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())

  return FirmService.getGuestFields(deliveryMethod, pickupPointId)
}

export const fetchAnnouncements = async (id: Id): Promise<GetAnnouncementsResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', id)

  return FirmService.getAnnouncements()
}

export const fetchNewsFeed = async (id: Id, limit = 5): Promise<Array<NewsFeed>> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', id)

  return FirmService.getNewsFeed(limit)
}

const clearHeaders = (): void => {
  [ 'storeId', 'firmId' ].forEach((key: string) => {
    ApiConfiguration.deleteHeader(key)
  })
}

/**
 * This method will check if the method exist in the firm and if the method is inactive or not
 * @param {Firm} firm
 * @param {DeliveryMethodNames} method
 * @returns {boolean}
 */
export const firmSupportDeliveryMethod = (firm: Firm, method: DeliveryMethodNames): boolean => {
  return !!firm.settings.delivery_methods[method] && !firm.settings.delivery_methods[method]?.temporary_inactive
}

/**
 * This method will map through the possible deliveryMethods in the firm
 * and check if there's one existing and active
 * @param {Firm | null} firm
 * @returns {DeliveryMethodNames | undefined}
 */
export const getFirstFirmSupportedDeliveryMethod = (firm: Firm | null): DeliveryMethodNames | undefined => {
  if (!firm) {
    return
  }

  const methods: DeliveryMethodNames[] = Object.keys(firm.settings.delivery_methods) as DeliveryMethodNames[]

  if (!methods?.length) {
    return
  }

  for (const method of methods) {
    if (firmSupportDeliveryMethod(firm, method as DeliveryMethodNames)) {
      return method as DeliveryMethodNames
    }
  }
}

/**
 * By passing a contentType and a firm this will return the firmInfo element found
 * @param {Firm} firm
 * @param {string} contentType
 * @returns {Info | undefined}
 */
export const getFirmInfo = (firm: Firm | undefined | null, contentType: ContentType): Info | undefined => {
  return firm?.info.find((info: Info) => info.content_type === contentType)
}
