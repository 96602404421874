import { menuItemClasses, paperClasses } from '@mui/material'
import StylesMenuServiceInterface from '~/src/components/mui-wrappers/styled/menu/styles/StylesMenuServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMenuServiceStyleOne extends TemplateServiceStyleBase implements StylesMenuServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`.${paperClasses.root}`]: {
        backgroundColor: (this.muiTheme.palette.background as any).main,
        color: (this.muiTheme.palette.background as any).contrastText,
        [`.${menuItemClasses.root}`]: {
          color: this.muiTheme.palette.primary.main,
          '> *': {
            color: 'inherit'
          }
        }
      }
    }
  }
}
