import { ButtonProps, MenuProps } from '@mui/material'
import FirmLanguagesMenu from '~/src/components/firms/FirmLanguagesMenu'
import LanguageDropdownLabel from '~/src/components/languages/LanguageDropdownLabel'
import useGetHeaderLanguageSelectorTemplate
  from '~/src/components/layout/header/header-language-selector/styles/useGetHeaderLanguageSelectorTemplate'
import Dropdown from '~/src/components/mui-wrappers/dropdown/Dropdown'
import { useFirmLanguages } from '~/src/hooks/firm/useFirmLanguages'

export interface HeaderLanguageSelectorProps extends Partial<Pick<ButtonProps, 'variant' | 'useAutomaticShading' | 'color'>> {
  useFlag?: boolean,
  showAsCode?: boolean,
  MenuProps?: Pick<MenuProps, 'color' | 'useAutomaticShading'>,
}

const HeaderLanguageSelector = (
  {
    showAsCode = false,
    useFlag = false,
    useAutomaticShading = true,
    color = 'header',
    MenuProps = {
      color: 'header',
      useAutomaticShading: true
    }
  }: HeaderLanguageSelectorProps
) => {
  const styles = useGetHeaderLanguageSelectorTemplate()
  const { selectedLanguage, languages } = useFirmLanguages()

  if (!selectedLanguage || languages.length <= 1) {
    return null
  }

  return (
    <Dropdown
      color={color}
      useAutomaticShading={useAutomaticShading}
      toggleContent={
        <LanguageDropdownLabel
          language={selectedLanguage}
          showAsCode={showAsCode}
        />
      }
      sx={styles.getDropdownSx()}
    >
      <FirmLanguagesMenu
        useFlag={useFlag}
        {...MenuProps}/>
    </Dropdown>
  )
}

export default HeaderLanguageSelector

