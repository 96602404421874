import { PluginListenerHandle } from '@capacitor/core'
import {
  ApiConfiguration,
  CheckSessionTokenResponse,
  Customer,
  Firm,
  HttpError,
  InitSocialLoginResponse,
  PlatformUtils,
  SocialLoginType
} from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkSocialToken, initSocialLogin } from '~/src/services/AuthenticationService'
import { Browser } from '@capacitor/browser'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import EnvUtils from '~/src/utils/EnvUtils'

interface ReturnsType {
  signInWithSocial(socialLoginType: SocialLoginType): Promise<Partial<Customer>>,
}

const useSocialSignInWithBackend = (): ReturnsType => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)
  const brand: Firm | null = useEoValue(BRAND_STATE)
  let windowRef: Window | null

  const _getWindowFeatures = () => {
    const screenWidth: number = screen.width
    const screenHeight: number = screen.height
    let width: number
    let height: number

    if (screenWidth < 480) {
      width = screenWidth / 1.1
      height = screenHeight / 1.1
    } else {
      width = screenWidth / 5
      height = screenHeight / 2
    }

    const left = screen.availWidth / 2 - width / 2
    const top = screen.availHeight / 2 - height / 2

    return `width=${width},height=${height},status,resizable,left=${left},top=${top},screenX=${left},screenY=${top}`
  }

  const _initWebWindow = () => {
    windowRef = window.open('', '_blank', _getWindowFeatures())
  }

  const _initAppWindow = (url: string) => {
    void Browser.open({ url })
  }

  const _setWindowUrl = (url: string) => {
    if (EnvUtils.isDeviceWeb && windowRef) {
      windowRef.location = url
      return
    }

    _initAppWindow(url)
  }

  const _closeWindow = () => {
    if (EnvUtils.isDeviceWeb && windowRef) {
      return windowRef.close()
    }

    PlatformUtils().isIOS && void Browser.close()
  }

  const _initSignInWindow = async (response: InitSocialLoginResponse): Promise<Partial<Customer>> => {
    return _initSocialTokenCheck(response.sessionToken)
  }

  const _initSocialTokenCheck = async (token: string, isMobile: boolean = EnvUtils.isDeviceApp): Promise<Partial<Customer>> => {
    // Promise is resolve if the user successfully logged in and is reject if the user close the window or if an error
    // occurred
    return new Promise((resolve, reject) => {
      let browserFinishedListener: Promise<PluginListenerHandle> & PluginListenerHandle | undefined

      const _handleUserClosedWindow = () => {
        if (PlatformUtils().isMobile && browserFinishedListener) {
          void browserFinishedListener.remove()
        }

        clearInterval(intervalId)
        reject('User deny')
      }

      // Every 2 seconds a check is initiate to the back end to know if the user is successfully logged in
      const intervalId = setInterval(() => {
        // Handle the window closed by user on desktop
        if (!isMobile && windowRef && windowRef.closed) {
          _handleUserClosedWindow()
        }

        checkSocialToken(firm?.id, brand?.id, token)
          .then(({ customer, customerToken }: CheckSessionTokenResponse) => {
            ApiConfiguration.addOrReplaceHeader('customerToken', customerToken)
            resolve(customer)

            intervalId && clearInterval(intervalId)
            _closeWindow()
          })
          .catch((e: HttpError | string) => {
            if (typeof e === 'string') {
              return
            }
            // catch the error 'CUSTOMER_NOT_ACTIVATED' which means that the user don't enter his credentials yet
            if (e?.body?.code !== 'CUSTOMER_NOT_ACTIVATED') {
              reject(e)
            }
          })
      }, 2000)

      // Handle the window closed by the user on mobile
      if (isMobile) {
        browserFinishedListener = Browser.addListener('browserFinished', () => {
          setTimeout(() => {
            _handleUserClosedWindow()
          }, 2000)
        })
      }
    })
  }

  const signInWithSocial = async (socialLoginType: SocialLoginType): Promise<Partial<Customer>> => {
    if (EnvUtils.isDeviceWeb) {
      _initWebWindow()
    }

    const response: InitSocialLoginResponse = await initSocialLogin(
      firm?.id,
      brand?.id,
      socialLoginType,
      generateReturnUrl()
    )

    _setWindowUrl(response.login_url)

    return _initSignInWindow(response)
  }

  const generateReturnUrl = (): string => {
    const { host, pathname } = window.location

    let protocol: string = window.location.protocol

    if (EnvUtils.isDeviceApp && brand) {
      protocol = brand.settings.app_url.replace('//', '')
    }

    return `${protocol}//${host}${pathname}`
  }

  return { signInWithSocial }
}

export default useSocialSignInWithBackend
