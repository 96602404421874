import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { Firm, LanguageType } from '@eo-storefronts/eo-core'
import CanPlaceOrderService from '~/src/services/CanPlaceOrderService'
import { TIMESLOT_FOR_SELECTED_DATE_SELECTOR } from '~/src/stores/timeslots'
import { useCallback, useEffect, useState } from 'react'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { isOnHoliday } from '~/src/services/OpeningHourService'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const useNextOrderAvailableTimeSentence = (): string => {
  const { t } = useTranslations()
  const lang = useEoValue<LanguageType|string>(LANGUAGE_STATE)
  const firm = useEoValue<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const { checkOrdersOnlyDuringOpeningHours, checkOrdersOnlyDuringOperationalHours } = useFirmAcceptsOrder()
  const timeslots = useEoValue(TIMESLOT_FOR_SELECTED_DATE_SELECTOR)
  const [ sentence, setSentence ] = useState<string>('')
  const canPlaceOrderService = new CanPlaceOrderService(
    firm || null,
    checkoutForm.delivery_method.method,
    timeslots
  )

  const build = useCallback((): string => {
    if (isOnHoliday(new Date(), firm?.settings.periods.holiday_period || [])) {
      return t('checkout.after_holidays')
    }

    if (!checkOrdersOnlyDuringOpeningHours()) {
      return canPlaceOrderService.getNextOpeningHour()
    }

    if (!checkOrdersOnlyDuringOperationalHours()) {
      return canPlaceOrderService.getNextOperationalHour()
    }

    return ''
  }, [ firm, checkoutForm.delivery_method.method, timeslots ])

  useEffect(() => {
    setSentence(build())
  }, [ lang, build ])

  return sentence
}

export default useNextOrderAvailableTimeSentence
