import { PushNotifications, Token } from '@capacitor/push-notifications'
import { Id } from '@eo-storefronts/eo-core'
import useInitNotificationPermission from '~/src/hooks/push-notifications/useInitNotificationPermission'
import useIsNotificationPermissionsAllowed from '~/src/hooks/push-notifications/useIsNotificationPermissionsAllowed'
import useSaveDeviceToken from '~/src/hooks/push-notifications/useSaveDeviceToken'
import EnvUtils from '~/src/utils/EnvUtils'

interface ReturnsType {
  initPushNotifications(customerId?: Id): Promise<void | undefined>,
}

const usePushNotifications = (): ReturnsType => {
  const { check: checkPermission } = useIsNotificationPermissionsAllowed()
  const { saveDeviceToken } = useSaveDeviceToken()
  const { initNotificationPermission } = useInitNotificationPermission()

  const initPushNotifications = async (customerId?: Id): Promise<void | undefined> => {
    if (EnvUtils.isDeviceWeb) {
      return
    }

    await initNotificationPermission()

    const permissionGranted: boolean | undefined = await checkPermission()

    if (!permissionGranted) {
      return
    }

    await PushNotifications.addListener('registration', async (token: Token) => {
      await saveDeviceToken(token.value, customerId)
    })

    await PushNotifications.register()
  }

  return { initPushNotifications }
}

export default usePushNotifications
