import getTextFieldTemplateService
  from '~/src/components/mui-wrappers/styled/text-field/styles/GetTextFieldTemplateService'
import StylesTextFieldServiceInterface
  from '~/src/components/mui-wrappers/styled/text-field/styles/StylesTextFieldServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetTextFieldTemplate = (): StylesTextFieldServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getTextFieldTemplateService(pageStyle, muiTheme)
}

export default useGetTextFieldTemplate
