import { useEffect } from 'react'
import useLoginAsGuest from '~/src/hooks/auth/useLoginAsGuest'
import { useEoValue } from '~/src/hooks/useEoState'
import {
  API_TOKEN_STATE,
  AUTHENTICATION_STATE,
  AuthenticationState,
  BaseAuthenticationState
} from '~/src/stores/authentication'
import { Firm } from '@eo-storefronts/eo-core'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

const useLoginAsGuestEffect = () => {
  const auth = useEoValue<AuthenticationState>(AUTHENTICATION_STATE)
  const apiToken = useEoValue<BaseAuthenticationState | null>(API_TOKEN_STATE)
  const firm = useEoValue<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const { login } = useLoginAsGuest()

  useEffect(() => {
    if (auth?.guest?.id || auth?.customer?.id || !apiToken?.token) {
      return
    }

    login({
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      society: null
    }, false)
  }, [ firm, auth, apiToken ])
}

export {
  useLoginAsGuestEffect
}
