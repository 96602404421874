import Environment from '~/src/config/environment'

export default class EnvUtils {
  public static get device(): 'app' | 'web' {
    return Environment.getDevice() as 'app' | 'web'
  }

  public static get isDeviceWeb(): boolean {
    return EnvUtils.device === 'web'
  }

  public static get isDeviceApp(): boolean {
    return EnvUtils.device === 'app'
  }

  public static get clientId(): string {
    return Environment.getClientId() as string
  }

  public static get clientSecret(): string {
    return Environment.getClientSecret() as string
  }

  public static get googleMapKey(): string {
    return Environment.getGoogleMapKey() as string
  }

  public static get apiEndpoint(): string {
    return Environment.getApiEndpoint() as string
  }

  public static get apiVersion(): string {
    return Environment.getApiVersion() as string
  }

  public static get appVersion(): string {
    return Environment.getAppVersion() as string
  }

  // 3rd party sign in
  public static get googleSignInClientIdWeb(): string {
    return Environment.getGoogleSignInClientIdWeb() as string
  }
}
