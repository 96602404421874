import { Location } from '@eo-storefronts/eo-core'
import { Skeleton } from '@mui/material'
import Image from '~/src/components/mui-wrappers/Image'

interface Props {
  location?: Location,
  isLoading?: boolean,
}

const LocationImage = ({ isLoading = false, location }: Props) => {
  if (isLoading || !location) {
    return (
      <Skeleton
        variant='rectangular'
        sx={{
          width: '100%',
          aspectRatio: '16/9',
          objectFit: 'cover',
          height: '30vh',
          maxHeight: '250px'
        }}
      />
    )
  }

  return (
    <Image
      src={location.image}
      alt={location.name}
      sx={{
        width: '100%',
        aspectRatio: '16/9',
        objectFit: 'cover',
        height: '30vh',
        maxHeight: '250px'
      }}
    />
  )
}

export default LocationImage
