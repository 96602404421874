import LayoutProductsServiceStyleOne from '~/src/pages/products/layout/LayoutProductsServiceStyleOne'
import LayoutProductsServiceStyleTwo from '~/src/pages/products/layout/LayoutProductsServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getProductsLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutProductsServiceStyleTwo().getLayout(getLayoutArgs)
    default:
      return new LayoutProductsServiceStyleOne().getLayout()
  }
}

export default getProductsLayout
