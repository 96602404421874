import { Box, Typography } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'
import FirmLanguagesMenu from '~/src/components/firms/FirmLanguagesMenu'
import useGetSideDrawerLanguageSwitcherTemplate
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/styles/useGetSideDrawerLanguageSwitcherTemplate'
import Dropdown from '~/src/components/mui-wrappers/dropdown/Dropdown'
import { useFirmLanguages } from '~/src/hooks/firm/useFirmLanguages'
import { useTranslations } from '~/src/hooks/useTranslations'
import SideDrawerLanguageToggle
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/side-drawer-language-toggle'
import LanguageIcon from '@mui/icons-material/Language'

interface Props extends Partial<Pick<ButtonProps, 'variant'>> {
  useFlag?: boolean,
}

const SideDrawerLanguageSwitcher = ({ useFlag = false, variant }: Props) => {
  const { t } = useTranslations()
  const styles = useGetSideDrawerLanguageSwitcherTemplate()
  const { selectedLanguage, languages } = useFirmLanguages()

  if (!selectedLanguage || languages.length <= 1) {
    return null
  }

  return (
    <Box sx={styles.getContainerSx()}>
      <Typography sx={styles.getTitleSx()}>
        <LanguageIcon /> {t('side_drawer.language')}
      </Typography>
      <Dropdown
        variant={variant}
        toggleContent={<SideDrawerLanguageToggle language={selectedLanguage} />}
        sx={styles.getDropdownSx()}
      >
        <FirmLanguagesMenu useFlag={useFlag} />
      </Dropdown>
    </Box>
  )
}

export default SideDrawerLanguageSwitcher
