import { OpeningHour } from '@eo-storefronts/eo-core'
import { buildOpeningHoursString } from '~/src/services/OpeningHourService'
import { useTranslations } from '~/src/hooks/useTranslations'

interface ReturnsType {
  sentence(period: OpeningHour, type?: 'short' | 'long'): string,
}

const useOpeningHourSentence = (): ReturnsType => {
  const { t } = useTranslations()

  const getOpeningHourSentence = (period: OpeningHour, type: 'short' | 'long' = 'long'): string => {
    let openingHourSentence = buildOpeningHoursString(period)
      .replace(/&/g, t('global.and').toLowerCase())

    if (type === 'long') {
      openingHourSentence = openingHourSentence.replace(/-/g, t('global.to').toLowerCase())
    }

    if (openingHourSentence === 'closed') {
      return t('firm.closed')
    }

    return `${t('firm.open_from')} ${openingHourSentence}`
  }

  return { sentence: getOpeningHourSentence }
}

export default useOpeningHourSentence
