import StylesShoppingCartCouponServiceInterface
  from '~/src/components/cart/shopping-cart-coupon/styles/StylesShoppingCartCouponServiceInterface'
import StylesShoppingCartCouponServiceStyleOne
  from '~/src/components/cart/shopping-cart-coupon/styles/StylesShoppingCartCouponServiceStyleOne'
import StylesShoppingCartCouponServiceStyleTwo
  from '~/src/components/cart/shopping-cart-coupon/styles/StylesShoppingCartCouponServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getShoppingCartCouponTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesShoppingCartCouponServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesShoppingCartCouponServiceStyleTwo(muiTheme)
    default:
      return new StylesShoppingCartCouponServiceStyleOne(muiTheme)
  }
}

export default getShoppingCartCouponTemplateService
