import { Divider, IconButtonProps, Theme, useMediaQuery } from '@mui/material'
import SearchResults from '~/src/components/marketplace/ui/search/SearchResults'
import useGlobalSearchInputProperties from '~/src/hooks/marketplace/search/useGlobalSearchInputProperties'

interface Props extends Required<Pick<IconButtonProps, 'size'>> {
  showPanel: boolean,
}

const LocationSearchAutocompletePanel = ({ size, showPanel }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { py } = useGlobalSearchInputProperties(size)

  if (isMobile || !showPanel) {
    return null
  }

  return (
    <>
      <Divider/>
      <SearchResults sx={{ py }}/>
    </>
  )
}

export default LocationSearchAutocompletePanel
