import { Firm, Tracking, TrackingField } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'

// @ts-ignore
import TagManager from 'react-gtm-module'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const useInitGoogleTagManager = (): void => {
  const firm = useEoValue(FIRM_SELECTOR)
  const currentFirm = useEoValue(FIRM_SELECTOR)

  const _getGoogleTagManagerId = (firm: Firm | null): string | undefined => firm
    ?.settings
    ?.tracking
    ?.find((tracking: Tracking) => tracking.type === 'GOOGLE_TAG_MANAGER')
    ?.fields.find((field: TrackingField) => field.id === 'google_tag_manager_id')
    ?.value

  useEffect(() => {
    if (!firm && !currentFirm) {
      return
    }

    const gtmId: string | undefined = _getGoogleTagManagerId(firm || currentFirm)

    if (!gtmId) {
      return
    }

    TagManager.initialize({ gtmId })
  }, [ firm, currentFirm ])
}

export default useInitGoogleTagManager
