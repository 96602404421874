enum FirmRoutesEnum {
  'LOCATOR' = '/shops',
  'FIRM_HOME' = 'firm-home',
  'FIRM_INFO' = '/firm/infos',
  'CART' = '/cart',
  'PRODUCTS' = '/products',
  'PRODUCT' = '/product',
  'CHECKOUT' = '/checkout',
  'EAT_IN' = 'spot',
  'DELIVERY_METHODS' = '',
}

export default FirmRoutesEnum
