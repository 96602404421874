import { SxProps } from '@mui/material'
import StylesSideDrawerLanguageSwitcherServiceInterface
  from '~/src/components/layout/side-drawer/side-drawer-language-switcher/styles/StylesSideDrawerLanguageSwitcherServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesSideDrawerLanguageSwitcherServiceStyles extends TemplateServiceStyleBase implements StylesSideDrawerLanguageSwitcherServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }

  public getTitleSx(): SxProps {
    return {
      display: 'flex',
      gap: 2,
      alignItems: 'center'
    }
  }

  public getDropdownSx(): SxProps {
    return {
      color: 'inherit',
      textTransform: 'capitalize',
      svg: {
        display: 'none'
      }
    }
  }
}
