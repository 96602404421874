import { HttpError, Id, Location } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { fetchMarketplaceLocation } from '~/src/services/MarketplaceService'
import { BRAND_STATE } from '~/src/stores/brand'

interface ReturnsType {
  data: Location | undefined,
  fetch(id: Id): Promise<Location | undefined>,
  loading: boolean,
  error: HttpError | unknown | undefined,
}

const useFetchLocation = (): ReturnsType => {
  const brand = useEoValue(BRAND_STATE)
  const [ location, setLocation ] = useState<Location | undefined>(undefined)
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)
  const [ loading, setLoading ] = useState<boolean>(false)

  const fetch = async (id: Id): Promise<Location | undefined> => {
    if (!brand?.id) {
      return
    }

    setLocation(undefined)
    setError(undefined)
    setLoading(true)

    try {
      const response: Location = await fetchMarketplaceLocation(brand.id, id)

      setLocation(response)

      return response
    } catch (e: HttpError | unknown) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  return { data: location, loading, error, fetch }
}

export default useFetchLocation
