import { Id } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import { useSetEoState } from '~/src/hooks/useEoState'
import { fetchCategories } from '~/src/services/ProductService'
import { CATEGORIES_STATE } from '~/src/stores/categories'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'

interface ReturnsType {
  fetch(id: Id): Promise<void>,
}

const useFetchCategories = (): ReturnsType => {
  const setCategories = useSetEoState(CATEGORIES_STATE)
  const setProducts = useSetEoState(PRODUCTS_STATE)
  const setModifierGroups = useSetEoState(MODIFIER_GROUPS_STATE)
  const setModifiers = useSetEoState(MODIFIERS_STATE)

  const fetch = useCallback(async (id: Id) => {
    const { products, categories, modifierGroups, modifiers } = await fetchCategories(id)
    setCategories(categories)
    setProducts(products)
    setModifierGroups(modifierGroups)
    setModifiers(modifiers)
  }, [])

  return { fetch }
}

export {
  useFetchCategories
}
