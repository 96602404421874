import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Button } from '@mui/material'

interface Props {
  onClick(): void,
}

const NextAnnouncementButton = ({ onClick }: Props) => (
  <Box sx={{ textAlign: 'right' }}>
    <Button
      onClick={onClick}
      variant='contained'
      disableElevation
      color='primary'
      sx={{
        p: 1,
        minWidth: 0,
        borderRadius: '50%'
      }}
    >
      <ArrowForwardIcon/>
    </Button>
  </Box>
)

export default NextAnnouncementButton
