import { Grid, Theme, useMediaQuery } from '@mui/material'
import { GridListProps } from '~/src/types/GridList'

const GridList = ({ children, ...otherProps }: GridListProps) => {
  const isLargeDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))

  return (
    <Grid
      container
      spacing={2}
      columns={isLargeDevice ? 10 : 12}
      {...otherProps}
    >
      {children}
    </Grid>
  )
}

export default GridList
