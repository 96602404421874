import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { LAST_MODIFICATION_STATE } from '~/src/stores/last-modification'
import { ApiConfiguration, DateUtils, DeliveryMethodNames, Firm } from '@eo-storefronts/eo-core'
import { fetchLastModification } from '~/src/services/FirmService'
import { INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'
import { useFetchFirm } from '~/src/hooks/firm/useFetchFirm'
import { useFetchCategories } from '~/src/hooks/categories/useFetchCategories'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

interface ReturnsType {
  fetch(): Promise<void>,
}

const useFetchLastModification = (): ReturnsType => {
  const [ lastModifications, setLastModifications ] = useEoState(LAST_MODIFICATION_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const { fetch: fetchFirm } = useFetchFirm()
  const { fetch: fetchCategories } = useFetchCategories()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)

  const _fetch = async (): Promise<void> => {
    if (
      !firm
      || firm.is_multifirm
      || !ApiConfiguration.getHeader('Authorization')
    ) {
      return
    }

    try {
      const lastModificationResponse = await fetchLastModification(Number(firm.id))

      if (
        DateUtils.isBefore(
          new Date(lastModifications.last_firm_update),
          new Date(lastModificationResponse.last_firm_update)
        )
      ) {
        const response = await fetchFirm(firm.id)

        // If pickup points was use before update but not after the checkout form selected pickup point is cleared
        if (response.settings.delivery_methods[DeliveryMethodNames.PICKUP]?.pickup_points.length === 0 && checkoutForm.delivery_method.pickupPoint) {
          setCheckoutForm((state: CheckoutFormState) => ({
            ...state,
            delivery_method: {
              ...state.delivery_method,
              pickupPoint: null
            }
          }))
        }

        setTimeslots({})
        setIntervals({})
      }

      if (
        DateUtils.isBefore(
          new Date(lastModifications.last_product_update),
          new Date(lastModificationResponse.last_product_update)
        )
      ) {
        await fetchCategories(firm.id)
      }

      setLastModifications(lastModificationResponse)
    } catch {
      // Ignore
    }
  }

  return {
    fetch: _fetch
  }
}

export default useFetchLastModification
