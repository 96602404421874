import { SxProps } from '@mui/material'
import StylesHeaderLanguageSelectorServiceInterface
  from '~/src/components/layout/header/header-language-selector/styles/StylesHeaderLanguageSelectorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesHeaderLanguageSelectorServiceStyleTwo extends TemplateServiceStyleBase implements StylesHeaderLanguageSelectorServiceInterface {
  public getDropdownSx(): SxProps {
    return {
      backgroundColor: this.firmTheme?.components?.header.background_color,
      color: this.firmTheme?.components?.header.color
    }
  }
}
