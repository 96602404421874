import { Id } from '@eo-storefronts/eo-core'
import ProductList from '~/src/components/products/product-list'
import useGetProductsModalListContainerTemplate
  from '~/src/components/products/modals/products-list/container/styles/useGetProductsModalListContainerTemplate'
import StylesProductsModalListContainerServiceInterface
  from '~/src/components/products/modals/products-list/container/styles/StylesProductsModalListContainerServiceInterface'
import { SxProps } from '@mui/material'
import merge from '@mui/system/merge'
import { MouseEvent } from 'react'

interface Props {
  ids: Id[],
  onProductChoose?(e: MouseEvent<HTMLElement>, productId: string): void,
  listSx?: {
    GridSx?: SxProps,
    ItemsSx?: SxProps,
  },
}

const ProductModalListContainer = ({ ids, onProductChoose, listSx }: Props) => {
  const styles: StylesProductsModalListContainerServiceInterface = useGetProductsModalListContainerTemplate()

  return (
    <ProductList
      GridProps={merge(
        styles.getGridProps(ids.length),
        { sx: listSx?.GridSx || {} }
      )}
      onItemClick={onProductChoose}
      ItemProps={merge(
        styles.getGridItemsProps(),
        { sx: listSx?.ItemsSx || {} }
      )}
      productsId={ids as string[]}
    />
  )
}

export default ProductModalListContainer
