import { Box, SxProps } from '@mui/material'
import { ReactNode } from 'react'
import Collapse from '~/src/components/mui-wrappers/Collapse'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  children: ReactNode,
  enableCollapse: boolean,
  sx?: SxProps,
}

const ShoppingCartItemsContainer = ({ children, enableCollapse, sx }: Props) => {
  const { t } = useTranslations()

  if (!enableCollapse) {
    return (
      <Box sx={sx}>
        {children}
      </Box>
    )
  }

  return (
    <Collapse
      justifyContent='flex-start'
      title={<Title>{t('order.summary')}</Title>}
      sx={sx}
    >
      {children}
    </Collapse>
  )
}

export default ShoppingCartItemsContainer
