import { Box } from '@mui/material'
import FirmInfoDialog from '~/src/components/firms/FirmInfoDialog'
import useGetHeaderCurrentFirmTemplate
  from '~/src/components/layout/header/header-current-firm/styles/useGetHeaderCurrentFirmTemplate'
import HeaderMultiFirmSwitch from '~/src/components/layout/header/header-multi-firm-switch'

interface Props {
  showFirmLabel?: boolean,
}

const HeaderCurrentFirm = ({ showFirmLabel = true }: Props) => {
  const styles = useGetHeaderCurrentFirmTemplate()

  return (
    <Box
      sx={styles.getContainerSx()}
    >
      {showFirmLabel && <FirmInfoDialog />}

      <HeaderMultiFirmSwitch />
    </Box>
  )
}

export default HeaderCurrentFirm
