import { LanguageType, Product } from '@eo-storefronts/eo-core'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box, IconButton, Typography } from '@mui/material'
import CartItemModifierGroups from '~/src/components/cart/CartItemModifierGroups'
import useGetShoppingCartItemTemplate from '~/src/components/cart/shopping-cart-item/styles/useGetShoppingCartItemTemplate'
import Price from '~/src/components/Price'
import ProductQuantity from '~/src/components/products/ProductQuantity'
import QuantitySelector from '~/src/components/products/QuantitySelector'
import { useRemoveProductOfCart } from '~/src/hooks/cart/useRemoveProductOfCart'
import { useUpdateCartProducts } from '~/src/hooks/cart/useUpdateCartProducts'
import { useEoValue } from '~/src/hooks/useEoState'
import { convertProductUnitKgInGr, getProductPrice } from '~/src/services/ProductService'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCT_BY_ID_SELECTOR, PRODUCT_COUPON } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'
import { CHECKOUT_PRODUCT_LOYALTY_AMOUNT, CHECKOUT_PRODUCT_LOYALTY_IN_CART } from '~/src/stores/checkout'
import ShoppingCartItemDiscounts from '~/src/components/cart/shopping-cart-item/ShoppingCartItemDiscounts'

interface Props {
  cartProduct: CartProduct,
  showIngredientsAndOptions?: boolean,
  showQuantityAsText?: boolean,
  showQuantitySelector?: boolean,
  showPrice?: boolean,
  showDeleteButton?: boolean,
}

const ShoppingCartItem = ({
  cartProduct,
  showIngredientsAndOptions = false,
  showQuantityAsText = false,
  showDeleteButton = true,
  showPrice = true,
  showQuantitySelector = true
}: Props) => {
  const styles = useGetShoppingCartItemTemplate()
  const { updateQuantity } = useUpdateCartProducts()
  const { remove } = useRemoveProductOfCart()
  const product = useEoValue<Product | null>(PRODUCT_BY_ID_SELECTOR(cartProduct.id))
  const lang = useEoValue<LanguageType | string>(LANGUAGE_STATE)
  const { max, min, unitInterval } = convertProductUnitKgInGr(product)
  const productCoupon = useEoValue(PRODUCT_COUPON(cartProduct.id))
  const productLoyaltyDiscount = useEoValue(CHECKOUT_PRODUCT_LOYALTY_IN_CART)
  const loyaltyAmount = useEoValue(CHECKOUT_PRODUCT_LOYALTY_AMOUNT)

  const handleUpdateQuantity = (value: number) => {
    updateQuantity(cartProduct, value)
  }

  const handleRemoveProductOfCart = () => {
    remove(cartProduct)
  }

  const calculateToTalPrice = (): number => {
    if (!product) {
      return 0
    }

    let price = cartProduct.quantity * getProductPrice(product)

    if (productCoupon && productCoupon.value && cartProduct.is_coupon) {
      price -= productCoupon.value
    }

    if (productLoyaltyDiscount && cartProduct.is_loyalty) {
      price -= loyaltyAmount
    }

    return price
  }

  const numberOfElementDisplayed = (): number => {
    let amount = 1

    if (showPrice) {
      amount++
    }
    if (showQuantityAsText) {
      amount++
    }
    if (showDeleteButton) {
      amount++
    }
    if (showQuantitySelector) {
      amount++
    }

    return amount
  }

  return (
    <Box sx={styles.getContainerSxProps()}>
      <Box>
        <Box sx={styles.getProductLabelSxProps()}>
          {showQuantityAsText && (
            <ProductQuantity
              quantity={cartProduct.quantity}
              unit={product?.unit}
            />
          )}
          <Typography
            component='p'
            variant='h6'
            sx={styles.getProductNameSxProps()}
          >
            {product?.name[lang]}
          </Typography>
        </Box>
        <ShoppingCartItemDiscounts cartProduct={cartProduct} />
      </Box>

      {showQuantitySelector && (
        <QuantitySelector
          sx={styles.getQuantitySelectorSxProps(cartProduct.is_coupon || cartProduct.is_loyalty)}
          quantity={cartProduct.quantity}
          onUpdate={handleUpdateQuantity}
          unitInterval={unitInterval}
          unit={product?.unit}
          min={min}
          max={max}
        />
      )}

      {showPrice && (
        <Box
          component='span'
          sx={styles.getPriceContainerSxProps()}
        >
          <Price
            price={calculateToTalPrice()}
            displayZeroAsFree
          />
        </Box>
      )}

      {showDeleteButton && (
        <IconButton
          onClick={handleRemoveProductOfCart}
          sx={styles.getDeleteButtonSxProps()}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      )}

      {showIngredientsAndOptions && (
        <Box
          sx={styles.getModifiersSxProps(numberOfElementDisplayed())}
        >
          <CartItemModifierGroups
            cartProduct={cartProduct}
            displayTitles={false}
          />
          {cartProduct.comment !== '' && (
            <Typography
              variant='body2'
              sx={{
                ml: 1,
                fontStyle: 'italic'
              }}
            >
              {cartProduct.comment}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ShoppingCartItem
