import { useEffect } from 'react'
import { useSetEoState } from '~/src/hooks/useEoState'
import { CATEGORY_ID_STATE } from '~/src/stores/categories'
import { PRODUCT_ID_STATE } from '~/src/stores/product'
import { useParams } from 'react-router-dom'

const useRouterSlugEffect = () => {
  const params = useParams()
  const setCategoryId = useSetEoState(CATEGORY_ID_STATE)
  const setProductId = useSetEoState(PRODUCT_ID_STATE)

  useEffect(() => {
    setCategoryId(params.catId || null)
    setProductId(params.productId || null)
  }, [ params ])
}

export default useRouterSlugEffect
