import Price from '~/src/components/Price'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_SUB_TOTAL_SELECTOR } from '~/src/stores/cart'

const CartTotalPrice = () => {
  const total = useEoValue(CART_SUB_TOTAL_SELECTOR)

  return (
    <Price price={total} />
  )
}

export default CartTotalPrice
