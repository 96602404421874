import { ReactNode } from 'react'
import DeliverooIcon from '~/src/components/marketplace/order-buttons/DeliverooIcon'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  href: string,
  children: ReactNode,
}

const DeliverooOrderButton = ({ children, href }: Props) => {
  return (
    <a href={href} target="_blank">
      <Button
        fullWidth
        sx={{
          backgroundColor: 'var(--deliveroo-color)',
          color: 'white',
          py: 1.25,
          textTransform: 'none',
          svg: {
            '> *': {
              fill: 'white'
            }
          },
          ':hover': {
            color: 'var(--deliveroo-color)',
            svg: {
              '> *': {
                fill: 'var(--deliveroo-color)'
              }
            }
          }
        }}
        startIcon={<DeliverooIcon />}
      >
        {children}
      </Button>
    </a>
  )
}

export default DeliverooOrderButton
