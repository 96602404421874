import { useEoValue } from '~/src/hooks/useEoState'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useFirmLanguages } from '~/src/hooks/firm/useFirmLanguages'
import { useTranslations } from '~/src/hooks/useTranslations'
import { BRAND_STATE } from '~/src/stores/brand'
import RoutesEnum from '~/src/router/enums/routes.enum'

const SelectAnotherShopButton = () => {
  const brand = useEoValue(BRAND_STATE)
  const { selectedLanguage } = useFirmLanguages()
  const { t } = useTranslations()

  if (!brand?.is_multifirm) {
    return null
  }

  return (
    <Button
      className='eo-w-full'
      variant='contained'
      color='primary'
      sx={{
        py: 2,
        px: 1,
        borderRadius: 'var(--btn-border-radius)'
      }}
      href={`/${selectedLanguage?.code}/${RoutesEnum.LOCATOR}`}
    >
      {t('firm.select_another_shop')}
    </Button>
  )
}

export default SelectAnotherShopButton
