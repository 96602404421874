import getSwitchTemplateService from '~/src/components/mui-wrappers/styled/toggle/styles/GetSwitchTemplateService'
import StylesSwitchServiceInterface
  from '~/src/components/mui-wrappers/styled/toggle/styles/StylesSwitchServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetSwitchTemplate = (): StylesSwitchServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getSwitchTemplateService(pageStyle, muiTheme)
}

export default useGetSwitchTemplate
