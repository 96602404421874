import { Address } from '@eo-storefronts/eo-core'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { fetchDeliveryAddressCosts } from '~/src/services/OrderService'
import { CHECKOUT_BUTTON_LOADING_STATE, CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { useSnackbar } from 'notistack'
import { useTranslations } from '~/src/hooks/useTranslations'

const useFetchDeliveryAddressCost = () => {
  const firm = useEoValue(FIRM_SELECTOR)
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)
  const setCheckoutButtonLoading = useSetEoState(CHECKOUT_BUTTON_LOADING_STATE)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslations()

  const fetchDeliveryCost = async (address: Address) => {
    if (!firm) {
      return
    }

    setCheckoutButtonLoading(true)
    let costs: any

    try {
      costs = await fetchDeliveryAddressCosts(firm.id, address)

      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        delivery_method: {
          ...state.delivery_method,
          deliveryFreeFromPrice: costs.free_from_price,
          initialDeliveryCost: costs.price,
          deliveryCost: costs.price,
          minAmount: costs.min_order_price
        }
      }))

    } catch (e: any) {
      enqueueSnackbar(t('orders.address_no_longer_valid'), { variant: 'error' })

      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        delivery_method: {
          ...state.delivery_method,
          address: null
        }
      }))
    }

    setCheckoutButtonLoading(false)

    return costs
  }

  return {
    fetchDeliveryCost
  }
}

export default useFetchDeliveryAddressCost
