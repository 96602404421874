import { Modifier } from '@eo-storefronts/eo-core'
import { createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'

export const MODIFIERS_STATE = createState<Record<string, Modifier>>({
  key: 'modifiersState',
  default: {}
})

export const GROUP_MODIFIERS_SELECTOR = createSelectorFamily<Modifier[], string>({
  key: 'groupModifiersSelectorFamily',
  get: (groupId: string) => ({ get }) => {
    const modifierGroups = get(MODIFIER_GROUPS_STATE)
    const modifiers = get(MODIFIERS_STATE)

    if (!modifierGroups[groupId]) {
      return []
    }

    return modifierGroups[groupId].modifiers.map((modifierId: string) => modifiers[modifierId])
  }
})
