import { useState } from 'react'
import { DeliveryMethodNames, Firm, FormField, HttpError, PickupPoint } from '@eo-storefronts/eo-core'
import { fetchGuestFormFields } from '~/src/services/FirmService'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  error: HttpError | unknown | undefined,
  isLoading: boolean, fields: FormField[] | undefined,
  fetch(deliveryMethod: DeliveryMethodNames, pickupPoint: PickupPoint | null): Promise<FormField[] | undefined>,
}

const useFetchGuestForm = (): ReturnsType => {
  const firm = useEoValue<Firm|null>(FIRM_SELECTOR)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)
  const [ fields, setFields ] = useState<FormField[] | undefined>(undefined)

  const fetch = async (deliveryMethod: DeliveryMethodNames, pickupPoint: PickupPoint | null): Promise<FormField[] | undefined> => {
    if (!firm) {
      return
    }

    setFields(undefined)
    setError(undefined)
    setIsLoading(true)

    try {
      const fields = await fetchGuestFormFields(
        firm.id,
        deliveryMethod,
        deliveryMethod === DeliveryMethodNames.PICKUP ? pickupPoint?.id : undefined
      )

      setFields(fields)
      return Promise.resolve(fields)
    } catch (e: HttpError | unknown) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return { error, isLoading, fields, fetch }
}

export default useFetchGuestForm
