import { DateUtils, DeliveryMethodNames, OpeningHour } from '@eo-storefronts/eo-core'
import { ClockPickerView } from '@mui/x-date-pickers'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'

const useTimePickerAvailability = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)

  const isTimeDisabled = (selectedTime: string, timeValue: number, clockType: ClockPickerView): boolean => {
    if (clockType === 'seconds') {
      return false
    }

    let periods: OpeningHour[] = []
    const clockTime: string = timeValue < 10 ? `0${timeValue}` : timeValue.toString()

    if (checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP) {
      periods = [ ...firm!.settings.periods.pickup_hours ]

      if (
        checkoutForm.delivery_method.pickupPoint
        && !checkoutForm.delivery_method.pickupPoint.main_branch
        && !checkoutForm.delivery_method.pickupPoint.use_pickup_hours
      ) {
        periods = [ ...checkoutForm.delivery_method.pickupPoint.periods.pickup_hours ]
      }
    } else if (checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY) {
      periods = [ ...firm!.settings.periods.delivery_hours ]
    }

    const selectedTimeDayId = DateUtils.getDayIdLikeMomentJs(new Date(selectedTime))
    const currentPeriod = periods.find((period: OpeningHour) => period.day_id === selectedTimeDayId)

    if (!currentPeriod) {
      return true
    }

    if (clockType === 'hours') {
      const fromAm = currentPeriod.am?.from_time
      const toAm = currentPeriod.am?.to_time
      const fromPm = currentPeriod.pm?.from_time
      const toPm = currentPeriod.pm?.to_time

      return !DateUtils.timeIsBetween(
        `${clockTime}:00`,
        {
          start: fromAm ? `${fromAm.split(':')[0]}:00` : undefined,
          end: toAm ? `${toAm.split(':')[0]}:00` : undefined
        }
      )
        && !DateUtils.timeIsBetween(
          `${clockTime}:00`,
          {
            start: fromPm ? `${fromPm.split(':')[0]}:00` : undefined,
            end: toPm ? `${toPm.split(':')[0]}:00` : undefined
          }
        )
    }

    if (clockType === 'minutes') {
      const [ hour ] = DateUtils.formatTime(new Date(selectedTime)).split(':')

      return !DateUtils.timeIsBetween(
        `${hour}:${clockTime}`,
        {
          start: currentPeriod.am?.from_time,
          end: currentPeriod.am?.to_time
        }
      )
        && !DateUtils.timeIsBetween(
          `${hour}:${clockTime}`,
          {
            start: currentPeriod.pm?.from_time,
            end: currentPeriod.pm?.to_time
          }
        )
    }

    return true
  }

  return {
    isTimeDisabled
  }
}

export {
  useTimePickerAvailability
}
