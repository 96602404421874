import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import {
  AppleSignInVerifyResponse,
  CreateCustomerResponse,
  Customer,
  CustomerAuthBaseResponse,
  Id,
  SocialLoginType
} from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useContext, useRef, useState } from 'react'
import useSignInWithApple from '~/src/hooks/auth/useSignInWithApple'
import useSignInWithAppleVerify from '~/src/hooks/auth/useSignInWithAppleVerify'
import useCreateCustomer from '~/src/hooks/customer/useCreateCustomer'
import useCustomerCheckEmail from '~/src/hooks/customer/useCustomerCheckEmail'
import useFetchCustomer from '~/src/hooks/customer/useFetchCustomer'
import usePushNotifications from '~/src/hooks/push-notifications/usePushNotifications'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import useGetQueryParam from '~/src/hooks/router/useGetQueryParam'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum, { RoutesEnumType } from '~/src/router/enums/routes.enum'
import { AuthenticationState, IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import useSocialSignInWithBackend from '~/src/hooks/auth/useSocialSignInWithBackend'
import useLinkFirm from '~/src/hooks/customer/useLinkFirm'
import useSignInWithGoogle from '~/src/hooks/auth/useSignInWithGoogle'
import EnvUtils from '~/src/utils/EnvUtils'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'
import useDialog from '~/src/hooks/useDialog'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import useCustomerExtraInfo from '~/src/hooks/customer/useCustomerExtraInfo'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'

interface ReturnsType {
  loading: boolean,
  login(): Promise<void>,
}

const useSocialSignIn = (socialLoginType: SocialLoginType): ReturnsType => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslations()
  const [ loading, setLoading ] = useState<boolean>(false)
  const isUsingNewBrandAuthSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const responsiveDialogContext = useContext(ResponsiveDialogContext)
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)
  const { setAuthState } = useSetAuthState()
  const setCustomer = useSetEoState(CUSTOMER_STATE)
  const { dismiss } = useDialog()
  const { needsExtraInfo } = useCustomerExtraInfo()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { params: redirectTo } = useGetQueryParam(Params.REDIRECT_TO)
  const { createCustomer } = useCreateCustomer()
  const { check: checkCustomerEmail } = useCustomerCheckEmail()
  const { fetch: fetchCustomer } = useFetchCustomer()
  const { login: signInWithApple } = useSignInWithApple()
  const { verify: verifyAppleSignInPayload } = useSignInWithAppleVerify()
  const { initPushNotifications } = usePushNotifications()
  const { signInWithSocial } = useSocialSignInWithBackend()
  const { doLinkFirm } = useLinkFirm()
  const { loginWithGoogle } = useSignInWithGoogle()
  const authStateRef = useRef<Partial<AuthenticationState>>({})

  const _displayError = (message = 'errors.default_error'): undefined => {
    enqueueSnackbar(t(message), { variant: 'error' })

    return
  }

  const _fetchCustomer = async (partialCustomer: Partial<Customer>): Promise<Customer | undefined> => {
    const customer: Customer | undefined = await fetchCustomer(isUsingNewBrandAuthSystem ? partialCustomer.uuid : partialCustomer.id)

    if (!customer) {
      return _displayError()
    }

    setCustomer(customer)

    return customer
  }

  const _saveCustomer = async (customer: Partial<Customer>): Promise<CreateCustomerResponse> => {
    return createCustomer(customer as Customer, true)
  }

  const _setCustomerInAuthState = (id: Id, uuid: string, token: string) => {
    authStateRef.current = {
      guest: undefined,
      customer: {
        id,
        uuid,
        token
      },
      loggedInAs: 'customer'
    }

    return setAuthState(authStateRef.current)
  }

  const _checkEmail = async (email: string, google?: boolean): Promise<CustomerAuthBaseResponse | void> => {
    const checkResponse: CustomerAuthBaseResponse | undefined = await checkCustomerEmail(email, google)

    if (!checkResponse) {
      return _displayError()
    }

    _setCustomerInAuthState(checkResponse.id, checkResponse.uuid, checkResponse.customerToken)

    return checkResponse
  }

  const _checkIfExistOrCreate = async (customer: Partial<Customer>): Promise<{
    id: Id,
    uuid: string | undefined,
  } | undefined> => {
    let customerId: Id
    let customerUUID: Id
    let customerToken: string

    try {
      if (!customer.email) {
        return _displayError()
      }

      const checkEmailResponse: CustomerAuthBaseResponse | void = await _checkEmail(customer.email!)

      if (!checkEmailResponse?.id) {
        return _displayError()
      }

      customerId = checkEmailResponse.id
      customerUUID = checkEmailResponse.uuid
      customerToken = checkEmailResponse.customerToken
    } catch {
      const { id, uuid, customerToken: token } = await _saveCustomer(customer)

      customerId = id
      customerUUID = uuid
      customerToken = token
    }

    _setCustomerInAuthState(customerId, customerUUID, customerToken)

    return {
      id: customerId,
      uuid: customerUUID
    }
  }

  const loginWithApple = async (): Promise<Partial<Customer> | undefined> => {
    const signInWithApplePayload: SignInWithAppleResponse = await signInWithApple()
    const verify: AppleSignInVerifyResponse | undefined = await verifyAppleSignInPayload(signInWithApplePayload)

    if (!verify) {
      return _displayError()
    }

    _setCustomerInAuthState(verify.customer.id, verify.customer.uuid, verify.customerToken)
    setCustomer(verify.customer)

    return {
      id: verify.customer.id,
      uuid: verify.customer.uuid
    }
  }

  const redirectAfterLogin = (customer: Customer) => {
    if (needsExtraInfo(customer)) {
      push(resolve(RoutesEnum.EXTRA_INFO_CUSTOMER))
      return
    }

    if (!redirectTo) {
      push(resolve(fallbackPage))
    } else if (redirectTo === 'ACCOUNT') {
      push(resolve(`${RoutesEnum.PROFILE}/${RoutesEnum.ACCOUNT}`))
    } else {
      push(resolve(RoutesEnum[redirectTo as keyof RoutesEnumType]))
    }
  }

  const login = async () => {
    setLoading(true)

    try {
      let partialCustomer: Partial<Customer> | undefined

      if (EnvUtils.isDeviceWeb) {
        partialCustomer = await signInWithSocial(socialLoginType)
      } else if (socialLoginType === 'apple') {
        partialCustomer = await loginWithApple()
      } else if (socialLoginType === 'google') {
        partialCustomer = await loginWithGoogle()
      } else {
        partialCustomer = await signInWithSocial(socialLoginType)
      }

      // cancel by the user
      if (!partialCustomer) {
        return
      }

      // creation managed by the backend
      if (socialLoginType !== 'apple') {
        const checkedCustomer = await _checkIfExistOrCreate(partialCustomer)
        partialCustomer = {
          ...partialCustomer,
          ...checkedCustomer
        }
      }

      if (partialCustomer.uuid || partialCustomer.id) {
        await doLinkFirm(undefined, authStateRef.current)
        const customer: Customer | undefined = await _fetchCustomer(partialCustomer)

        if (!customer) {
          return _displayError()
        }

        await initPushNotifications(customer.id)
        enqueueSnackbar({ message: `${t('auth.welcome')} ${customer.first_name ?? ''}`, variant: 'success' })

        responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose({
          successfullyLoggedIn: true,
          customer
        })
        dismiss && dismiss('login-form-dialog')
        redirectAfterLogin(customer)
      }
    } catch {
      // Ignore
    } finally {
      setLoading(false)
    }
  }

  return { login, loading }
}

export default useSocialSignIn
