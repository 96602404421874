import { CustomLink, Firm, HttpError } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { fetchCustomLinks } from '~/src/services/FirmService'
import { CUSTOM_LINKS_STATE } from '~/src/stores/custom-links'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  fetch(setStore?: boolean): Promise<CustomLink[] | undefined>,
  data: CustomLink[] | undefined,
  errors: HttpError | unknown | undefined,
  loading: boolean,
}

export const useFetchCustomLinks = (): ReturnsType => {
  const [ data, setData ] = useState<CustomLink[] | undefined>()
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ errors, setErrors ] = useState<HttpError | unknown | undefined>()
  const setCustomLinks = useSetEoState(CUSTOM_LINKS_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)

  const fetch = async (setStore = true): Promise<CustomLink[] | undefined> => {
    if (!firm?.id) {
      return
    }

    setData(undefined)
    setErrors(undefined)
    setLoading(true)

    try {
      const response: CustomLink[] = await fetchCustomLinks(firm.id)

      setData(response)
      setStore && setCustomLinks(response)

      return response
    } catch (e: HttpError | unknown) {
      setErrors(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    fetch,
    data,
    errors,
    loading
  }
}

export default useFetchCustomLinks
