import { useContext } from 'react'
import FirmTitle from '~/src/components/firms/FirmTitle'
import useGetHeaderCurrentFirmTitleTemplate
  from '~/src/components/layout/header/header-current-firm-title/styles/useGetHeaderCurrentFirmTitleTemplate'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'


const HeaderCurrentFirmTitle = () => {
  const styles = useGetHeaderCurrentFirmTitleTemplate()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <FirmTitle
      sx={styles.getTitleSx()}
      onClick={responsiveDialogContext.toggleOpen}
    />
  )
}

export default HeaderCurrentFirmTitle
