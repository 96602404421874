import LayoutLocationsServiceStyleOne from '~/src/pages/category/[slug]/locations/layout/LayoutLocationsServiceStyleOne'
import LayoutLocationsServiceStyleThree
  from '~/src/pages/category/[slug]/locations/layout/LayoutLocationsServiceStyleThree'
import LayoutLocationsServiceStyleTwo from '~/src/pages/category/[slug]/locations/layout/LayoutLocationsServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

const getLocationsLayout = (style: TemplateEnum, getLayoutArgs: GetLayoutArgs): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutLocationsServiceStyleTwo().getLayout()
    case TemplateEnum.STYLE_THREE:
      return new LayoutLocationsServiceStyleThree().getLayout(getLayoutArgs)
    default:
      return new LayoutLocationsServiceStyleOne().getLayout()
  }
}

export default getLocationsLayout
