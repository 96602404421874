import { Box } from '@mui/material'
import DeliveryMethodsSummaryMethodButton
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-method-button'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import DeliveryMethodsSummaryAddressButton
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-address-button'
import useGetDeliveryMethodsSummaryMainButtonsTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-main-buttons/styles/useGetDeliveryMethodsSummaryMainButtonsTemplate'

const DeliveryMethodsSummaryMainButtons = () => {
  const styles = useGetDeliveryMethodsSummaryMainButtonsTemplate()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { method } = checkoutForm.delivery_method

  return (
    <Box {...styles.getContainerProps()}>
      <DeliveryMethodsSummaryMethodButton />
      {method === DeliveryMethodNames.DELIVERY && <DeliveryMethodsSummaryAddressButton />}
    </Box>
  )
}

export default DeliveryMethodsSummaryMainButtons
