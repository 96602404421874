import SearchIcon from '@mui/icons-material/Search'
import { Button, InputAdornment, InputAdornmentProps } from '@mui/material'

interface Props extends InputAdornmentProps {
  buttonMode: boolean,
}

const ProductSearchIconAdornment = ({ buttonMode, sx, ...inputAdornmentProps }: Props) => {
  if (!buttonMode) {
    return (
      <InputAdornment {...inputAdornmentProps} sx={{ p: 1, ...sx }}>
        <SearchIcon />
      </InputAdornment>
    )
  }

  return (
    <InputAdornment {...inputAdornmentProps} sx={sx}>
      <Button
        variant='contained'
        type='submit'
        component='button'
        disableElevation={true}
        sx={{
          p: 1,
          minWidth: 0
        }}
      >
        <SearchIcon />
      </Button>
    </InputAdornment>
  )
}

export default ProductSearchIconAdornment
