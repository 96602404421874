import getChipTemplateService from '~/src/components/mui-wrappers/styled/chip/styles/GetChipTemplateService'
import StylesChipServiceInterface from '~/src/components/mui-wrappers/styled/chip/styles/StylesChipServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetChipTemplate = (): StylesChipServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getChipTemplateService(pageStyle, muiTheme)
}

export default useGetChipTemplate
