import { Day } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import FirmOpeningHour from '~/src/components/firms/FirmOpeningHour'
import FirmPickUpHour from '~/src/components/firms/FirmPickUpHour'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { IS_FIRM_OPEN_FOR_DAY_ID_SELECTOR } from '~/src/stores/firm'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  day: Day,
}

const FirmOpeningHourDay = ({ day }: Props) => {
  const { t } = useTranslations()
  const isOpen = useEoValue(IS_FIRM_OPEN_FOR_DAY_ID_SELECTOR(day.day_id))

  return (
    <Box>
      <Title variant='h6'>{t(`global.${day.name}`)}</Title>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        color: 'inherit'
      }}>
        {!isOpen && <Title variant='subtitle2'>{t('firm.shop_is_closed')}</Title>}
        {isOpen && (
          <>
            <FirmOpeningHour day={day} />
            <FirmPickUpHour day={day} />
          </>
        )}
      </Box>
    </Box>
  )
}

export default FirmOpeningHourDay
