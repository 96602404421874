import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import DeliveryMethodsDateContainer from '~/src/components/delivery-methods/DeliveryMethodsDateContainer'
import DeliveryMethodsCustomDays from '~/src/components/delivery-methods/DeliveryMethodsCustomDays'
import DeliveryMethodsTimeContainer from '~/src/components/delivery-methods/DeliveryMethodsTimeContainer'
import DeliveryMethodsTimeslotsContainer from '~/src/components/delivery-methods/DeliveryMethodsTimeslotsContainer'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_WITH_LOCATOR_STATE_SELECTOR } from '~/src/stores/firm'
import { hasCustomDays, hasOrderTimeslots, mustShowAsapButtonOnly } from '~/src/services/DeliveryTimeService'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import FirmClosed from '~/src/components/firms/FirmClosed'
import CanOnlyOrderDuringOperationalHoursResponsiveModal
  from '~/src/components/error/CanOnlyOrderDuringOperationalHoursResponsiveModal'

const DeliveryMethodsDateTime = () => {
  const firm = useEoValue(FIRM_WITH_LOCATOR_STATE_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { checkAll } = useFirmAcceptsOrder()

  if (!checkAll()) {
    return (
      <>
        <FirmClosed sx={{ mb: 2 }} />
        <CanOnlyOrderDuringOperationalHoursResponsiveModal />
      </>
    )
  }

  if (
    !firm
    || !checkoutForm.delivery_method.method
    || checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
  ) {
    return null
  }

  if (hasCustomDays(firm, checkoutForm.delivery_method.method)) {
    return <DeliveryMethodsCustomDays />
  }

  return (
    <>
      <DeliveryMethodsDateContainer />
      <Box sx={{ mt: 1 }}>
        {hasOrderTimeslots(firm, checkoutForm) && !mustShowAsapButtonOnly(firm, checkoutForm.delivery_method.method) && <DeliveryMethodsTimeslotsContainer />}
        {(!hasOrderTimeslots(firm, checkoutForm) || mustShowAsapButtonOnly(firm, checkoutForm.delivery_method.method)) && <DeliveryMethodsTimeContainer />}
      </Box>
    </>
  )
}

export default DeliveryMethodsDateTime
