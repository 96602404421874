import SwipeableDrawerStyled from '~/src/components/mui-wrappers/styled/drawer'
import { ResponsiveDialogEventType } from '~/src/types/ResponsiveDialogEventType'
import { ResponsiveDialogWrapperProps, SwipeableDrawerOverriddenProps } from '~/src/types/ResponsiveDialogWrapperProps'

const SwipeableDrawerWrapper = ({
  open,
  onOpen,
  onClose,
  anchor = 'bottom',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fullWidth,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxWidth,
  ...otherProps
}: ResponsiveDialogWrapperProps) => (
  <SwipeableDrawerStyled
    open={open}
    anchor={anchor}
    onOpen={($event: ResponsiveDialogEventType) => {
      onOpen && onOpen($event)
    }}
    onClose={($event: ResponsiveDialogEventType) => {
      onClose && onClose($event)
    }}
    {...otherProps as SwipeableDrawerOverriddenProps} />
)

export default SwipeableDrawerWrapper
