import { LayoutState } from '~/src/stores/layout'
import LayoutServiceInterface, { GetLayoutArgs } from '~/src/utils/layout/LayoutServiceInterface'

export default class LayoutProfileServiceStyleTwo implements LayoutServiceInterface {
  public getLocationLayout({ isMobile }: GetLayoutArgs): Partial<LayoutState> {
    return {
      header: {
        display: !isMobile,
        displayMenuBurger: false,
        displayCart: false,
        displayCurrentFirm: true,
        displayDeliveryOptions: false,
        displayLanguagesSelector: false,
        displayUserActions: false,
        displayProductSearch: false,
        displayLocationSearch: false,
        logo: {
          disableLink: false
        },
        title: '',
        type: 'navigation'
      },
      subHeader: {
        display: false,
        variant: 'default'
      },
      content: {
        displayNotification: false
      },
      mainSideBar: {
        display: false,
        displayCart: false,
        displayDeliveryOptions: false,
        displayOrderSummary: false
      },
      footer: {
        display: false,
        displayGoToCheckoutBtn: false,
        displayGoToCartBtn: false,
        displayInformations: false,
        displayLinks: false
      },
      mobileNavigation: {
        display: true
      }
    }
  }

  public getBrandLayout(): Partial<LayoutState> {
    return {
      header: {
        display: true,
        displayMenuBurger: false,
        displayCart: false,
        displayCurrentFirm: false,
        displayLanguagesSelector: false,
        displayDeliveryOptions: false,
        displayUserActions: false,
        displayProductSearch: false,
        displayLocationSearch: false,
        logo: {
          disableLink: false
        },
        title: '',
        type: 'simple'
      },
      subHeader: {
        display: false,
        variant: 'default'
      },
      content: {
        displayNotification: false
      },
      mainSideBar: {
        display: false,
        displayCart: false,
        displayDeliveryOptions: false,
        displayOrderSummary: false
      },
      footer: {
        display: false,
        displayGoToCartBtn: false,
        displayGoToCheckoutBtn: false,
        displayInformations: false,
        displayLinks: false
      },
      mobileNavigation: {
        display: false
      }
    }
  }

  public getLayout(args: GetLayoutArgs): Partial<LayoutState> {
    const { firm } = args

    if (firm?.is_multifirm || firm?.is_brand) {
      return this.getBrandLayout()
    }

    return this.getLocationLayout(args)
  }
}
