import { Address, Customer } from '@eo-storefronts/eo-core'
import { fetchCustomerAddresses } from '~/src/services/CustomerService'

interface ReturnsType {
  fetch(customer: Customer): Promise<Address[] | undefined>,
}

const useFetchCustomerAddresses = (): ReturnsType => {
  const fetch = async (customer: Customer): Promise<Address[] | undefined> => {
    if (!customer?.id || !customer?.uuid) {
      return
    }

    return fetchCustomerAddresses(customer.id, customer.uuid)
  }

  return { fetch }
}

export default useFetchCustomerAddresses
