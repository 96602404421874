import FirmResolver from '~/src/router/utils/FirmResolver'
import AppUtils from '~/src/utils/AppUtils'
import { EoContainer, IndexDBService, LocationToFirm } from '@eo-storefronts/eo-core'
import LanguageResolver from '~/src/router/utils/LanguageResolver'
import MarketplaceDefaultPage from '~/src/router/utils/default-page-resolver/MarketplaceDefaultPage'
import FirmDefaultPage from '~/src/router/utils/default-page-resolver/FirmDefaultPage'

const AppInjector = new EoContainer({ skipBaseClassChecks: true })
AppInjector.bind(IndexDBService).to(IndexDBService).inSingletonScope()
AppInjector.bind(FirmResolver).to(FirmResolver)
AppInjector.bind(AppUtils).to(AppUtils).inSingletonScope()
AppInjector.bind(LanguageResolver).to(LanguageResolver).inSingletonScope()
AppInjector.bind(LocationToFirm).to(LocationToFirm).inSingletonScope()
AppInjector.bind(MarketplaceDefaultPage).to(MarketplaceDefaultPage).inSingletonScope()
AppInjector.bind(FirmDefaultPage).to(FirmDefaultPage).inSingletonScope()

export {
  AppInjector
}
