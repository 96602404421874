import RoundedLinearProgress from '~/src/components/mui-wrappers/styled/rounded-linear-progress'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'

const FirmLoyaltyRewardProgress = () => {
  const loyaltyService = useLoyaltyService()

  return (
    <RoundedLinearProgress
      variant='determinate'
      value={loyaltyService.getCurrentPointsPercentage()}
    />
  )
}

export default FirmLoyaltyRewardProgress
