import useSetCustomerState from '~/src/hooks/customer/useSetCustomerState'
import { useEffect } from 'react'

const useCustomerFetchEffect = () => {
  const { setCustomer } = useSetCustomerState()

  useEffect(() => {
    void setCustomer()
  }, [ setCustomer ])
}

export default useCustomerFetchEffect
