import { NavigationThemeFlowEnum, TemplateEnum } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { STYLES_STATE } from '~/src/stores/styles'
import { TemplatePages, Templates } from '~/src/types/Templates'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import { useLocation } from 'react-router-dom'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'
import MarketplaceDefaultPage from '~/src/router/utils/default-page-resolver/MarketplaceDefaultPage'
import FirmDefaultPage from '~/src/router/utils/default-page-resolver/FirmDefaultPage'
import { useMemo } from 'react'

const useGetPageStyle = (whichPageToResolve: TemplatePages | 'auto' = 'auto'): TemplateEnum | null => {
  const { pathname } = useLocation()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)
  const isOnMarketplacePage: boolean = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)
  const styles = useEoValue<Templates | null>(STYLES_STATE)

  const _getDefaultPage = useMemo((): TemplatePages => {
    if (isOnMarketplacePage) {
      return AppInjector.get(MarketplaceDefaultPage).resolve(pathname)
    }

    if (whichPageToResolve === 'auto') {
      return AppInjector.get(FirmDefaultPage)
        .resolve(
          pathname,
          [
            {
              key: 'navigate-by-product',
              pass: firmTheme?.flow?.product_list_navigation === NavigationThemeFlowEnum.PRODUCTS
            },
            {
              key: 'navigate-by-categories',
              pass: firmTheme?.flow?.product_list_navigation === NavigationThemeFlowEnum.CATEGORIES
            }
          ]
        )
    }

    return whichPageToResolve
  }, [ pathname, firmTheme ])

  if (!styles) {
    return null
  }

  return styles.pages[_getDefaultPage]
}

export default useGetPageStyle
