import LayoutLocatorServiceStyleOne from '~/src/pages/locator/layout/LayoutLocatorServiceStyleOne'
import LayoutLocatorServiceStyleTwo from '~/src/pages/locator/layout/LayoutLocatorServiceStyleTwo'
import { LayoutState } from '~/src/stores/layout'
import { DeepPartial } from '~/src/types/DeepPartial'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorLayout = (style: TemplateEnum): DeepPartial<LayoutState> => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new LayoutLocatorServiceStyleTwo().getLayout()
    default:
      return new LayoutLocatorServiceStyleOne().getLayout()
  }
}

export default getLocatorLayout
