import { TypographyProps } from '@mui/material/Typography/Typography'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const FirmTitle = ({ ...otherProps }: TypographyProps) => {
  const firm = useEoValue(FIRM_SELECTOR)

  return (
    <Title
      variant='h6'
      {...otherProps}
    >
      {firm && firm.name}
    </Title>
  )
}

export default FirmTitle
