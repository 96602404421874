import { UNSAFE_RouteContext, useLocation } from 'react-router-dom'
import { ReactElement, Suspense, useContext, useEffect, useState } from 'react'

const AppOutlet = () => {
  const location = useLocation()
  const context = useContext(UNSAFE_RouteContext)
  const [ outlet, setOutlet ] = useState<ReactElement | null>(context.outlet)

  useEffect(() => {
    if (location.state?.modal) {
      return
    }

    setOutlet(context.outlet)
  }, [ location ])

  return (
    <Suspense>
      {location.state?.modal ? outlet : context.outlet}
    </Suspense>
  )
}

export default AppOutlet
