import { DateUtils, Firm, OpeningHour, Period } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'
import {
  isBeforeAmOpeningHours,
  isBeforePmOpeningHours,
  isBetweenAmOpeningHours,
  isBetweenPmOpeningHours,
  isClosed,
  isOffline,
  isOnHoliday,
  isOpenAM
} from '~/src/services/OpeningHourService'
import { OpeningTimeState } from '~/src/types/OpeningTimesStates'

interface ReturnsType {
  state: OpeningTimeState | undefined,
  sentence: string,
}

const useFirmOpeningTimeStateAndSentence = (location: Firm): ReturnsType => {
  const { t } = useTranslations()
  const [ openingState, setOpeningState ] = useState<OpeningTimeState | undefined>()
  const [ openingTimeSentence, setOpeningTimeSentence ] = useState<string>(t('firms.info_opening'))

  useEffect(() => {
    const period: Period | {} = 'periods' in location.settings ? location.settings.periods : {}
    const openingHours = 'opening_hours' in period ? period.opening_hours : []
    const holidayPeriods = 'holiday_period' in period ? period.holiday_period : []
    const now = new Date()

    if (isOffline(location)) {
      setOpeningTimeSentence(t('firm.offline'))
      setOpeningState('offline')

      return
    }

    if (isOnHoliday(now, holidayPeriods)) {
      setOpeningTimeSentence(t('firm.on_holiday'))
      setOpeningState('on_holiday')

      return
    }

    const currentDayId = DateUtils.getDayIdLikeMomentJs(now)
    const currentDayPeriod = openingHours?.find(
      (openingHour: OpeningHour) => openingHour.day_id === currentDayId
    )

    if (!currentDayPeriod) {
      return
    }

    if (isBeforeAmOpeningHours(now, currentDayPeriod)) {
      setOpeningTimeSentence(`${t('firm.opens_at')} ${currentDayPeriod.am!.from_time}`)
      setOpeningState('closed')

      return
    }

    if (isBetweenAmOpeningHours(now, currentDayPeriod)) {
      setOpeningTimeSentence(`${t('firm.opened_until')} ${currentDayPeriod.am!.to_time}`)
      setOpeningState('open')

      return
    }

    if (isBeforePmOpeningHours(now, currentDayPeriod)) {
      setOpeningTimeSentence(`${t('firm.opens_at')} ${currentDayPeriod.pm!.from_time}`)
      setOpeningState('closed')

      return
    }

    if (isBetweenPmOpeningHours(now, currentDayPeriod)) {
      setOpeningTimeSentence(`${t('firm.opened_until')} ${currentDayPeriod.pm!.to_time}`)
      setOpeningState('open')

      return
    }

    let counter = 1
    let nextDayId = currentDayId

    do {
      nextDayId = nextDayId + 1 > 7 ? 1 : nextDayId + 1

      const nextDayPeriod = openingHours?.find(
        (openingHour: OpeningHour) => openingHour.day_id === nextDayId
      )

      if (!nextDayPeriod || isClosed(nextDayPeriod)) {
        counter++
        continue
      }

      if (isOpenAM(nextDayPeriod)) {
        setOpeningTimeSentence(`${t('firm.opens_on')} ${nextDayPeriod.day} ${nextDayPeriod.am!.from_time}`)
        setOpeningState('closed')

        break
      }

      setOpeningTimeSentence(`${t('firm.opens_on')} ${nextDayPeriod.day} ${nextDayPeriod.pm!.from_time}`)
      setOpeningState('closed')

      break
    } while (counter < 7)
  }, [ t ])

  return {
    state: openingState,
    sentence: openingTimeSentence
  }
}

export {
  useFirmOpeningTimeStateAndSentence
}
