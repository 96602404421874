import {
  CheckoutOption,
  DeliveryMethod,
  DeliveryMethodNames,
  Firm,
  OpeningHour,
  ServiceFee,
  Theme
} from '@eo-storefronts/eo-core'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { isClosed } from '~/src/services/OpeningHourService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { BRAND_STATE } from '~/src/stores/brand'
import { LOCATOR_STATE } from '~/src/stores/locator'
import { CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR } from '~/src/stores/cart'
import NumberUtils from '~/src/utils/NumberUtils'

export const SELECTED_SHOP_STATE = createState<string>({
  key: 'selectedShop',
  default: ''
})

export const FIRM_SELECTOR = createSelector<Firm | null>({
  key: 'firmSelector',
  get: ({ get }): Firm | null => {
    const brand = get<Firm|null>(BRAND_STATE)
    const selectedShop = get<string>(SELECTED_SHOP_STATE)

    if (!brand?.is_multifirm) {
      return brand
    }

    if (selectedShop !== '') {
      return brand.locations?.find((location: Firm) => location.sitename === selectedShop) || null
    }

    return brand
  }
})

export const FIRM_WITH_LOCATOR_STATE_SELECTOR = createSelector<Firm | null>({
  key: 'firmWithLocatorStateSelector',
  get: ({ get }): Firm | null => {
    const firm = get(FIRM_SELECTOR)
    const { firmSelected } = get(LOCATOR_STATE)

    return firmSelected ?? firm
  }
})

export const FIRM_THEME_SELECTOR = createSelector<Theme | undefined>({
  key: 'firmThemeSelector',
  get: ({ get }): Theme | undefined => {
    return get<Firm | null>(FIRM_SELECTOR)?.settings.theme
  }
})

export const GET_ACTIVE_SERVICE_FEES_SELECTOR = createSelector<ServiceFee[]>({
  key: 'getActiveServiceFeesSelector',
  get: ({ get }) => {
    const firm: Firm | null = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const checkout: CheckoutFormState = get(CHECKOUT_FORM_STATE)

    if (!firm?.settings.service_fee) {
      return []
    }

    const activeFees: ServiceFee[] = firm.settings.service_fee.filter((serviceFee: ServiceFee) => serviceFee.active)

    if (checkout.delivery_method.pickupPoint) {
      return activeFees.filter((serviceFee: ServiceFee) =>
        serviceFee.firm_pickup_point_id === checkout.delivery_method.pickupPoint?.id
        || !serviceFee.firm_pickup_point_id
      )
    }

    return activeFees
  }
})

export const GET_TOTAL_AMOUNT_OF_SERVICE_FEES_SELECTOR = createSelector<number>({
  key: 'getTotalAmountOfServiceFeesSelector',
  get: ({ get }) => {
    const activeServiceFee: ServiceFee[] = get(GET_ACTIVE_SERVICE_FEES_SELECTOR)
    const subTotal = get(CART_SUBTOTAL_WITH_DISCOUNTS_SELECTOR)

    if (!activeServiceFee) {
      return 0
    }

    return activeServiceFee.reduce((accumulator: number, current: ServiceFee) => {
      if (current.free_from && subTotal >= current.free_from_amount) {
        return accumulator
      }

      if (current.fee_choice === 'percentage') {
        return accumulator + NumberUtils.getPercentageValue(subTotal, current.percentage)
      }

      return accumulator + current.fee
    }, 0)
  }
})

export const GET_FIRM_CHECKOUT_OPTIONS_SELECTOR = createSelector<CheckoutOption[]>({
  key: 'getFirmCheckoutOptionsSelector',
  get: ({ get }) => {
    const firm: Firm | null = get(FIRM_WITH_LOCATOR_STATE_SELECTOR)

    if (!firm?.settings.checkout_options) {
      return []
    }

    return firm.settings.checkout_options
  }
})

export const IS_FIRM_OPEN_FOR_DAY_ID_SELECTOR = createSelectorFamily({
  key: 'isFirmOpenForDayIdSelector',
  get: (day_id: number) => ({ get }) => {
    const firm = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const openingHour = firm?.settings.periods?.opening_hours?.find((openingHour: OpeningHour) => openingHour.day_id === day_id)

    return openingHour && !isClosed(openingHour)
  }
})

export const IS_DELIVERY_METHOD_ENABLED_FOR_FIRM = createSelector({
  key: 'isDeliveryMethodEnabledForFirm',
  get: ({ get }) => {
    const firm = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)
    const checkoutForm = get<CheckoutFormState>(CHECKOUT_FORM_STATE)

    if (!firm) {
      return false
    }

    return Object.keys(firm.settings.delivery_methods).includes(checkoutForm.delivery_method.method as DeliveryMethodNames)
  }
})

export const IS_ALL_DELIVERY_METHODS_INACTIVE_FOR_FIRM = createSelector({
  key: 'isAllDeliveryMethodsInactiveFormFirm',
  get: ({ get }) => {
    const firm = get<Firm | null>(FIRM_WITH_LOCATOR_STATE_SELECTOR)

    if (!firm) {
      return false
    }

    return Object.values(firm.settings.delivery_methods).every((deliveryMethod: DeliveryMethod) => deliveryMethod?.temporary_inactive)
  }
})
