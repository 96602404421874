import { createContext } from 'react'

interface SwipeAbleDrawerContextInterface {
  toggle: (() => void) | null,
}

const SwipeAbleDrawerContext = createContext<SwipeAbleDrawerContextInterface>({
  toggle: null
})

export default SwipeAbleDrawerContext
export type { SwipeAbleDrawerContextInterface }
