import { SocialLoginType } from '@eo-storefronts/eo-core'
import LoadingButton, { loadingButtonClasses } from '@mui/lab/LoadingButton'
import { buttonClasses } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'
import { ReactNode } from 'react'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props extends ButtonProps {
  socialType: SocialLoginType,
  children: ReactNode,
  loading: boolean,
}

const SocialLoginBtn = ({ loading, socialType, children, sx, ...btnProps }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <LoadingButton
      variant='contained'
      color='inherit'
      loading={loading}
      sx={{
        transition: 'var(--ease-in-out)',
        borderRadius: 'var(--btn-border-radius)',
        backgroundColor: `var(--${socialType}-color)`,
        border: `solid 0.5px var(--${socialType}-color)`,
        color: 'white',
        boxShadow: muiTheme.elevation,
        textTransform: 'inherit',
        fontWeight: 'bold',
        fontSize: '1em',
        [`&.${loadingButtonClasses.loading}`]: {
          borderColor: 'inherit',
          [`.${buttonClasses.startIcon}`]: {
            svg: {
              display: 'none'
            }
          }
        },
        [`&:not(.${loadingButtonClasses.loading}):hover`]: {
          borderColor: muiTheme.palette.getContrastText((muiTheme.palette as any).background.main),
          backgroundColor: 'background.main',
          color: muiTheme.palette.getContrastText((muiTheme.palette as any).background.main),
          svg: {
            path: {
              fill: muiTheme.palette.getContrastText((muiTheme.palette as any).background.main)
            }
          }
        },
        ...sx
      }}
      {...btnProps}
    >
      {children}
    </LoadingButton>
  )
}

export default SocialLoginBtn
