import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button } from '@mui/material'
import useGetDeliveryMethodsSummaryEditButtonTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-edit-button/styles/useGetDeliveryMethodsSummaryEditButtonTemplate'

const DeliveryMethodsSummaryEditButton = () => {
  const styles = useGetDeliveryMethodsSummaryEditButtonTemplate()

  return (
    <Button {...styles.getButtonProps()}>
      <EditOutlinedIcon {...styles.getEditOutlinedIconProps()} />
    </Button>
  )
}

export default DeliveryMethodsSummaryEditButton
