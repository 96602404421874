import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { useCallback, useEffect } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { firmSupportDeliveryMethod, getFirstFirmSupportedDeliveryMethod } from '~/src/services/FirmService'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const usePreselectDeliveryMethod = (): void => {
  const [ checkout, setCheckout ] = useEoState(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)

  const _checkMethod = useCallback((method: DeliveryMethodNames): boolean => {
    if (firm && !firmSupportDeliveryMethod(firm, method)) {
      return false
    }

    setCheckout((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        method
      }
    }))

    return true
  }, [ firm ])

  const _selectFirstMethod = useCallback(() => {
    const method: DeliveryMethodNames | undefined = getFirstFirmSupportedDeliveryMethod(firm)

    if (!method) {
      return
    }

    setCheckout((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        method
      }
    }))
  }, [ firm ])

  useEffect(() => {
    if (!!checkout.delivery_method.method || !firm) {
      return
    }

    _checkMethod(DeliveryMethodNames.PICKUP)
    || _checkMethod(DeliveryMethodNames.DELIVERY)
    || _selectFirstMethod()
  }, [ checkout, firm ])
}

export {
  usePreselectDeliveryMethod
}
