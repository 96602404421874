import { ReactNode } from 'react'
import DeliveryMethods from '~/src/components/delivery-methods/DeliveryMethods'
import DeliveryMethodsDialogActions from '~/src/components/delivery-methods/DeliveryMethodsDialogActions'
import DeliveryMethodsTitle from '~/src/components/delivery-methods/DeliveryMethodsTitle'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { paperClasses } from '@mui/material'

interface Props {
  activator: ReactNode,
}

const DeliveryMethodsResponsiveDialog = ({ activator }: Props) => {
  return (
    <ResponsiveDialog
      Title={<DeliveryMethodsTitle />}
      Actions={<DeliveryMethodsDialogActions />}
      activator={activator}
      keepMounted={true}
      modalType='DELIVERY_METHODS'
      ModalProps={{
        keepMounted: true,
        id: 'delivery-methods-dialog'
      }}
      maxWidth='sm'
      fullWidth
      sx={{
        [`.${paperClasses.root}`]: {
          bgcolor: 'secondary.main',
          color: 'secondary.contrastText'
        }
      }}
    >
      <DeliveryMethods />
    </ResponsiveDialog>
  )
}

export default DeliveryMethodsResponsiveDialog
